.credentials-section {
  background-color: $wcm-bg-gray;
  .section-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;

    > .section-row {
      > div:not(:last-child) {
        margin-bottom: 2rem;
      }

      @include breakpoint($md) {
        display: flex;
        flex-wrap: wrap;

        > div:not(:last-child) {
          margin-bottom: unset;
        }
      }
    }
  }

  &__education,
  &__appointments {
    @include make-xs-column(12);
    @include make-md-column(6);

    @include breakpoint($md) {
      display: flex;
      flex-direction: column;
    }

    .column-wrapper {
      background: $wcm-white;
      border: 1px solid $wcm-border-gray;
      padding: 2rem;

      @include breakpoint($md) {
        padding: 3rem 8rem;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }

      .cta-icon {
        width: 100%;
        height: 3rem;
      }
    }

    .field-collection-container {
      margin: 0;
      border: 0;

      .field-label {
        text-align: center;
      }

      > div[class^="field-"] {
        text-align: center;

        > .field-content-items {
          display: inline-block;
          text-align: left;
          padding-left: 2rem;
          font-size: 1.5rem;
          color: $wcm-med-gray;
          list-style: none;

          > .field-content-item {
            .field-collection-view {
              display: inline-block;
              vertical-align: middle;
              padding: 0;
              margin: 1rem 0;
              border: 0;

              .entity-field-collection-item {
                .content {
                  div {
                    display: inline;
                  }

                  > div ~ div::before {
                    content: ", ";
                  }
                }
              }
            }
          }
        }
      }
    }
  }
} // .credentials-section