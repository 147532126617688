// Insurance section
.insurance-accepted-section {
  background-color: $wcm-bg-gray;
  padding-bottom: 2rem;
  padding-top: 20px;

  .field-collection-container {
    @include make-xs-column(12);
    border: 0;

    .field-label {
      font-size: 2rem;
      color: $wcm-red;
      text-transform: capitalize;

      @include breakpoint($md) {
        font-size: 3rem;
      }
    }

    .field-provider-insurances {
      .insurance-asterisk {
        margin-bottom: 20px;

        span {
          font-size: 23px;
          color: $wcm-red;
          font-family: $cornell-sans-regular;
          position: relative;
          top: 7px;
          margin-right: 5px;
        }
      }

      .see-insurances-trigger {
        font-size: 1.4rem;

        @include breakpoint($md) {
          display: none;
        }

        &:after {
          @include fontello();
          content: " \e80f";
          padding-left: 0.4rem;
          color: $wcm-dark-orange;
        }
      }

      .field-content-items {
        @include make-row();
        display: none; // hide initially for mobile

        @include breakpoint($md) {
          display: block;
        }

        .column {
          @include make-xs-column(12);
          @include make-md-column(4);

          .field-content-item {
            background: #fff;
            margin-bottom: 1.6rem;

            input {
              display: none;
            }

            label {
              transition: 0.2s ease;
              padding: 1.7rem 4rem 1.7rem 3.5rem;
              font-size: 1.6rem;
              margin-bottom: 0;
              position: relative;
            }

            label.is-closed:before {
              content: "*";
              font-size: 23px;
              color: $wcm-red;
              font-family: $cornell-sans-regular;
              position: absolute;
              left: 15px;
              top: 24px;
            }

            &.children {
              label {
                position: relative;
                cursor: pointer;

                &::after {
                  content: "+";
                  font-weight: bold;
                  position: absolute;
                  right: 1.5rem;
                  font-size: 3rem;
                  color: $wcm-dark-orange;
                  padding-left: 1.5rem;
                  width: 4rem;
                }
              }

              &:hover {
                label {
                  color: $wcm-dark-orange;
                }
              }
            }

            .content {
              padding-left: 7px;
              list-style: none;
              padding-bottom: 10px;

              li {
                margin-bottom: 10px;
                position: relative;

                &.is-closed span {
                  font-size: 23px;
                  color: $wcm-red;
                  font-family: $cornell-sans-regular;
                  position: absolute;
                  left: -17px;
                  top: 7px;
                }
              }
            }

            input + label + .content {
              display: none;
              height: 0;
              font-size: 0;
              transition: 0.25s ease;
              border-top: 1px solid $wcm-border-gray;
              margin: 0 30px;
              padding-top: 20px;
            }

            input:checked + label + .content {
              display: block;
              height: auto;
              font-size: unset;
              transition: 0.25s ease;
              font-size: 15px;
            }

            &.children {
              input:checked + label {
                &::after {
                  content: "–";
                }
              }
            }
          }
        }
      }
    }
  }
} // end insurance section
