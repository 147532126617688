.external-relationships-section {
  .section-row {
    > div[class^="field-"] {
      @include make-xs-column(12);

      .field-label {
        text-transform: capitalize;
        margin-bottom: 0;

        .tooltip-icon {
          position: relative;

          i {
            position: absolute;
            left: 40%;
            margin-left: -12px;
            top: -2.3rem;
            width: 24px;
            height: 12px;
            overflow: hidden;
            display: none;
            z-index: 9999;

            &::after {
              content: "";
              position: absolute;
              width: 1.5rem;
              height: 1.5rem;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              background-color: $wcm-white;
              border: 1px solid $wcm-border-gray;
              box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.5);
            }
          }

          &::after {
            content: attr(aria-description);
            display: none;
          }

          &:hover::after,
          &:focus::after {
            /* Show the tooltip on hover and focus */
            display: block;
            position: absolute;
            z-index: 999;
            top: -20px;
            left: 10%;
            min-width: 2000%;
            max-width: 3000%;
            transform: translate(-5%, -100%);
            background-color: $wcm-white;
            padding: 1.5rem 2rem;
            font-size: 1.4rem;
            color: $wcm-dark-gray;
            font-weight: normal;
            transition: all 0.2s ease-in-out;
            border: 0.3rem solid $wcm-border-gray;
            border-radius: 2rem;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            text-align: left;
          }

          &:hover{
            i{
              display: block;
            }
          }
        }
      }

      .field-content-items {
        margin-bottom: 2rem;
        padding-left: 2.2rem;
        padding-top: 1rem;
      }

      @include breakpoint($md) {
        > * {
          vertical-align: top;
        }

        .field-label {
          margin: 0;
          min-width: 25%;
          position: relative;

          .tooltip-icon {
            &:hover::after,
            &:focus::after {
              left: 40%;
              transform: translate(-20%, -100%);
            }
          }
        }
      }
    }
  }
} // .external-relationships-section