/* =Base partial for shared variables and mixin library
----------------------------------------------------------------------------------*/

/* =Base Grid
----------------------------------------------------------------------------------*/

// Specify the number of columns and set column and gutter widths
$columns: 12;
$column-width: 60;
$gutter-width: 0;

// Utility function you should never need to modify this
@function gridsystem-width($columns:$columns) {
  @return ($column-width * $columns) + ($gutter-width * $columns);
}

$total-width: gridsystem-width($columns);

// Enable sub-pixel fix for IE6 & 7. See http://tylertate.com/blog/2012/01/05/subpixel-rounding.html
$min-width: 999999;
$correction: 0.5 / $min-width * 100;

// Remove the definition below for a pixel-based layout
$total-width: 100%;

/* =Grid Functions
----------------------------------------------------------------------------------*/

// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  *zoom:1;

  &:before,
  &:after {
    content:"";
	display:table;
  }
  &:after {
    clear:both;
  }
}

body {
	width: 100%;
	@include clearfix();
}

@mixin row($columns:$columns) {
	display: block;
	width: $total-width*(($gutter-width + gridsystem-width($columns))/gridsystem-width($columns));
	margin: 0 $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1);
	// *width: $total-width*(($gutter-width + gridsystem-width($columns))/gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1)-$correction;
	@include clearfix();
}

@mixin column($x,$columns:$columns) {
	display: inline;
	float: left;
	width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns));
	margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns));
	// *width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns))-$correction;
}

@mixin push($offset:1) {
	margin-left: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}

@mixin pull($offset:1) {
	margin-right: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}

@mixin neg($offset:1) {
	margin-left: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)*-1) - $total-width*(($gutter-width*.5)/gridsystem-width($columns)*-1);
}

//http://css-tricks.com/fluid-width-equal-height-columns/
@mixin equal-heights() {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

/* =Breakpoints
----------------------------------------------------------------------------------*/

$desktop: 970px;
$tablet: 768px;

/* =Sass Mixins
----------------------------------------------------------------------------------*/

@mixin font-size($size:1.6, $line: $size * 1.5){
  font-size:   ($size * 10) + px;
  line-height: ($line * 10) + px;
  font-size:   $size + rem;
  line-height: $line + rem;
}

@mixin box-sizing() {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
}

@mixin border-radius($radius: 8px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false) {
 $params: $shadow1;
  @if $shadow2
    { $params: $shadow1, $shadow2; }
    @if $shadow3 != false
      { $params: $shadow1, $shadow2, $shadow3; }
      @if $shadow4 != false
        { $params: $shadow1, $shadow2, $shadow3, $shadow4; }
        @if $shadow5 != false
          { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5; }

  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

@mixin transition($vars) {
	-webkit-transition: $vars;
	-moz-transition: $vars;
	-ms-transition: $vars;
	-o-transition: $vars;
	transition: $vars;
}
