// Header Nav section
.header-nav-section {
  display: none;

  @include breakpoint($md) {
    display: block;
    margin-top: -3rem !important;
  }

  .section-row {
    box-shadow: 0 0.5rem 0.25rem -0.25rem $wcm-border-gray;
    > .btn-wrapper {
      @include make-xs-column(12);
      @include make-md-column(4);
      border: 1px solid $wcm-border-gray;
      background-color: $wcm-white;

      &:hover,
      &.active {
        color: $wcm-dark-orange;
      }

      .btn {
        color: #555;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding-top: 14px;
        padding-bottom: 14px;

        &:after {
          content: " ";
        }
      }

      @include breakpoint($md) {
        .btn {
          display: block;
        }
      }
    }
  }
}
