.locations-appointments-section {
  padding: 20px;

  .title-tag-wrapper {
    @include breakpoint($sm-only) {
      margin: 0 10px;

      h2 {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .loading {
    clear: both;
    margin: 80px 0;
    padding-top: 10px;
    text-align: center;
  }

  .locations-map-wrapper {
    padding: 15px;
    display: flex;
    flex-direction: row;

    @include breakpoint($sm) {
      padding: 30px;
    }
  }

  .locations-wrapper {
    border: 1px solid #e6e6e6;
    clear: both;
    margin-top: 15px;

    .title-area {
      background: #f7f7f7;
      float: left;
      width: 100%;
      display: none;

      @include breakpoint($sm) {
        display: block;
      }
    }

    .title-wrapper {
      padding: 35px 25px 40px;
      display: block;
      float: left;

      .cta-icon {
        display: block;
        width: 25px;
        height: 25px;
        margin: 0 auto;
      }

      span {
        display: block;
        padding-top: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .location-info {
      background: #fff;
      color: #555;
    }

    .patient-portal {
      color: $wcm-dark-orange;
    }

    .react-locations-wrapper {
      clear: both;

      .location-listing-container {
        margin-bottom: 30px;

        @include breakpoint($sm) {
          margin-bottom: 50px;
        }

        .location-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 15px;
          line-height: 2.4rem;

          a{
            text-decoration: underline;
            border-bottom: none;
            color: $wcm-red;
          }
        }

        .address-line-1 {
          font-size: 15px;
          color: #555;
          margin-bottom: 2px;
        }

        .directions-wrapper {
          margin-bottom: 25px;

          a {
            color: $wcm-red;
          }
        }

        .phone {
          span {
            display: block;
            font-size: 16px;
            color: $wcm-black;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .telephone-number {
            font-size: 15px;

            &::before {
              display: inline-block;
              content: '';
              background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/mobile.svg');
              background-size: contain;
              vertical-align: top;
              width: 2rem;
              height: 2rem;
              margin-right: .5rem;
            }

            a {
              color: $wcm-red;
            }
          }
        }
      }

      .call-to-action-wrapper {

        .cta-button-inner {
          text-align: center;
        }

        .cta-container {
          margin-top: 40px;
        }

        .cta-button {
          border-radius: 5px;
          padding: 15px 60px;
          font-size: 14px;
          font-weight: 700;
          height: unset;

          @include breakpoint($sm-only) {
            width: 100%;
          }
        }

        .direct-scheduling-cta {
          margin-top: 10px;
          text-align: center;

          a {
            color: $wcm-red;
          }
        }

        .phone-cta {
          .cta-button {
            padding: 15px 30px;
          }
        }
      }
    }
  }

  .google-map-react-wrapper {
    display: none;

    @include breakpoint($sm) {
      display: block;
    }
  }

  .location-listing, .video-visit-listing {
    border-top: none;
  }

  .disable-message {
    p{
      font-size: 1.3rem;
      line-height: 2.0rem;
    }
  }

  .provider-profile-wrapper{
    .header-pane{
      #info-pane{
        #provider-header{
          h3{
            text-transform: uppercase;
            font-family: $font-family-sans-serif;
            font-size: 13px;
            font-weight: bold;
            color: $wcm-med-gray;
            letter-spacing: 2px;
          }
        }
      }
    }
  }

}
// .locations-appointments-section
