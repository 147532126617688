.sticky-section {
  background-color: $wcm-white;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: $wcm-border-gray 0rem 0.3rem 0.8rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  text-align: center;
  transition: top 0.25s ease-in-out;
  margin-top: 0;

  @include breakpoint($md) {
    background-color: $wcm-bg-gray;
    text-align: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .section-row {
    &.row-1 {
      .provider-info {
        @include make-xs-column(12);
        @include make-md-column(8);
        .provider-name {
          margin-top: 0;
        }
        .field-primary-specialty {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.8rem;
          display: none;
          color: $wcm-med-gray;
        }

        .provider-name {
          font-size: 22px;
          margin-bottom: 10px;

          @include breakpoint($md) {
            font-size: 40px;
            margin-bottom: 5px;
          }
        }

        @include breakpoint($md) {
          .field-primary-specialty {
            display: unset;
          }
        }
      }
      .schedule-appointment {
        @include make-xs-column(12);
        @include make-md-column(4);
        .btn {
          width: 100%;
          white-space: normal;
        }
      }
    }

    &.row-2 {
      display: none;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      @include breakpoint($md) {
        display: unset;
      }

      > .btn-wrapper {
        @include make-xs-column(12);
        @include make-md-column(4);
        border: 1px solid $wcm-border-gray;
        background-color: $wcm-white;

        &:hover,
        &.active {
          .btn {
            color: $wcm-dark-orange;
          }
        }

        .btn {
          color: #555;
          background-color: transparent;
          border: none;
          border-radius: 0;
          padding-top: 14px;
          padding-bottom: 14px;

          &:after {
            content: " ";
          }
        }

        @include breakpoint($md) {
          .btn {
            display: block;
          }
        }
      }
    }
  }
}
