// Header section
.header-section {
  background-color: $wcm-bg-gray;
  padding-bottom: 1rem;

  @include breakpoint($md) {
    padding-bottom: 5rem;
  }

  &__profile-image {
    .field-profile-image {
      background-color: $wcm-white;
      padding: 10px;
      margin-top: 30px;
      box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem $wcm-border-gray;

      .field-content-items {
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        width: 100%;

        img {
          position: absolute;
          width: 100%;
          left: 50%;
          margin-left: -50%;
        }
      }
    }
  }

  &__profile-and-award {
    position: relative;
    @include make-xs-column(6);
    @include make-xs-column-offset(3);
    @include make-md-column(3);
    @include make-md-column-offset(0);

    .award-container {
      position: absolute;
      top: 15px;
      left: -20px;
      width: 90px;
      display: none;

      @include breakpoint($md) {
        display: block;
      }
    }

    .awards-wrapper {
      padding: 15px;
      background: $wcm-white;
      margin: 0 20px;
      @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));

      @include breakpoint($md) {
        padding: 5% 10% 1% 10%;
        background: initial;
        margin: initial;
        margin-bottom: -1px;
      }

      @include breakpoint($md) {
        background-color: $wcm-white;
        @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
      }

      img {
        display: inline-block;
        max-width: 30%;

        @include breakpoint($md) {
          max-width: 49.5%;
        }

        @include breakpoint($md) {
          display: block;
          max-width: 100%;
        }
      }
    }

    .awards-banner-arrow {
      position: relative;
      display: none;

      @include breakpoint($md) {
        display: unset;
      }

      svg {
        filter: drop-shadow(0.0025rem 0.3rem 0.2rem rgba(0,0,0,0.2));

        .triangle {
          color: $wcm-white;
          fill: $wcm-white;
        }
      }
    }
  }

  &__provider-info {
    @include make-xs-column(12);
    @include make-md-column(9);

    .field-primary-specialty {
      text-transform: uppercase;
      margin-top: 3rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: $wcm-med-gray;

      @include breakpoint($md) {
        font-size: 1.8rem;
        margin-top: 5rem;
      }
    }

    .name-wrapper {
      width: 100%;

      @include breakpoint($md) {
        float: left;
        width: 70%;
      }
    }

    .btn-wrapper.schedule-appointment {
      margin-top: 2rem;

      @include breakpoint($md) {
        float: left;
        width: 30%;
      }
    }

    .provider-name {
      margin-top: 1.5rem;
      font-size: 2rem;

      @include breakpoint($md) {
        font-size: 4rem;
        margin-top: 2.5rem;
      }
    }

    .secondary-info-wrapper {
      clear: both;
    }

    .nyp-branding {
      padding: 5px 0;
      text-align: center;

      @include breakpoint($md) {
        text-align: unset;
        padding: 15px 0;
      }

      span {
        font-size: 0.9rem;
        color: $wcm-med-gray;
        font-family: $wcm-bold;
        text-transform: uppercase;
        margin-top: 0.5rem;
        margin-right: 3px;
        letter-spacing: 0.05rem;

        @include breakpoint($md) {
          font-size: 1.2rem;
        }
      }

      img {
        width: 140px;
        position: relative;
        top: -3px;

        @include breakpoint($md) {
          width: 200px;
          top: 0;
        }
      }

    }

    .provider-name,
    .field-primary-specialty {
      text-align: center;

      @include breakpoint($md) {
        text-align: unset;
      }
    }

    .field-medical-specialties {
      text-align: left;
      margin-left: 20px;

      @include breakpoint($md) {
        text-align: left;
        margin-left: 0;
      }

      .field-label {
        font-size: 1.6rem;
        text-transform: capitalize;
        color: $wcm-black;
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
      }

      .field-content-items {
        font-size: 1.4rem;
        color: $wcm-dark-gray;
        display: inline-block;

        @include breakpoint($md) {
          font-size: 1.6rem;
        }
        .field-content-item {
          display: inline-block;
        }
      }

      .more-anchor {
        display: inline-block;
        font-size: 14px;
        margin-left: 5px;

        @include breakpoint($md) {
          font-size: 1.6rem;
        }

        &:after {
          @include fontello();
          content: " \e80f";
          padding-left: 0.4rem;
          color: $wcm-dark-orange;
        }
      }
    }

    .scheduling-options {
      margin-top: 2rem;

      > [class^="field-"] {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 1rem;
        }

        > img {
          display: inline-block;
          height: 2rem;
          margin-right: 0.25rem;
        }
        .field-label {
          display: inline-block;
          line-height: 2rem;
          font-family: $font-family-sans-serif;
          font-size: 1.5rem;
          text-transform: capitalize;
          font-weight: normal;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    // Note: the following will be used instead of the above .scheduling-options code
    // as I revised/reverted the template code due to additional pre-process logic that is needed,
    // so this scss is needed as well.
    .online-scheduling-info-container {
      color: #666;
      font-size: 13px;
      line-height: 20px;
      margin: 20px 0 15px 20px;
      display: inline-block;

      @include breakpoint($sm) {
        margin: 15px 0 5px;
        font-size: 15px;
      }

      .text-tag {
        margin-right: 15px;
        margin-bottom: 5px;
        white-space: nowrap;

        @include breakpoint($sm) {
          display: inline-block;
        }

        &:last-of-type {
          margin-right: 0;
        }

        span:first-of-type {
          margin-right: 5px;
        }

        span.dot {
          margin-left: 2px;
          margin-right: 11px;

          @include breakpoint($sm) {
            margin-left: 0;
            margin-right: 5px;
          }
        }

        span.icon.treats-children {
          margin-left: -1px;
          margin-right: 6px;

          @include breakpoint($sm) {
            margin-left: 0;
            margin-right: 5px;
            margin-right: -2px;
          }
        }

        .dot {
          height: 14px;
          width: 14px;
          background-color: #66ff33;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: 3px;
          margin-right: 10px;
        }

        &.not-accepting {
          .dot {
            background-color: #f44242;
          }
        }

        .icon {
          display: inline-block;
          width: 23px;

          @include breakpoint($sm) {
            width: initial;
          }

          img {
            width: 17px;
            height: 17px;
          }

          img.treats-children-icon {
            width: 21px;
            height: 21px;
          }

          img.concierge-medicine-icon {
            width: 19px;
            height: 20px;
          }
        }

        .video-visit-icon,
        .treats-children-icon,
        .concierge-medicine-icon {
          opacity: 0.6;
        }
      }
    }
  }

  &__provider-awards {
    text-align: center;
    margin-bottom: 10px;
    display: block;

    @include breakpoint($md) {
      display: none;
      text-align: left;
      margin-bottom: 0;
    }

    .awards-wrapper {
      padding: 15px;
      background: $wcm-white;
      margin: 0 20px;
      box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem $wcm-border-gray;

      @include breakpoint($md) {
        padding: 5% 10% 5% 10%;
        background: initial;
        margin: initial;
      }

      @include breakpoint($md) {
        background-color: $wcm-white;
        box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem $wcm-border-gray;
      }

      img {
        display: inline-block;
        max-width: 30%;

        @include breakpoint($md) {
          max-width: 49.5%;
        }

        @include breakpoint($md) {
          display: block;
          max-width: 100%;
        }
      }
    }

    .awards-banner-arrow {
      position: relative;
      display: none;

      @include breakpoint($md) {
        display: unset;
      }

      svg {
        filter: drop-shadow(0.0025rem 0.3rem 0.2rem $wcm-border-gray);
        .triangle {
          color: $wcm-white;
          fill: $wcm-white;
        }
      }
    }
  }

  // The Social Icons are not displayed, rather the html is returned when the share icon is clicked
  .social-icons {
    display: none;

    a i:hover {
      filter: brightness(0.2);
    }

    a i &:after {
      font-size: 35px !important;
      color: red;
    }
  }

  // Share and Print
  .provider-ctas {
    display: none;

    @include breakpoint($md) {
      display: initial;
      float: right;
    }

    // These are the share and print icons
    a[class$="-icon"] {
      display: inline-block;
      min-width: 2rem;
      min-height: 2rem;
      margin: 1rem 0.5rem;
      background-size: contain;
      background-repeat: no-repeat;
      text-decoration: none;
      border: none;
      filter: brightness(1.2);

      &:hover {
        filter: brightness(0.5);
      }

      &.share-icon {
        background-image: url("../images/share-icon_awesome.svg");
      }
      &.print-icon {
        background-image: url("../images/print-icon_awesome.svg");
      }
    }

    // This is the share icon popup
    .popover {
      .popover-content {
        display: inline-block;
        a {
          display: block;
          border: 1px solid $wcm-med-gray;
          width: 4rem;
          height: 4rem;
          border-radius: 2rem;
          position: relative;
          margin-bottom: 0.5rem;
          i {
            @include fontello();
            position: absolute;
            font-size: 2rem;
            color: $wcm-med-gray;
            text-align: center;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
          &:after {
            content: "";
          }

          &:hover {
            border-color: $wcm-dark-orange;

            i {
              color: $wcm-dark-orange;
            }
          }
        }
      }
    }
  }
} // .header-section
