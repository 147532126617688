@charset "UTF-8";
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
/* =============================================================================
   ~Sass Mixins
   ========================================================================== */
/*A variety of these are pulled from Bourbon so that it isn't a dependency*/
/* =============================================================================
   ~Useful classes
   ========================================================================== */
.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.stretchy {
  width: 100%;
  font-size: 0px; }

.float-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  float: left; }

.clearfix, .menu, #primary-nav, .address-location, .landing-page-footer .footer-statement, .node-type-practice .information-blocks, .node-type-practice .information-blocks .information-block a, .node-type-practice #our-physicians {
  *zoom: 1; }
  .clearfix:before, .menu:before, #primary-nav:before, .address-location:before, .landing-page-footer .footer-statement:before, .node-type-practice .information-blocks:before, .node-type-practice .information-blocks .information-block a:before, .node-type-practice #our-physicians:before, .clearfix:after, .menu:after, #primary-nav:after, .address-location:after, .landing-page-footer .footer-statement:after, .node-type-practice .information-blocks:after, .node-type-practice .information-blocks .information-block a:after, .node-type-practice #our-physicians:after {
    content: "";
    display: table; }
  .clearfix:after, .menu:after, #primary-nav:after, .address-location:after, .landing-page-footer .footer-statement:after, .node-type-practice .information-blocks:after, .node-type-practice .information-blocks .information-block a:after, .node-type-practice #our-physicians:after {
    clear: both; }

/* =Base partial for shared variables and mixin library
----------------------------------------------------------------------------------*/
/* =Base Grid
----------------------------------------------------------------------------------*/
/* =Grid Functions
----------------------------------------------------------------------------------*/
body {
  width: 100%;
  *zoom: 1; }
  body:before, body:after {
    content: "";
    display: table; }
  body:after {
    clear: both; }

/* =Breakpoints
----------------------------------------------------------------------------------*/
/* =Sass Mixins
----------------------------------------------------------------------------------*/
/*Navigation menus

/* =============================================================================
   Site Brand
   ========================================================================== */
.site-branding {
  position: relative;
  margin: 0 0 0 10px; }
  .site-branding h1 {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    font-size: 14px;
    line-height: 21px;
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: bold;
    font-family: "franklin-gothic-urw", Helvetica, sans-serif;
    line-height: 1.5;
    word-wrap: break-word;
    width: 80%;
    padding: 0; }
    .site-branding h1 > a:hover {
      text-decoration: none; }
    .lt-ie9 .site-branding h1 {
      font-size: 28px;
      line-height: 42px;
      font-size: 2.8rem;
      line-height: 4.2rem;
      width: auto; }
    @media screen and (min-width: 600px) {
      .site-branding h1 {
        font-size: 28px;
        line-height: 42px;
        font-size: 2.8rem;
        line-height: 4.2rem;
        width: auto; } }
    .site-branding h1 .affiliation {
      font-size: .75em;
      display: none;
      text-align: left;
      text-transform: none; }
      .lt-ie9 .site-branding h1 .affiliation {
        display: block;
        line-height: 1; }
      @media screen and (min-width: 985px) {
        .site-branding h1 .affiliation {
          display: block;
          line-height: 1; } }
    .lt-ie9 .site-branding h1 {
      margin: 0; }
    @media screen and (min-width: 985px) {
      .site-branding h1 {
        margin: 0; } }
  .lt-ie9 .site-branding {
    margin: 0; }
  @media screen and (min-width: 600px) {
    .site-branding {
      margin: 0; } }
  .lt-ie9 .site-branding {
    padding: 1em 0;
    padding: 1rem 0;
    position: absolute; }
  @media screen and (min-width: 985px) {
    .site-branding {
      padding: 1em 0;
      padding: 1rem 0;
      position: absolute; } }

.menu-button {
  font-size: 25px;
  line-height: 37.5px;
  font-size: 2.5rem;
  line-height: 3.75rem;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  position: relative;
  left: .5em;
  line-height: 1;
  margin-top: .25em;
  padding: .25em .25em .5em;
  cursor: pointer;
  -webkit-transition: all 0.25s 0s;
  -moz-transition: all 0.25s 0s;
  -ms-transition: all 0.25s 0s;
  -o-transition: all 0.25s 0s;
  transition: all 0.25s 0s; }
  .lt-ie9 .menu-button {
    display: none; }
  @media screen and (min-width: 600px) {
    .menu-button {
      display: none; } }
  .menu-button.is-expanded {
    -webkit-transition: all 0.5s 0.25s;
    -moz-transition: all 0.5s 0.25s;
    -ms-transition: all 0.5s 0.25s;
    -o-transition: all 0.5s 0.25s;
    transition: all 0.5s 0.25s; }

#top-nav {
  padding: 1em;
  padding: 1rem;
  display: none;
  clear: both;
  line-height: 40px; }
  .lt-ie9 #top-nav {
    display: block;
    padding: 0; }
  @media screen and (min-width: 600px) {
    #top-nav {
      display: block;
      padding: 0; } }
  .no-js #top-nav {
    display: block; }

#top-nav .global-search {
  margin: 1em auto; }
  #top-nav .global-search .global-search-input {
    min-width: 50%; }
  #top-nav .global-search .search-button {
    width: 15%; }
  #top-nav .global-search select {
    width: 35%;
    max-width: 100px; }
  .lt-ie9 #top-nav .global-search {
    display: none; }
  @media screen and (min-width: 600px) {
    #top-nav .global-search {
      display: none; } }

#primary-nav {
  display: none;
  font-size: 18px;
  line-height: 27px;
  font-size: 1.8rem;
  line-height: 2.7rem; }
  #primary-nav .menu {
    max-width: 985px; }
  .lt-ie9 #primary-nav {
    display: block;
    float: none;
    border-bottom: 1px solid; }
    .lt-ie9 #primary-nav .level-1 {
      float: left;
      list-style: none;
      margin: 0;
      padding: 0 1em;
      text-align: center;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      position: relative; }
      .lt-ie9 #primary-nav .level-1:after {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        width: 0;
        height: 0;
        content: ' ';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        position: absolute;
        bottom: -10px;
        left: 5%; }
      .lt-ie9 #primary-nav .level-1 > .active-trail {
        background: transparent;
        color: #fff;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s; }
    .lt-ie9 #primary-nav .expand-menu {
      display: none; }
  @media screen and (min-width: 600px) {
    #primary-nav {
      display: block;
      float: none;
      border-bottom: 1px solid; }
      #primary-nav .level-1 {
        float: left;
        list-style: none;
        margin: 0;
        padding: 0 1em;
        text-align: center;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        position: relative; }
        #primary-nav .level-1:after {
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          width: 0;
          height: 0;
          content: ' ';
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid transparent;
          position: absolute;
          bottom: -10px;
          left: 5%; }
        #primary-nav .level-1 > .active-trail {
          background: transparent;
          color: #fff;
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s; }
      #primary-nav .expand-menu {
        display: none; } }

#drawer-nav {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem; }
  #drawer-nav .menu {
    max-width: 960px; }
  #drawer-nav li {
    margin: 0;
    padding: 10px 0; }
    .lt-ie9 #drawer-nav li {
      margin: 0 0 10px;
      padding: 0; }
    @media screen and (min-width: 600px) {
      #drawer-nav li {
        margin: 0 0 10px;
        padding: 0; } }
  .lt-ie9 #drawer-nav {
    clear: both;
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    font-size: 2rem;
    line-height: 3rem; }
    .lt-ie9 #drawer-nav .menu {
      padding: 0 10px; }
    .lt-ie9 #drawer-nav .expand-menu {
      display: none; }
  @media screen and (min-width: 600px) {
    #drawer-nav {
      clear: both;
      margin: 0;
      font-size: 20px;
      line-height: 30px;
      font-size: 2rem;
      line-height: 3rem; }
      #drawer-nav .menu {
        padding: 0 10px; }
      #drawer-nav .expand-menu {
        display: none; } }
  #drawer-nav .level-1 {
    display: block;
    font-size: 1.5em;
    border-bottom: 1px solid;
    width: 100%;
    clear: both; }
    #drawer-nav .level-1.last {
      border: none;
      padding-bottom: 0; }
    #drawer-nav .level-1 > .menu {
      display: none;
      padding-left: 2.5em;
      margin: 0 -10px;
      background-color: #727d84; }
      .no-js #drawer-nav .level-1 > .menu {
        display: block; }
    .lt-ie9 #drawer-nav .level-1 {
      *zoom: 1;
      display: none;
      border-bottom: none;
      padding-top: .25em; }
      .lt-ie9 #drawer-nav .level-1:before, .lt-ie9 #drawer-nav .level-1:after {
        content: "";
        display: table; }
      .lt-ie9 #drawer-nav .level-1:after {
        clear: both; }
      .lt-ie9 #drawer-nav .level-1 > .menu {
        display: block;
        border-top: 1px solid;
        padding-left: 10px;
        padding-top: .5em;
        margin: .3em 0 0;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
    @media screen and (min-width: 600px) {
      #drawer-nav .level-1 {
        *zoom: 1;
        display: none;
        border-bottom: none;
        padding-top: .25em; }
        #drawer-nav .level-1:before, #drawer-nav .level-1:after {
          content: "";
          display: table; }
        #drawer-nav .level-1:after {
          clear: both; }
        #drawer-nav .level-1 > .menu {
          display: block;
          border-top: 1px solid;
          padding-left: 10px;
          padding-top: .5em;
          margin: .3em 0 0;
          width: 100%;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); } }
  #drawer-nav .level-2 {
    margin-left: 0;
    font-size: 16px;
    line-height: 24px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    line-height: 1.5; }
    .lt-ie9 #drawer-nav .level-2 {
      font-size: 18px;
      line-height: 27px;
      font-size: 1.8rem;
      line-height: 2.7rem;
      margin: 0;
      padding-bottom: 10px; }
    @media screen and (min-width: 600px) {
      #drawer-nav .level-2 {
        font-size: 18px;
        line-height: 27px;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin: 0;
        padding-bottom: 10px; } }
    #drawer-nav .level-2 > .menu {
      display: none; }
      .lt-ie9 #drawer-nav .level-2 > .menu {
        display: block;
        margin-top: .25em; }
      @media screen and (min-width: 600px) {
        #drawer-nav .level-2 > .menu {
          display: block;
          margin-top: .25em; } }
  .lt-ie9 #drawer-nav .level-3 {
    list-style-type: disc;
    font-size: 13px;
    line-height: 19.5px;
    font-size: 1.3rem;
    line-height: 1.95rem;
    margin-left: 1em;
    font-weight: 100; }
  @media screen and (min-width: 600px) {
    #drawer-nav .level-3 {
      list-style-type: disc;
      font-size: 13px;
      line-height: 19.5px;
      font-size: 1.3rem;
      line-height: 1.95rem;
      margin-left: 1em;
      font-weight: 100; } }

#active-second-level-nav {
  display: none; }
  .lt-ie9 #active-second-level-nav {
    font-size: 14px;
    line-height: 21px;
    font-size: 1.4rem;
    line-height: 2.1rem;
    display: block;
    text-align: center; }
    .lt-ie9 #active-second-level-nav .level-2:after {
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      content: '';
      width: 0;
      display: block;
      margin: 0 auto;
      border-bottom: 1px solid;
      opacity: 0; }
    .lt-ie9 #active-second-level-nav .last:after {
      border-bottom: none; }
    .lt-ie9 #active-second-level-nav .is-expanded .level-2:after {
      opacity: 1;
      width: 30px; }
    .lt-ie9 #active-second-level-nav .active-trail > a {
      background-position-x: 100%;
      padding: 0 25px;
      background-position-y: middle; }
    .lt-ie9 #active-second-level-nav .level-2 {
      display: none;
      text-align: center;
      margin: 0; }
      .no-js .lt-ie9 #active-second-level-nav .level-2 {
        display: list-item; }
      .lt-ie9 #active-second-level-nav .level-2.active, .lt-ie9 #active-second-level-nav .level-2.active-trail {
        display: list-item; }
  @media screen and (min-width: 600px) {
    #active-second-level-nav {
      font-size: 14px;
      line-height: 21px;
      font-size: 1.4rem;
      line-height: 2.1rem;
      display: block;
      text-align: center; }
      #active-second-level-nav .level-2:after {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        content: '';
        width: 0;
        display: block;
        margin: 0 auto;
        border-bottom: 1px solid;
        opacity: 0; }
      #active-second-level-nav .last:after {
        border-bottom: none; }
      #active-second-level-nav .is-expanded .level-2:after {
        opacity: 1;
        width: 30px; }
      #active-second-level-nav .active-trail > a {
        background-position-x: 100%;
        padding: 0 25px;
        background-position-y: middle; }
      #active-second-level-nav .level-2 {
        display: none;
        text-align: center;
        margin: 0; }
        .no-js #active-second-level-nav .level-2 {
          display: list-item; }
        #active-second-level-nav .level-2.active, #active-second-level-nav .level-2.active-trail {
          display: list-item; } }

.lt-ie9 #active-third-level-nav {
  display: block; }

@media screen and (min-width: 600px) {
  #active-third-level-nav {
    display: block; } }

/*#active-third-level-nav{
  display: none;
  border-bottom: 1px solid;
  max-width: 960px;
  @include breakpoint($bp1){
    display: block;
    margin: 0 auto;
    @include font-size(1.4);
    .menu{
      text-align: justify;
      -ms-text-justify: distribute-all-lines;
      // text-justify: distribute-all-lines; //Forget you, IE7.
      max-width: 940px;
      line-height: normal;
      font-size: 0;

    }
    .level-3{
      @include font-size(1.5);
      display: inline-block;
      *display: inline;
      zoom: 1;
      position: relative;
      top: .75em;
      margin: 0 1em 1.5em;
    }
    .level-3.active-trail:before{
      @include triangle(20px, 10px, down, transparent);
      display: block;
      position: absolute;
      left: 50%;
      top: -.75em;
      margin-left: -13px;
    }
  }
}*/
.expand-menu {
  cursor: pointer;
  float: right;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  text-align: center;
  vertical-align: middle;
  font-size: 36px;
  line-height: 54px;
  font-size: 3.6rem;
  line-height: 5.4rem;
  width: 25px;
  height: 25px; }

/* =============================================================================
   ~Secondary Navigation
   ========================================================================== */
#body-nav {
  margin: 20px -10px;
  padding: 0 1em;
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: bold;
  line-height: 35px;
  position: relative; }
  #body-nav li {
    margin: 5px 0;
    display: none;
    position: relative; }
    #body-nav li:first-of-type {
      display: block; }
      #body-nav li:first-of-type:after {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 11px 9px 0 9px;
        display: block;
        position: absolute;
        content: ' ';
        top: 40%;
        right: 10px;
        opacity: 1; }
    .no-js #body-nav li {
      display: list-item; }
  #body-nav .is-expanded li:after {
    opacity: 0; }
  .lt-ie9 #body-nav {
    display: none; }
  @media screen and (min-width: 600px) {
    #body-nav {
      display: none; } }
  #body-nav #mobile-sub-nav:after {
    opacity: 0;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 9px 11px 9px;
    display: block;
    position: absolute;
    content: ' ';
    right: 10px;
    bottom: 0; }
  #body-nav #mobile-sub-nav.is-expanded:after {
    opacity: 1;
    -webkit-transition: all 0.5s 0.25s;
    -moz-transition: all 0.5s 0.25s;
    -ms-transition: all 0.5s 0.25s;
    -o-transition: all 0.5s 0.25s;
    transition: all 0.5s 0.25s; }

/* =============================================================================
   ~Breadcrumb
   ========================================================================== */
.breadcrumb {
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 1em 0; }
  .breadcrumb a {
    text-decoration: none; }

#second-level-nav .wcmc-button {
  margin-bottom: 1em;
  display: none; }
  .lt-ie9 #second-level-nav .wcmc-button {
    display: block; }
  @media screen and (min-width: 600px) {
    #second-level-nav .wcmc-button {
      display: block; } }

/* Weill Cornell Physicians Layout
----------------------------------------------------------------------------------*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
.top-bar-primary-action-items .primary-action-icon {
  max-height: 7rem; }
  .top-bar-primary-action-items .primary-action-icon.active {
    background-color: #b31b1b; }

footer.page-footer .footer-partnership {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding-top: 1.5rem;
  max-width: 100%; }
  footer.page-footer .footer-partnership__wcm, footer.page-footer .footer-partnership__nyp {
    display: block;
    margin-left: auto;
    background-size: contain; }
  footer.page-footer .footer-partnership__wcm {
    width: 40rem;
    max-width: 100%;
    height: 4.2rem;
    max-height: auto;
    margin-right: auto; }
  footer.page-footer .footer-partnership__nyp {
    background-image: url("/sites/all/themes/weillcornell/images/nyp-logo-with-connecting-language.svg");
    background-size: cover;
    background-position-x: 1.5rem;
    background-position-y: 44%;
    width: 38.3rem;
    max-width: 100%;
    height: 1.9rem;
    max-height: auto;
    margin-right: 0;
    margin-top: 1.2rem; }

footer.page-footer .magical-back-to-top-button {
  margin: 10px;
  background-color: #cf4520;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  left: 10px;
  border-radius: 25px;
  border: none;
  z-index: 100;
  display: none; }
  @media screen and (min-width: 992px) {
    footer.page-footer .magical-back-to-top-button {
      display: inline-block; } }
  footer.page-footer .magical-back-to-top-button:after {
    content: " \e80b";
    font-family: "fontello";
    font-size: 24px;
    text-align: center;
    color: #fff;
    top: 45%;
    transform: translateY(-50%);
    position: absolute;
    width: 100%; }
  footer.page-footer .magical-back-to-top-button:hover {
    background-color: #b31b1b;
    cursor: pointer; }

/* =============================================================================
   ~Sass Mixins
   ========================================================================== */
/*A variety of these are pulled from Bourbon so that it isn't a dependency*/
/* =============================================================================
   ~Useful classes
   ========================================================================== */
.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.stretchy {
  width: 100%;
  font-size: 0px; }

.float-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  float: left; }

.clearfix, .menu, #primary-nav, .address-location, .landing-page-footer .footer-statement, .node-type-practice .information-blocks, .node-type-practice .information-blocks .information-block a, .node-type-practice #our-physicians {
  *zoom: 1; }
  .clearfix:before, .menu:before, #primary-nav:before, .address-location:before, .landing-page-footer .footer-statement:before, .node-type-practice .information-blocks:before, .node-type-practice .information-blocks .information-block a:before, .node-type-practice #our-physicians:before, .clearfix:after, .menu:after, #primary-nav:after, .address-location:after, .landing-page-footer .footer-statement:after, .node-type-practice .information-blocks:after, .node-type-practice .information-blocks .information-block a:after, .node-type-practice #our-physicians:after {
    content: "";
    display: table; }
  .clearfix:after, .menu:after, #primary-nav:after, .address-location:after, .landing-page-footer .footer-statement:after, .node-type-practice .information-blocks:after, .node-type-practice .information-blocks .information-block a:after, .node-type-practice #our-physicians:after {
    clear: both; }

/* =Base partial for shared variables and mixin library
----------------------------------------------------------------------------------*/
/* =Base Grid
----------------------------------------------------------------------------------*/
/* =Grid Functions
----------------------------------------------------------------------------------*/
body {
  width: 100%;
  *zoom: 1; }
  body:before, body:after {
    content: "";
    display: table; }
  body:after {
    clear: both; }

/* =Breakpoints
----------------------------------------------------------------------------------*/
/* =Sass Mixins
----------------------------------------------------------------------------------*/
/* =Mobile First
----------------------------------------------------------------------------------*/
header,
#main-menu,
#main-content,
#sidebar-first,
#sidebar-second {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }

header,
#main-menu,
#main,
.wrap {
  margin: 0 auto;
  display: block;
  float: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }

#finder {
  min-height: 30px;
  clear: both;
  background-color: #992800; }

#page {
  background: #fff; }

#main-menu {
  padding: 0; }

#main {
  clear: both;
  background: #fff;
  overflow: hidden;
  min-height: 400px; }

#main-content {
  padding: 0 10px 45px 25px; }

/* Weill Cornell Physicians Styles
----------------------------------------------------------------------------------*/
body:before {
  content: "mobile";
  display: none;
  /* Prevent from displaying. */ }

@media (min-width: 767px) {
  body:before {
    content: "tablet"; } }

@media (min-width: 990px) {
  body:before {
    content: "desktop"; } }

@media (min-width: 1200px) {
  body:before {
    content: "desktop-large"; } }

.alert {
  display: none; }

/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
.wcm-site-logo img {
  height: 100px;
  padding: 15px 0; }

.btn-primary {
  background-color: #cf4520;
  border-color: #cf4520; }
  .btn-primary:hover {
    background-color: #b31b1b;
    border-color: #b31b1b; }

.btn-secondary {
  background-color: #cf4520;
  border-color: #cf4520;
  color: #fff;
  height: 20px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 17px;
  padding: 0px 15px; }

.btn:hover, .btn:focus, .btn:active {
  background-color: #b31b1b;
  border-color: #b31b1b; }

.btn-primary-rect {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: #b31b1b;
  border: 1px solid #b31b1b;
  font-size: 14px;
  padding: 7px 40px 7px 40px;
  height: 40px;
  color: #fff; }
  .btn-primary-rect:hover {
    background: #cf4520;
    border: 1px solid #cf4520;
    -webkit-transition: all 0s 0s;
    -moz-transition: all 0s 0s;
    -ms-transition: all 0s 0s;
    -o-transition: all 0s 0s;
    transition: all 0s 0s; }

.small-grey {
  border-color: #505d63;
  border-width: 2px;
  padding: 1px 20px;
  color: #505d63;
  font-size: 14px; }
  .small-grey:hover {
    background-color: #505d63;
    border-color: #505d63; }

.read-more,
.read-less {
  font-size: 14px; }

.more-link {
  margin-left: 5px; }

.read-less {
  display: inline-block;
  margin-bottom: 15px; }

ul.move-it-in {
  margin-left: 20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.external-link-icon:after {
  content: " \e811";
  text-decoration: none;
  font-family: "fontello"; }

.cta-icon {
  color: #cf4520;
  fill: #e7751d; }

.cta-icon-youtube,
.cta-icon-facebook,
.cta-icon-twitter,
.cta-icon-linkedin,
.cta-icon-instagram,
.cta-icon-vimeo,
.cta-icon-google-plus,
.cta-icon-flickr {
  fill: #fff; }

.svg-icon {
  width: 45px;
  height: 45px;
  float: left;
  margin-right: 15px; }

.form-error {
  color: #cc0000;
  margin-bottom: 15px;
  display: none; }

.form-field-error {
  border: 2px solid #cc0000; }

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999; }

a.skip-main:focus,
a.skip-main:active {
  color: #cf4520;
  background-color: #fff;
  left: auto;
  top: auto;
  width: 25%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  text-align: center;
  font-size: 1.2em;
  z-index: 999; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 45px; }

/* =Typography
----------------------------------------------------------------------------------*/
h1 strong {
  color: #992800;
  font-weight: 600;
  font-style: italic; }

p {
  font-size: 14px;
  line-height: 26px;
  font-size: 1.4rem;
  line-height: 2.6rem; }

.pane-node-body ul,
.pane-node-body ol {
  padding: 0 0 0 15px; }

.pane-node-body li {
  padding: 0 0 10px; }

dt {
  font-weight: bold; }

#main {
  max-width: 960px;
  padding: 0 10px; }
  .lt-ie9 #main {
    max-width: 1180px; }
  @media screen and (min-width: 1200px) {
    #main {
      max-width: 1180px; } }
  #main .search-results ul,
  #main .node-search-result ul {
    font-size: 13px;
    line-height: 19.5px;
    font-size: 1.3rem;
    line-height: 1.95rem; }
  #main ul,
  #main ol {
    font-size: 14px;
    line-height: 26px;
    font-size: 1.4rem;
    line-height: 2.6rem; }
  #main:focus {
    outline: none; }

.wcmc_onecol .container {
  padding: 0; }
  @media screen and (min-width: 990px) and (max-width: 1199px) {
    .wcmc_onecol .container {
      width: 940px; } }

.hr {
  width: 80%;
  height: 2.5em;
  margin: 0 auto 30px;
  border-bottom: 1px solid #e2e2d5;
  float: none;
  clear: both; }

blockquote {
  -webkit-box-shadow: 0, 1px, 4px, rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0, 1px, 4px, rgba(0, 0, 0, 0.2);
  box-shadow: 0, 1px, 4px, rgba(0, 0, 0, 0.2);
  background: #fff; }

.wcmc-banner--local {
  position: relative;
  z-index: 999; }

.wcmc-banner__indicator {
  line-height: 1.5;
  margin-right: 1em; }

.lt-ie9 .wcmc-banner__message {
  line-height: 34px; }

@media screen and (min-width: 985px) {
  .wcmc-banner__message {
    line-height: 34px; } }

#branding a {
  display: block;
  width: 220px;
  height: 77px;
  padding-top: 12px;
  text-indent: -9999px;
  background: url(../images/WCM-logo.png) no-repeat center;
  background-size: 215px; }
  #branding a:focus {
    outline: 5px auto -webkit-focus-ring-color; }

.site-title {
  background: #cf4520;
  text-align: center;
  color: #fff;
  padding-bottom: 0;
  margin-top: 0;
  line-height: 1.5;
  font-size: 18px;
  display: none;
  font-family: "1898Sans-Regular", sans-serif;
  position: relative;
  -webkit-transition: all 0.25s 0s;
  -moz-transition: all 0.25s 0s;
  -ms-transition: all 0.25s 0s;
  -o-transition: all 0.25s 0s;
  transition: all 0.25s 0s; }
  .lt-ie9 .site-title {
    font-size: 46px;
    display: block; }
  @media screen and (min-width: 600px) {
    .site-title {
      font-size: 46px;
      display: block; } }
  .site-title:before, .site-title:after {
    content: " ";
    display: table; }
  .site-title:after {
    clear: both; }
  .site-title:hover {
    background: #b31b1b; }
    .site-title:hover a span {
      opacity: 1; }
  .site-title.mobile-version {
    display: block;
    text-align: left; }
    .site-title.mobile-version a {
      padding-bottom: 2px; }
    .lt-ie9 .site-title.mobile-version {
      display: none; }
    @media screen and (min-width: 600px) {
      .site-title.mobile-version {
        display: none; } }
  .site-title a {
    position: relative;
    max-width: 960px;
    padding: 0.25em 10px 0;
    margin: 0 auto;
    display: block;
    color: #fff;
    border-bottom: none; }
    .site-title a span {
      float: left;
      font-size: 3rem;
      color: #fff;
      position: absolute;
      left: 10px;
      top: 0;
      height: 100%;
      padding: 0em 1em;
      line-height: 3.2;
      background: #cf4520;
      display: none;
      opacity: 0;
      -webkit-transition: all 0.25s 0s;
      -moz-transition: all 0.25s 0s;
      -ms-transition: all 0.25s 0s;
      -o-transition: all 0.25s 0s;
      transition: all 0.25s 0s; }
      .lt-ie9 .site-title a span {
        display: block; }
      @media screen and (min-width: 600px) {
        .site-title a span {
          display: block; } }
      .site-title a span:before {
        color: #b31b1b; }
    .site-title a:hover {
      text-decoration: none;
      border-bottom: none; }
    .site-title a:focus {
      outline: 5px auto -webkit-focus-ring-color; }

.header-nav-wrapper {
  float: right; }
  .header-nav-wrapper .primary-action-icons-wrapper {
    display: none;
    padding-top: 10px;
    margin-left: 20px; }
    .lt-ie9 .header-nav-wrapper .primary-action-icons-wrapper {
      display: block; }
    @media screen and (min-width: 600px) {
      .header-nav-wrapper .primary-action-icons-wrapper {
        display: block; } }
    .header-nav-wrapper .primary-action-icons-wrapper .primary-action-icon:first-child {
      margin-right: 10px;
      padding-left: 20px;
      border-left: 1px solid #ddd; }
      .header-nav-wrapper .primary-action-icons-wrapper .primary-action-icon:first-child:focus {
        outline: 5px auto -webkit-focus-ring-color; }

.primary-action-icons-wrapper {
  float: left; }
  .primary-action-icons-wrapper .primary-action-icon {
    text-align: center;
    display: inline-block; }
  .primary-action-icons-wrapper .cta-icon {
    width: 35px;
    height: 35px;
    display: block;
    margin: 0 auto 5px; }
  .primary-action-icons-wrapper a span {
    font-size: 12px; }

.top-bar-primary-action-items {
  float: left;
  margin: 0;
  display: none; }
  .lt-ie9 .top-bar-primary-action-items {
    display: block; }
  @media screen and (min-width: 990px) {
    .top-bar-primary-action-items {
      display: block; } }
  .top-bar-primary-action-items .primary-action-icon {
    display: block;
    float: left;
    padding: 11px 10px; }
    .top-bar-primary-action-items .primary-action-icon:hover {
      background: #b31b1b; }
    .top-bar-primary-action-items .primary-action-icon:first-of-type {
      border-right: 1px solid #e7751d; }
    @media screen and (min-width: 1050px) {
      .top-bar-primary-action-items .primary-action-icon {
        padding: 11px 20px; } }
  .top-bar-primary-action-items .cta-icon {
    width: 30px;
    height: 30px;
    margin: 10px 10px 3px 0;
    color: #fff;
    fill: #fff; }
  .top-bar-primary-action-items a span {
    font-size: 12px;
    color: #fff;
    position: relative;
    top: -12px; }
    @media screen and (min-width: 1050px) {
      .top-bar-primary-action-items a span {
        font-size: 15px; } }

.lt-ie9 .slide-nav-primary-action-items {
  display: none; }

@media screen and (min-width: 990px) {
  .slide-nav-primary-action-items {
    display: none; } }

.slide-nav-primary-action-items .primary-action-icon {
  display: block;
  float: left;
  padding: 35px 0;
  width: 50%;
  text-align: center;
  border-bottom: 1px solid #868686; }
  .slide-nav-primary-action-items .primary-action-icon:first-of-type {
    border-right: 1px solid #868686; }
  .slide-nav-primary-action-items .primary-action-icon:focus, .slide-nav-primary-action-items .primary-action-icon:hover {
    text-decoration: none;
    background: #555; }

.slide-nav-primary-action-items .cta-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  color: #fff;
  fill: #fff; }

.slide-nav-primary-action-items a span {
  font-size: 12px;
  color: #fff;
  display: block;
  margin-top: 2px; }

/* =Navigation
----------------------------------------------------------------------------------*/
.expand-menu {
  font-family: "Open Sans", sans-serif;
  line-height: inherit;
  color: #fff !important; }

.menu-button {
  color: #555; }
  .menu-button.is-expanded {
    color: #b31b1b; }

.fap-links {
  background-color: #fff;
  display: block; }
  .fap-links:before, .fap-links:after {
    content: " ";
    display: table; }
  .fap-links:after {
    clear: both; }
  .lt-ie9 .fap-links {
    display: none; }
  @media screen and (min-width: 600px) {
    .fap-links {
      display: none; } }
  .fap-links .primary-action-icons-wrapper {
    width: 100%; }
    .fap-links .primary-action-icons-wrapper .primary-action-icon {
      float: left;
      width: 100%; }
      .fap-links .primary-action-icons-wrapper .primary-action-icon:first-child {
        border-right: 1px solid #b31b1b; }
    .fap-links .primary-action-icons-wrapper .cta-icon {
      margin: 12px auto 0; }
    .fap-links .primary-action-icons-wrapper a span {
      font-size: 16px; }

#top-nav {
  max-width: 960px;
  margin: 0 15px 15px;
  padding: 1rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  background-color: #b31b1b; }
  .lt-ie9 #top-nav {
    background-color: #fff;
    padding: 0 10px;
    margin: 0 auto; }
  @media screen and (min-width: 600px) {
    #top-nav {
      background-color: #fff;
      padding: 0 10px;
      margin: 0 auto; } }
  .lt-ie9 #top-nav {
    max-width: 1180px; }
  @media screen and (min-width: 1200px) {
    #top-nav {
      max-width: 1180px; } }

#primary-nav {
  background-color: #fff;
  border-color: #ccc;
  border-bottom: none;
  line-height: 2.5; }

#primary-nav .active-trail {
  background: #fff; }
  #primary-nav .active-trail.temp-inactive {
    background: transparent;
    color: #cf4520; }

#primary-nav .menu {
  font-size: 1.4rem;
  margin: 0 auto;
  max-width: 100%;
  border-bottom: 5px solid #cf4520; }

#primary-nav .level-1 {
  width: 25%;
  padding: 1em 0.2em; }
  #primary-nav .level-1 a {
    color: #cf4520;
    font-weight: 700; }
    #primary-nav .level-1 a:hover {
      border-bottom: 5px solid #ddd;
      text-decoration: none; }
  .lt-ie9 #primary-nav .level-1.active-trail:after {
    border-bottom-color: #cf4520;
    border-bottom: 10px solid #cf4520;
    bottom: 0;
    left: 45%; }
  @media screen and (min-width: 600px) {
    #primary-nav .level-1.active-trail:after {
      border-bottom-color: #cf4520;
      border-bottom: 10px solid #cf4520;
      bottom: 0;
      left: 45%; } }
  #primary-nav .level-1 > .active-trail {
    color: #666; }
    #primary-nav .level-1 > .active-trail:hover {
      border-bottom: none;
      text-decoration: none; }
  #primary-nav .level-1.is-open {
    background-color: #fff; }
    #primary-nav .level-1.is-open:after {
      border-bottom-color: #ccc;
      border-top-color: transparent; }

#drawer-nav {
  line-height: 40px; }
  #drawer-nav ul.menu {
    font-size: inherit;
    margin: 0 auto; }
    .lt-ie9 #drawer-nav ul.menu {
      font-size: 1.6rem; }
    @media screen and (min-width: 600px) {
      #drawer-nav ul.menu {
        font-size: 1.6rem; } }
  #drawer-nav a {
    color: white;
    text-decoration: none; }
    #drawer-nav a:hover {
      color: white;
      text-decoration: underline; }
    .lt-ie9 #drawer-nav a {
      color: black; }
      .lt-ie9 #drawer-nav a:hover {
        color: #cf4520; }
    @media screen and (min-width: 600px) {
      #drawer-nav a {
        color: black; }
        #drawer-nav a:hover {
          color: #cf4520; } }
    #drawer-nav a.active {
      color: #fff; }
      .lt-ie9 #drawer-nav a.active {
        color: inherit; }
      @media screen and (min-width: 600px) {
        #drawer-nav a.active {
          color: inherit; } }
  #drawer-nav .level-1 {
    font-family: "Open Sans", sans-serif;
    border-color: #ddd; }
    #drawer-nav .level-1 > .external-link:after {
      color: #fff; }
    .lt-ie9 #drawer-nav .level-1 > a:after, .lt-ie9
    #drawer-nav .level-1 > .external-link:after {
      color: #cf4520; }
    @media screen and (min-width: 600px) {
      #drawer-nav .level-1 > a:after,
      #drawer-nav .level-1 > .external-link:after {
        color: #cf4520; } }
    #drawer-nav .level-1 > .menu {
      border-color: #ddd;
      background: #910614; }
      .lt-ie9 #drawer-nav .level-1 > .menu {
        background-color: #f7f7f7; }
      @media screen and (min-width: 600px) {
        #drawer-nav .level-1 > .menu {
          background-color: #f7f7f7; } }
  #drawer-nav .level-2 a {
    color: #fff; }
  .lt-ie9 #drawer-nav .level-2 {
    float: left;
    width: 33%; }
    .lt-ie9 #drawer-nav .level-2 a {
      color: inherit; }
  @media screen and (min-width: 600px) {
    #drawer-nav .level-2 {
      float: left;
      width: 33%; }
      #drawer-nav .level-2 a {
        color: inherit; } }
  .lt-ie9 #drawer-nav .level-2 {
    width: 23%;
    font-size: 13px;
    line-height: 19.5px;
    font-size: 1.3rem;
    line-height: 1.95rem;
    padding-right: 10px; }
    .lt-ie9 #drawer-nav .level-2 a:hover {
      color: #cf4520; }
  @media screen and (min-width: 985px) {
    #drawer-nav .level-2 {
      width: 23%;
      font-size: 13px;
      line-height: 19.5px;
      font-size: 1.3rem;
      line-height: 1.95rem;
      padding-right: 10px; }
      #drawer-nav .level-2 a:hover {
        color: #cf4520; } }
  #drawer-nav .level-3.active-trail {
    color: #cf4520; }
  #drawer-nav .level-4 {
    display: none; }

#active-second-level-nav {
  background: #fff;
  line-height: inherit; }
  #active-second-level-nav > .menu {
    max-width: none; }
    .lt-ie9 #active-second-level-nav > .menu {
      border-top: 5px solid #ddd;
      border-bottom: 1px solid #ddd; }
    @media screen and (min-width: 600px) {
      #active-second-level-nav > .menu {
        border-top: 5px solid #ddd;
        border-bottom: 1px solid #ddd; } }
  .lt-ie9 #active-second-level-nav .active-trail > a {
    background: none;
    font-size: 13px;
    line-height: 19.5px;
    font-size: 1.3rem;
    line-height: 1.95rem; }
    .lt-ie9 #active-second-level-nav .active-trail > a:after {
      content: "\e800";
      font-size: 15px;
      padding-left: 10px;
      color: #cf4520;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none;
      display: inline-block; }
  @media screen and (min-width: 600px) {
    #active-second-level-nav .active-trail > a {
      background: none;
      font-size: 13px;
      line-height: 19.5px;
      font-size: 1.3rem;
      line-height: 1.95rem; }
      #active-second-level-nav .active-trail > a:after {
        content: "\e800";
        font-size: 15px;
        padding-left: 10px;
        color: #cf4520;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none;
        display: inline-block; } }

#active-third-level-nav {
  max-width: none; }
  #active-third-level-nav .level-3 {
    margin: 0 25px 20px; }

#body-nav {
  background: #727d84;
  color: #fff; }
  #body-nav li:after {
    border-color: #fff transparent transparent transparent; }
  #body-nav a {
    color: #fff;
    text-decoration: none; }

#mobile-sub-nav:after {
  border-color: transparent transparent #fff transparent; }

#second-level-nav .wcmc-button {
  background-color: #727d84;
  transition: background-color 0.25s; }
  #second-level-nav .wcmc-button:hover {
    color: white;
    background-color: #667076;
    text-decoration: none; }

.third-level-navigation,
.mobile-section-navigation {
  margin: 10px 0 25px;
  padding: 0 1em;
  font-size: 12px;
  line-height: 28px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #666; }
  .third-level-navigation h2,
  .third-level-navigation h3,
  .mobile-section-navigation h2,
  .mobile-section-navigation h3 {
    font-size: 14px;
    margin-top: 14px; }
    .third-level-navigation h2:after,
    .third-level-navigation h3:after,
    .mobile-section-navigation h2:after,
    .mobile-section-navigation h3:after {
      content: "+";
      width: 45px;
      display: block;
      position: absolute;
      top: 8px;
      right: 0;
      font-size: 24px;
      font-weight: 600;
      text-align: center; }
    .third-level-navigation h2.menu-active:after,
    .third-level-navigation h3.menu-active:after,
    .mobile-section-navigation h2.menu-active:after,
    .mobile-section-navigation h3.menu-active:after {
      content: " -"; }
    .third-level-navigation h2:hover,
    .third-level-navigation h3:hover,
    .mobile-section-navigation h2:hover,
    .mobile-section-navigation h3:hover {
      cursor: pointer; }
  .third-level-navigation .content,
  .mobile-section-navigation .content {
    display: none; }
  .third-level-navigation > ul,
  .mobile-section-navigation > ul {
    display: none; }
  .third-level-navigation ul,
  .mobile-section-navigation ul {
    padding-bottom: 0;
    margin-bottom: 5px;
    line-height: 3.5rem !important; }
    .third-level-navigation ul li,
    .mobile-section-navigation ul li {
      list-style: none;
      border-top: 1px solid #f8f8f8; }
      .third-level-navigation ul li a,
      .mobile-section-navigation ul li a {
        display: block;
        border-bottom: none; }
        .third-level-navigation ul li a:hover,
        .mobile-section-navigation ul li a:hover {
          text-decoration: none; }
    .third-level-navigation ul ul,
    .mobile-section-navigation ul ul {
      padding-left: 15px;
      margin-bottom: 0; }
      .third-level-navigation ul ul li,
      .mobile-section-navigation ul ul li {
        font-size: 12px; }

.third-level-navigation {
  display: block;
  margin: 0 10px 20px; }
  .lt-ie9 .third-level-navigation {
    display: none; }
  @media screen and (min-width: 990px) {
    .third-level-navigation {
      display: none; } }

.node-type-program #active-third-level-nav,
.node-type-program-page #active-third-level-nav {
  display: none; }

.node-type-program .mobile-section-navigation,
.node-type-program-page .mobile-section-navigation {
  display: block; }
  .lt-ie9 .node-type-program .mobile-section-navigation, .lt-ie9
  .node-type-program-page .mobile-section-navigation {
    display: none; }
  @media screen and (min-width: 990px) {
    .node-type-program .mobile-section-navigation,
    .node-type-program-page .mobile-section-navigation {
      display: none; } }

.node-type-program .mobile-section-navigation {
  margin: 10px 10px 25px; }

.node-type-program .section-navigation > ul.menu {
  width: initial; }
  .lt-ie9 .node-type-program .section-navigation > ul.menu {
    width: 940px; }
  @media screen and (min-width: 990px) {
    .node-type-program .section-navigation > ul.menu {
      width: 940px; } }
  .lt-ie9 .node-type-program .section-navigation > ul.menu {
    width: 1160px; }
  @media screen and (min-width: 1200px) {
    .node-type-program .section-navigation > ul.menu {
      width: 1160px; } }

.node-type-clinical-service #active-third-level-nav {
  display: none; }

/********************/
#main h1 {
  border-bottom: none;
  padding-top: 10px;
  margin-top: 0;
  font-size: 28px;
  line-height: 35px;
  font-size: 2.8rem;
  line-height: 3.5rem;
  margin-bottom: 0;
  border-bottom: none;
  padding-top: 10px;
  margin-top: 0; }

.menu-button {
  font-size: 25px;
  line-height: 37.5px;
  font-size: 2.5rem;
  line-height: 3.75rem;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  position: relative;
  width: auto;
  float: right;
  margin-right: 5%;
  line-height: 1;
  margin-top: -60px;
  padding: 0.25em;
  cursor: pointer;
  background: none;
  border: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none; }
  .lt-ie9 .menu-button {
    display: none; }
  @media screen and (min-width: 600px) {
    .menu-button {
      display: none; } }
  .menu-button:before {
    font-family: "fontello";
    content: "\e808";
    font-size: 30px; }
  .menu-button.is-expanded {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none; }
    .menu-button.is-expanded:before {
      font-size: 35px;
      line-height: 2rem;
      font-family: "fontello"; }

#main-menu {
  max-width: 970px;
  width: 100%;
  display: none; }
  .lt-ie9 #main-menu {
    display: block; }
  @media screen and (min-width: 600px) {
    #main-menu {
      display: block; } }

#main-menu-links {
  margin: 0;
  padding: 0;
  clear: both; }

#main-menu-links li {
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #fdfaf3;
  list-style-type: none; }
  .lt-ie9 #main-menu-links li {
    float: left;
    border-right: 1px solid #fdfaf3;
    border-bottom: none; }
  @media screen and (min-width: 767px) {
    #main-menu-links li {
      float: left;
      border-right: 1px solid #fdfaf3;
      border-bottom: none; } }

#main-menu-links li.last {
  padding: 0; }

#main-menu-links li a {
  font-size: 14px;
  line-height: 21px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  font-weight: bold;
  text-decoration: none;
  display: block;
  background-color: #aaa;
  color: #fff;
  padding: 9px 0 2px 0;
  text-align: center;
  transition: background 0.3s; }

#main-menu-links li a:hover {
  background-color: #666; }

#main-menu-links li a.active,
#main-menu-links li a.active-trail {
  background: #992800; }

.lt-ie9 .menu-1112 a {
  width: 106.7px; }

@media screen and (min-width: 767px) {
  .menu-1112 a {
    width: 106.7px; } }

.lt-ie9 .menu-1195 a {
  width: 155.2px; }

@media screen and (min-width: 767px) {
  .menu-1195 a {
    width: 155.2px; } }

.lt-ie9 .menu-1138 a {
  width: 164.9px; }

@media screen and (min-width: 767px) {
  .menu-1138 a {
    width: 164.9px; } }

.lt-ie9 .menu-1139 a {
  width: 145.5px; }

@media screen and (min-width: 767px) {
  .menu-1139 a {
    width: 145.5px; } }

.lt-ie9 .menu-1140 a {
  width: 184.3px; }

@media screen and (min-width: 767px) {
  .menu-1140 a {
    width: 184.3px; } }

.menu-1141 a {
  background: url(../images/WCC_Logo_NavBar.png) #aea89d no-repeat center;
  background-size: contain;
  background-size: auto;
  height: auto;
  text-indent: -999em; }
  .lt-ie9 .menu-1141 a {
    width: 207px; }
  @media screen and (min-width: 767px) {
    .menu-1141 a {
      width: 207px; } }

.menu-1141 a:hover {
  background: url(../images/WCC_Logo_NavBar.png) #c1beb7 no-repeat center; }

/*.menu-1112 a {width: 106.7px;}
.menu-1195 a {width: 155.2px;}
.menu-1138 a {width: 164.9px;}
.menu-1139 a {width: 145.5px;}
.menu-1140 a {width: 184.3px;}
.menu-1141 a {
      width: 207.0px;
      background: url(../images/WCC_Logo_NavBar.png) #aea89d no-repeat center;
      background-size: contain;
      background-size: auto;
      height: auto;
      text-indent: -999em;
}

.menu-1141 a:hover {
  background: url(../images/WCC_Logo_NavBar.png) #c1beb7 no-repeat center;
}*/
/* =Secondary Navigation
----------------------------------------------------------------------------------*/
#sidebar-first .menu {
  font-size: 13px;
  line-height: 13px;
  font-size: 1.3rem;
  line-height: 1.3rem;
  margin: 0 0 20px;
  padding: 0; }

#sidebar-first .menu li {
  float: left;
  padding: 0 0 8px 0;
  margin: 0 0 8px;
  list-style: none;
  list-style-type: none;
  list-style-image: none; }

#sidebar-first .menu li.first {
  border-bottom: 1px dotted #aea89d;
  padding: 0 0 10px;
  margin: 0 0 10px; }

#sidebar-first .menu li a {
  color: #607890;
  border-left: 4px solid #fdfaf3;
  padding-left: 7px; }

#sidebar-first .menu li.first a {
  border-left: 4px solid #f6f0e1; }

#sidebar-first .menu li a:hover,
#sidebar-first .menu li a.active,
#sidebar-first .menu li a.active-trail {
  border-left: 4px solid #e5d6ba;
  color: #544842; }

#sidebar-first .menu li a {
  float: left;
  width: 144px; }

.page-schedule-an-appointment .site-main,
.page-request-an-appointment .site-main {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px; }
  .lt-ie9 .page-schedule-an-appointment .site-main, .lt-ie9
  .page-request-an-appointment .site-main {
    width: 960px;
    padding: 0; }
  @media screen and (min-width: 990px) {
    .page-schedule-an-appointment .site-main,
    .page-request-an-appointment .site-main {
      width: 960px;
      padding: 0; } }
  .lt-ie9 .page-schedule-an-appointment .site-main, .lt-ie9
  .page-request-an-appointment .site-main {
    width: 1160px;
    padding: 0; }
  @media screen and (min-width: 1200px) {
    .page-schedule-an-appointment .site-main,
    .page-request-an-appointment .site-main {
      width: 1160px;
      padding: 0; } }

.page-schedule-an-appointment .alert,
.page-request-an-appointment .alert {
  margin-left: 10px;
  margin-right: 10px; }

/* Forms
----------------------------------------------------------------------------------*/
.form-base {
  margin: 40px 10px; }
  .form-base .panel-heading {
    background: none;
    border: none;
    padding-top: 15px;
    padding-bottom: 0; }
    .form-base .panel-heading .panel-title {
      font-family: "1898Sans-Bold", sans-serif;
      color: #b31b1b; }
  .form-base fieldset {
    width: 100%; }
  .form-base .captcha {
    margin-bottom: 30px; }
  .form-base .form-control {
    max-width: 100%; }
  .lt-ie9 .form-base .form-type-textfield,
  .lt-ie9 .form-base .form-type-password {
    width: 47.5%;
    float: left;
    margin-right: 20px; }
  @media screen and (min-width: 767px) {
    .form-base .form-type-textfield,
    .form-base .form-type-password {
      width: 47.5%;
      float: left;
      margin-right: 20px; } }
  .form-base hr {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px; }

.request-appointment-form {
  margin-top: 15px; }
  .request-appointment-form .breadcrumb {
    padding: 0; }
  .request-appointment-form h2 {
    margin-bottom: 20px; }
  .lt-ie9 .request-appointment-form .form-item-first-name,
  .lt-ie9 .request-appointment-form .form-item-middle-name,
  .lt-ie9 .request-appointment-form .form-item-last-name {
    width: 31%; }
  .lt-ie9 .request-appointment-form .form-item-date-of-birth {
    width: 31%; }
  .lt-ie9 .request-appointment-form .form-item-address-line-1 {
    clear: both; }
  .lt-ie9 .request-appointment-form .form-item-state,
  .lt-ie9 .request-appointment-form .form-item-zip-code {
    width: 22.5%; }
  @media screen and (min-width: 767px) {
    .request-appointment-form .form-item-first-name,
    .request-appointment-form .form-item-middle-name,
    .request-appointment-form .form-item-last-name {
      width: 31%; }
    .request-appointment-form .form-item-date-of-birth {
      width: 31%; }
    .request-appointment-form .form-item-address-line-1 {
      clear: both; }
    .request-appointment-form .form-item-state,
    .request-appointment-form .form-item-zip-code {
      width: 22.5%; } }

.location-wrapper {
  border-radius: 4px;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  margin: 0 2px 25px;
  padding: 20px 20px 16px 16px; }
  .location-wrapper .selected-location-title {
    color: #b31b1b;
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 17px;
    padding: 0 10px 15px 5px; }
  .location-wrapper .cta-icon {
    width: 25px;
    height: 25px; }
  .location-wrapper .location-snippet {
    position: relative;
    display: inline-block;
    top: -6px;
    font-size: 14px;
    line-height: 18px;
    margin-left: 7px; }

.simple-details-wrapper {
  border-radius: 4px;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  margin: 20px 0 25px;
  padding: 0 20px 10px 20px; }
  .simple-details-wrapper h3 {
    margin-bottom: 10px; }
  .simple-details-wrapper .detail-entry {
    margin-bottom: 15px; }
    .simple-details-wrapper .detail-entry .cta-icon {
      width: 35px;
      height: 35px;
      float: left; }
    .simple-details-wrapper .detail-entry .detail-entry-content {
      font-size: 14px;
      margin-top: 6px; }
      .simple-details-wrapper .detail-entry .detail-entry-content span {
        font-weight: bold; }
      .simple-details-wrapper .detail-entry .detail-entry-content .reference-number {
        font-weight: normal;
        text-transform: uppercase; }

.in-person-visit-wrapper .simple-details-wrapper,
.video-concierge-wrapper .simple-details-wrapper {
  padding: 15px 20px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 22px; }
  .in-person-visit-wrapper .simple-details-wrapper .date-time,
  .video-concierge-wrapper .simple-details-wrapper .date-time {
    margin-top: 15px; }

.in-person-visit-wrapper fieldset,
.video-concierge-wrapper fieldset {
  width: 100%;
  border: none;
  padding: 0; }

.in-person-visit-wrapper label,
.video-concierge-wrapper label {
  margin: 15px 0 10px; }

.in-person-visit-wrapper span,
.video-concierge-wrapper span {
  font-weight: bold; }

.in-person-visit-wrapper ol,
.video-concierge-wrapper ol {
  padding-left: 10px; }

.in-person-visit-wrapper li,
.video-concierge-wrapper li {
  padding: 5px; }

.in-person-visit-wrapper .new-user ol,
.in-person-visit-wrapper ul,
.video-concierge-wrapper .new-user ol,
.video-concierge-wrapper ul {
  padding-left: 20px; }

.in-person-visit-wrapper .register-button-wrapper,
.video-concierge-wrapper .register-button-wrapper {
  margin-top: 15px; }
  .in-person-visit-wrapper .register-button-wrapper .btn-secondary,
  .video-concierge-wrapper .register-button-wrapper .btn-secondary {
    -webkit-border-radius: 5px;
    padding: 10px 40px 5px 40px;
    height: 40px;
    font-size: 14px; }

.panel-flexi-a-fullwidth #main {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: 0; }

.panel-flexi-a-fullwidth #main-content {
  padding: 0; }

.panel-flexi-a-fullwidth .inner-wrapper {
  margin: 0 -20px; }

.promotion-tile-wrapper {
  float: left;
  color: #fff;
  border: none;
  position: relative;
  width: 100%;
  margin: 20px 0;
  height: 273px;
  overflow: hidden; }
  .lt-ie9 .promotion-tile-wrapper {
    width: 66.6%;
    margin-left: 20px;
    margin-right: -20px;
    margin: 0 -20px 0 20px; }
  @media screen and (min-width: 767px) {
    .promotion-tile-wrapper {
      width: 66.6%;
      margin-left: 20px;
      margin-right: -20px;
      margin: 0 -20px 0 20px; } }
  .promotion-tile-wrapper img {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    width: 100%;
    margin-bottom: 0 !important; }
  .promotion-tile-wrapper:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  .promotion-tile-wrapper a {
    border-bottom: none; }
  .promotion-tile-wrapper .background-wrapper {
    background-color: #cf4520; }
  .promotion-tile-wrapper .background-image img {
    mix-blend-mode: multiply;
    transition: all 0.25s; }
  .promotion-tile-wrapper .promotion-tile-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; }
    .promotion-tile-wrapper .promotion-tile-content a {
      position: absolute;
      top: 0;
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      text-align: center;
      padding: 0 20px; }
  .promotion-tile-wrapper .title {
    font-size: 28px;
    line-height: 62px;
    font-family: "1898Sans-Bold", sans-serif; }
    .lt-ie9 .promotion-tile-wrapper .title {
      font-size: 42px; }
    @media screen and (min-width: 767px) {
      .promotion-tile-wrapper .title {
        font-size: 42px; } }
  .promotion-tile-wrapper .description {
    font-size: 14px;
    line-height: 22px;
    margin: 0 auto 20px; }
    .lt-ie9 .promotion-tile-wrapper .description {
      font-size: 16px;
      width: 70%; }
    @media screen and (min-width: 767px) {
      .promotion-tile-wrapper .description {
        font-size: 16px;
        width: 70%; } }
  .promotion-tile-wrapper:hover {
    color: #fff;
    border-bottom: none; }

.circle-arrow {
  display: inline-block;
  width: 55px;
  height: 55px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid #fff; }
  .circle-arrow:after {
    color: #fff;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    padding-left: 10px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .circle-arrow:hover:after, .circle-arrow:active:after {
    text-decoration: none; }
  .circle-arrow:after {
    font-size: 24px;
    padding-top: 10px;
    line-height: 1.5em;
    padding-left: 8px; }

.arrow-up {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -5px; }

.fap-search-formz {
  background: #cf4520;
  color: #fff;
  text-align: center;
  padding: 30px;
  clear: both;
  margin-bottom: 30px; }
  .fap-search-formz form .submit-button {
    border: none;
    padding: 6px 50px; }
    .lt-ie9 .fap-search-formz form .submit-button {
      width: 220px; }
    @media screen and (min-width: 990px) {
      .fap-search-formz form .submit-button {
        width: 220px; } }
    .fap-search-formz form .submit-button:hover {
      background: #fff;
      color: #cf4520; }
  .fap-search-formz h3 {
    color: #fff;
    font-size: 32px;
    line-height: 44px;
    font-family: "1898Sans-Regular", sans-serif;
    margin: 0 0 10px; }
  .fap-search-formz label {
    font-weight: normal;
    display: inline;
    margin-left: 8px; }
  .fap-search-formz .fap-form {
    margin: 0 auto; }
    .lt-ie9 .fap-search-formz .fap-form {
      width: 65%; }
    @media screen and (min-width: 990px) {
      .fap-search-formz .fap-form {
        width: 65%; } }
  .fap-search-formz .form-text {
    border: none;
    height: 48px;
    max-width: none;
    width: 100%; }
  .fap-search-formz .search-term .form-group {
    position: relative; }
  .fap-search-formz select {
    max-width: 100%; }
  .fap-search-formz .select2-selection__rendered {
    font-size: 15px;
    text-align: left;
    color: #666; }
  .lt-ie9 .fap-search-formz .insurance {
    padding-right: 8px; }
  @media screen and (min-width: 990px) {
    .fap-search-formz .insurance {
      padding-right: 8px; } }
  .lt-ie9 .fap-search-formz .location {
    padding-left: 8px; }
  @media screen and (min-width: 990px) {
    .fap-search-formz .location {
      padding-left: 8px; } }
  .fap-search-formz .accepting-new-patients {
    text-align: left; }
  .fap-search-formz .btn-primary:hover {
    background-color: #b53a21;
    border-color: #b53a21; }
  .fap-search-formz .btn-primary[disabled] {
    background-color: #e7751d; }
  .fap-search-formz .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #b73b21;
    border-color: #b73b21; }

.autosuggest-wrapper {
  position: absolute;
  z-index: 10;
  margin: 0;
  width: 100%;
  color: black;
  text-align: left; }
  .autosuggest-wrapper .list-group-item {
    border-bottom: 1px solid #eee; }
  .autosuggest-wrapper .list-group-item.heading {
    font-size: 16px;
    font-weight: bold;
    color: #444; }
  .autosuggest-wrapper .list-group-item.list-item {
    padding-left: 30px; }
    .autosuggest-wrapper .list-group-item.list-item:hover {
      background: #f3f3f3;
      color: #cf4520;
      cursor: pointer; }

#main .block-facetapi {
  clear: both;
  padding-bottom: 0; }
  #main .block-facetapi h2 {
    font-size: 16px; }
  #main .block-facetapi .content {
    position: relative;
    border: none; }

.view-provider-profiles-faceted {
  clear: both; }

.timegrid-datescrubber-container {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  display: inline-block;
  z-index: 100;
  background: white;
  padding: 7px 0;
  float: right; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .timegrid-datescrubber-container {
      width: 100%; } }
  @media screen and (min-width: 600px) and (max-width: 769px) {
    .timegrid-datescrubber-container {
      width: 100%; } }
  .lt-ie9 .timegrid-datescrubber-container {
    padding: 20px 0px; }
  @media screen and (min-width: 767px) {
    .timegrid-datescrubber-container {
      padding: 20px 0px; } }

.timegrid-datescrubber-wrapper {
  text-align: center; }
  .lt-ie9 .timegrid-datescrubber-wrapper {
    float: right; }
  @media screen and (min-width: 767px) {
    .timegrid-datescrubber-wrapper {
      float: right; } }

.date-picker-container {
  float: left; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .date-picker-container {
      width: 100%;
      text-align: center;
      margin-bottom: 5px; } }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .date-picker-container {
      width: 100%;
      text-align: center;
      margin-bottom: 5px; } }

.date-scrubber-container {
  margin: 0 auto;
  display: inline-block;
  text-align: center; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .date-scrubber-container {
      width: 100%; } }
  .lt-ie9 .date-scrubber-container {
    float: left;
    text-align: left; }
  @media screen and (min-width: 767px) {
    .date-scrubber-container {
      float: left;
      text-align: left; } }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .date-scrubber-container {
      width: 320px; } }

.date-picker-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-top: 5px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .date-picker-icon {
      width: 32px;
      height: 32px;
      margin-right: 0; } }
  .date-picker-icon:hover {
    cursor: pointer; }

.date-scrubber-wrapper {
  background: #f7f7f7;
  display: inline-flex;
  padding: 10px 10px;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  .lt-ie9 .date-scrubber-wrapper {
    width: 318px; }
  @media screen and (min-width: 990px) {
    .date-scrubber-wrapper {
      width: 318px; } }
  .lt-ie9 .date-scrubber-wrapper {
    width: 390px; }
  @media screen and (min-width: 1200px) {
    .date-scrubber-wrapper {
      width: 390px; } }
  .date-scrubber-wrapper .date-scrubber-dates {
    display: flex;
    flex-direction: row;
    width: 100%; }
    .date-scrubber-wrapper .date-scrubber-dates .date-scrub-item div {
      text-align: center; }
  .date-scrubber-wrapper .date-scrub-item {
    float: left;
    margin: auto; }
    .date-scrubber-wrapper .date-scrub-item.date-scrub-control a {
      border-bottom: none;
      display: block;
      width: 30px;
      height: 30px; }
      .date-scrubber-wrapper .date-scrub-item.date-scrub-control a:hover:after {
        color: #b31b1b; }
    .date-scrubber-wrapper .date-scrub-item.date-scrub-left {
      padding-right: 0;
      margin-top: 9px;
      margin-right: 0;
      border: none; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .date-scrubber-wrapper .date-scrub-item.date-scrub-left a {
          width: 15px; } }
    .date-scrubber-wrapper .date-scrub-item.date-scrub-right {
      border-right: none;
      margin-right: 0;
      margin-top: 9px;
      padding-right: 0px; }
      .date-scrubber-wrapper .date-scrub-item.date-scrub-right.date-scrub-control a {
        padding-left: 10px; }
    .date-scrubber-wrapper .date-scrub-item a:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 100%;
      padding-left: 5px;
      line-height: 24px;
      color: #cf4520; }
    .date-scrubber-wrapper .date-scrub-item.date-scrub-left a:after {
      content: " \e810"; }
    .date-scrubber-wrapper .date-scrub-item span {
      display: none; }

.timeslots-loading-spinner {
  margin-top: 20px; }

.scheduling-container {
  margin: auto;
  float: none;
  padding: 0px 20px 20px 20px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  .scheduling-container .date-picker-icon {
    margin: 0 0 12px 0; }
  .scheduling-container .date-scrubber-wrapper,
  .scheduling-container .timegrid-datescrubber-container,
  .scheduling-container .date-scrubber-container,
  .scheduling-container .timegrid-datescrubber-wrapper,
  .scheduling-container .date-picker-container {
    width: 100%;
    float: none; }

.pane-wcmc-react-scheduling-timeslots {
  min-height: 430px; }
  .pane-wcmc-react-scheduling-timeslots h1 {
    text-align: center; }

.wcm-react-modal .modal-contents-wrapper {
  padding: 15px;
  text-align: center; }
  .lt-ie9 .wcm-react-modal .modal-contents-wrapper {
    padding: 20px; }
  @media screen and (min-width: 767px) {
    .wcm-react-modal .modal-contents-wrapper {
      padding: 20px; } }

.wcm-react-modal .modal-heading {
  padding: 0;
  margin-bottom: 20px; }

.wcm-react-modal h2 {
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  font-size: 18px;
  font-weight: normal; }

.visit-type-modal .modal-close {
  top: 5px;
  right: 5px; }

.visit-type-modal .visit-type-item {
  margin-bottom: 20px; }

.visit-type-modal .modal-container {
  height: auto;
  padding: 20px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .visit-type-modal .modal-container {
      padding: 10px; } }
  .lt-ie9 .visit-type-modal .modal-container {
    padding: 30px; }
  @media screen and (min-width: 985px) {
    .visit-type-modal .modal-container {
      padding: 30px; } }

.provider-scheduling-modal .modal-contents-wrapper {
  min-height: 380px; }

@media screen and (min-width: 0) and (max-width: 599px) {
  .provider-scheduling-modal > div:nth-child(2) > div {
    margin: 15px; } }

.provider-scheduling-modal .date-picker-wrapper {
  width: 100%;
  display: inline-block;
  text-align: center; }
  .lt-ie9 .provider-scheduling-modal .date-picker-wrapper {
    padding-right: 50px;
    text-align: left; }
  @media screen and (min-width: 767px) {
    .provider-scheduling-modal .date-picker-wrapper {
      padding-right: 50px;
      text-align: left; } }

.provider-scheduling-modal .cta-container {
  margin: 15px 0 0; }

.provider-scheduling-modal .timeslots-grid-container {
  width: 92%; }
  .lt-ie9 .provider-scheduling-modal .timeslots-grid-container {
    width: 62%; }
  @media screen and (min-width: 767px) {
    .provider-scheduling-modal .timeslots-grid-container {
      width: 62%; } }

.schedule-appointment-form .patient-identification,
.schedule-appointment-form .patient-information,
.schedule-appointment-form .insurance-information,
.schedule-appointment-form .back-button,
.schedule-appointment-form .captcha-and-submit,
.schedule-appointment-form .proxy-information {
  display: none; }

.schedule-appointment-form .insurance-list,
.schedule-appointment-form .relationship-list {
  width: 520px;
  max-width: initial; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .schedule-appointment-form .insurance-list,
    .schedule-appointment-form .relationship-list {
      width: 100% !important; } }

.form-details-wrapper,
.scheduling-method,
.captcha-and-submit {
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none; }
  .form-details-wrapper > .panel-body,
  .scheduling-method > .panel-body,
  .captcha-and-submit > .panel-body {
    padding: 0; }

.method-text {
  margin-top: 20px;
  padding: 0 20px; }
  .lt-ie9 .method-text {
    padding: 0; }
  @media screen and (min-width: 990px) {
    .method-text {
      padding: 0; } }
  .method-text .method-options {
    text-align: center; }
  .lt-ie9 .method-text .guest-option {
    padding: 0 60px 0 20px; }
  @media screen and (min-width: 990px) {
    .method-text .guest-option {
      padding: 0 60px 0 20px; } }
  .lt-ie9 .method-text .login-option {
    padding: 0 20px 0 60px; }
  @media screen and (min-width: 990px) {
    .method-text .login-option {
      padding: 0 20px 0 60px; } }
  .method-text .or-col span {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 18px;
    color: #555;
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
    text-align: left; }
    .lt-ie9 .method-text .or-col span {
      margin-left: -30px;
      margin-top: 50px;
      text-align: center; }
    @media screen and (min-width: 990px) {
      .method-text .or-col span {
        margin-left: -30px;
        margin-top: 50px;
        text-align: center; } }
  .method-text .title-text {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 17px;
    color: #b31b1b;
    margin-bottom: 15px;
    display: block; }

.appointment-information {
  margin-top: 20px;
  font-size: 14px; }
  .appointment-information .provider-headshot-wrapper {
    width: 125px; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .appointment-information .provider-headshot-wrapper {
        width: 100%;
        text-align: center; } }
  .appointment-information .provider-headshot {
    margin-bottom: 20px;
    border-radius: 5px; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .appointment-information .provider-headshot {
        width: 55%; } }
  .appointment-information .detail-heading {
    font-family: "1898Sans-Bold", sans-serif;
    color: #333;
    display: inline-block; }
  .appointment-information .provider-heading {
    display: inline-block;
    margin-bottom: 18px; }
    .lt-ie9 .appointment-information .provider-heading {
      margin-bottom: 0; }
    @media screen and (min-width: 600px) {
      .appointment-information .provider-heading {
        margin-bottom: 0; } }
  .appointment-information .location-label {
    display: inline-block;
    margin-top: 18px; }
    .lt-ie9 .appointment-information .location-label {
      margin-top: 0; }
    @media screen and (min-width: 767px) {
      .appointment-information .location-label {
        margin-top: 0; } }
  .appointment-information .detail-label {
    font-weight: bold;
    font-size: 13px;
    width: 150px;
    display: inline-block;
    margin-bottom: 4px; }
    .lt-ie9 .appointment-information .detail-label {
      margin-bottom: 15px; }
    @media screen and (min-width: 600px) {
      .appointment-information .detail-label {
        margin-bottom: 15px; } }
  .appointment-information .reason-for-visit {
    margin-top: 18px; }
    .lt-ie9 .appointment-information .reason-for-visit {
      margin-top: 0; }
    @media screen and (min-width: 600px) {
      .appointment-information .reason-for-visit {
        margin-top: 0; } }
  .appointment-information .phone {
    display: inline-block;
    margin-top: 5px; }
  .appointment-information .form-item-reason-for-visit {
    display: inline-block;
    margin-top: 18px; }
    .lt-ie9 .appointment-information .form-item-reason-for-visit {
      display: inline;
      margin-top: 0; }
    @media screen and (min-width: 767px) {
      .appointment-information .form-item-reason-for-visit {
        display: inline;
        margin-top: 0; } }

.appointment-info-title {
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  padding: 15px 0 0 15px; }

.appointment-info-wrapper {
  padding: 0.35em 0.625em 0; }

.schedule-appointment-form .btn {
  width: 200px; }

.schedule-appointment-form .back-button {
  margin: 0 0 25px 0;
  color: #fff; }
  .schedule-appointment-form .back-button:before {
    color: #fff !important; }

.schedule-appointment-form .form-item-insurance-name {
  display: none; }

.schedule-appointment-form .video-visit-inner-wrapper {
  margin-top: 15px; }

.schedule-appointment-form .form-item-contact-information {
  width: 100%;
  float: left;
  margin-right: 20px; }
  .schedule-appointment-form .form-item-contact-information label {
    display: inline-block;
    font-weight: bold; }

.schedule-appointment-form .form-item-visit-for {
  display: inline-block; }
  .schedule-appointment-form .form-item-visit-for.radio:last-child {
    margin-left: 37px; }
  .schedule-appointment-form .form-item-visit-for.form-group {
    margin-top: 20px;
    width: 100%; }

.proxy-information .form-item-proxy-contact-information {
  width: 100%;
  float: left;
  margin-right: 20px; }
  .proxy-information .form-item-proxy-contact-information label {
    font-weight: bold; }

.alert ul {
  margin-left: 20px; }

.page-schedule-an-appointment .phone,
.page-schedule-an-appointment .direct-scheduling-cta {
  display: none; }

.select-new-time-slot-wrapper {
  display: none; }
  .select-new-time-slot-wrapper .modal-title {
    font-size: 20px;
    line-height: 28px;
    font-family: "1898Sans-Bold", sans-serif;
    color: #555;
    text-align: center;
    margin-bottom: 20px; }
  .select-new-time-slot-wrapper .instruction-text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px; }
  .select-new-time-slot-wrapper .provider-name {
    font-weight: bold; }
  .select-new-time-slot-wrapper .button-wrapper {
    text-align: center;
    margin-top: 30px; }
  .select-new-time-slot-wrapper .back-to-search-results {
    margin-top: 20px;
    text-align: center; }
  .lt-ie9 .select-new-time-slot-wrapper {
    padding: 30px; }
  @media screen and (min-width: 767px) {
    .select-new-time-slot-wrapper {
      padding: 30px; } }
  .lt-ie9 .select-new-time-slot-wrapper {
    width: 980px; }
  @media screen and (min-width: 1200px) {
    .select-new-time-slot-wrapper {
      width: 980px; } }
  .select-new-time-slot-wrapper .google-map-react-wrapper,
  .select-new-time-slot-wrapper .visit-type-filter-wrapper {
    display: none; }
  .select-new-time-slot-wrapper .timegrid-datescrubber-container {
    background: transparent;
    padding-top: 15px;
    top: 0px !important; }
  .lt-ie9 .select-new-time-slot-wrapper .date-scrubber-wrapper {
    width: 422px; }
  @media screen and (min-width: 990px) {
    .select-new-time-slot-wrapper .date-scrubber-wrapper {
      width: 422px; } }
  .lt-ie9 .select-new-time-slot-wrapper .date-scrubber-wrapper {
    width: 520px; }
  @media screen and (min-width: 1200px) {
    .select-new-time-slot-wrapper .date-scrubber-wrapper {
      width: 520px; } }
  .lt-ie9 .select-new-time-slot-wrapper .timeslots-grid-container {
    width: 85%; }
  @media screen and (min-width: 990px) {
    .select-new-time-slot-wrapper .timeslots-grid-container {
      width: 85%; } }
  .select-new-time-slot-wrapper .cta-container {
    max-height: 183px;
    overflow: scroll; }

.fancybox-skin {
  background: #fff; }

.fancybox-overlay {
  z-index: 1000; }

.fancybox-opened {
  z-index: 1005; }

.fancybox-close {
  text-decoration: none;
  border-bottom: none; }
  .fancybox-close:hover {
    text-decoration: none;
    border-bottom: none; }

.ui-widget-content {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  border: 1px solid #c2dcd7;
  background: #d0ebe6;
  color: #444; }

.ui-widget-content a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  color: #444; }

.ui-widget-content a:hover {
  color: #036; }

/* Interaction states
----------------------------------*/
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555555;
  text-decoration: none; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #005d88;
  background: #005d88 url(../images/ui-bg_flat_75_005d88_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #fff; }

.ui-state-hover a,
.ui-state-hover a:hover {
  color: #fff;
  text-decoration: none; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #aaaaaa;
  background: #ffffff url(../images/ui-bg_flat_65_ffffff_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #212121; }

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121;
  text-decoration: none; }

.ui-widget :active {
  outline: none; }

/* =Blocks
----------------------------------------------------------------------------------*/
.best_doctor {
  margin: 15px 0;
  width: 165px;
  height: 344px;
  background: url(../images/dr_banner.png) center top no-repeat;
  float: left; }

.best_doctor a.top {
  width: 165px;
  height: 234px;
  float: left; }

div.best_doctor a.bottom {
  width: 165px;
  height: 110px;
  float: left; }

/* =Sidebars
----------------------------------------------------------*/
#sidebar-first,
#sidebar-second {
  font-size: 14px;
  line-height: 22px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  margin-bottom: 20px; }

#sidebar-first h2,
#sidebar-second h2 {
  font-size: 18px;
  line-height: 27px;
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: #423934;
  font-weight: normal;
  padding: 0 0 10px;
  margin: 0 0 10px;
  border-bottom: 1px dotted #aea89d; }

#sidebar-first ul li,
#sidebar-second ul li {
  list-style: none;
  line-height: 1.5;
  padding-bottom: 10px; }

#sidebar-second .panel-pane {
  clear: both; }

#sidebar-first .related-providers ul li a {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem;
  padding: 0; }

.jump-to-top {
  width: 200px; }
  .jump-to-top a {
    font-size: 1.5rem;
    width: 200px;
    border: none;
    color: #666;
    font-weight: 400; }
    .jump-to-top a:hover {
      text-decoration: none;
      color: #666; }
    .jump-to-top a:before {
      font-size: 15px;
      line-height: 22.5px;
      font-size: 1.5rem;
      line-height: 2.25rem;
      line-height: inherit;
      content: "\F500";
      font-family: "SSStandard";
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      text-rendering: optimizeLegibility;
      white-space: nowrap;
      display: block;
      color: #e7751d; }
    .jump-to-top a span {
      border-top: 1px solid #ddd;
      display: block;
      padding-top: 5px;
      margin-top: 5px; }

.landing-page-connect-logo {
  width: 400px;
  margin-top: 15px;
  margin-bottom: 30px; }

.wcc-title span {
  font-family: "1898Sans-Regular", sans-serif;
  color: #e7751d; }

.wcc-features {
  margin-top: 20px; }
  .wcc-features .cta-icon {
    width: 35px;
    height: 35px;
    float: left;
    margin-right: 15px;
    display: none; }
    .lt-ie9 .wcc-features .cta-icon {
      width: 50px;
      height: 50px; }
    @media screen and (min-width: 600px) {
      .wcc-features .cta-icon {
        width: 50px;
        height: 50px; } }
  .wcc-features .icon-title-wrapper {
    margin-top: 20px; }
    .wcc-features .icon-title-wrapper h4, .wcc-features .icon-title-wrapper .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .wcc-features .icon-title-wrapper .teaser-title {
      color: #423934;
      font-weight: normal;
      font-family: "1898Sans-Bold", sans-serif;
      line-height: 3.7rem; }
  .wcc-features .details-wrapper {
    margin-bottom: 10px;
    margin-top: 15px; }
    .lt-ie9 .wcc-features .details-wrapper {
      float: left; }
    @media screen and (min-width: 600px) {
      .wcc-features .details-wrapper {
        float: left; } }

.wcc-login-widget {
  margin: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative; }
  .wcc-login-widget .qtip {
    position: absolute;
    top: 7px;
    right: 7px; }
    .wcc-login-widget .qtip.tip-top:after {
      top: -25px; }
    .wcc-login-widget .qtip.tip-top:before {
      top: -10px;
      right: 10%;
      left: initial;
      transform: translate(10%, calc(-100% - 8px)); }
    .lt-ie9 .wcc-login-widget .qtip:before {
      min-width: 360px; }
    @media screen and (min-width: 767px) {
      .wcc-login-widget .qtip:before {
        min-width: 360px; } }
  .wcc-login-widget hr {
    width: 100px;
    border-top: 2px solid #ddd;
    margin: 15px auto 20px; }
  .wcc-login-widget .widget-title {
    font-size: 16px; }
  .wcc-login-widget h3 {
    margin-top: 0; }
  .wcc-login-widget .btn--wcm.external-link:before {
    content: ""; }
  .wcc-login-widget .label-text {
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 16px; }
  .wcc-login-widget .forgot-stuff {
    font-size: 11px;
    text-align: center;
    margin-top: 10px; }
    .wcc-login-widget .forgot-stuff a:first-of-type {
      margin-right: 10px; }
  .wcc-login-widget .form-text {
    margin-bottom: 15px; }
  .wcc-login-widget .btn {
    width: 95%;
    display: block;
    margin: 0 auto;
    font-size: 13px;
    line-height: 18px; }
  .wcc-login-widget .btn-primary {
    padding: 9px 0; }
  .wcc-login-widget .new-user {
    margin-top: 20px; }
    .wcc-login-widget .new-user .btn {
      width: 55%; }

.front .wcc-login-widget {
  width: 100%;
  float: left;
  margin: 20px 0; }
  .lt-ie9 .front .wcc-login-widget {
    width: 33.3%;
    margin: 0 20px 20px -20px; }
  @media screen and (min-width: 767px) {
    .front .wcc-login-widget {
      width: 33.3%;
      margin: 0 20px 20px -20px; } }

.app-store-badges {
  display: inline-block; }
  .app-store-badges a {
    float: left;
    width: 45%;
    margin-right: 10px;
    border-bottom: none; }
    .lt-ie9 .app-store-badges a {
      width: 160px;
      margin-right: 20px; }
    @media screen and (min-width: 990px) {
      .app-store-badges a {
        width: 160px;
        margin-right: 20px; } }

.app-store-badges-footer-wrapper {
  display: inline-block;
  position: relative; }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .app-store-badges-footer-wrapper {
      width: 60%; } }
  .lt-ie9 .app-store-badges-footer-wrapper {
    float: left; }
  @media screen and (min-width: 990px) {
    .app-store-badges-footer-wrapper {
      float: left; } }
  .lt-ie9 .app-store-badges-footer-wrapper {
    width: 200px; }
  @media screen and (min-width: 1200px) {
    .app-store-badges-footer-wrapper {
      width: 200px; } }
  .app-store-badges-footer-wrapper .link-wrapper {
    list-style: none;
    margin: 15px 0 0 0; }
  .app-store-badges-footer-wrapper .link-wrapper span,
  .app-store-badges-footer-wrapper .link-wrapper a {
    display: block;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #666666;
    font-size: 12px;
    margin-bottom: 10px; }
  .app-store-badges-footer-wrapper .link-wrapper span {
    margin-bottom: 0; }

.events-widget {
  margin: 40px 0;
  padding: 30px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center; }
  .events-widget hr {
    width: 100px;
    border-top: 2px solid #ddd;
    margin: 15px auto 20px; }
  .events-widget .widget-title {
    font-size: 16px; }
  .events-widget a {
    font-size: 14px; }
  .events-widget .view-calendar .cta-icon {
    width: 25px;
    height: 25px;
    position: relative;
    top: 7px;
    margin-right: 6px; }

.app-store-badges-footer ul li,
.app-store-badges-callout ul li {
  list-style: none; }

.app-store-badges-footer ul,
.app-store-badges-callout ul {
  margin: 0; }

.app-store-badges-footer a,
.app-store-badges-callout a {
  float: left;
  width: 45%;
  border-bottom: none; }

.app-store-badges-footer a {
  margin: 10px 10px 0px 0px; }
  .lt-ie9 .app-store-badges-footer a {
    width: 140px; }
  @media screen and (min-width: 990px) {
    .app-store-badges-footer a {
      width: 140px; } }

.lt-ie9 .app-store-badges-callout {
  padding: 10px; }

@media screen and (min-width: 990px) {
  .app-store-badges-callout {
    padding: 10px; } }

.app-store-badges-callout a {
  margin: 20px 10px auto;
  width: 90%; }
  .lt-ie9 .app-store-badges-callout a {
    width: 180px; }
  @media screen and (min-width: 990px) {
    .app-store-badges-callout a {
      width: 180px; } }

.app-store-badges-callout ul {
  display: flex; }

.app-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .lt-ie9 .app-summary {
    flex-direction: row; }
  @media screen and (min-width: 990px) {
    .app-summary {
      flex-direction: row; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .app-summary {
      text-align: center; } }

.wcc-links a {
  margin-right: 20px; }

.notice-widget {
  background: #f5f7f8;
  padding: 20px 25px 25px 20px;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px; }
  .notice-widget h3 {
    font-size: 17px;
    margin-top: 0; }
  .notice-widget .notice-icon {
    position: absolute;
    left: 20px;
    top: 30px; }
  .notice-widget .notice-icon:after {
    content: " \e813";
    font-family: "fontello";
    font-size: 45px;
    color: #838484; }
  .notice-widget .details {
    padding-left: 60px; }
  .notice-widget p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0; }
  .notice-widget .btn {
    margin: 10px auto 0;
    display: block;
    width: 140px; }

.contact-us {
  padding: 0 10px; }
  .contact-us h3 {
    font-size: 20px;
    border-bottom: none;
    margin-bottom: 0; }
  .contact-us p {
    margin-bottom: 3px; }
  .contact-us .cta-icon {
    width: 35px;
    height: 35px;
    margin-right: 9px;
    float: left;
    position: relative;
    margin-top: -5px; }

.guest-pay-wrapper {
  margin-top: 30px;
  padding: 0 10px; }
  .guest-pay-wrapper p {
    margin-bottom: 3px; }
  .guest-pay-wrapper .cta-icon {
    width: 35px;
    height: 35px;
    margin-right: 9px;
    float: left;
    position: relative;
    margin-top: -5px; }

/* =Connect Login
----------------------------------------------------------*/
#defaultColumn {
  background: #eaeaea;
  width: 213px;
  float: left;
  padding: 7px;
  border: 1px solid black;
  position: relative; }

#defaultColumn h3 {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem; }

#defaultColumn h3 .WCCLogo {
  background-image: url(../images/WCCLogo.png);
  -webkit-background-size: auto 34px;
  -moz-background-size: auto 34px;
  background-size: auto 34px;
  height: 34px;
  background-repeat: no-repeat;
  padding-top: 5px;
  margin: 0 auto; }

.lt-ie9 #defaultColumn h3 .WCCLogo {
  background-image: none;
  height: 30px;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='http://webmedia.med.cornell.edu/cmn/weillcornell/images/WCCLogo.png', sizingMethod='scale'); }

#defaultColumn span.login_title {
  float: right;
  margin-right: 2%;
  font-weight: 500; }

#defaultForm {
  width: 100%; }

#defaultForm > a:first-child {
  margin: 20px 0 0; }

#defaultColumn #defaultForm h3 {
  font-size: 10px;
  line-height: 15px;
  font-size: 1rem;
  line-height: 1.5rem;
  display: block;
  bottom: 10px;
  text-align: right;
  width: 215px;
  float: right;
  margin-bottom: 5px;
  padding: 0;
  font-weight: normal; }

#defaultForm a {
  font-size: 13px;
  line-height: 13px;
  font-size: 1.3rem;
  line-height: 1.3rem;
  display: block;
  text-align: right; }

#defaultFooter {
  float: left;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  margin: 10px 0 0 0;
  padding: 8px 0 0;
  width: 100%; }

#defaultFooter h2 {
  font-size: 1em;
  padding: 0; }

#defaultFooter h2 span {
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 400;
  font-family: sans-serif;
  margin-top: 0; }

#signupFooter ul {
  list-style: none;
  padding-left: 0;
  margin: 5px 0; }

#signupFooter ul li {
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  line-height: 1.4em;
  height: auto; }

#defaultColumn .button {
  font-size: 19px;
  line-height: 23px;
  font-size: 1.9rem;
  line-height: 2.3rem;
  color: #ca3510;
  text-align: center;
  display: block;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #ffffff;
  background-image: -ms-linear-gradient(top, white, #d6d6d6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#d6d6d6));
  background-image: -webkit-linear-gradient(top, white, #d6d6d6);
  background-image: -o-linear-gradient(top, white, #d6d6d6);
  background-image: linear-gradient(top, white, #d6d6d6);
  background-image: -moz-linear-gradient(top, white, #d6d6d6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  text-decoration: none;
  border-color: #dddddd #dddddd #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=0);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  margin: 4px auto;
  border: 1px solid rgba(0, 0, 0, 0.25); }

#defaultColumn .button:hover {
  text-decoration: none;
  background-color: #d6d6d6;
  background-position: 0 -30px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear; }

#defaultColumn .red {
  color: white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ca3510', endColorstr='#920401', GradientType=0);
  background-image: -moz-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -o-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -webkit-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: linear-gradient(0deg, #920401 0%, #ca3510 100%);
  background-color: #920401;
  text-shadow: 0 1px 1px #960000; }

#defaultColumn .red:hover {
  background-color: #920401; }

/* Patient Resource Center
----------------------------------------------------------*/
.view-online-resources .views-exposed-form {
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;
  margin-bottom: 25px; }
  .view-online-resources .views-exposed-form .form-select {
    max-width: initial;
    width: 215px; }
  .view-online-resources .views-exposed-form .btn-primary {
    background: #cf4520;
    border-color: #cf4520;
    margin-top: 30px; }
    .view-online-resources .views-exposed-form .btn-primary:hover {
      background: #e7751d;
      border-color: #e7751d; }

.view-online-resources .views-row {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee; }
  .view-online-resources .views-row:last-child {
    border-bottom: none; }

.view-online-resources .view-empty {
  margin-bottom: 30px; }

.view-online-resources p {
  padding-bottom: 0; }

.view-online-resources .resource-link {
  margin-bottom: 15px; }

.view-online-resources .resource-document {
  display: inline-block; }
  .view-online-resources .resource-document a {
    text-align: center; }
    .view-online-resources .resource-document a img {
      margin-bottom: 5px; }

.view-online-resources .document-icon {
  width: 35px; }

.view-online-resources .resource-video .media-vimeo-player {
  width: 100%; }

.tag-wrapper {
  margin: 10px 0; }
  .tag-wrapper .tag-label {
    font-weight: bold;
    display: inline-block; }
  .tag-wrapper .tags {
    color: #888;
    display: inline-block; }

.mmprc-localist-pane {
  margin-top: 20px; }
  .mmprc-localist-pane .view-teaser:last-child {
    border: none; }

.view-events-button,
.view-news-button {
  text-align: center;
  margin: 20px 0 30px; }

.view-news-button {
  padding-bottom: 30px;
  border-bottom: 1px solid #dddddd; }

.mmprc-map-widget {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 20px;
  position: relative; }
  .mmprc-map-widget #mmprc-map {
    width: 100%;
    height: 280px;
    margin-bottom: 10px; }
  .mmprc-map-widget .map-larger-view {
    position: absolute;
    top: 20px;
    background: #fff;
    z-index: 2;
    left: 20px; }
    .mmprc-map-widget .map-larger-view a {
      padding: 0.3em 1em;
      display: block;
      font-size: 12px;
      border-bottom: none; }
  .mmprc-map-widget h4, .mmprc-map-widget .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .mmprc-map-widget .teaser-title {
    margin-top: 5px;
    font-family: "1898Sans-Bold", sans-serif; }
  .mmprc-map-widget .mmprc-contact-details div {
    margin-bottom: 5px;
    font-size: 14px; }
  .mmprc-map-widget .btn-primary {
    margin-top: 15px;
    width: 100%; }

.email-button-wrapper {
  margin-bottom: 20px; }

#main-content .email-button-wrapper a {
  color: #fff; }
  #main-content .email-button-wrapper a:after {
    content: "";
    display: none; }

.back-button-wrapper {
  margin-bottom: 20px; }

.back-button {
  width: 100%; }
  .back-button.btn--wcm:before {
    content: "\e810";
    opacity: 1;
    left: 7%;
    color: #cf4520; }
  .back-button:hover.btn--wcm:before {
    color: #fff; }

.social-icons-wrapper {
  border-top: 1px solid #eee;
  margin: 10px 0; }
  .social-icons-wrapper h3 {
    font-size: 18px;
    color: #000;
    font-weight: normal;
    font-family: "1898Sans-Regular", sans-serif;
    text-align: center; }
  .social-icons-wrapper .social-icons {
    text-align: center; }
  .social-icons-wrapper .icon {
    display: inline-block;
    text-align: center;
    margin: 5px 15px; }
    .social-icons-wrapper .icon svg {
      width: 45px;
      height: 45px; }
    .social-icons-wrapper .icon a {
      color: #000;
      border-bottom: none; }
      .social-icons-wrapper .icon a:hover {
        text-decoration: underline; }

.form-item {
  margin-bottom: 20px; }

.webform-confirmation {
  margin: 20px 0; }

.webform-client-form h3 {
  padding-bottom: 0; }

.webform-client-form label {
  font-size: 14px; }

.webform-client-form .radio label,
.webform-client-form .checkbox label {
  font-size: 13px; }

.lt-ie9 .webform-client-form .half-n-half .form-type-checkbox, .lt-ie9
.webform-client-form .half-n-half .form-type-radio {
  width: 48%;
  display: inline-block; }

@media screen and (min-width: 985px) {
  .webform-client-form .half-n-half .form-type-checkbox,
  .webform-client-form .half-n-half .form-type-radio {
    width: 48%;
    display: inline-block; } }

.lt-ie9 .webform-client-form .half-n-half-component {
  width: 47%;
  display: inline-block; }

@media screen and (min-width: 985px) {
  .webform-client-form .half-n-half-component {
    width: 47%;
    display: inline-block; } }

.lt-ie9 .webform-client-form .webform-component-textfield.half-n-half-textfield {
  width: 47%;
  display: inline-block; }
  .lt-ie9 .webform-client-form .webform-component-textfield.half-n-half-textfield + .half-n-half-textfield {
    float: right;
    margin-right: 11px; }

@media screen and (min-width: 985px) {
  .webform-client-form .webform-component-textfield.half-n-half-textfield {
    width: 47%;
    display: inline-block; }
    .webform-client-form .webform-component-textfield.half-n-half-textfield + .half-n-half-textfield {
      float: right;
      margin-right: 11px; } }

.lt-ie9 .webform-client-form .thirds .form-type-checkbox, .lt-ie9
.webform-client-form .thirds .form-type-radio {
  width: 32%;
  display: inline-block; }

@media screen and (min-width: 985px) {
  .webform-client-form .thirds .form-type-checkbox,
  .webform-client-form .thirds .form-type-radio {
    width: 32%;
    display: inline-block; } }

.asterisk-description {
  color: #666;
  font-size: 14px; }
  .asterisk-description span {
    color: #b31b1b; }

.small-allcaps {
  color: #666;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase; }

.resources-form-gender,
.age-group-fieldset,
.send-info-fieldset {
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 0px; }
  .resources-form-gender .panel-heading,
  .age-group-fieldset .panel-heading,
  .send-info-fieldset .panel-heading {
    display: none; }
  .resources-form-gender .panel-body,
  .age-group-fieldset .panel-body,
  .send-info-fieldset .panel-body {
    padding: 0px; }

.appointment-cta-widget {
  text-align: center;
  margin: 20px 0 35px;
  padding: 20px 0;
  background: #f7f7f7; }
  .appointment-cta-widget h3,
  .appointment-cta-widget .small-allcaps {
    color: #666;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase; }
  .appointment-cta-widget h3 {
    margin: 0 0 15px;
    padding-bottom: 0; }
  .appointment-cta-widget .small-allcaps {
    margin: 15px 0; }
  .appointment-cta-widget hr {
    width: 55%;
    margin: 15px auto;
    border-top: 1px solid #e3e3e3; }
  .appointment-cta-widget .phone-number {
    font-size: 30px;
    line-height: 38px;
    margin: 20px 0; }
    .appointment-cta-widget .phone-number a {
      border-bottom: none;
      color: #161616; }
      .appointment-cta-widget .phone-number a:hover {
        text-decoration: underline; }
  .appointment-cta-widget .request-appointment {
    margin: 20px 0; }
    .appointment-cta-widget .request-appointment a {
      font-size: 14px; }
    .appointment-cta-widget .request-appointment a:hover {
      text-decoration: none; }
    .appointment-cta-widget .request-appointment a.btn {
      color: #b31b1b; }
      .appointment-cta-widget .request-appointment a.btn:hover, .appointment-cta-widget .request-appointment a.btn:active, .appointment-cta-widget .request-appointment a.btn:focus {
        text-decoration: none;
        color: #fff; }
    .appointment-cta-widget .request-appointment span {
      display: block; }
    .appointment-cta-widget .request-appointment .cta-icon {
      width: 55px;
      height: 55px; }
  .appointment-cta-widget .request-appointment a.btn.request-appointment-btn:hover {
    color: #fff; }

.program-cta-widget .request-appointment a {
  color: #b31b1b; }
  .program-cta-widget .request-appointment a:hover, .program-cta-widget .request-appointment a:active, .program-cta-widget .request-appointment a:focus {
    color: #b31b1b; }

.lt-ie9 .main-content .clinical-service-cta-widget, .lt-ie9
.main-content .clinical-service-fap-widget, .lt-ie9
.main-content .clinical-service-scheduling-cta, .lt-ie9
.main-content .video-visit-callout-wrapper {
  display: none; }

@media screen and (min-width: 600px) {
  .main-content .clinical-service-cta-widget,
  .main-content .clinical-service-fap-widget,
  .main-content .clinical-service-scheduling-cta,
  .main-content .video-visit-callout-wrapper {
    display: none; } }

@media screen and (min-width: 0) and (max-width: 599px) {
  .information-column .clinical-service-cta-widget,
  .information-column .clinical-service-fap-widget,
  .information-column .clinical-service-scheduling-cta,
  .information-column .video-visit-callout-wrapper {
    display: none; } }

.lt-ie9 .information-column .clinical-service-cta-widget, .lt-ie9
.information-column .clinical-service-fap-widget, .lt-ie9
.information-column .clinical-service-scheduling-cta, .lt-ie9
.information-column .video-visit-callout-wrapper {
  display: block; }

@media screen and (min-width: 600px) {
  .information-column .clinical-service-cta-widget,
  .information-column .clinical-service-fap-widget,
  .information-column .clinical-service-scheduling-cta,
  .information-column .video-visit-callout-wrapper {
    display: block; } }

.clinical-service-cta-widget .request-appointment .btn,
.program-cta-widget .request-appointment .btn {
  width: 80%; }
  .lt-ie9 .clinical-service-cta-widget .request-appointment .btn, .lt-ie9
  .program-cta-widget .request-appointment .btn {
    width: 260px; }
  @media screen and (min-width: 600px) {
    .clinical-service-cta-widget .request-appointment .btn,
    .program-cta-widget .request-appointment .btn {
      width: 260px; } }

.standard-widget {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 20px;
  position: relative; }
  .standard-widget h3 {
    color: #555;
    font-family: "1898Sans-Bold", sans-serif;
    margin-top: 0;
    padding-bottom: 4px; }

.clinical-service-phone-widget {
  margin: 20px 0 30px; }
  .clinical-service-phone-widget .phone-number {
    font-size: 20px;
    margin-bottom: 5px; }
    .clinical-service-phone-widget .phone-number .cta-icon {
      height: 25px;
      width: 25px;
      position: relative;
      top: 5px;
      margin-right: 7px; }
    .clinical-service-phone-widget .phone-number a {
      color: #000;
      cursor: default; }
      .clinical-service-phone-widget .phone-number a:hover, .clinical-service-phone-widget .phone-number a:active, .clinical-service-phone-widget .phone-number a:focus {
        text-decoration: none !important;
        outline: none; }

.main-content .clinical-service-phone-widget {
  margin: 10px 0 20px; }
  .main-content .clinical-service-phone-widget h3 {
    font-size: 17px; }
  .main-content .clinical-service-phone-widget .phone-number {
    font-size: 18px; }
    .main-content .clinical-service-phone-widget .phone-number .cta-icon {
      height: 20px;
      width: 20px;
      top: 4px; }
  .lt-ie9 .main-content .clinical-service-phone-widget {
    display: none; }
  @media screen and (min-width: 600px) {
    .main-content .clinical-service-phone-widget {
      display: none; } }

@media screen and (min-width: 0) and (max-width: 599px) {
  .information-column .clinical-service-phone-widget {
    display: none; } }

.lt-ie9 .information-column .clinical-service-phone-widget {
  display: block; }

@media screen and (min-width: 600px) {
  .information-column .clinical-service-phone-widget {
    display: block; } }

.view-clinical-services-report .form-select {
  max-width: initial; }

/* Section Navigation / Program Menu
----------------------------------------------------------*/
.section-navigation,
#block-menu-menu-headache-program {
  display: none; }
  .lt-ie9 .section-navigation, .lt-ie9
  #block-menu-menu-headache-program {
    display: block; }
  @media screen and (min-width: 990px) {
    .section-navigation,
    #block-menu-menu-headache-program {
      display: block; } }
  .section-navigation ul.menu,
  #block-menu-menu-headache-program ul.menu {
    background: #cf4520;
    width: 100%; }
    .section-navigation ul.menu li,
    #block-menu-menu-headache-program ul.menu li {
      font-weight: bold;
      font-size: 13px;
      float: left;
      list-style: none;
      zoom: 1;
      background: #cf4520;
      margin-bottom: 0; }
      .section-navigation ul.menu li:hover,
      #block-menu-menu-headache-program ul.menu li:hover {
        background: #b31b1b;
        color: #fff;
        position: relative; }
      .section-navigation ul.menu li a,
      #block-menu-menu-headache-program ul.menu li a {
        display: block;
        padding: 5px 26px;
        line-height: 26px;
        color: #fff;
        border-bottom: none; }
        .lt-ie9 .section-navigation ul.menu li a, .lt-ie9
        #block-menu-menu-headache-program ul.menu li a {
          padding: 5px 44px; }
        @media screen and (min-width: 1200px) {
          .section-navigation ul.menu li a,
          #block-menu-menu-headache-program ul.menu li a {
            padding: 5px 44px; } }
        .section-navigation ul.menu li a:hover,
        #block-menu-menu-headache-program ul.menu li a:hover {
          color: #fff;
          text-decoration: none; }
      .section-navigation ul.menu li:hover > ul,
      #block-menu-menu-headache-program ul.menu li:hover > ul {
        visibility: visible; }
    .section-navigation ul.menu ul,
    #block-menu-menu-headache-program ul.menu ul {
      width: 250px;
      visibility: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      padding-left: 0;
      margin-left: 0;
      padding-bottom: 0;
      z-index: 1; }
      .section-navigation ul.menu ul li,
      #block-menu-menu-headache-program ul.menu ul li {
        font-weight: normal;
        background: #f7f7f7;
        float: none;
        font-size: 13px; }
        .section-navigation ul.menu ul li a,
        #block-menu-menu-headache-program ul.menu ul li a {
          color: #cf4520;
          border-top: 1px solid #ddd;
          line-height: 19px;
          padding: 8px 26px; }
        .section-navigation ul.menu ul li:hover,
        #block-menu-menu-headache-program ul.menu ul li:hover {
          background: #fff; }
          .section-navigation ul.menu ul li:hover a,
          #block-menu-menu-headache-program ul.menu ul li:hover a {
            color: #cf4520; }
        .section-navigation ul.menu ul li:first-of-type a,
        #block-menu-menu-headache-program ul.menu ul li:first-of-type a {
          border: none; }
        .section-navigation ul.menu ul li ul,
        #block-menu-menu-headache-program ul.menu ul li ul {
          visibility: visible; }
          .section-navigation ul.menu ul li ul li,
          #block-menu-menu-headache-program ul.menu ul li ul li {
            font-size: 11px; }
            .section-navigation ul.menu ul li ul li a,
            #block-menu-menu-headache-program ul.menu ul li ul li a {
              padding-left: 40px; }

.section-navigation.pane-menu-block {
  width: 100%;
  text-align: center; }
  .section-navigation.pane-menu-block > ul {
    display: inline-block; }
  .section-navigation.pane-menu-block .menu {
    text-align: left; }

#block-menu-menu-headache-program {
  width: 100%;
  text-align: center; }
  #block-menu-menu-headache-program .content {
    display: inline-block; }
  #block-menu-menu-headache-program .menu {
    text-align: left; }

.standard-view-listing {
  margin-bottom: 30px; }
  .standard-view-listing h3 {
    padding-bottom: 5px; }
  .standard-view-listing .views-row {
    font-size: 14px;
    padding: 8px 10px 8px 0;
    border-bottom: 1px solid #f8f8f8; }

/* Programs
----------------------------------------------------------*/
.node-type-program #main {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: 0; }

.node-type-program #main-content {
  padding: 0; }

.node-type-program .inner-wrapper {
  margin: 0 -20px; }

.node-type-program .pane-node-field-hero-image .content {
  max-height: 420px;
  overflow: hidden; }

.node-type-program .pane-node-field-hero-image img {
  width: 100%; }

.node-type-program .pane-node-body {
  padding: 0 100px; }

.node-type-program .pane-node-body p,
.node-type-program .call-to-action-wrapper p {
  color: #737373;
  font-size: 18px;
  line-height: 30px;
  text-align: center; }

@media screen and (min-width: 0) and (max-width: 599px) {
  .node-type-program .pane-node-body,
  .node-type-program .call-to-action-wrapper {
    padding: 0 10px; }
    .node-type-program .pane-node-body p,
    .node-type-program .call-to-action-wrapper p {
      font-size: 14px;
      line-height: 20px; } }

.node-type-program .pane-node-body {
  margin: 30px 0 10px; }

.node-type-program .call-to-action-wrapper {
  margin: 25px 0; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .node-type-program .call-to-action-wrapper {
      margin: 10px 0 40px; } }
  .node-type-program .call-to-action-wrapper .call-to-action {
    text-align: center; }
    .node-type-program .call-to-action-wrapper .call-to-action .cta-text {
      margin-top: 20px; }
    .node-type-program .call-to-action-wrapper .call-to-action .phone-number {
      color: #e7751d;
      font-size: 20px;
      font-weight: bold; }

.node-type-program .program-locations-container .address-location {
  width: 100%; }
  .lt-ie9 .node-type-program .program-locations-container .address-location {
    width: 50%; }
  @media screen and (min-width: 767px) {
    .node-type-program .program-locations-container .address-location {
      width: 50%; } }
  .lt-ie9 .node-type-program .program-locations-container .address-location {
    width: 25%; }
  @media screen and (min-width: 1200px) {
    .node-type-program .program-locations-container .address-location {
      width: 25%; } }

.node-type-program .image-callouts-a .container {
  margin: 0; }

.node-type-program .image-callouts-a .row:first-of-type {
  padding: 20px 0 10px; }

.node-type-program .image-callouts-a .image-tile-callout {
  height: 220px;
  padding-top: 100px; }
  .node-type-program .image-callouts-a .image-tile-callout span {
    font-size: 24px;
    line-height: 28px; }

.social-buttons {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  margin: 30px 0; }
  .social-buttons .container {
    padding: 25px 120px 20px; }
  .social-buttons .small-allcaps {
    padding-top: 15px;
    display: inline-block; }
  .social-buttons .callout {
    display: block;
    text-align: center;
    padding: 20px 0;
    border-bottom: none; }
    .social-buttons .callout:hover {
      text-decoration: underline; }
  .social-buttons svg.cta-icon {
    width: 50px;
    height: 50px; }

.program-locations-container {
  margin-top: 30px; }
  .program-locations-container #map-canvas {
    width: 100%;
    height: 340px;
    margin: 0;
    border: none; }
  .program-locations-container .addresses-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd; }
  .program-locations-container .address-location {
    width: 100%;
    margin: 0;
    clear: none;
    float: left; }
    .lt-ie9 .program-locations-container .address-location {
      width: 50%; }
    @media screen and (min-width: 767px) {
      .program-locations-container .address-location {
        width: 50%; } }
    .program-locations-container .address-location .practice-count {
      float: left;
      width: 60px;
      padding: 2px 0 2px 20px;
      font-size: 2.5rem;
      line-height: 3rem;
      color: #e7751d; }
      .lt-ie9 .program-locations-container .address-location .practice-count {
        width: 13%;
        padding-left: 0; }
      @media screen and (min-width: 1200px) {
        .program-locations-container .address-location .practice-count {
          width: 13%;
          padding-left: 0; } }
    .program-locations-container .address-location .practice-address {
      width: 80%; }
      .lt-ie9 .program-locations-container .address-location .practice-address {
        width: 87%; }
      @media screen and (min-width: 1200px) {
        .program-locations-container .address-location .practice-address {
          width: 87%; } }
    .program-locations-container .address-location .practice-name {
      font-size: 14px;
      padding-bottom: 5px;
      display: inline-block; }
    .program-locations-container .address-location .address {
      width: 97%;
      float: left; }
    .program-locations-container .address-location:hover {
      cursor: pointer; }
      .program-locations-container .address-location:hover .practice-name {
        text-decoration: underline; }
  .program-locations-container .location-contact-details {
    display: none; }

.program-title,
#main h1.program-title {
  color: #666;
  font-size: 30px;
  line-height: 36px;
  font-family: "1898Sans-Bold", sans-serif;
  text-align: center;
  padding: 5px 0 30px 0;
  display: inline-block;
  width: 100%; }
  .program-title a,
  #main h1.program-title a {
    color: #666;
    font-weight: normal;
    border-bottom: none; }
    .program-title a:hover,
    #main h1.program-title a:hover {
      text-decoration: underline; }
    .program-title a:after,
    #main h1.program-title a:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
      color: #e7751d;
      vertical-align: initial; }

.program-secondary-actions,
.program-secondary-actions-contact {
  border-top: 1px solid #dddddd;
  margin-top: 30px;
  padding-top: 30px; }

.program-contact-body-wrapper,
.program-contact-referring-physicians-wrapper {
  width: 50%;
  float: left;
  margin-bottom: 30px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .program-contact-body-wrapper,
    .program-contact-referring-physicians-wrapper {
      width: 100%; } }

.program-contact-body-wrapper {
  padding-right: 20px; }

.program-contact-referring-physicians-wrapper {
  padding-left: 20px; }

.node-type-landing-page .brand__nav {
  background-color: #cf4520;
  margin: 0;
  padding: 0; }
  .node-type-landing-page .brand__nav .navbar-nav > li > a {
    display: inline-block; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .node-type-landing-page .brand__nav .navbar-nav > li > a {
        line-height: 20px;
        padding: 10px;
        font-size: 1.2rem; } }

.lt-ie9 .node-type-landing-page .file-video-youtube {
  text-align: center; }

@media screen and (min-width: 767px) {
  .node-type-landing-page .file-video-youtube {
    text-align: center; } }

.node-type-landing-page .header .section-title-a {
  margin-top: 5px;
  text-align: left; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .node-type-landing-page .header .section-title-a {
      text-align: center; } }

@media screen and (min-width: 0) and (max-width: 599px) {
  .node-type-landing-page .header p {
    text-align: center; } }

.node-type-landing-page .featured-review-background-image {
  margin-bottom: 0px !important;
  padding-bottom: 30px !important;
  overflow: hidden;
  background-image: none !important; }

.node-type-landing-page .read-more-wrapper {
  margin-bottom: 30px;
  justify-content: center;
  display: flex; }

.white-link {
  color: #fff;
  border-bottom: 1px dotted #fff; }
  .white-link:hover {
    color: #fff;
    border-bottom: 1px solid #fff; }

.photo-callout {
  background: url(https://weillcornell.org/sites/default/files/styles/panopoly_image_original/public/patient-care.jpg) center no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;
  margin: 15px 0 30px 0; }
  .photo-callout .inner-content {
    text-align: center;
    padding: 30px 0;
    width: 70%;
    margin: 0 auto; }
    .lt-ie9 .photo-callout .inner-content {
      padding: 60px 0;
      width: 40%; }
    @media screen and (min-width: 990px) {
      .photo-callout .inner-content {
        padding: 60px 0;
        width: 40%; } }
    .photo-callout .inner-content .title {
      margin: 20px 0 10px;
      color: white;
      font-weight: normal;
      font-family: "1898Sans-Bold", sans-serif;
      font-size: 2.4rem;
      line-height: 3rem; }
      .lt-ie9 .photo-callout .inner-content .title {
        margin: 30px 0 20px;
        font-size: 3.8rem;
        line-height: 4.6rem; }
      @media screen and (min-width: 990px) {
        .photo-callout .inner-content .title {
          margin: 30px 0 20px;
          font-size: 3.8rem;
          line-height: 4.6rem; } }
    .photo-callout .inner-content .blurb p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: white; }
      .lt-ie9 .photo-callout .inner-content .blurb p {
        font-size: 1.6rem;
        line-height: 2.2rem; }
      @media screen and (min-width: 990px) {
        .photo-callout .inner-content .blurb p {
          font-size: 1.6rem;
          line-height: 2.2rem; } }

.custom-logo {
  position: relative; }
  .lt-ie9 .custom-logo img {
    height: 80px; }
  @media screen and (min-width: 600px) {
    .custom-logo img {
      height: 80px; } }
  @media screen and (max-width: 850px) {
    .custom-logo img {
      margin-right: 50px;
      display: block; } }
  .custom-logo .contact-info {
    display: inline;
    right: 0px;
    margin-right: 20px;
    font-size: 18px;
    color: #b31b1b;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    line-height: 27px; }
    .custom-logo .contact-info a {
      text-decoration: underline; }
    .lt-ie9 .custom-logo .contact-info {
      position: absolute; }
    @media screen and (min-width: 767px) {
      .custom-logo .contact-info {
        position: absolute; } }
    @media screen and (max-width: 850px) {
      .custom-logo .contact-info {
        width: 100%;
        float: left;
        display: block;
        padding-top: 15px; } }

.program-landing-page {
  padding-top: 0 !important; }
  .program-landing-page .brand__nav {
    background-color: #cf4520;
    margin: 0;
    padding: 0; }
    .program-landing-page .brand__nav .navbar-nav > li > a {
      display: inline-block; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .program-landing-page .brand__nav .navbar-nav > li > a {
          line-height: 20px; } }
  .program-landing-page hr.red-divider {
    margin-top: 0; }
  .program-landing-page .global-links a {
    color: #fff;
    transition: color 0.25s;
    border: none; }
    .program-landing-page .global-links a:hover {
      background: none;
      color: #fff;
      text-decoration: underline; }
  .program-landing-page header {
    padding: 20px 0 10px 0;
    height: auto; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .program-landing-page .header-component {
      background-image: none;
      min-height: 250px; }
      .program-landing-page .header-component .header-panel {
        padding: 35px 0; } }
  .program-landing-page #main {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 0; }
  .program-landing-page #main-content {
    padding: 0; }
  .program-landing-page .inner-wrapper {
    margin: 0 -20px; }
  .program-landing-page .video-player-wrapper {
    margin: 40px 0;
    background: #555; }
  .lt-ie9 .program-landing-page .callout {
    min-height: 180px; }
  @media screen and (min-width: 600px) {
    .program-landing-page .callout {
      min-height: 180px; } }
  .program-landing-page .col-md-3 {
    padding-top: 10px;
    padding-bottom: 10px; }
    .lt-ie9 .program-landing-page .col-md-3 {
      padding-top: 0px;
      padding-bottom: 0px; }
    @media screen and (min-width: 990px) {
      .program-landing-page .col-md-3 {
        padding-top: 0px;
        padding-bottom: 0px; } }
  .program-landing-page .image-tile-callout.adult-kidney, .program-landing-page .image-tile-callout.living-kidney {
    background-image: url(http://wcmcpops.lndo.site/sites/default/files/styles/panopoly_image_original/public/vascular-biology.jpg?itok=OX0aHXf0); }
  .program-landing-page .image-tile-callout.pediatric-kidney {
    background-image: url(http://wcmcpops.lndo.site/sites/default/files/styles/panopoly_image_original/public/metabolism-and-diabetes.jpg?itok=EEu3Fgrn); }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .program-landing-page .image-tile-callout.financial-district, .program-landing-page .image-tile-callout.tribeca {
      margin-bottom: 20px; } }
  @media screen and (min-width: 600px) and (max-width: 769px) {
    .program-landing-page .image-tile-callout.financial-district, .program-landing-page .image-tile-callout.tribeca {
      margin-bottom: 20px; } }
  .program-landing-page .fieldable-panels-pane .field-basic-text-text iframe {
    aspect-ratio: 16 / 9;
    width: 100%; }

.locations-listing h2 {
  padding-bottom: 0;
  margin-top: 20px; }
  .lt-ie9 .locations-listing h2 {
    margin-top: 30px; }
  @media screen and (min-width: 768px) {
    .locations-listing h2 {
      margin-top: 30px; } }

.locations-listing hr {
  margin: 15px 0 0 0; }

.locations-listing .location {
  border-bottom: 1px solid #eee;
  padding: 0 0 10px;
  margin: 10px 0; }
  .lt-ie9 .locations-listing .location {
    padding: 20px 0;
    margin: 20px 0; }
  @media screen and (min-width: 768px) {
    .locations-listing .location {
      padding: 20px 0;
      margin: 20px 0; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .locations-listing .location .practice-photo {
      height: 170px;
      overflow: hidden; }
      .locations-listing .location .practice-photo img {
        margin-top: -30px; } }
  .lt-ie9 .locations-listing .location .practice-photo {
    height: 220px;
    overflow: hidden; }
  @media screen and (min-width: 768px) {
    .locations-listing .location .practice-photo {
      height: 220px;
      overflow: hidden; } }
  .locations-listing .location .title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-family: "1898Sans-Regular", sans-serif;
    color: #b31b1b;
    padding-top: 5px; }
    .lt-ie9 .locations-listing .location .title {
      font-size: 1.8rem;
      line-height: 2.6rem;
      padding-top: 10px; }
    @media screen and (min-width: 990px) {
      .locations-listing .location .title {
        font-size: 1.8rem;
        line-height: 2.6rem;
        padding-top: 10px; } }
  .locations-listing .location .address,
  .locations-listing .location .phone-number {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #333;
    padding-bottom: 5px; }
    .lt-ie9 .locations-listing .location .address, .lt-ie9
    .locations-listing .location .phone-number {
      font-size: 1.6rem;
      line-height: 2.4rem; }
    @media screen and (min-width: 990px) {
      .locations-listing .location .address,
      .locations-listing .location .phone-number {
        font-size: 1.6rem;
        line-height: 2.4rem; } }
  .locations-listing .location .detail-label {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #666;
    font-weight: bold; }
  .locations-listing .location .view-location-button {
    margin: 10px 0; }

hr.red-divider {
  border-top: 5px solid #cf4520; }

hr.no-margins {
  margin: 0; }

.intro-wrapper {
  text-align: center; }
  .lt-ie9 .intro-wrapper {
    width: 80%;
    margin: 0 auto; }
  @media screen and (min-width: 990px) {
    .intro-wrapper {
      width: 80%;
      margin: 0 auto; } }

.section-title-a {
  text-align: center;
  font-size: 2rem;
  line-height: 2.6rem;
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  margin-top: 35px;
  margin-bottom: 15px; }
  .lt-ie9 .section-title-a {
    font-size: 3rem;
    line-height: 3.6rem; }
  @media screen and (min-width: 990px) {
    .section-title-a {
      font-size: 3rem;
      line-height: 3.6rem; } }

.section-blurb-a {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #555;
  margin-bottom: 30px; }
  .section-blurb-a p {
    font-size: 1.4rem;
    line-height: 1.8rem; }
  .lt-ie9 .section-blurb-a {
    font-size: 1.8rem;
    line-height: 2.6rem; }
    .lt-ie9 .section-blurb-a p {
      font-size: 1.8rem;
      line-height: 2.6rem; }
  @media screen and (min-width: 990px) {
    .section-blurb-a {
      font-size: 1.8rem;
      line-height: 2.6rem; }
      .section-blurb-a p {
        font-size: 1.8rem;
        line-height: 2.6rem; } }

.section-title-buttons .btn--wcm {
  display: block;
  margin: 10px auto; }
  .lt-ie9 .section-title-buttons .btn--wcm {
    margin-right: 10px;
    display: inline-block; }
  @media screen and (min-width: 990px) {
    .section-title-buttons .btn--wcm {
      margin-right: 10px;
      display: inline-block; } }

.landing-breadcrumbs {
  font-size: 1.4rem; }

.callout-tiles .callout-tile {
  text-align: center;
  border-bottom: 5px solid #b31b1b;
  margin-top: 10px;
  margin-bottom: 20px; }
  .callout-tiles .callout-tile .photo-wrapper {
    overflow: hidden;
    height: 120px; }
    .lt-ie9 .callout-tiles .callout-tile .photo-wrapper {
      height: 100px; }
    @media screen and (min-width: 767px) {
      .callout-tiles .callout-tile .photo-wrapper {
        height: 100px; } }
    .lt-ie9 .callout-tiles .callout-tile .photo-wrapper {
      height: 140px; }
    @media screen and (min-width: 990px) {
      .callout-tiles .callout-tile .photo-wrapper {
        height: 140px; } }
    .lt-ie9 .callout-tiles .callout-tile .photo-wrapper {
      height: 180px; }
    @media screen and (min-width: 1200px) {
      .callout-tiles .callout-tile .photo-wrapper {
        height: 180px; } }
  .callout-tiles .callout-tile .callout-label {
    margin-top: 12px;
    margin-bottom: 8px;
    color: #b31b1b;
    font-weight: normal;
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem; }
    .lt-ie9 .callout-tiles .callout-tile .callout-label {
      margin-top: 16px;
      margin-bottom: 13px;
      font-size: 2.2rem;
      line-height: 2.8rem; }
    @media screen and (min-width: 990px) {
      .callout-tiles .callout-tile .callout-label {
        margin-top: 16px;
        margin-bottom: 13px;
        font-size: 2.2rem;
        line-height: 2.8rem; } }
  .callout-tiles .callout-tile .learn-more-button {
    margin-bottom: 20px; }

.callout-cards .callout-card {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 30px;
  border-bottom: none;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); }
  .callout-cards .callout-card:hover {
    border-bottom: none; }
  .callout-cards .callout-card .photo-wrapper {
    overflow: hidden;
    height: 120px; }
    .lt-ie9 .callout-cards .callout-card .photo-wrapper {
      height: 100px; }
    @media screen and (min-width: 767px) {
      .callout-cards .callout-card .photo-wrapper {
        height: 100px; } }
    .lt-ie9 .callout-cards .callout-card .photo-wrapper {
      height: 140px; }
    @media screen and (min-width: 990px) {
      .callout-cards .callout-card .photo-wrapper {
        height: 140px; } }
    .lt-ie9 .callout-cards .callout-card .photo-wrapper {
      height: 160px; }
    @media screen and (min-width: 1200px) {
      .callout-cards .callout-card .photo-wrapper {
        height: 160px; } }
  .callout-cards .callout-card .callout-label {
    margin-top: 25px;
    margin-bottom: 8px;
    color: #b31b1b;
    font-weight: normal;
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem; }
    .lt-ie9 .callout-cards .callout-card .callout-label {
      margin-top: 25px;
      margin-bottom: 9px;
      font-size: 2rem;
      line-height: 2.6rem; }
    @media screen and (min-width: 990px) {
      .callout-cards .callout-card .callout-label {
        margin-top: 25px;
        margin-bottom: 9px;
        font-size: 2rem;
        line-height: 2.6rem; } }
    .callout-cards .callout-card .callout-label:before {
      content: "\e802" !important;
      font-family: "fontello";
      position: absolute;
      color: #ef8e3c;
      font-size: 16px;
      line-height: 30px;
      height: 50px;
      width: 50px;
      text-align: center;
      display: block;
      left: 45%;
      margin-top: -50px;
      border: 10px solid #fff;
      border-radius: 25px;
      background-color: #fff;
      margin-bottom: 35px;
      padding-left: 2px; }
  .callout-cards .callout-card .learn-more-button {
    margin-bottom: 20px; }
  .callout-cards .callout-card .blurb {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #333; }
    .lt-ie9 .callout-cards .callout-card .blurb {
      font-size: 1.4rem;
      line-height: 2rem; }
    @media screen and (min-width: 990px) {
      .callout-cards .callout-card .blurb {
        font-size: 1.4rem;
        line-height: 2rem; } }

.lt-ie9 .marketing-callouts {
  width: 80%;
  margin: 30px auto; }

@media screen and (min-width: 990px) {
  .marketing-callouts {
    width: 80%;
    margin: 30px auto; } }

.marketing-callouts .marketing-callout {
  text-align: center;
  margin: 10px 0; }
  .lt-ie9 .marketing-callouts .marketing-callout {
    margin: 20px 0; }
  @media screen and (min-width: 990px) {
    .marketing-callouts .marketing-callout {
      margin: 20px 0; } }
  .marketing-callouts .marketing-callout .cta-icon {
    width: 40px;
    height: 40px; }
    .lt-ie9 .marketing-callouts .marketing-callout .cta-icon {
      width: 50px;
      height: 50px; }
    @media screen and (min-width: 600px) {
      .marketing-callouts .marketing-callout .cta-icon {
        width: 50px;
        height: 50px; } }
  .marketing-callouts .marketing-callout .callout-label {
    margin-top: 10px;
    color: #423934;
    font-weight: normal;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem; }
    .lt-ie9 .marketing-callouts .marketing-callout .callout-label {
      margin-top: 20px;
      font-size: 1.8rem;
      line-height: 2.4rem; }
    @media screen and (min-width: 990px) {
      .marketing-callouts .marketing-callout .callout-label {
        margin-top: 20px;
        font-size: 1.8rem;
        line-height: 2.4rem; } }

.sticky-banner {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1899; }

@media screen and (min-width: 770px) {
  .pane-wcmc-landing-page-map .program-locations-container .address-location {
    width: 25%; } }

.lt-ie9 .pane-wcmc-landing-page-map .program-locations-container .address-location {
  height: 120px; }

@media screen and (min-width: 990px) {
  .pane-wcmc-landing-page-map .program-locations-container .address-location {
    height: 120px; } }

.pane-wcmc-landing-page-map #map-canvas {
  height: 1000px !important; }

.pane-wcmc-landing-page-map .address-location .schedule-btn-wrapper {
  padding-top: 5px; }

.pane-wcmc-landing-page-map .h2 a:after {
  display: none !important;
  content: none; }

.landing-map {
  padding-top: 20px; }

@media screen and (min-width: 992px) {
  .bar-callout__text {
    font-size: 15px; } }

@media screen and (min-width: 768px) {
  .bar-callout__text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    margin: 0;
    font-size: 13px; } }

@media screen and (min-width: 992px) {
  .bar-callout__text .bar-callout__title {
    font-size: 18px; } }

@media screen and (min-width: 992px) {
  .bar-callout__text .bar-callout__description {
    font-size: 18px;
    line-height: 1.6; } }

.providers {
  margin-top: 1em;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap; }

.provider-images {
  width: 80px;
  float: left;
  margin-right: 15px;
  border-radius: 0; }
  .lt-ie9 .provider-images {
    display: inline-block;
    width: 144px;
    height: 192px;
    float: none;
    margin-right: 0; }
  @media screen and (min-width: 600px) {
    .provider-images {
      display: inline-block;
      width: 144px;
      height: 192px;
      float: none;
      margin-right: 0; } }

.provider {
  margin-bottom: 1em;
  width: 100%;
  padding-left: 1em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
  .lt-ie9 .provider {
    margin-bottom: 2em;
    text-align: center;
    padding-left: 0em;
    width: 48%;
    margin-right: 2%; }
  @media screen and (min-width: 600px) {
    .provider {
      margin-bottom: 2em;
      text-align: center;
      padding-left: 0em;
      width: 48%;
      margin-right: 2%; } }
  .lt-ie9 .provider {
    width: 31%;
    margin-bottom: 10px;
    min-height: 290px; }
  @media screen and (min-width: 1200px) {
    .provider {
      width: 31%;
      margin-bottom: 10px;
      min-height: 290px; } }
  .provider .provider-name {
    font-weight: bold;
    margin-top: 5px; }
    .provider .provider-name a {
      color: #000;
      border-bottom: none; }
      .provider .provider-name a:hover {
        text-decoration: underline; }
      .provider .provider-name a:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 75%;
        padding-left: 5px;
        line-height: 15px;
        display: inline-block;
        color: #e7751d; }
  .provider .provider-specialty {
    margin: 0 0 0.5em; }

.providers-grid-listing .view-content:after {
  clear: both;
  content: "";
  display: block; }

.pane-provider-listing-grid-listing-pane .providers {
  display: block; }

.pane-provider-listing-grid-listing-pane .provider {
  float: left; }

.homepage-tiles-wrapper {
  text-align: center; }
  .lt-ie9 .homepage-tiles-wrapper {
    padding-top: 20px;
    margin-bottom: 30px; }
  @media screen and (min-width: 600px) {
    .homepage-tiles-wrapper {
      padding-top: 20px;
      margin-bottom: 30px; } }

.affiliation-wrapper {
  text-align: center;
  display: none; }
  .affiliation-wrapper .logos-wrapper {
    margin-bottom: 20px; }
  .affiliation-wrapper span {
    height: 40px;
    display: inline-block; }
    .lt-ie9 .affiliation-wrapper span:first-of-type {
      border-right: 1px solid #e7e7e7; }
    @media screen and (min-width: 600px) {
      .affiliation-wrapper span:first-of-type {
        border-right: 1px solid #e7e7e7; } }
  .affiliation-wrapper img.wcm-logo {
    height: 21px;
    position: relative; }
    .lt-ie9 .affiliation-wrapper img.wcm-logo {
      padding-right: 20px;
      top: 8px; }
    @media screen and (min-width: 600px) {
      .affiliation-wrapper img.wcm-logo {
        padding-right: 20px;
        top: 8px; } }
  .affiliation-wrapper img.nyp-logo {
    height: 32px;
    position: relative; }
    .lt-ie9 .affiliation-wrapper img.nyp-logo {
      padding-left: 15px;
      top: 15px; }
    @media screen and (min-width: 600px) {
      .affiliation-wrapper img.nyp-logo {
        padding-left: 15px;
        top: 15px; } }

.homepage-tiles {
  width: 80%; }
  .lt-ie9 .homepage-tiles {
    width: initial;
    display: inline-block; }
  @media screen and (min-width: 767px) {
    .homepage-tiles {
      width: initial;
      display: inline-block; } }
  .homepage-tiles .wcm-cta__tile {
    width: 50%; }
    .lt-ie9 .homepage-tiles .wcm-cta__tile {
      width: 160px;
      border: none;
      border-right: 1px solid #ddd; }
      .lt-ie9 .homepage-tiles .wcm-cta__tile:nth-child(4) {
        border: none; }
    @media screen and (min-width: 767px) {
      .homepage-tiles .wcm-cta__tile {
        width: 160px;
        border: none;
        border-right: 1px solid #ddd; }
        .homepage-tiles .wcm-cta__tile:nth-child(4) {
          border: none; } }
    .lt-ie9 .homepage-tiles .wcm-cta__tile {
      width: 200px; }
    @media screen and (min-width: 990px) {
      .homepage-tiles .wcm-cta__tile {
        width: 200px; } }

.wcc_connect-app-promotion {
  border-top: 5px solid #e87722;
  margin-top: 10px; }
  .wcc_connect-app-promotion .connect-form-wrapper {
    width: 100%; }
    .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper {
      display: table; }
    @media screen and (min-width: 767px) {
      .wcc_connect-app-promotion .connect-form-wrapper {
        display: table; } }
    .wcc_connect-app-promotion .connect-form-wrapper .connect-info {
      display: table-cell;
      vertical-align: top; }
      .wcc_connect-app-promotion .connect-form-wrapper .connect-info .homepage-connect-logo {
        width: 400px;
        display: inline-block;
        text-align: center;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 15px; }
        .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .connect-info .homepage-connect-logo {
          margin: 40px 0px 20px 135px;
          font-size: 4rem;
          line-height: 6rem; }
        @media screen and (min-width: 767px) {
          .wcc_connect-app-promotion .connect-form-wrapper .connect-info .homepage-connect-logo {
            margin: 40px 0px 20px 135px;
            font-size: 4rem;
            line-height: 6rem; } }
        @media screen and (min-width: 767px) and (max-width: 989px) {
          .wcc_connect-app-promotion .connect-form-wrapper .connect-info .homepage-connect-logo {
            margin: 40px 0px 20px 85px; } }
      .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell {
        color: #b31b1b;
        font-size: 3.5rem;
        line-height: 5rem;
        display: inline-block;
        text-align: center;
        margin-top: 10px;
        margin-left: 10px; }
        .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell {
          margin: 40px 0px 0px 125px;
          font-size: 4rem;
          line-height: 6rem; }
        @media screen and (min-width: 767px) {
          .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell {
            margin: 40px 0px 0px 125px;
            font-size: 4rem;
            line-height: 6rem; } }
        @media screen and (min-width: 767px) and (max-width: 989px) {
          .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell {
            margin: 40px 0px 0px 85px; } }
        .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell .wcc-connect {
          font-family: "1898Sans-Regular", sans-serif;
          color: #e87722;
          font-size: 3.5rem;
          font-weight: 400;
          display: inline-block; }
          .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell .wcc-connect {
            font-size: 4rem; }
          @media screen and (min-width: 767px) {
            .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-cornell .wcc-connect {
              font-size: 4rem; } }
      .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-links {
        text-align: center; }
        .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-links {
          margin: 30px 0px 30px 160px;
          text-align: left; }
        @media screen and (min-width: 767px) {
          .wcc_connect-app-promotion .connect-form-wrapper .connect-info .wcc-links {
            margin: 30px 0px 30px 160px;
            text-align: left; } }
    .wcc_connect-app-promotion .connect-form-wrapper ul {
      margin-left: 85px; }
      .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper ul {
        margin-left: 160px; }
      @media screen and (min-width: 767px) {
        .wcc_connect-app-promotion .connect-form-wrapper ul {
          margin-left: 160px; } }
    .wcc_connect-app-promotion .connect-form-wrapper .wcc-wrapper {
      margin-top: 20px; }
      .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .wcc-wrapper {
        margin-top: 0;
        display: table-cell; }
      @media screen and (min-width: 767px) {
        .wcc_connect-app-promotion .connect-form-wrapper .wcc-wrapper {
          margin-top: 0;
          display: table-cell; } }
    .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .wcc-login-widget {
      margin-right: 30px;
      margin-top: 100px; }
    @media screen and (min-width: 767px) {
      .wcc_connect-app-promotion .connect-form-wrapper .wcc-login-widget {
        margin-right: 30px;
        margin-top: 100px; } }
    .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .wcc-login-widget {
      margin-right: 100px;
      margin-top: 100px; }
    @media screen and (min-width: 990px) {
      .wcc_connect-app-promotion .connect-form-wrapper .wcc-login-widget {
        margin-right: 100px;
        margin-top: 100px; } }
    .lt-ie9 .wcc_connect-app-promotion .connect-form-wrapper .wcc-login-widget {
      margin-right: 205px;
      margin-top: 100px; }
    @media screen and (min-width: 1200px) {
      .wcc_connect-app-promotion .connect-form-wrapper .wcc-login-widget {
        margin-right: 205px;
        margin-top: 100px; } }
  .wcc_connect-app-promotion .download-app-wrapper {
    display: table; }
    .wcc_connect-app-promotion .download-app-wrapper .iphone-wcm-app {
      display: block; }
      .lt-ie9 .wcc_connect-app-promotion .download-app-wrapper .iphone-wcm-app {
        padding-left: 125px;
        display: table-cell; }
      @media screen and (min-width: 767px) {
        .wcc_connect-app-promotion .download-app-wrapper .iphone-wcm-app {
          padding-left: 125px;
          display: table-cell; } }
      .wcc_connect-app-promotion .download-app-wrapper .iphone-wcm-app:before {
        background-image: url(../images/iphone-wcm-app-3.jpg);
        content: "";
        width: 300px;
        display: block;
        height: 400px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin: auto auto;
        margin-top: 10px; }
        .lt-ie9 .wcc_connect-app-promotion .download-app-wrapper .iphone-wcm-app:before {
          width: 350px; }
        @media screen and (min-width: 767px) {
          .wcc_connect-app-promotion .download-app-wrapper .iphone-wcm-app:before {
            width: 350px; } }
    .wcc_connect-app-promotion .download-app-wrapper .download-app-info {
      display: inline-block;
      text-align: center; }
      .lt-ie9 .wcc_connect-app-promotion .download-app-wrapper .download-app-info {
        display: table-cell;
        float: right;
        margin: 0px 0px 80px 0px; }
      @media screen and (min-width: 767px) {
        .wcc_connect-app-promotion .download-app-wrapper .download-app-info {
          display: table-cell;
          float: right;
          margin: 0px 0px 80px 0px; } }
      .lt-ie9 .wcc_connect-app-promotion .download-app-wrapper .download-app-info {
        margin: 0px 0px 120px 155px; }
      @media screen and (min-width: 1200px) {
        .wcc_connect-app-promotion .download-app-wrapper .download-app-info {
          margin: 0px 0px 120px 155px; } }
      @media screen and (min-width: 767px) and (max-width: 989px) {
        .wcc_connect-app-promotion .download-app-wrapper .download-app-info {
          margin-left: 0px; } }
      .wcc_connect-app-promotion .download-app-wrapper .download-app-info h2 {
        font-size: 3.6rem; }
      @media screen and (min-width: 990px) and (max-width: 1199px) {
        .wcc_connect-app-promotion .download-app-wrapper .download-app-info .app-store-badges {
          margin-left: 20px; } }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .wcc_connect-app-promotion .download-app-wrapper .download-app-info .app-store-badges a {
          padding-left: 20px; } }

.connect-promotion {
  justify-content: center;
  display: flex;
  flex-direction: column; }
  .connect-promotion .connect-apps {
    display: block; }
    .connect-promotion .connect-apps:before {
      background-image: url(../images/iphone-wcm-app-3.jpg);
      content: "";
      width: 250px;
      display: block;
      height: 350px;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      margin: auto auto;
      margin-top: 20px; }
  .connect-promotion img {
    padding-top: 20px;
    margin: auto;
    width: 300px;
    display: table-cell; }
  .connect-promotion h2,
  .connect-promotion p {
    text-align: center; }
  .connect-promotion ul {
    margin: auto;
    width: fit-content; }
  .connect-promotion .download-app-wrapper {
    margin: auto;
    padding-top: 20px; }

.header-banner,
.image-tile-callout {
  background-color: #ffc72c;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  height: 275px;
  padding: 100px 10px 0;
  display: block;
  border-bottom: none; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .header-banner,
    .image-tile-callout {
      height: 220px;
      padding-top: 85px; } }
  .header-banner h2,
  .header-banner span,
  .image-tile-callout h2,
  .image-tile-callout span {
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    font-family: "1898Sans-Bold", sans-serif;
    display: block;
    line-height: 35px; }
  .header-banner div.cta-text,
  .image-tile-callout div.cta-text {
    text-align: center;
    margin-top: 30px; }
    .header-banner div.cta-text span,
    .image-tile-callout div.cta-text span {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      line-height: 20px;
      font-weight: normal;
      border-bottom: 1px dotted white;
      display: inline; }
  .header-banner.red-style,
  .image-tile-callout.red-style {
    background-color: #b31b1b; }
    .header-banner.red-style:hover,
    .image-tile-callout.red-style:hover {
      background-color: #c91e1e; }
  .header-banner.orange-style,
  .image-tile-callout.orange-style {
    background-color: #cf4520; }
    .header-banner.orange-style:hover,
    .image-tile-callout.orange-style:hover {
      background-color: #de502a; }
  .header-banner:hover,
  .image-tile-callout:hover {
    background-color: #ffce46;
    text-decoration: none;
    border-bottom: none; }
    .header-banner:hover div.cta-text span,
    .image-tile-callout:hover div.cta-text span {
      border-bottom: 1px solid white; }

.image-callouts-a .container {
  width: 100%; }

.lt-ie9 .image-callouts-a .row:first-of-type {
  padding-bottom: 40px; }

@media screen and (min-width: 600px) {
  .image-callouts-a .row:first-of-type {
    padding-bottom: 40px; } }

.pane-wcmc-specialty-callouts {
  display: none; }
  .pane-wcmc-specialty-callouts h2 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px; }
    .lt-ie9 .pane-wcmc-specialty-callouts h2 {
      margin-top: 40px; }
    @media screen and (min-width: 600px) {
      .pane-wcmc-specialty-callouts h2 {
        margin-top: 40px; } }

.specialty-callouts {
  margin-top: 0;
  margin-bottom: 0; }
  .lt-ie9 .specialty-callouts {
    margin-top: 20px;
    margin-bottom: 40px; }
  @media screen and (min-width: 600px) {
    .specialty-callouts {
      margin-top: 20px;
      margin-bottom: 40px; } }
  .lt-ie9 .specialty-callouts {
    margin-bottom: 40px; }
  @media screen and (min-width: 990px) {
    .specialty-callouts {
      margin-bottom: 40px; } }
  .specialty-callouts .image-tile-callout {
    padding-top: 50px;
    height: 185px;
    margin-bottom: 30px; }
    .lt-ie9 .specialty-callouts .image-tile-callout {
      margin-bottom: 0; }
    @media screen and (min-width: 990px) {
      .specialty-callouts .image-tile-callout {
        margin-bottom: 0; } }
    .specialty-callouts .image-tile-callout span {
      font-size: 26px;
      line-height: 30px; }
  .specialty-callouts .row {
    margin-bottom: 0; }
    .lt-ie9 .specialty-callouts .row {
      margin-bottom: 20px; }
    @media screen and (min-width: 990px) {
      .specialty-callouts .row {
        margin-bottom: 20px; } }
    .specialty-callouts .row:first-of-type {
      padding-bottom: 0; }

.listing-component {
  margin-top: 10px; }
  .listing-component .blurb {
    margin: 20px 0 0; }
  .listing-component .item-list ul li {
    margin: 0;
    padding: 10px; }
  .listing-component li {
    color: #cf4520;
    border-bottom: 1px solid #ddd;
    list-style-position: inside;
    margin: 0; }
    .listing-component li:last-of-type {
      border: none; }
    .listing-component li .views-field-title {
      display: inline-block;
      font-size: 15px; }
    .listing-component li a {
      border-bottom: none; }
      .listing-component li a:hover {
        text-decoration: underline; }

.conditions-component {
  margin-right: 20px; }
  .lt-ie9 .conditions-component {
    margin-right: 25px; }
  @media screen and (min-width: 1200px) {
    .conditions-component {
      margin-right: 25px; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .conditions-component {
      margin-right: 0; } }

.treatments-component {
  margin-left: 20px; }
  .lt-ie9 .treatments-component {
    margin-left: 25px; }
  @media screen and (min-width: 1200px) {
    .treatments-component {
      margin-left: 25px; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .treatments-component {
      margin-left: 0; } }
  .treatments-component .header-banner {
    background-color: #e7751d; }
    .treatments-component .header-banner:hover {
      background-color: #e98334; }

.view-treatments-listing .sub-title,
.view-conditions-listing .sub-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: #555;
  display: block;
  margin-bottom: 10px; }

.view-treatments-listing .views-field-body .item-list,
.view-conditions-listing .views-field-body .item-list {
  margin-bottom: 30px; }

.view-treatments-listing .field-content ul,
.view-conditions-listing .field-content ul {
  padding-left: 20px;
  margin-bottom: 20px; }

.instructions-container {
  height: auto;
  background: #f7f7f7;
  margin-bottom: 20px; }
  .instructions-container h2 {
    padding-bottom: 20px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .instructions-container .hide-on-mobile {
      display: none !important; } }
  .instructions-container .content-container {
    padding: 20px 100px 20px 100px;
    text-align: center;
    margin: auto !important;
    display: flex;
    flex-direction: column; }
    .lt-ie9 .instructions-container .content-container {
      max-width: 1180px; }
    @media screen and (min-width: 1200px) {
      .instructions-container .content-container {
        max-width: 1180px; } }
    @media screen and (min-width: 990px) and (max-width: 1199px) {
      .instructions-container .content-container {
        max-width: 980px; } }
    .instructions-container .content-container .step,
    .instructions-container .content-container .step-1 {
      margin: auto;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 15px;
      background: white;
      color: #cf4520;
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px; }
    .instructions-container .content-container .active ~ li .step {
      background: white;
      color: #cf4520; }
      .instructions-container .content-container .active ~ li .step::before {
        background: white;
        color: #cf4520; }
    .instructions-container .content-container li {
      list-style-type: none; }
      .instructions-container .content-container li .step,
      .instructions-container .content-container li .step-1 {
        background: #cf4520;
        color: white; }
        .instructions-container .content-container li .step::before,
        .instructions-container .content-container li .step-1::before {
          background: #cf4520;
          color: white; }
    .instructions-container .content-container .title {
      font-weight: bold;
      font-size: 14px; }
    .instructions-container .content-container p {
      margin-top: 15px; }
    .instructions-container .content-container .step::before {
      content: "";
      position: absolute;
      top: 23px;
      left: -40%;
      width: 80%;
      height: 0.2em;
      background: white;
      display: none; }
      @media screen and (min-width: 990px) and (max-width: 1199px) {
        .instructions-container .content-container .step::before {
          width: 78%;
          left: -36%; } }
      .lt-ie9 .instructions-container .content-container .step::before {
        display: inline; }
      @media screen and (min-width: 985px) {
        .instructions-container .content-container .step::before {
          display: inline; } }
  .instructions-container .step-1::before {
    display: none; }

.callouts-a {
  background: #f7f7f7;
  padding: 20px 0;
  margin: 20px 0; }
  .callouts-a .col-1 {
    border-right: 2px solid #e4e3e4; }
  .lt-ie9 .callouts-a .column {
    border-right: 2px solid #e4e3e4; }
  @media screen and (min-width: 600px) {
    .callouts-a .column {
      border-right: 2px solid #e4e3e4; } }
  .callouts-a .column:last-of-type {
    border: none; }
  .callouts-a a.callout {
    display: block;
    text-align: center;
    padding: 20px 0;
    border-bottom: none; }
    .callouts-a a.callout:hover {
      text-decoration: underline; }
  .callouts-a svg.cta-icon {
    width: 65px;
    height: 65px; }
  .callouts-a span {
    display: block;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px; }
    .lt-ie9 .callouts-a span {
      font-size: 24px;
      line-height: 30px; }
    @media screen and (min-width: 600px) {
      .callouts-a span {
        font-size: 24px;
        line-height: 30px; } }
    .callouts-a span:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
      color: #e7751d; }
  .callouts-a.style-b {
    background: #fff; }
    .callouts-a.style-b span {
      font-size: 18px;
      line-height: 24px; }
      .lt-ie9 .callouts-a.style-b span {
        font-size: 20px;
        line-height: 26px; }
      @media screen and (min-width: 600px) {
        .callouts-a.style-b span {
          font-size: 20px;
          line-height: 26px; } }

.blurb-a .internal-wrapper {
  text-align: center;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 0;
  margin: 10px 0; }
  .lt-ie9 .blurb-a .internal-wrapper {
    padding: 35px 100px;
    margin: 20px 0; }
  @media screen and (min-width: 600px) {
    .blurb-a .internal-wrapper {
      padding: 35px 100px;
      margin: 20px 0; } }
  .blurb-a .internal-wrapper span {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #555; }
    .lt-ie9 .blurb-a .internal-wrapper span {
      font-size: 24px;
      line-height: 34px; }
    @media screen and (min-width: 600px) {
      .blurb-a .internal-wrapper span {
        font-size: 24px;
        line-height: 34px; } }

.simple-callout {
  margin: 20px 0; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .simple-callout {
      margin: 20px 30px; } }
  .simple-callout a {
    color: #fff;
    font-size: 22px;
    font-family: "1898Sans-Regular", sans-serif;
    display: block;
    text-align: center;
    padding-top: 80px;
    height: 180px;
    width: 100%;
    background: #b31b1b;
    border-bottom: none; }
    .simple-callout a:hover {
      text-decoration: underline; }
    .simple-callout a:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block; }
  .simple-callout.style-red a {
    background: #b31b1b; }
  .simple-callout.style-bright-orange a {
    background: #cf4520; }
  .simple-callout.style-dark-orange a {
    background: #cf4520; }
  .simple-callout.style-yellow a {
    background: #ffc72c; }
  .simple-callout.small-version a {
    font-size: 18px;
    padding-top: 58px;
    height: 130px; }

.contact-us-callouts {
  margin: -15px 0 20px; }

.callouts-404 {
  margin: -15px 0 20px; }

.simple-callout-heading-and-link {
  margin: 20px 0;
  padding: 17px 23px 16px;
  min-height: 75px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .simple-callout-heading-and-link {
      margin: 10px 0; } }
  .simple-callout-heading-and-link span {
    color: #fff;
    font-size: 17px;
    line-height: 20px;
    font-family: "1898Sans-Bold", sans-serif;
    display: block;
    margin-bottom: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; }
  .simple-callout-heading-and-link a {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px dotted white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; }
    .simple-callout-heading-and-link a:hover {
      text-decoration: none;
      border-bottom: 1px solid white; }
    .simple-callout-heading-and-link a:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
      border-bottom: none; }
  .simple-callout-heading-and-link.style-red {
    background: #b31b1b; }
  .simple-callout-heading-and-link.style-bright-orange {
    background: #e7751d; }
  .simple-callout-heading-and-link.style-dark-orange {
    background: #cf4520; }
  .simple-callout-heading-and-link.style-yellow {
    background: #ffc72c; }
  .simple-callout-heading-and-link.style-neutral {
    background: #ffffff;
    border: 1px solid #e7e7e7; }
    .simple-callout-heading-and-link.style-neutral span {
      color: #b31b1b; }
    .simple-callout-heading-and-link.style-neutral a {
      color: #cf4520;
      border-bottom-color: #cf4520; }

.info-pane-a h3 {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #555;
  font-weight: bold;
  font-size: 24px;
  padding: 20px 0;
  margin-bottom: 20px; }

.info-pane-a .text-box p {
  font-size: 16px;
  line-height: 28px; }

.info-pane-b h3 {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #555;
  font-weight: bold;
  font-size: 24px;
  padding: 20px 0;
  margin-bottom: 20px; }

.lt-ie9 .info-pane-b .info-wrapper {
  padding: 0 140px; }

@media screen and (min-width: 990px) {
  .info-pane-b .info-wrapper {
    padding: 0 140px; } }

.info-pane-b .info-wrapper img {
  float: left; }

.simple-image-callout {
  margin: 20px 0; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .simple-image-callout {
      margin: 20px 50px; } }
  .simple-image-callout a {
    color: #b31b1b;
    font-size: 22px;
    font-family: "1898Sans-Regular", sans-serif;
    display: block;
    height: 165px;
    overflow: hidden; }
    .simple-image-callout a span {
      display: block;
      margin: 4px 0 10px; }
      .simple-image-callout a span:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 75%;
        padding-left: 7px;
        line-height: 20px;
        display: inline-block; }

.document-image-listing {
  margin: 30px 0; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .document-image-listing {
      margin: 30px 50px; } }
  .document-image-listing .thumbnail {
    border: 1px solid #ddd;
    padding: 2px; }
  .document-image-listing .title {
    border-bottom: 1px solid #ccc;
    padding: 8px 0 10px;
    margin: 0 0 20px; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .document-image-listing .title {
        padding: 15px 0 10px; } }
    .document-image-listing .title span {
      font-size: 18px;
      font-weight: bold;
      color: #666; }

.icon-link {
  margin: 10px 0 10px; }
  .icon-link .cta-icon {
    font-size: 24px;
    margin-right: 8px;
    position: relative;
    top: 7px; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .icon-link .cta-icon {
        font-size: 16px;
        margin-right: 3px;
        top: 3px; } }
  .icon-link a {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px; }

.contact-details {
  text-align: center; }
  .contact-details h4, .contact-details .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .contact-details .teaser-title {
    font-family: "1898Sans-Bold", sans-serif; }

/* Providers / Practices
----------------------------------------------------------*/
.view-profiles table {
  width: 100%; }

.node-type-practice .content h1 {
  font-size: 30px;
  line-height: 30px;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 200;
  padding-bottom: 5px; }

.node-type-profile .field-name-field-primary-specialty {
  font-size: 16px;
  line-height: 24px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 12px;
  font-weight: 200; }
  .node-type-profile .field-name-field-primary-specialty h3 {
    color: #000000; }
  .node-type-profile .field-name-field-primary-specialty ul {
    color: #b31b1b; }

#provider-tabs {
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 20px 0; }
  #provider-tabs:before, #provider-tabs:after {
    content: " ";
    display: table; }
  #provider-tabs:after {
    clear: both; }

#provider-tabs li {
  float: left;
  list-style-type: none; }

#provider-tabs li a {
  display: block;
  float: left;
  padding: 5px 14px;
  background: #aaa;
  color: #fff; }

#provider-tabs li a:hover,
#provider-tabs li a.active {
  background: #992800;
  text-decoration: none; }

.provider-image {
  max-height: 216px;
  max-width: 144px;
  border: 1px solid #b8b2a7;
  margin: 5px 20px 10px 0;
  padding: 2px;
  float: left; }
  .lt-ie9 .provider-image {
    max-height: 100%;
    max-width: 100%;
    height: 95%;
    width: 95%; }
  @media screen and (min-width: 1200px) {
    .provider-image {
      max-height: 100%;
      max-width: 100%;
      height: 95%;
      width: 95%; } }

.provider-locations-wrapper {
  background: #fff;
  color: #000;
  clear: both;
  padding: 13px 0 10px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  float: left;
  width: 100%;
  position: relative;
  z-index: 100; }
  .provider-locations-wrapper .map-larger-view {
    position: absolute;
    top: 20px;
    background: #fff;
    z-index: 2;
    left: 20px; }
    .provider-locations-wrapper .map-larger-view a {
      padding: 0.3em 1em;
      display: block;
      color: #cf4520;
      border-bottom: none; }

.address-location {
  margin: 0em 1em 0.25em;
  padding: 1em 1em 0.75em 0.5em; }
  .address-location.active {
    background-color: #f2f2f2; }
  .address-location .address-wrapper,
  .address-location .practice-address-container {
    cursor: pointer; }
  .address-location .practice-count {
    float: left;
    width: 10%;
    margin-top: 3px; }
  .address-location .practice-address {
    float: left;
    width: 90%; }
    .address-location .practice-address a.telephone-wrapper {
      border-bottom: none; }
      .address-location .practice-address a.telephone-wrapper:hover span {
        text-decoration: underline; }
  .address-location .cta-icon-location {
    width: 25px;
    height: 25px; }
  .address-location .hours {
    margin: 5px 0; }
  .address-location .directions-wrapper {
    padding-bottom: 5px; }
  .address-location .practice-phone {
    float: left;
    margin-top: 5px; }
  .address-location .contact-label {
    text-transform: uppercase;
    font-size: 12px;
    color: #666;
    font-weight: bold; }
  .address-location .telephone-number,
  .address-location .fax-number {
    display: inline-block; }
  .address-location .fax-number {
    color: #555;
    margin-bottom: 5px; }
  .address-location .practice-name {
    font-weight: bold; }
    .address-location .practice-name a {
      color: #000;
      border-bottom: none;
      line-height: 17px;
      display: block;
      margin-bottom: 7px; }
      .address-location .practice-name a:hover {
        text-decoration: underline; }
      .address-location .practice-name a:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 70%;
        padding-left: 5px;
        line-height: 20px;
        color: #e7751d; }
        .address-location .practice-name a:after:hover {
          text-decoration: none; }

.practice-contact-container a {
  border-bottom: none; }
  .practice-contact-container a:hover {
    text-decoration: underline; }

.address-location .request-appointment {
  margin-top: 10px; }
  .address-location .request-appointment .btn {
    width: 90%; }
    .lt-ie9 .address-location .request-appointment .btn {
      width: 250px;
      margin: 0; }
    @media screen and (min-width: 600px) {
      .address-location .request-appointment .btn {
        width: 250px;
        margin: 0; } }

.provider-locations-container .request-appointment {
  padding: 0 1em;
  margin-bottom: 1em; }
  .provider-locations-container .request-appointment .btn {
    width: 80%; }
    .lt-ie9 .provider-locations-container .request-appointment .btn {
      width: 260px; }
    @media screen and (min-width: 600px) {
      .provider-locations-container .request-appointment .btn {
        width: 260px; } }

.pane-wcmc-practice-locations .request-appointment {
  padding: 0;
  margin-bottom: 0; }
  .pane-wcmc-practice-locations .request-appointment .btn {
    width: 80%; }
    .lt-ie9 .pane-wcmc-practice-locations .request-appointment .btn {
      width: 250px; }
    @media screen and (min-width: 600px) {
      .pane-wcmc-practice-locations .request-appointment .btn {
        width: 250px; } }

.location-map {
  text-align: center;
  padding-bottom: 10px; }

.contact-option {
  padding: 0 2em;
  font-weight: bold; }
  .contact-option .phone-number {
    padding-top: 5px;
    color: #b31b1b;
    font-size: 24px;
    font-weight: normal; }
    .contact-option .phone-number a {
      border-bottom: none; }
      .contact-option .phone-number a:hover {
        text-decoration: underline; }

.request-appointment-secondary {
  margin: -5px 0 10px;
  text-align: center;
  color: #888; }
  .request-appointment-secondary a {
    color: #888;
    text-decoration: underline;
    border-bottom: none; }
    .request-appointment-secondary a:hover {
      text-decoration: none; }

.open-schedule-container {
  position: relative;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  padding: 0 1em; }
  .open-schedule-container .open_schedule_btn,
  .open-schedule-container .open_schedule_btn-react {
    width: 80%;
    z-index: 10; }
    .lt-ie9 .open-schedule-container .open_schedule_btn, .lt-ie9
    .open-schedule-container .open_schedule_btn-react {
      width: 260px; }
    @media screen and (min-width: 600px) {
      .open-schedule-container .open_schedule_btn,
      .open-schedule-container .open_schedule_btn-react {
        width: 260px; } }
    .open-schedule-container .open_schedule_btn:before,
    .open-schedule-container .open_schedule_btn-react:before {
      content: "\e80a";
      opacity: 1;
      left: 86%;
      color: #e7751d; }
    .open-schedule-container .open_schedule_btn:hover:before,
    .open-schedule-container .open_schedule_btn-react:hover:before {
      color: #fff; }
    .open-schedule-container .open_schedule_btn.activated,
    .open-schedule-container .open_schedule_btn-react.activated {
      background-color: #e7751d;
      border-color: #cf4520;
      color: #fff; }
      .open-schedule-container .open_schedule_btn.activated.btn--wcm:before,
      .open-schedule-container .open_schedule_btn-react.activated.btn--wcm:before {
        content: "\e809";
        opacity: 1;
        left: 86%;
        color: #fff; }

.schedule-options,
.schedule-options-react {
  display: none;
  z-index: 5;
  position: relative;
  width: 260px;
  padding: 5px 15px;
  margin: 0 auto;
  border-radius: 20px;
  border-width: 4px;
  font-size: 13px;
  border-style: solid;
  background-color: transparent;
  border-color: #e0e0e0;
  margin-top: -40px;
  padding-top: 40px; }
  .schedule-options a,
  .schedule-options .open-schedule-visit-type,
  .schedule-options-react a,
  .schedule-options-react .open-schedule-visit-type {
    border-bottom: 1px solid #e6e6e6;
    padding: 7px 0;
    display: block;
    z-index: 40; }
    .schedule-options a:hover,
    .schedule-options .open-schedule-visit-type:hover,
    .schedule-options-react a:hover,
    .schedule-options-react .open-schedule-visit-type:hover {
      text-decoration: underline; }
  .schedule-options span.call,
  .schedule-options-react span.call {
    padding: 7px 0;
    display: block;
    z-index: 40; }
  .schedule-options a.mychart,
  .schedule-options-react a.mychart {
    border-top: 1px solid #e6e6e6;
    border-bottom: none; }
    .schedule-options a.mychart.no-border,
    .schedule-options-react a.mychart.no-border {
      border: none; }

.open-schedule {
  cursor: pointer;
  position: relative; }
  .open-schedule:before {
    font-family: "fontello";
    content: "\e80a";
    opacity: 1;
    position: absolute;
    right: 10px;
    color: #e7751d; }
  .open-schedule.activated:before {
    font-family: "fontello";
    content: "\e809";
    opacity: 1;
    position: absolute;
    right: 10px;
    color: #e7751d; }

.visit-type-options {
  display: none;
  margin-left: 15px; }
  .visit-type-options a:last-child,
  .visit-type-options .call-link {
    border-bottom: none; }
  .visit-type-options .call-link:hover {
    text-decoration: none; }
  .visit-type-options .telephone-wrapper {
    display: inline; }
  .visit-type-options .phone-vt {
    display: inline; }
    .visit-type-options .phone-vt:hover {
      text-decoration: underline; }

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: url(../images/img_overlay.png);
  left: 0;
  top: 0;
  display: none; }

.returning-patient-modal {
  background: #fff;
  max-width: 860px;
  width: 100%;
  padding: 20px;
  height: 90%;
  z-index: 1;
  opacity: 1;
  margin: 0 auto;
  left: 0;
  right: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .returning-patient-modal {
      padding: 10px; } }
  .lt-ie9 .returning-patient-modal {
    padding: 30px;
    height: auto;
    padding: 30px; }
  @media screen and (min-width: 985px) {
    .returning-patient-modal {
      padding: 30px;
      height: auto;
      padding: 30px; } }
  .returning-patient-modal .header {
    background: #cf4520;
    color: #fff;
    padding: 1em;
    font-size: 18px;
    font-family: "1898Sans-Bold", sans-serif;
    font-weight: normal; }
  .returning-patient-modal .modal-container {
    border: 1px solid #ccc;
    height: 100%;
    overflow: auto; }
  .returning-patient-modal .login-container {
    margin-top: 0; }
    .lt-ie9 .returning-patient-modal .login-container {
      margin-top: 2em; }
    @media screen and (min-width: 600px) {
      .returning-patient-modal .login-container {
        margin-top: 2em; } }
    .returning-patient-modal .login-container .alert {
      margin: 0 20px 0 20px; }
      .lt-ie9 .returning-patient-modal .login-container .alert {
        transform: translate(0, -20px); }
      @media screen and (min-width: 767px) {
        .returning-patient-modal .login-container .alert {
          transform: translate(0, -20px); } }
  .returning-patient-modal .login-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: block;
    margin-bottom: 1em; }
  .returning-patient-modal .login-section {
    text-align: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
    padding: 1em 1em;
    font-size: 14px;
    line-height: 21px;
    font-size: 1.4rem;
    line-height: 2.1rem; }
    .lt-ie9 .returning-patient-modal .login-section {
      padding: 1em 3em; }
    @media screen and (min-width: 600px) {
      .returning-patient-modal .login-section {
        padding: 1em 3em; } }
    .returning-patient-modal .login-section .form-text {
      margin-bottom: 15px; }
    .returning-patient-modal .login-section .forgot-stuff {
      font-size: 11px;
      text-align: center;
      margin-top: 10px; }
      .returning-patient-modal .login-section .forgot-stuff a:first-of-type {
        margin-right: 10px; }
  .returning-patient-modal .divider {
    margin: 0 1em;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #abb0b6;
    background: #fff;
    position: relative; }
    .lt-ie9 .returning-patient-modal .divider {
      float: left;
      width: 3%;
      margin: 0; }
    @media screen and (min-width: 600px) {
      .returning-patient-modal .divider {
        float: left;
        width: 3%;
        margin: 0; } }
    .returning-patient-modal .divider span {
      background: #fff;
      padding: 0 5px;
      position: relative; }
    .returning-patient-modal .divider:before {
      content: "";
      border-bottom: 1px solid #abb0b6;
      position: absolute;
      top: 21px;
      width: 100%;
      left: 0; }
  .lt-ie9 .returning-patient-modal .section-login {
    width: 46%;
    float: left; }
  @media screen and (min-width: 600px) {
    .returning-patient-modal .section-login {
      width: 46%;
      float: left; } }
  .lt-ie9 .returning-patient-modal .section-login {
    width: 46%;
    float: left; }
  @media screen and (min-width: 600px) {
    .returning-patient-modal .section-login {
      width: 46%;
      float: left; } }
  .lt-ie9 .returning-patient-modal .section-register {
    width: 51%;
    float: left;
    border-left: 1px solid #ccc;
    margin-left: -10px;
    margin-bottom: 70px; }
  @media screen and (min-width: 600px) {
    .returning-patient-modal .section-register {
      width: 51%;
      float: left;
      border-left: 1px solid #ccc;
      margin-left: -10px;
      margin-bottom: 70px; } }
  .returning-patient-modal .section-register .note-asterisk {
    color: #b31b1b; }

.modal-close {
  background-image: url(../images/img_sprite.png);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.field-name-field-related-practice {
  font-size: 16px;
  line-height: 24px;
  font-size: 1.6rem;
  line-height: 2.4rem; }

.field-name-field-related-neighborhood {
  font-weight: 700; }

.field-type-addressfield {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem; }

.field-name-field-related-fax {
  margin-bottom: 10px; }

.field-name-field-related-phone {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem; }

.pane-node-field-provider-appointments,
.pane-node-field-board-certifications,
.pane-node-field-educational-background {
  padding-top: 10px; }

.insurance-plans {
  font-size: 18px;
  line-height: 27px;
  font-size: 1.8rem;
  line-height: 2.7rem;
  background: #fafafa;
  border: 1px solid gray;
  float: left;
  width: 463px;
  margin: 0.5em 0 1.5em 0;
  padding: 30px 30px 10px 30px; }

.insurance-plans li {
  line-height: 1.25em !important;
  width: 100%;
  margin-bottom: 1em;
  list-style: none; }

.plan {
  font-size: 14px;
  line-height: 21px;
  font-size: 1.4rem;
  line-height: 2.1rem; }

.field-collection-item-field-related-practices a {
  font-size: 16px;
  line-height: 20px;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #cf4520;
  border-bottom: none; }
  .field-collection-item-field-related-practices a:hover {
    border-bottom: none;
    text-decoration: underline; }

.organisation-name {
  font-weight: bold; }

/* =Breadcrumb
----------------------------------------------------------------------------------*/
.breadcrumb {
  color: #666;
  margin: 0.5em auto;
  max-width: 960px;
  padding: 0 10px 10px;
  font-size: 11px;
  line-height: 16.5px;
  font-size: 1.1rem;
  line-height: 1.65rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
  .lt-ie9 .breadcrumb {
    padding: 10px 10px 10px; }
  @media screen and (min-width: 600px) {
    .breadcrumb {
      padding: 10px 10px 10px; } }
  .lt-ie9 .breadcrumb {
    max-width: 1180px; }
  @media screen and (min-width: 1200px) {
    .breadcrumb {
      max-width: 1180px; } }
  .breadcrumb a {
    color: #666; }
  .breadcrumb > .active {
    color: #666; }
  .breadcrumb a:hover {
    color: #cf4520; }

.breadcrumb .last a {
  color: #544842; }

/* =Page Options
----------------------------------------------------------------------------------*/
.page_options {
  float: left;
  width: 215px;
  margin-bottom: 10px;
  text-align: right; }

.page_options ul li {
  float: left;
  list-style: none; }

.page_options img {
  float: left;
  margin-bottom: 10px; }

/* =Content Styles
----------------------------------------------------------------------------------*/
.pane-node-field-featured-image img {
  margin: 0 0 15px 0;
  width: 100%; }

img.float_left {
  float: left;
  margin: 5px 15px 5px 0;
  border: 1px solid #b8b2a7;
  padding: 2px; }

div.caption-right {
  float: right;
  margin: 5px 0 5px 15px; }

div.caption-inner {
  border: none;
  background: none; }

.pane-content img {
  vertical-align: middle; }

ul.reg_list_bullets {
  margin: 0 0 0 18px; }

ul.reg_list {
  margin: 0; }

ul.reg_list li {
  list-style-type: none; }

ul.d_list {
  padding: 0; }

div.list_left {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  padding: 0 10px; }
  .lt-ie9 div.list_left {
    width: 50%;
    float: left; }
  @media screen and (min-width: 600px) {
    div.list_left {
      width: 50%;
      float: left; } }
  div.list_left a {
    /*color: $cornell-light-text; // #0e5d86;
        &:hover {
            color: $cornell-bright-orange;
        }*/ }

div.list_right {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  padding: 0 10px; }
  .lt-ie9 div.list_right {
    width: 50%;
    float: left; }
  @media screen and (min-width: 600px) {
    div.list_right {
      width: 50%;
      float: left; } }
  div.list_right a {
    /*color: $cornell-light-text;
        &:hover {
            color: $cornell-bright-orange;
        }*/ }

.pane-bundle-image-link {
  margin-bottom: 10px; }

/* =Home
----------------------------------------------------------*/
.front #main {
  margin: 0 auto;
  width: 100%; }

.front #content {
  padding: 0px 0px 45px 0; }

.front h1.title {
  font-size: 32px;
  line-height: 48px;
  font-size: 3.2rem;
  line-height: 4.8rem;
  padding-bottom: 25px;
  color: #544842; }

.wcc_connect {
  margin: 3em 0;
  text-align: center; }
  .wcc_connect .wcc-cornell {
    color: #b31b1b;
    font-size: 40px;
    line-height: 60px;
    font-size: 4rem;
    line-height: 6rem;
    display: inline-block; }
  .wcc_connect .wcc-connect {
    font-family: "1898Sans-Regular", sans-serif;
    color: #e7751d;
    font-size: 4rem;
    font-weight: 400;
    display: inline-block; }
  .wcc_connect .wcc_connect_form div {
    display: inline-block;
    text-align: right; }
    .wcc_connect .wcc_connect_form div a {
      font-size: 1.1rem;
      color: #666;
      margin-bottom: 0.2em;
      display: block;
      text-decoration: underline;
      border-bottom: none; }
      .wcc_connect .wcc_connect_form div a:hover {
        border-bottom: none;
        text-decoration: none; }
  .wcc_connect .wcc_connect_form div:nth-child(1),
  .wcc_connect .wcc_connect_form div:nth-child(2) {
    width: 100%; }
    .lt-ie9 .wcc_connect .wcc_connect_form div:nth-child(1), .lt-ie9
    .wcc_connect .wcc_connect_form div:nth-child(2) {
      width: auto; }
    @media screen and (min-width: 600px) {
      .wcc_connect .wcc_connect_form div:nth-child(1),
      .wcc_connect .wcc_connect_form div:nth-child(2) {
        width: auto; } }
  .lt-ie9 .wcc_connect .username-wrapper {
    margin-right: 10px; }
  @media screen and (min-width: 600px) {
    .wcc_connect .username-wrapper {
      margin-right: 10px; } }
  .wcc_connect .password-wrapper {
    margin-bottom: 15px; }
    .lt-ie9 .wcc_connect .password-wrapper {
      margin-right: 10px;
      margin-bottom: 0; }
    @media screen and (min-width: 600px) {
      .wcc_connect .password-wrapper {
        margin-right: 10px;
        margin-bottom: 0; } }
  .wcc_connect .login-button-wrapper {
    margin-right: 10px; }
  .wcc_connect .wcc-icons {
    -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    margin: 1.5em 0 1em; }
    .wcc_connect .wcc-icons:before, .wcc_connect .wcc-icons:after {
      content: " ";
      display: table; }
    .wcc_connect .wcc-icons:after {
      clear: both; }
    .wcc_connect .wcc-icons .wcc-icon {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
      width: 100%;
      text-align: left;
      line-height: 5;
      border-bottom: 1px solid #adadad;
      float: left;
      padding: 1em 0 1em;
      color: #666;
      font-weight: 600; }
      .lt-ie9 .wcc_connect .wcc-icons .wcc-icon {
        width: 20%;
        border-right: 1px solid #adadad;
        border-bottom: none;
        text-align: center;
        line-height: 2; }
      @media screen and (min-width: 600px) {
        .wcc_connect .wcc-icons .wcc-icon {
          width: 20%;
          border-right: 1px solid #adadad;
          border-bottom: none;
          text-align: center;
          line-height: 2; } }
      .wcc_connect .wcc-icons .wcc-icon:last-child {
        border-right: none; }
      .wcc_connect .wcc-icons .wcc-icon:before {
        content: "";
        background: url(../images/wcc-icons.png) no-repeat center;
        width: 100px;
        float: left;
        display: block;
        height: 65px; }
        .lt-ie9 .wcc_connect .wcc-icons .wcc-icon:before {
          width: auto;
          float: none; }
        @media screen and (min-width: 600px) {
          .wcc_connect .wcc-icons .wcc-icon:before {
            width: auto;
            float: none; } }
      .wcc_connect .wcc-icons .wcc-icon.icon-heart:before {
        background-position: center 0; }
      .wcc_connect .wcc-icons .wcc-icon.icon-calendar:before {
        background-position: center -65px; }
      .wcc_connect .wcc-icons .wcc-icon.icon-pill:before {
        background-position: center -130px; }
      .wcc_connect .wcc-icons .wcc-icon.icon-speech-box:before {
        background-position: center -195px; }
      .wcc_connect .wcc-icons .wcc-icon.icon-money:before {
        background-position: center -260px; }
  .wcc_connect .download-btns {
    font-size: 1.2rem;
    color: #666; }
    .wcc_connect .download-btns p {
      font-size: 1.2rem; }
    .wcc_connect .download-btns a {
      color: #666;
      display: inline-block;
      padding: 0 0.5em;
      border-bottom: none; }
      .wcc_connect .download-btns a:after {
        content: "";
        background: url(../images/icon-download.png) no-repeat;
        width: 19px;
        height: 18px;
        display: block;
        margin: 0.3em auto 0; }
      .wcc_connect .download-btns a:hover {
        border-bottom: none; }

.welcome-message {
  padding-top: 1em; }
  .welcome-message h1 {
    font-size: 3rem; }

@media screen and (min-width: 0) and (max-width: 599px) {
  .panel-home .welcome-message {
    padding: 8px 0 0; } }

.patient-stories {
  text-align: center;
  border-top: 5px solid #e7751d;
  border-bottom: 5px solid #e7751d;
  padding: 2em 0;
  margin-bottom: 2em; }
  .patient-stories h2 {
    color: #cf4520;
    font-size: 3.3rem;
    line-height: 4rem; }
  .patient-stories iframe {
    height: 250px; }
    .lt-ie9 .patient-stories iframe {
      height: 540px; }
    @media screen and (min-width: 600px) {
      .patient-stories iframe {
        height: 540px; } }

.localist-event {
  border-bottom: none;
  padding-bottom: 0; }
  .localist-event:before, .localist-event:after {
    content: " ";
    display: table; }
  .localist-event:after {
    clear: both; }
  .localist-event .event-details {
    display: table; }
  .localist-event .date {
    display: table-cell;
    vertical-align: middle;
    background: #ddd;
    width: 125px;
    height: 100px;
    position: relative; }
    .localist-event .date:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: #ddd;
      border-width: 20px;
      margin-top: -20px; }
    .localist-event .date .month {
      text-align: center;
      display: block;
      color: #555;
      text-transform: uppercase;
      font-size: 2.2rem;
      line-height: 2.5rem;
      margin-bottom: 1px; }
    .localist-event .date .day {
      display: block;
      text-align: center;
      font-weight: 700;
      font-size: 3.8rem;
      line-height: 3rem;
      margin-bottom: 2px;
      color: #555; }
    .localist-event .date .year {
      display: block;
      text-align: center;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: #555; }
  .localist-event .event-title {
    display: table-cell;
    vertical-align: middle;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 15px;
    padding-bottom: 5px;
    word-break: break-word; }
    .localist-event .event-title a {
      color: #cf4520; }
      .localist-event .event-title a:after {
        padding-left: 6px;
        font-size: 80%; }
      .localist-event .event-title a:hover {
        text-decoration: underline; }
  .localist-event .details-wrapper {
    padding: 5px 0 0 30px;
    font-size: 13px; }
    .localist-event .details-wrapper .location {
      color: #000;
      border-bottom: none; }
      .localist-event .details-wrapper .location:after {
        font-size: 80%; }
      .localist-event .details-wrapper .location:hover {
        border-bottom: none; }

.survey-callout {
  border: 5px solid #e7751d;
  text-align: center;
  padding: 2em;
  margin-top: 2em; }
  .lt-ie9 .survey-callout {
    padding: 2em 10em; }
  @media screen and (min-width: 600px) {
    .survey-callout {
      padding: 2em 10em; } }
  .survey-callout .icon-survey {
    width: 59px;
    height: 67px;
    background: url(../images/icon-survey.png) no-repeat;
    display: inline-block;
    margin-bottom: 1em; }
  .survey-callout h3 {
    color: #666;
    padding-bottom: 0;
    font-weight: 400; }

.orange-hover {
  background: #e7751d; }
  .orange-hover:hover {
    background: #cf4520;
    text-decoration: none; }

#featured_physicians,
#wcc {
  clear: both;
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto; }

#who_we_are {
  width: 65%;
  float: left; }

#who_we_are p {
  font-size: 15px;
  line-height: 22.5px;
  font-size: 1.5rem;
  line-height: 2.25rem; }

#who_we_are dl.first {
  margin-left: 26px;
  float: left;
  width: 55%; }

#who_we_are dl dt {
  font-size: 19px;
  line-height: 28.5px;
  font-size: 1.9rem;
  line-height: 2.85rem;
  font-family: Helvetica, sans-serif;
  font-weight: normal; }

#who_we_are dl dd {
  font-size: 14px;
  line-height: 20px;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1px; }

.slideshow {
  float: left;
  margin-right: 1em;
  width: 200px;
  height: 300px; }

#wcc_intro {
  clear: both;
  font-size: 15px;
  line-height: 22.5px;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: Georgia, serif; }

#wcc_intro p {
  font-size: 15px;
  line-height: 22.5px;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: Georgia, serif; }

#WCC_Logo {
  background: url(../images/WCCLogo.png) no-repeat;
  background-size: contain;
  height: 53px;
  padding: 0;
  text-indent: -999em; }

#wcc_intro .column {
  float: left;
  height: auto;
  width: 28%;
  padding: 0.5em;
  position: relative; }

#wcc_intro .column.first {
  width: 65%; }

#wcc_intro ul {
  font-size: 16px;
  line-height: 20px;
  font-size: 1.6rem;
  line-height: 2rem;
  clear: left;
  margin: 0 16px 16px 32px; }

#wcc_intro .column.last {
  width: 31%;
  padding: 0.5em;
  background: url(../images/WCC-iPhone.png) 95% 0.5em no-repeat;
  height: 255px;
  margin-top: -50px; }

#wcc_intro p.disclaimer {
  margin: 16px 0;
  font-size: small;
  width: auto;
  float: left; }

#wcc_intro .column.last #mychart_app {
  margin: 16px 8px 6px 8px;
  float: left; }

#wcc_intro .column.last p {
  margin-right: 35%;
  margin-top: 15px;
  clear: right;
  margin-bottom: 6px; }

#video_player {
  float: left;
  width: 970px;
  height: 360px;
  background: #f6f0e2;
  margin-top: 15px; }

#video-player {
  font-size: 1.5rem;
  background: #f5f5f5;
  color: #000;
  padding: 10px 15px 10px 10px; }
  #video-player .video {
    width: 640px;
    float: left; }
  #video-player .video-desc {
    line-height: 1.3;
    padding-top: 10px; }

.clearfix, .menu, #primary-nav, .address-location, .landing-page-footer .footer-statement, .node-type-practice .information-blocks, .node-type-practice .information-blocks .information-block a, .node-type-practice #our-physicians {
  clear: both; }

/* =Clinical Services
----------------------------------------------------------*/
.footer-secondary-menu .view-clinical-services h2 a:after {
  content: ""; }

.view-clinical-services,
.d_list_cont,
.content .callout-links {
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: none;
  float: left;
  padding: 10px 10px 0; }

.view-clinical-services,
.callout-links {
  padding: 20px 10px; }

.view-clinical-services {
  font-size: 14px; }
  .view-clinical-services .views-row {
    padding: 8px 10px;
    border-bottom: 1px solid #f8f8f8; }
    .view-clinical-services .views-row a {
      border-bottom: none; }

.callout-links {
  margin-bottom: 10px; }

.callout-links li,
.d_list li {
  list-style: none;
  float: left;
  clear: left;
  padding: 0 0 5px; }

.view-clinical-services .visible-xs,
.view-clinical-services .visible-sm {
  display: none !important; }

.view-clinical-services .row {
  margin: 0; }

.view-clinical-services .cols-2 a,
.view-clinical-services .col-1,
.view-clinical-services .col-2 {
  float: left;
  padding: 5px;
  margin-bottom: 0.2em;
  width: 50%; }

.callout-links a,
.d_list li a {
  width: 100%;
  float: left;
  padding: 5px;
  margin-bottom: 5px; }

.view-clinical-services a:hover,
.d_list li a:hover,
.callout-links a:hover {
  text-decoration: underline; }

.wcmc_threecol .d_list_cont {
  width: 100%;
  margin-bottom: 24px; }

.wcmc_twocol_right .list_left {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  padding: 0 10px; }
  .lt-ie9 .wcmc_twocol_right .list_left {
    width: 50%;
    float: left; }
  @media screen and (min-width: 600px) {
    .wcmc_twocol_right .list_left {
      width: 50%;
      float: left; } }

.wcmc_twocol_right .list_right {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  padding: 0 10px; }
  .lt-ie9 .wcmc_twocol_right .list_right {
    width: 50%;
    float: left; }
  @media screen and (min-width: 600px) {
    .wcmc_twocol_right .list_right {
      width: 50%;
      float: left; } }

.wcmc_twocol_right .d_list li a {
  width: 325px;
  float: left; }

.callout-links {
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: none;
  float: left;
  padding: 15px 10px 0; }

.clinical-service-scheduling-cta .cta-online {
  display: inline; }
  .clinical-service-scheduling-cta .cta-online .btn {
    border-radius: 5px; }

.clinical-service-scheduling-cta .cta-phone {
  font-size: 15px;
  display: inline; }
  .clinical-service-scheduling-cta .cta-phone .cta-icon {
    margin-left: 7px; }

.clinical-service-fap-widget {
  text-align: center;
  margin: 30px 0 30px;
  padding: 25px 20px 30px;
  background: #f7f7f7; }
  .clinical-service-fap-widget h3 {
    color: #555;
    margin-top: 0;
    padding-bottom: 15px; }
  .clinical-service-fap-widget .btn {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    background: #cf4520;
    border: 1px solid #cf4520;
    font-size: 14px;
    width: 80%; }
    .clinical-service-fap-widget .btn:hover {
      background: #b31b1b;
      border: 1px solid #b31b1b; }

.clinical-services-footer-block {
  width: 80%;
  float: left;
  border-right: 1px solid #ddd; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .clinical-services-footer-block {
      width: 100%; } }
  .clinical-services-footer-block h2,
  .clinical-services-footer-block h3 {
    font-size: 1.2rem;
    padding-bottom: 0;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-top: 3px;
    color: #666; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .clinical-services-footer-block h2,
      .clinical-services-footer-block h3 {
        text-align: center; } }
    .clinical-services-footer-block h2 a:after,
    .clinical-services-footer-block h3 a:after {
      content: ""; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .clinical-services-footer-block h2 a:after,
        .clinical-services-footer-block h3 a:after {
          padding: 0; } }
  .clinical-services-footer-block .view-content {
    margin-bottom: 15px;
    display: none; }
    .lt-ie9 .clinical-services-footer-block .view-content {
      column-count: 2;
      column-gap: 15px;
      padding-right: 15px;
      display: block; }
    @media screen and (min-width: 767px) {
      .clinical-services-footer-block .view-content {
        column-count: 2;
        column-gap: 15px;
        padding-right: 15px;
        display: block; } }
    .lt-ie9 .clinical-services-footer-block .view-content {
      column-count: 3;
      column-gap: 15px;
      padding-right: 15px; }
    @media screen and (min-width: 990px) {
      .clinical-services-footer-block .view-content {
        column-count: 3;
        column-gap: 15px;
        padding-right: 15px; } }
    .clinical-services-footer-block .view-content .views-row a {
      font-size: 12px;
      border-bottom: 1px solid #f8f8f8;
      height: 32px;
      line-height: 31px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }

.footer-clinical-services-wrapper {
  width: 80%;
  float: left; }
  .footer-clinical-services-wrapper .clinical-services-footer-block {
    width: 100%; }

/* =Two Sidebar Layout
----------------------------------------------------------------------------------*/
.pane-profiles-panel-pane-2 a {
  color: #b31b1b; }

.node-type-physicians h1 {
  font-size: 30px;
  line-height: 30px;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
  font-weight: 200;
  color: #544842;
  padding: 0; }

.node-type-physicians #sidebar-first,
.node-type-physicians #sidebar-second {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem;
  letter-spacing: 0.01em; }

.node-type-physicians #sidebar-first h2,
.node-type-physicians #sidebar-second h2 {
  font-size: 18px;
  line-height: 27px;
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: normal;
  margin-bottom: 9px;
  padding-bottom: 6px;
  border-bottom: 1px dotted #aea89d;
  color: #423934; }

.node-type-physicians #sidebar-first p,
.node-type-physicians #sidebar-second p {
  line-height: 1.5; }

.node-type-physicians #sidebar-first .field-item,
.node-type-physicians #sidebar-second .field-item {
  padding-bottom: 10px; }

.pane-node-field-main-specialty {
  font-size: 16px;
  line-height: 24px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 200;
  color: #992800;
  line-height: 2em;
  border-bottom: 1px dotted #aea89d;
  margin-bottom: 10px; }

.physician_profile_pic {
  width: 144px;
  height: 216px;
  border: 1px solid #b8b2a7;
  margin: 5px 20px 10px 0;
  padding: 2px;
  float: left; }

.field-name-field-appointments li {
  list-style-type: none; }

.field-name-field-appointments span {
  font-size: 11px;
  line-height: 16.5px;
  font-size: 1.1rem;
  line-height: 1.65rem; }

.page-search h3.pane-title {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 1.3rem;
  color: #000; }

/* =Provider / Practice Indexes
----------------------------------------------------------------------------------*/
html.js input.form-autocomplete {
  background-position: 100% 7px; }

html.js input.throbbing {
  background-position: 100% -14px; }

/* =Footer
----------------------------------------------------------------------------------*/
footer.landing-page-footer .wrap {
  max-width: 970px;
  width: auto;
  padding: 0 10px; }
  .lt-ie9 footer.landing-page-footer .wrap {
    max-width: 1180px; }
  @media screen and (min-width: 1200px) {
    footer.landing-page-footer .wrap {
      max-width: 1180px; } }
  footer.landing-page-footer .wrap .footer-links {
    border-top: 1px solid #ddd;
    padding-top: 1em;
    text-align: center; }
  footer.landing-page-footer .wrap .footer-wcm {
    text-align: center;
    margin-bottom: 1.5em; }
  footer.landing-page-footer .wrap .nypp-logo {
    display: inline-block;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
    background-image: url("../../../../../profiles/wcmc/themes/wcmc_base/images/NYP_logo_red.png");
    background-size: 270px 31px;
    background-repeat: no-repeat;
    background-position: center left;
    width: 270px;
    height: 60px; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
      footer.landing-page-footer .wrap .nypp-logo {
        background-image: url("../../../../../profiles/wcmc/themes/wcmc_base/images/NYP_logo_red_2x.png"); } }
  footer.landing-page-footer .wrap .wcm-logo {
    font: 0/0 a;
    display: inline-block;
    background: url("../images/wcm-logo-footer.png") no-repeat center left;
    background-size: 275px 25px;
    width: 275px;
    height: 60px;
    padding-right: 0; }
    .lt-ie9 footer.landing-page-footer .wrap .wcm-logo {
      margin-right: 8rem; }
    @media screen and (min-width: 600px) {
      footer.landing-page-footer .wrap .wcm-logo {
        margin-right: 8rem; } }

footer.landing-page-footer ul {
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem; }

footer.landing-page-footer ul.links li {
  list-style-type: none;
  list-style-image: none; }
  .lt-ie9 footer.landing-page-footer ul.links li {
    float: left;
    margin: 0 2em 0 2px;
    list-style-type: disc;
    list-style-position: outside; }
    .lt-ie9 footer.landing-page-footer ul.links li:first-child {
      list-style-type: none;
      list-style-image: none; }
    .lt-ie9 footer.landing-page-footer ul.links li:last-child {
      margin-right: 0;
      padding-right: 0; }
  @media screen and (min-width: 600px) {
    footer.landing-page-footer ul.links li {
      float: left;
      margin: 0 2em 0 2px;
      list-style-type: disc;
      list-style-position: outside; }
      footer.landing-page-footer ul.links li:first-child {
        list-style-type: none;
        list-style-image: none; }
      footer.landing-page-footer ul.links li:last-child {
        margin-right: 0;
        padding-right: 0; } }

footer.landing-page-footer ul.links li.menu-1150,
footer.landing-page-footer ul.links li.last {
  border: none; }

footer.landing-page-footer a,
footer.landing-page-footer a.active {
  color: #666; }

footer.landing-page-footer a:hover {
  color: #cf4520;
  text-decoration: underline; }

footer.landing-page-footer #footer-menu {
  clear: both; }
  .lt-ie9 footer.landing-page-footer #footer-menu {
    clear: none;
    padding-top: 0;
    display: inline-block; }
  @media screen and (min-width: 600px) {
    footer.landing-page-footer #footer-menu {
      clear: none;
      padding-top: 0;
      display: inline-block; } }

footer.landing-page-footer .nyp_logo {
  background-image: url(../images/nyp_logo.png);
  height: 27px !important;
  width: 201px; }
  .lt-ie9 footer.landing-page-footer .nyp_logo {
    float: right; }
  @media screen and (min-width: 600px) {
    footer.landing-page-footer .nyp_logo {
      float: right; } }

footer.landing-page-footer .my_nyp {
  font-size: 11px;
  line-height: 16.5px;
  font-size: 1.1rem;
  line-height: 1.65rem;
  float: left;
  clear: both;
  margin-top: 10px; }
  .lt-ie9 footer.landing-page-footer .my_nyp {
    float: right; }
  @media screen and (min-width: 600px) {
    footer.landing-page-footer .my_nyp {
      float: right; } }

footer.landing-page-footer .cornell_copyright {
  font-size: 12px;
  line-height: 18px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: inline-block;
  vertical-align: top; }

.landing-page-footer .footer-statement {
  border-top: 5px solid #cf4520;
  border-bottom: 5px solid #cf4520;
  padding: 1em 0;
  margin: 1.5em 0 0.5em;
  clear: both; }
  .landing-page-footer .footer-statement .wcmc-emblem {
    float: none;
    cursor: default;
    background-image: url("../../../../../profiles/wcmc/themes/wcmc_base/images/WCMC-EMBLEM_web_red.png");
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: left;
    width: 60px;
    height: 60px;
    padding-left: 0;
    margin: 0 auto 1em; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
      .landing-page-footer .footer-statement .wcmc-emblem {
        background-image: url("../../../../../profiles/wcmc/themes/wcmc_base/images/WCMC-EMBLEM_web_red_2x.png"); } }
    .lt-ie9 .landing-page-footer .footer-statement .wcmc-emblem {
      float: left;
      margin-right: 20px; }
    @media screen and (min-width: 600px) {
      .landing-page-footer .footer-statement .wcmc-emblem {
        float: left;
        margin-right: 20px; } }
  .landing-page-footer .footer-statement .wcmc-statement {
    color: #666;
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-family: "1898Sans-Regular", sans-serif;
    font-style: italic; }
    .landing-page-footer .footer-statement .wcmc-statement span {
      font-family: "1898Sans-Bold", sans-serif; }
    .lt-ie9 .landing-page-footer .footer-statement .wcmc-statement {
      width: 87%;
      float: left; }
    @media screen and (min-width: 600px) {
      .landing-page-footer .footer-statement .wcmc-statement {
        width: 87%;
        float: left; } }
  .landing-page-footer .footer-statement .wcmc-person {
    color: #cf4520;
    margin-top: 0.5em;
    font-size: 1.4rem;
    font-family: "1898Sans-Regular", sans-serif; }

.footer-statement__statement,
.footer-links {
  color: #666; }

/* =Form Elements
----------------------------------------------------------------------------------*/
fieldset.fieldset_gender,
fieldset.fieldset_languages {
  width: 100%; }
  .lt-ie9 fieldset.fieldset_gender, .lt-ie9
  fieldset.fieldset_languages {
    width: 16%; }
  @media screen and (min-width: 600px) {
    fieldset.fieldset_gender,
    fieldset.fieldset_languages {
      width: 16%; } }

label {
  font-size: 14px;
  line-height: 20px;
  font-size: 1.4rem;
  line-height: 2rem;
  display: block;
  font-weight: bold;
  margin: 0 0 5px; }

.submit {
  font-size: 19px;
  line-height: 28.5px;
  font-size: 1.9rem;
  line-height: 2.85rem;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 100%;
  color: #ca3510;
  text-align: center;
  display: block;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #ffffff;
  background-image: -ms-linear-gradient(top, white, #d6d6d6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#d6d6d6));
  background-image: -webkit-linear-gradient(top, white, #d6d6d6);
  background-image: -o-linear-gradient(top, white, #d6d6d6);
  background-image: linear-gradient(top, white, #d6d6d6);
  background-image: -moz-linear-gradient(top, white, #d6d6d6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  text-decoration: none;
  border-color: #ddd #ddd #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=0);
  margin: 4px auto;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-family: Helvetica, sans-serif; }

.submit:hover {
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
  text-decoration: none;
  background-color: #d6d6d6;
  background-position: 0 -30px; }

.red {
  color: #fff;
  font-weight: normal;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ca3510', endColorstr='#920401', GradientType=0);
  background-image: -moz-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -o-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -webkit-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-color: #920401;
  text-shadow: 0 1px 1px #960000;
  padding: 4px 0px 4px; }

.submit.red:hover {
  background-color: #920401;
  color: #fff; }

.node-webform h2 {
  display: none; }

.node-webform fieldset.captcha {
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }

/* =Utility
----------------------------------------------------------------------------------*/
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.form-item-name,
.form-item-departments {
  margin-bottom: 0; }

.physicians-directory .views-exposed-widgets {
  margin-bottom: 30px; }

.physicians-directory table a {
  color: #b31b1b; }

/* Provider Profiles */
.node-type-profile #main {
  width: 100%;
  padding: 0;
  max-width: inherit; }

.node-type-profile .content h1 {
  border-bottom: none; }

.node-type-profile #insurances,
.node-type-profile #education,
.node-type-profile #accordion,
.node-type-profile .provider-accordion {
  clear: both;
  font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, sans-serif; }
  .node-type-profile #insurances .accordion-panel,
  .node-type-profile #insurances .element__hide,
  .node-type-profile #education .accordion-panel,
  .node-type-profile #education .element__hide,
  .node-type-profile #accordion .accordion-panel,
  .node-type-profile #accordion .element__hide,
  .node-type-profile .provider-accordion .accordion-panel,
  .node-type-profile .provider-accordion .element__hide {
    display: none;
    overflow: auto;
    padding-top: 10px;
    padding-bottom: 10px; }
    .node-type-profile #insurances .accordion-panel .list-items,
    .node-type-profile #insurances .element__hide .list-items,
    .node-type-profile #education .accordion-panel .list-items,
    .node-type-profile #education .element__hide .list-items,
    .node-type-profile #accordion .accordion-panel .list-items,
    .node-type-profile #accordion .element__hide .list-items,
    .node-type-profile .provider-accordion .accordion-panel .list-items,
    .node-type-profile .provider-accordion .element__hide .list-items {
      margin-bottom: 10px;
      margin-bottom: 1rem; }
    .node-type-profile #insurances .accordion-panel ul,
    .node-type-profile #insurances .element__hide ul,
    .node-type-profile #education .accordion-panel ul,
    .node-type-profile #education .element__hide ul,
    .node-type-profile #accordion .accordion-panel ul,
    .node-type-profile #accordion .element__hide ul,
    .node-type-profile .provider-accordion .accordion-panel ul,
    .node-type-profile .provider-accordion .element__hide ul {
      margin-bottom: 0; }
    .node-type-profile #insurances .accordion-panel p,
    .node-type-profile #insurances .element__hide p,
    .node-type-profile #education .accordion-panel p,
    .node-type-profile #education .element__hide p,
    .node-type-profile #accordion .accordion-panel p,
    .node-type-profile #accordion .element__hide p,
    .node-type-profile .provider-accordion .accordion-panel p,
    .node-type-profile .provider-accordion .element__hide p {
      margin: 0 0 5px; }
    .node-type-profile #insurances .accordion-panel .education ul li,
    .node-type-profile #insurances .element__hide .education ul li,
    .node-type-profile #education .accordion-panel .education ul li,
    .node-type-profile #education .element__hide .education ul li,
    .node-type-profile #accordion .accordion-panel .education ul li,
    .node-type-profile #accordion .element__hide .education ul li,
    .node-type-profile .provider-accordion .accordion-panel .education ul li,
    .node-type-profile .provider-accordion .element__hide .education ul li {
      list-style-position: inside; }
  .node-type-profile #insurances h2,
  .node-type-profile #education h2,
  .node-type-profile #accordion h2,
  .node-type-profile .provider-accordion h2 {
    font-size: 22px;
    font-weight: normal;
    font-family: "1898Sans-Regular", sans-serif;
    color: #000;
    font-weight: normal;
    padding: 19px 55px 13px 10px; }
  .node-type-profile #insurances h3,
  .node-type-profile #education h3,
  .node-type-profile #accordion h3,
  .node-type-profile .provider-accordion h3 {
    font-size: 17px; }
  .node-type-profile #insurances div:first-child h3,
  .node-type-profile #education div:first-child h3,
  .node-type-profile #accordion div:first-child h3,
  .node-type-profile .provider-accordion div:first-child h3 {
    margin-top: 0; }

.node-type-profile .accordion-expand-holder {
  clear: both;
  float: right;
  margin-bottom: 5px; }
  .node-type-profile .accordion-expand-holder a {
    font-weight: bold;
    color: #000;
    cursor: pointer;
    float: none;
    font-size: inherit;
    opacity: inherit;
    text-shadow: inherit;
    border-bottom: none; }
    .node-type-profile .accordion-expand-holder a:hover {
      border-bottom: none; }

.node-type-profile #physicians-home .pane-content,
.node-type-profile #physicians-home strong {
  vertical-align: top; }

.node-type-profile .pane-wcmc-collapsible-fields {
  margin-bottom: 2em;
  border-bottom: 1px solid #ddd; }

.node-type-profile .ui-widget-content,
.node-type-profile .accordion-panel {
  border: none;
  background: none;
  margin: 1em 1.5em;
  clear: both;
  position: relative;
  width: 93%; }

.node-type-profile .provider-bio h3 {
  margin-bottom: 0;
  padding: 0; }

.node-type-profile .appt-title {
  font-weight: 700; }

.node-type-profile .provider-appointments {
  margin-bottom: 1em; }

.node-type-profile .pane-node-field-biographical-info {
  margin-top: 2em; }

.node-type-profile .pane-node-field-educational-background {
  padding-top: 0; }

.node-type-profile #main-content-header {
  padding: 15px 0;
  background-color: #f7f7f7;
  position: relative;
  width: 100%;
  z-index: 5;
  -webkit-box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.3); }
  .node-type-profile #main-content-header .content {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 10px; }
    .lt-ie9 .node-type-profile #main-content-header .content {
      max-width: 1180px; }
    @media screen and (min-width: 1200px) {
      .node-type-profile #main-content-header .content {
        max-width: 1180px; } }
    .node-type-profile #main-content-header .content .pane-wcmc-display-name {
      float: left; }
      .node-type-profile #main-content-header .content .pane-wcmc-display-name h1 {
        font-family: "1898Sans-Bold", sans-serif;
        padding-top: 5px;
        padding-bottom: 0;
        border-bottom: none; }
    .node-type-profile #main-content-header .content .pane-node-field-primary-specialty {
      clear: both; }
      .node-type-profile #main-content-header .content .pane-node-field-primary-specialty .field .field-label {
        font-family: "Open Sans", sans-serif;
        margin: 0 3px 0 0;
        padding: 0;
        font-weight: normal;
        float: left;
        font-size: 16px;
        line-height: 24px;
        font-size: 1.6rem;
        line-height: 2.4rem; }
      .node-type-profile #main-content-header .content .pane-node-field-primary-specialty ul {
        float: left;
        padding-bottom: 0;
        margin-bottom: 0; }
      .node-type-profile #main-content-header .content .pane-node-field-primary-specialty .field .field-item,
      .node-type-profile #main-content-header .content .pane-node-field-primary-specialty ul li {
        font-weight: bold;
        float: left;
        font-size: 16px;
        line-height: 24px;
        font-size: 1.6rem;
        line-height: 2.4rem; }
      .lt-ie9 .node-type-profile #main-content-header .content .pane-node-field-primary-specialty {
        float: right;
        clear: none; }
      @media screen and (min-width: 767px) {
        .node-type-profile #main-content-header .content .pane-node-field-primary-specialty {
          float: right;
          clear: none; } }

.node-type-profile #main-content {
  margin: 0 auto;
  padding: 0; }
  .node-type-profile #main-content .main-content-bg {
    background: #f7f7f7;
    max-height: 540px;
    width: 100%;
    height: 100%;
    position: absolute; }
    .lt-ie9 .node-type-profile #main-content .main-content-bg {
      max-height: 306px; }
    @media screen and (min-width: 600px) {
      .node-type-profile #main-content .main-content-bg {
        max-height: 306px; } }
  .node-type-profile #main-content .content-container {
    max-width: 970px;
    min-height: 600px;
    margin: 0 auto;
    padding: 0 10px 0;
    position: relative; }
    .lt-ie9 .node-type-profile #main-content .content-container {
      max-width: 1180px; }
    @media screen and (min-width: 1200px) {
      .node-type-profile #main-content .content-container {
        max-width: 1180px; } }
  .node-type-profile #main-content .content {
    position: relative; }
    .lt-ie9 .node-type-profile #main-content .content {
      width: 100%;
      max-width: 585px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
    @media screen and (min-width: 767px) {
      .node-type-profile #main-content .content {
        width: 100%;
        max-width: 585px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); } }
    .lt-ie9 .node-type-profile #main-content .content {
      max-width: 780px; }
    @media screen and (min-width: 1200px) {
      .node-type-profile #main-content .content {
        max-width: 780px; } }
  .node-type-profile #main-content .empty-btn {
    padding: 1em; }
  .node-type-profile #main-content .profile-btn {
    background: #555;
    display: block;
    text-align: center;
    color: #fff;
    padding: 1em;
    font-weight: 700;
    font-size: 1.5rem;
    text-decoration: none;
    margin-bottom: 1px;
    border-bottom: none;
    -webkit-transition: all 0.25s 0s;
    -moz-transition: all 0.25s 0s;
    -ms-transition: all 0.25s 0s;
    -o-transition: all 0.25s 0s;
    transition: all 0.25s 0s; }
    .node-type-profile #main-content .profile-btn:last-child {
      margin-bottom: 0; }
    .node-type-profile #main-content .profile-btn:hover {
      background: #fff;
      color: #555; }
  .node-type-profile #main-content #physicians-home .providers-image {
    text-align: center;
    margin-bottom: 10px; }
    .lt-ie9 .node-type-profile #main-content #physicians-home .providers-image {
      float: left;
      margin: 2px 20px 10px 0; }
    @media screen and (min-width: 600px) {
      .node-type-profile #main-content #physicians-home .providers-image {
        float: left;
        margin: 2px 20px 10px 0; } }
    .node-type-profile #main-content #physicians-home .providers-image .social-icons {
      position: absolute;
      left: -45px;
      width: 40px;
      display: none;
      font-size: 1rem; }
      .lt-ie9 .node-type-profile #main-content #physicians-home .providers-image .social-icons {
        display: block; }
      @media screen and (min-width: 767px) {
        .node-type-profile #main-content #physicians-home .providers-image .social-icons {
          display: block; } }
      .node-type-profile #main-content #physicians-home .providers-image .social-icons a {
        display: block;
        margin-bottom: 5px;
        width: 40px;
        height: 32px;
        background: url(../images/WCM-org_mobile_share_icons_dark.png) no-repeat top center;
        background-size: 32px;
        border-bottom: none; }
        .node-type-profile #main-content #physicians-home .providers-image .social-icons a:hover {
          border-bottom: none; }
        .node-type-profile #main-content #physicians-home .providers-image .social-icons a.social-tw {
          background-position: center -33px; }
        .node-type-profile #main-content #physicians-home .providers-image .social-icons a.social-ln {
          background-position: center -66px; }
        .node-type-profile #main-content #physicians-home .providers-image .social-icons a.social-email {
          background-position: center -99px; }
        .node-type-profile #main-content #physicians-home .providers-image .social-icons a img {
          display: none; }
  .node-type-profile #main-content .provider-badge {
    font-size: 1.5rem;
    margin-top: 1rem;
    padding-top: 7px;
    font-weight: bold; }
    .node-type-profile #main-content .provider-badge a {
      font-weight: normal; }
      .node-type-profile #main-content .provider-badge a:hover {
        text-decoration: underline; }
  .node-type-profile #main-content .provider-badge-icon {
    height: 33px;
    width: 32px;
    display: none;
    float: left;
    margin-top: -7px; }
    .node-type-profile #main-content .provider-badge-icon.provider-badge-icon-voluntary {
      background: url(../images/profile_badges_32x33.png) 0 0;
      background-size: cover;
      display: none; }
    .node-type-profile #main-content .provider-badge-icon.provider-badge-icon-fulltime {
      background: url(../images/profile_badges_32x33.png) 0 -33px;
      background-size: cover; }
    .node-type-profile #main-content .provider-badge-icon.provider-badge-icon-wcpn {
      background: url(../images/profile_badges_32x33.png) 0 -66px;
      background-size: cover; }
  .node-type-profile #main-content .specialties-and-expertise-outer {
    margin-bottom: 20px; }
  .node-type-profile #main-content .personal-statement {
    clear: both;
    color: #000000;
    padding-top: 0; }
    .node-type-profile #main-content .personal-statement h2 {
      font-size: 19px; }
  .node-type-profile #main-content .anchor-btns {
    float: left;
    margin: 0.3em 0 2.5em; }
    .lt-ie9 .node-type-profile #main-content .anchor-btns {
      margin-bottom: 0;
      width: 420px; }
    @media screen and (min-width: 600px) {
      .node-type-profile #main-content .anchor-btns {
        margin-bottom: 0;
        width: 420px; } }
    .lt-ie9 .node-type-profile #main-content .anchor-btns {
      width: 615px; }
    @media screen and (min-width: 1200px) {
      .node-type-profile #main-content .anchor-btns {
        width: 615px; } }
  .node-type-profile #main-content .provider-anchor-btn {
    border-bottom: 1px solid #e6e6e6;
    padding: 11px 0;
    float: left;
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #555;
    text-decoration: none;
    box-sizing: border-box;
    background: url(../images/wcm-org_jump_arrow.png) no-repeat 95% 50%;
    background-size: 15px; }
    .node-type-profile #main-content .provider-anchor-btn:hover {
      color: #e7751d;
      text-decoration: underline;
      -webkit-transition: all 0.25s 0s;
      -moz-transition: all 0.25s 0s;
      -ms-transition: all 0.25s 0s;
      -o-transition: all 0.25s 0s;
      transition: all 0.25s 0s; }
    .node-type-profile #main-content .provider-anchor-btn:first-child {
      border-top: 1px solid #e6e6e6; }

.provider-badge a {
  border-bottom: none; }
  .provider-badge a:hover {
    text-decoration: underline; }

.is-sticky #main-content-header {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5); }

.spacer {
  height: 10px; }

.closed-panel {
  color: #b31b1b; }

.insurance-plans {
  width: auto;
  font-size: inherit;
  border: none;
  float: none;
  background: none;
  padding: 0;
  margin: 0; }
  .insurance-plans:after {
    content: "";
    display: block;
    clear: both; }
  .insurance-plans ul {
    padding-bottom: 0; }
  .insurance-plans li {
    box-sizing: border-box;
    margin-bottom: 0;
    padding-right: 10px;
    list-style-position: inside;
    line-height: 1.8em !important; }
    .insurance-plans li:nth-child(odd) {
      clear: both; }

.list-items {
  width: auto;
  font-size: inherit;
  border: none;
  float: none;
  background: none;
  padding: 0;
  margin: 0; }
  .list-items:after {
    content: "";
    display: block;
    clear: both; }
  .list-items ul {
    padding-bottom: 0; }
  .list-items li {
    box-sizing: border-box;
    width: 50%;
    float: left;
    margin-bottom: 0;
    padding-right: 10px;
    list-style-position: inside;
    line-height: 1.8em !important; }
    .list-items li:nth-child(odd) {
      clear: both; }

.provider-image {
  float: none;
  padding: 0;
  border: none;
  margin: 0; }

.view-research-link {
  display: block;
  text-align: left;
  padding-top: 8px;
  color: #000;
  border-bottom: none; }
  .view-research-link:hover {
    border-bottom: none; }

.provider-profile-wrapper .simple-news-listing {
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 15px;
  padding-left: 15px; }

.provider-profile-wrapper .back-to-search {
  display: none; }

.provider-profile-wrapper .location-listings-wrapper,
.provider-profile-wrapper .video-visit-listing-wrapper {
  margin-bottom: 30px; }
  .lt-ie9 .provider-profile-wrapper .location-listings-wrapper, .lt-ie9
  .provider-profile-wrapper .video-visit-listing-wrapper {
    margin-bottom: 0; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .location-listings-wrapper,
    .provider-profile-wrapper .video-visit-listing-wrapper {
      margin-bottom: 0; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .location-listings-wrapper .item-0,
    .provider-profile-wrapper .video-visit-listing-wrapper .item-0 {
      border-top: none !important;
      margin-top: 17px !important; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .location-listings-wrapper .location-listing .date-picker-container,
    .provider-profile-wrapper .location-listings-wrapper .video-visit-listing .date-picker-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .location-listing .date-picker-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .video-visit-listing .date-picker-container {
      display: block !important; } }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .provider-profile-wrapper .location-listings-wrapper .location-listing .date-picker-container,
    .provider-profile-wrapper .location-listings-wrapper .video-visit-listing .date-picker-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .location-listing .date-picker-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .video-visit-listing .date-picker-container {
      display: block !important; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .location-listings-wrapper .location-listing .date-scrubber-container,
    .provider-profile-wrapper .location-listings-wrapper .video-visit-listing .date-scrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .location-listing .date-scrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .video-visit-listing .date-scrubber-container {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .provider-profile-wrapper .location-listings-wrapper .location-listing .date-scrubber-container,
    .provider-profile-wrapper .location-listings-wrapper .video-visit-listing .date-scrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .location-listing .date-scrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .video-visit-listing .date-scrubber-container {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .location-listings-wrapper .location-listing .timegrid-datescrubber-container,
    .provider-profile-wrapper .location-listings-wrapper .video-visit-listing .timegrid-datescrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .location-listing .timegrid-datescrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .video-visit-listing .timegrid-datescrubber-container {
      margin-top: 0px !important; } }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .location-listings-wrapper .location-listing .timegrid-datescrubber-container .date-scrubber-container,
    .provider-profile-wrapper .location-listings-wrapper .video-visit-listing .timegrid-datescrubber-container .date-scrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .location-listing .timegrid-datescrubber-container .date-scrubber-container,
    .provider-profile-wrapper .video-visit-listing-wrapper .video-visit-listing .timegrid-datescrubber-container .date-scrubber-container {
      margin-top: 0px !important; } }

.provider-profile-wrapper .timegrid-datescrubber-container {
  background: transparent;
  padding-top: 15px;
  top: 0px !important; }

.lt-ie9 .provider-profile-wrapper .date-scrubber-wrapper {
  width: 422px; }

@media screen and (min-width: 990px) {
  .provider-profile-wrapper .date-scrubber-wrapper {
    width: 422px; } }

.lt-ie9 .provider-profile-wrapper .date-scrubber-wrapper {
  width: 520px; }

@media screen and (min-width: 1200px) {
  .provider-profile-wrapper .date-scrubber-wrapper {
    width: 520px; } }

.lt-ie9 .provider-profile-wrapper .timeslots-grid-container {
  width: 85%; }

@media screen and (min-width: 990px) {
  .provider-profile-wrapper .timeslots-grid-container {
    width: 85%; } }

.provider-profile-wrapper .provider-headshot {
  width: 100%;
  display: none; }
  .lt-ie9 .provider-profile-wrapper .provider-headshot {
    display: block; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .provider-headshot {
      display: block; } }

.provider-profile-wrapper .headshot-mobile {
  padding: 7px 10px 18px;
  margin-left: auto;
  margin-right: auto;
  width: 40%; }
  .lt-ie9 .provider-profile-wrapper .headshot-mobile {
    display: none; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .headshot-mobile {
      display: none; } }

.provider-profile-wrapper .provider-headshot-mobile {
  display: block; }
  .lt-ie9 .provider-profile-wrapper .provider-headshot-mobile {
    display: none; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .provider-headshot-mobile {
      display: none; } }

.lt-ie9 .provider-profile-wrapper .header-pane {
  padding: 0 0 0 30px;
  margin-bottom: 10px;
  min-height: 243px; }

@media screen and (min-width: 767px) {
  .provider-profile-wrapper .header-pane {
    padding: 0 0 0 30px;
    margin-bottom: 10px;
    min-height: 243px; } }

.provider-profile-wrapper .header-pane a#back {
  display: inline;
  width: fit-content;
  order: -3;
  margin: 0px 0px 23px 20px; }
  .lt-ie9 .provider-profile-wrapper .header-pane a#back {
    display: none; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .header-pane a#back {
      display: none; } }

.provider-profile-wrapper .header-pane .social-icons {
  display: none; }
  .provider-profile-wrapper .header-pane .social-icons a {
    display: block;
    margin-bottom: 5px; }

.provider-profile-wrapper .header-pane #info-pane {
  width: 100%; }
  .provider-profile-wrapper .header-pane #info-pane #provider-header {
    position: relative;
    width: -webkit-fill-available; }
    @media (max-width: 767px) {
      .provider-profile-wrapper .header-pane #info-pane #provider-header {
        padding-left: 0; } }
    @media (max-width: 767px) {
      .provider-profile-wrapper .header-pane #info-pane #provider-header h1 {
        font-size: 20px;
        line-height: 20px;
        text-align: center; } }
    .provider-profile-wrapper .header-pane #info-pane #provider-header h3 {
      margin-top: 0px;
      color: #555;
      padding-bottom: 5px; }
      @media (max-width: 767px) {
        .provider-profile-wrapper .header-pane #info-pane #provider-header h3 {
          font-size: 18px;
          text-align: center; } }
  .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper {
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
    border-left: 1px solid #e6e6e6;
    margin-top: 10px;
    width: 100px; }
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .print-icon,
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .share-icon {
      padding-left: 20px; }
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper a[class="share-icon"] {
      background: url(/sites/all/themes/weillcornell/images/share-icon.png);
      display: block;
      background-repeat: no-repeat;
      width: 35px;
      height: 35px;
      margin: 0px 0px 10px 30px;
      border-bottom: 0px; }
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper a[class="share-icon"]:focus {
        color: transparent; }
      @media (max-width: 767px) {
        .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper a[class="share-icon"] {
          background: url(/sites/all/themes/weillcornell/images/share-icon-mobile.png);
          display: none;
          background-repeat: no-repeat;
          margin: 0px 0px 10px 5px; } }
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper a:hover {
      color: transparent; }
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .print-icon:hover,
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .share-icon:hover {
      cursor: pointer; }
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .print-icon {
      background: url(/sites/all/themes/weillcornell/images/print-icon.png);
      display: block;
      width: 35px;
      height: 27px;
      background-repeat: no-repeat;
      margin: 10px 0px 0px 30px; }
      @media (max-width: 767px) {
        .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .print-icon {
          display: none; } }
    @media (max-width: 767px) {
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper {
        border-left: 0px;
        margin-top: 0px;
        position: absolute;
        left: 250px; } }
    .lt-ie9 .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover {
      top: -55px !important; }
    @media screen and (min-width: 767px) {
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover {
        top: -55px !important; } }
    .lt-ie9 .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover.left > .arrow {
      top: 70px !important; }
    @media screen and (min-width: 767px) {
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover.left > .arrow {
        top: 70px !important; } }
    .lt-ie9 .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content {
      display: block; }
    @media screen and (min-width: 767px) {
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content {
        display: block; } }
    .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content a {
      display: block;
      margin-bottom: 5px;
      width: 40px;
      height: 32px;
      background: url(../images/WCM-org_mobile_share_icons_dark.png) no-repeat top center;
      background-size: 32px;
      border-bottom: none; }
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content a:hover {
        border-bottom: none; }
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content a.social-tw {
        background-position: center -33px; }
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content a.social-ln {
        background-position: center -66px; }
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content a.social-email {
        background-position: center -99px; }
      .provider-profile-wrapper .header-pane #info-pane #share-print-wrapper .popover-content a img {
        display: none; }

@media (max-width: 767px) {
  .provider-profile-wrapper .header-pane [class*="col-"]:nth-child(2) {
    position: relative;
    margin-left: auto;
    display: flex;
    flex-direction: row; } }

.provider-profile-wrapper .header-pane [class*="col-"]:nth-child(2):after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 100;
  width: 1px;
  height: 60%;
  background: #e6e6e6; }
  @media (max-width: 767px) {
    .provider-profile-wrapper .header-pane [class*="col-"]:nth-child(2):after {
      display: none; } }

@media (max-width: 767px) {
  .provider-profile-wrapper .header-pane [class*="col-"]:nth-child(3) {
    padding-top: 0px; } }

.provider-profile-wrapper .header-pane [class*="col-"]:nth-child(3):after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 100;
  width: 1px;
  height: 100%;
  background: #e6e6e6; }
  @media (max-width: 767px) {
    .provider-profile-wrapper .header-pane [class*="col-"]:nth-child(3):after {
      display: none; } }

@media (max-width: 767px) {
  .provider-profile-wrapper .header-pane [class*="col-"]:nth-child(4) {
    display: none; } }

.provider-profile-wrapper .header-pane [class*="col-"] {
  border-top: 1px solid #e6e6e6;
  padding-top: 20px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .provider-profile-wrapper .header-pane [class*="col-"] {
      border-top: none;
      padding-top: 5px;
      margin-top: 0px; } }

.provider-profile-wrapper .header-pane .btn-secondary {
  border-radius: 5px;
  padding: 10px 40px 5px 40px;
  height: 40px;
  font-size: 14px; }
  @media (max-width: 767px) {
    .provider-profile-wrapper .header-pane .btn-secondary {
      width: 100%; } }

.provider-profile-wrapper .header-pane a {
  font-size: 14px;
  font-weight: 400; }

.provider-profile-wrapper .header-pane .about-voluntary {
  margin-top: 20px;
  padding-left: 10px;
  border-top: 1px solid #e7e7e7; }

.provider-profile-wrapper .header-pane .voluntary-label-wrapper {
  font-size: 1.5rem;
  margin-top: 0;
  padding-top: 0;
  font-weight: bold;
  width: 100%;
  text-align: center; }
  .provider-profile-wrapper .header-pane .voluntary-label-wrapper a {
    border-bottom: none; }
    .provider-profile-wrapper .header-pane .voluntary-label-wrapper a:hover {
      text-decoration: underline; }
  .provider-profile-wrapper .header-pane .voluntary-label-wrapper a {
    font-weight: normal; }
    .provider-profile-wrapper .header-pane .voluntary-label-wrapper a:hover {
      text-decoration: underline; }
  .lt-ie9 .provider-profile-wrapper .header-pane .voluntary-label-wrapper {
    text-align: left; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .header-pane .voluntary-label-wrapper {
      text-align: left; } }

.provider-profile-wrapper .header-pane .provider-badge-icon {
  height: 33px;
  width: 32px;
  display: block;
  float: left;
  margin-top: -7px; }
  .provider-profile-wrapper .header-pane .provider-badge-icon.provider-badge-icon-voluntary {
    background: url(../images/profile_badges_32x33.png) 0 0;
    background-size: cover; }
  .provider-profile-wrapper .header-pane .provider-badge-icon.provider-badge-icon-fulltime {
    background: url(../images/profile_badges_32x33.png) 0 -33px;
    background-size: cover; }
  .provider-profile-wrapper .header-pane .provider-badge-icon.provider-badge-icon-wcpn {
    background: url(../images/profile_badges_32x33.png) 0 -66px;
    background-size: cover; }

.provider-profile-wrapper .research {
  margin: 7px 0 10px; }
  .provider-profile-wrapper .research a {
    font-size: 14px; }

.provider-profile-wrapper .research-inner-wrapper {
  padding-left: 15px; }

.provider-profile-wrapper .online-scheduling-info-wrapper {
  margin-top: 5px;
  display: inline-block; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .online-scheduling-info-wrapper {
      width: 80%;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px; } }

.provider-profile-wrapper .appointment-btn-wrapper {
  margin-bottom: 10px; }
  .lt-ie9 .provider-profile-wrapper .appointment-btn-wrapper {
    margin-top: 10px; }
  @media screen and (min-width: 767px) {
    .provider-profile-wrapper .appointment-btn-wrapper {
      margin-top: 10px; } }

.provider-profile-wrapper .header-pane-sticky {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 5;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5); }
  .provider-profile-wrapper .header-pane-sticky .physician-title {
    font-size: 2.8rem;
    line-height: 3.5rem;
    margin: 0;
    font-weight: normal;
    color: #b31b1b;
    font-family: "1898Sans-Bold", sans-serif; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .provider-profile-wrapper .header-pane-sticky .physician-title {
        font-size: 18px;
        line-height: 24px; } }
  .provider-profile-wrapper .header-pane-sticky .physician-specialty {
    color: #555;
    font-size: 20px;
    line-height: 26px;
    margin: 4px 0 10px;
    font-family: "1898Sans-Bold", sans-serif; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .provider-profile-wrapper .header-pane-sticky .physician-specialty {
        font-size: 15px;
        line-height: 20px;
        margin-top: 3px; } }
  .provider-profile-wrapper .header-pane-sticky h3 {
    margin-top: 0px;
    color: #555; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .provider-profile-wrapper .header-pane-sticky h3 {
        font-size: 18px; } }
  .provider-profile-wrapper .header-pane-sticky .physician-info,
  .provider-profile-wrapper .header-pane-sticky .appointment-btn-wrapper {
    display: inline-block;
    padding-top: 15px; }
  .provider-profile-wrapper .header-pane-sticky .appointment-btn-wrapper {
    float: right;
    margin-top: 10px; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .provider-profile-wrapper .header-pane-sticky .appointment-btn-wrapper {
        width: 100%;
        margin-right: 0;
        padding-top: 0;
        margin-top: 0; } }
    .provider-profile-wrapper .header-pane-sticky .appointment-btn-wrapper .btn-secondary {
      font-size: 14px;
      border-radius: 5px;
      padding: 10px 40px 5px 40px;
      height: 40px;
      width: 100%;
      margin-bottom: 10px; }
  .provider-profile-wrapper .header-pane-sticky .header-pane-sticky-content {
    margin-left: auto;
    margin-right: auto; }

.provider-profile-wrapper .gender-languages-wrapper {
  display: inline-block;
  padding-top: 3px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .gender-languages-wrapper {
      width: 100%;
      margin: 7px 0 10px; } }

.provider-profile-wrapper .provider-languages,
.provider-profile-wrapper .provider-gender {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: #555; }
  .provider-profile-wrapper .provider-languages span,
  .provider-profile-wrapper .provider-gender span {
    font-weight: 400;
    color: #000; }
  @media (max-width: 767px) {
    .provider-profile-wrapper .provider-languages,
    .provider-profile-wrapper .provider-gender {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      text-align: center; } }

.provider-profile-wrapper .section-content h3 {
  background-color: #f8f8f8;
  padding-top: 10px;
  padding-left: 15px;
  margin-top: 10px;
  color: #555; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .section-content h3 {
      font-size: 15px;
      padding: 8px 0px 8px 15px; } }

.provider-profile-wrapper .section-content h2 {
  color: #555; }

.provider-profile-wrapper .section-content h4, .provider-profile-wrapper .section-content .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .provider-profile-wrapper .section-content .teaser-title {
  font-weight: bold;
  margin-top: 10px; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .provider-profile-wrapper .section-content h4, .provider-profile-wrapper .section-content .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .provider-profile-wrapper .section-content .teaser-title {
      font-size: 15px; } }

.provider-profile-wrapper .section-content .list-items {
  width: auto;
  font-size: inherit;
  border: none;
  float: none;
  background: none;
  padding-left: 15px;
  margin: 0;
  margin-top: -15px; }

.provider-profile-wrapper .section-content ul li {
  list-style-position: inside; }

@media (max-width: 767px) {
  .provider-profile-wrapper .section-content li {
    width: 100%; } }

.provider-profile-wrapper .section-content #provider-bio,
.provider-profile-wrapper .section-content .personal-statement-inner-wrapper,
.provider-profile-wrapper .section-content #provider-insurances,
.provider-profile-wrapper .section-content #provider-honors,
.provider-profile-wrapper .section-content .voluntary-inner-wrapper {
  padding-left: 15px; }

.provider-profile-wrapper .about-section .specialties-and-expertise-wrapper {
  padding-left: 15px; }

@media screen and (min-width: 0) and (max-width: 599px) {
  .provider-profile-wrapper .locations-and-appointments-section .direct-scheduling-cta {
    width: 100%; } }

.provider-profile-wrapper .patient-forms-inner-wrapper ul {
  padding: 15px 0 0 15px;
  list-style: none; }

.provider-profile-wrapper .provider-education-wrapper .list-items {
  margin: 20px 0; }

@media screen and (min-width: 0) and (max-width: 599px) {
  .provider-profile-wrapper #honors-and-awards h3 {
    display: none; } }

.provider-profile-wrapper #honors-and-awards #provider-honors ul {
  margin-top: 20px; }

.provider-profile-wrapper #honors-and-awards #provider-honors span {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important; }

.provider-profile-wrapper #honors-and-awards #provider-honors li {
  line-height: 28px !important; }

.lt-ie9 .provider-profile-wrapper .video-outer-wrapper {
  padding: 0 100px; }

@media screen and (min-width: 990px) {
  .provider-profile-wrapper .video-outer-wrapper {
    padding: 0 100px; } }

.provider-profile-wrapper .video-outer-wrapper p {
  margin-top: -45px; }

.provider-profile-wrapper .video-wrapper {
  margin: 30px 0; }

.provider-profile-wrapper .photo-wrapper {
  margin: 20px 0;
  max-width: 100%;
  padding: 0; }
  .lt-ie9 .provider-profile-wrapper .photo-wrapper {
    padding: 0 100px; }
  @media screen and (min-width: 990px) {
    .provider-profile-wrapper .photo-wrapper {
      padding: 0 100px; } }

.provider-profile-wrapper .visit-type-filter-wrapper {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 20px 0 10px;
  margin-top: -20px; }
  .provider-profile-wrapper .visit-type-filter-wrapper .visit-type-label {
    display: inline;
    font-size: 14px !important;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    line-height: 24px;
    padding-top: 15px;
    margin: 12px 30px 0px 15px; }
    .provider-profile-wrapper .visit-type-filter-wrapper .visit-type-label .form-group {
      display: inline !important; }
  .provider-profile-wrapper .visit-type-filter-wrapper .clear-selections {
    font-family: "Open Sans", sans-serif;
    margin-left: 15px;
    padding-top: 5px;
    font-size: 13px; }
  .provider-profile-wrapper .visit-type-filter-wrapper .visit-type-wrapper {
    min-width: fit-content; }
  .provider-profile-wrapper .visit-type-filter-wrapper .checkbox-wrapper {
    padding: 0 10px 0 0; }
  .provider-profile-wrapper .visit-type-filter-wrapper .filter-item {
    margin-right: 35px; }
    .provider-profile-wrapper .visit-type-filter-wrapper .filter-item span {
      font-size: 14px !important;
      font-family: "Open Sans", sans-serif;
      line-height: 24px; }

.provider-profile-wrapper .visittype-filter {
  margin-top: 12px;
  padding: 10px 20px 0px 20px !important; }
  .provider-profile-wrapper .visittype-filter .expansions-panel-summary {
    margin: 0px !important;
    padding: 0; }
    .provider-profile-wrapper .visittype-filter .expansions-panel-summary p {
      margin: 0px !important; }
  .provider-profile-wrapper .visittype-filter .filter-wrapper {
    border: 1px solid #ddd;
    border-radius: 5px; }
  .provider-profile-wrapper .visittype-filter .panel-title {
    margin: 0px !important;
    padding: 0px; }

.provider-profile-wrapper .external-relationships-inner-container {
  padding: 0 15px; }
  .provider-profile-wrapper .external-relationships-inner-container .external-relationship-row {
    margin-bottom: 20px; }
    .provider-profile-wrapper .external-relationships-inner-container .external-relationship-row .er-label {
      font-weight: bold;
      text-align: left;
      font-size: 14px;
      line-height: 22px; }
      .lt-ie9 .provider-profile-wrapper .external-relationships-inner-container .external-relationship-row .er-label {
        text-align: right; }
      @media screen and (min-width: 990px) {
        .provider-profile-wrapper .external-relationships-inner-container .external-relationship-row .er-label {
          text-align: right; } }
      .provider-profile-wrapper .external-relationships-inner-container .external-relationship-row .er-label .tooltip-icon {
        margin-right: 5px;
        position: relative;
        top: 2px; }
    .provider-profile-wrapper .external-relationships-inner-container .external-relationship-row .er-content {
      font-size: 14px;
      line-height: 22px; }

#accepting-patients,
.simple-tag {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  border-radius: 20px;
  width: 200px;
  margin-top: 0; }
  .lt-ie9 #accepting-patients, .lt-ie9
  .simple-tag {
    float: left;
    height: 40px;
    margin-right: 15px; }
  @media screen and (min-width: 767px) {
    #accepting-patients,
    .simple-tag {
      float: left;
      height: 40px;
      margin-right: 15px; } }
  @media (max-width: 767px) {
    #accepting-patients,
    .simple-tag {
      position: relative;
      width: 97%;
      text-align: center;
      margin-top: 5px;
      background-color: transparent;
      border-color: transparent; } }
  #accepting-patients .new-patients,
  #accepting-patients .tag-text,
  .simple-tag .new-patients,
  .simple-tag .tag-text {
    position: relative;
    top: -7px;
    padding-left: 8px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    color: #555; }
    @media (max-width: 767px) {
      #accepting-patients .new-patients,
      #accepting-patients .tag-text,
      .simple-tag .new-patients,
      .simple-tag .tag-text {
        top: -2px;
        padding-left: 5px;
        font-weight: normal; } }
  #accepting-patients .dot,
  .simple-tag .dot {
    height: 22px;
    width: 23px;
    background-color: #66ff33;
    border-radius: 50%;
    display: inline-block;
    margin: 9px 0px 0px 11px; }
    @media (max-width: 767px) {
      #accepting-patients .dot,
      .simple-tag .dot {
        height: 13px;
        width: 12px; } }
  .lt-ie9 #accepting-patients.not-accepting, .lt-ie9
  .simple-tag.not-accepting {
    width: 250px; }
  @media screen and (min-width: 767px) {
    #accepting-patients.not-accepting,
    .simple-tag.not-accepting {
      width: 250px; } }
  #accepting-patients.not-accepting .dot,
  .simple-tag.not-accepting .dot {
    background-color: #f44242 !important; }
  #accepting-patients .icon,
  .simple-tag .icon {
    padding: 10px 0 0 15px;
    display: inline-block; }
    #accepting-patients .icon img,
    .simple-tag .icon img {
      width: 20px;
      height: 20px; }
  #accepting-patients .video-visit-icon,
  .simple-tag .video-visit-icon {
    opacity: 0.6; }
  #accepting-patients .tag-text,
  .simple-tag .tag-text {
    top: 0;
    padding-left: 6px; }

.not-accepting-new-patients {
  background-color: #f44242 !important; }

.for-selected-insurance {
  width: 370px !important;
  display: inline; }
  .lt-ie9 .for-selected-insurance {
    display: block; }
  @media screen and (min-width: 600px) {
    .for-selected-insurance {
      display: block; } }

.not-for-selected-insurance {
  width: 400px !important; }
  .not-for-selected-insurance .dot {
    background-color: #f44242 !important; }

.search-result-item #accepting-patients,
.search-result-item .simple-tag {
  height: 33px;
  margin-top: 0;
  margin-left: 0;
  float: none; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .search-result-item #accepting-patients,
    .search-result-item .simple-tag {
      margin-top: 0; } }
  .lt-ie9 .search-result-item #accepting-patients, .lt-ie9
  .search-result-item .simple-tag {
    width: 180px; }
  @media screen and (min-width: 990px) {
    .search-result-item #accepting-patients,
    .search-result-item .simple-tag {
      width: 180px; } }
  .search-result-item #accepting-patients .new-patients,
  .search-result-item .simple-tag .new-patients {
    top: -8px;
    font-size: 12px; }
  .search-result-item #accepting-patients .dot,
  .search-result-item .simple-tag .dot {
    height: 15px;
    width: 15px;
    margin: 9px 0px 5px 11px; }
  .search-result-item #accepting-patients .tag-text,
  .search-result-item .simple-tag .tag-text {
    top: -1px;
    font-size: 12px; }
  .search-result-item #accepting-patients .icon,
  .search-result-item .simple-tag .icon {
    padding: 7px 0 0 11px; }
    .search-result-item #accepting-patients .icon img,
    .search-result-item .simple-tag .icon img {
      width: 17px;
      height: 17px; }

.lt-ie9 .search-result-item .not-accepting {
  width: 220px !important; }

@media screen and (min-width: 767px) {
  .search-result-item .not-accepting {
    width: 220px !important; } }

.search-result-item .video-visit-details {
  text-align: center; }
  .lt-ie9 .search-result-item .video-visit-details {
    text-align: left; }
  @media screen and (min-width: 767px) {
    .search-result-item .video-visit-details {
      text-align: left; } }
  .search-result-item .video-visit-details img {
    height: 35px;
    margin-top: 3px;
    padding: 0; }
    .lt-ie9 .search-result-item .video-visit-details img {
      height: 45px;
      margin-top: 20px;
      margin-left: 5px; }
    @media screen and (min-width: 990px) {
      .search-result-item .video-visit-details img {
        height: 45px;
        margin-top: 20px;
        margin-left: 5px; } }

.search-result-item .video-visit-listing {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.hospital-badge-wrapper {
  border: 3px solid #e6e6e6;
  margin: 30px 0;
  position: relative; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .hospital-badge-wrapper {
      margin: 30px; } }
  .hospital-badge-wrapper .badge-title {
    position: relative;
    top: -12px;
    background: #fff;
    padding: 0 20px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0%); }
  .hospital-badge-wrapper .hospital-badge-logo-wrapper {
    padding-top: 10px;
    display: inline-block; }
    .hospital-badge-wrapper .hospital-badge-logo-wrapper img {
      width: 100%; }
    @media screen and (max-width: 529px) and (min-width: 0px) {
      .hospital-badge-wrapper .hospital-badge-logo-wrapper {
        padding: 0px 60px 10px 60px; } }
    @media screen and (max-width: 599px) and (min-width: 530px) {
      .hospital-badge-wrapper .hospital-badge-logo-wrapper {
        padding: 0px 80px 10px 80px; } }
    .lt-ie9 .hospital-badge-wrapper .hospital-badge-logo-wrapper {
      margin: -12px 40px 0px; }
    @media screen and (min-width: 767px) {
      .hospital-badge-wrapper .hospital-badge-logo-wrapper {
        margin: -12px 40px 0px; } }
    @media screen and (min-width: 600px) and (max-width: 769px) {
      .hospital-badge-wrapper .hospital-badge-logo-wrapper {
        margin: -12px 14px 0; } }
    @media screen and (min-width: 990px) and (max-width: 1199px) {
      .hospital-badge-wrapper .hospital-badge-logo-wrapper {
        margin: -12px 30px 0px; } }
    .hospital-badge-wrapper .hospital-badge-logo-wrapper a {
      border-bottom: none; }
      .hospital-badge-wrapper .hospital-badge-logo-wrapper a:hover {
        text-decoration: none;
        border: none; }
  .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
    display: inline-block; }
    @media screen and (max-width: 529px) and (min-width: 0px) {
      .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
        padding: 0px 60px 10px 60px; } }
    @media screen and (max-width: 599px) and (min-width: 530px) {
      .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
        padding: 0px 80px 10px 80px; } }
    .lt-ie9 .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
      margin: 0px 40px 0px; }
    @media screen and (min-width: 767px) {
      .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
        margin: 0px 40px 0px; } }
    @media screen and (min-width: 600px) and (max-width: 769px) {
      .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
        margin: 0px 14px 0; } }
    @media screen and (min-width: 990px) and (max-width: 1199px) {
      .hospital-badge-wrapper .hospital-badge-image-logo-wrapper {
        margin: 0px 30px 0px; } }
    .hospital-badge-wrapper .hospital-badge-image-logo-wrapper a {
      border-bottom: none; }
      .hospital-badge-wrapper .hospital-badge-image-logo-wrapper a:hover {
        text-decoration: none;
        border: none; }
  .hospital-badge-wrapper .nyp-logo-wrapper {
    padding: 25px 100px 25px 100px; }
  .hospital-badge-wrapper .badge-affiliations {
    position: relative;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0%);
    padding-bottom: 20px; }
    .hospital-badge-wrapper .badge-affiliations a {
      font-size: 14px; }

.castle-connolly-badge-wrapper {
  border: 3px solid #e6e6e6;
  margin: 30px 0;
  position: relative; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .castle-connolly-badge-wrapper {
      margin: 30px; } }
  .castle-connolly-badge-wrapper .badge-title {
    position: absolute;
    top: -12px;
    left: 20px;
    background: #fff;
    padding: 0 20px; }
  .castle-connolly-badge-wrapper .castle-connolly-logo-wrapper {
    margin: 30px 70px 0; }
    .lt-ie9 .castle-connolly-badge-wrapper .castle-connolly-logo-wrapper {
      margin: 40px; }
    @media screen and (min-width: 767px) {
      .castle-connolly-badge-wrapper .castle-connolly-logo-wrapper {
        margin: 40px; } }
    @media screen and (min-width: 600px) and (max-width: 769px) {
      .castle-connolly-badge-wrapper .castle-connolly-logo-wrapper {
        margin: 30px 210px 0; } }
    .lt-ie9 .castle-connolly-badge-wrapper .castle-connolly-logo-wrapper {
      padding-right: 30px;
      border-right: 2px solid #e6e6e6; }
    @media screen and (min-width: 990px) {
      .castle-connolly-badge-wrapper .castle-connolly-logo-wrapper {
        padding-right: 30px;
        border-right: 2px solid #e6e6e6; } }
  .castle-connolly-badge-wrapper .castle-connolly-text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 90px 80px 15px 0; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .castle-connolly-badge-wrapper .castle-connolly-text {
        padding: 30px;
        font-size: 14px;
        line-height: 20px; } }
    @media screen and (min-width: 600px) and (max-width: 769px) {
      .castle-connolly-badge-wrapper .castle-connolly-text {
        padding: 30px; } }
  .castle-connolly-badge-wrapper a {
    border-bottom: none; }
    .castle-connolly-badge-wrapper a:hover {
      text-decoration: none;
      border: none; }

.lt-ie9 .section-title {
  display: none; }

@media screen and (min-width: 767px) {
  .section-title {
    display: none; } }

.section-content {
  display: none; }
  .lt-ie9 .section-content.active {
    display: block;
    position: relative; }
  @media screen and (min-width: 767px) {
    .section-content.active {
      display: block;
      position: relative; } }

.v-tab-page-nav {
  display: none;
  list-style: none;
  text-align: center; }
  .lt-ie9 .v-tab-page-nav {
    display: block; }
  @media screen and (min-width: 767px) {
    .v-tab-page-nav {
      display: block; } }
  .v-tab-page-nav li a {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: #666;
    border-bottom: none;
    padding: 25px 0;
    display: block;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6; }
    .v-tab-page-nav li a:hover {
      text-decoration: none;
      background: #f9f9f9; }
    .v-tab-page-nav li a:active {
      background: #fff; }
    .v-tab-page-nav li a:focus {
      outline: 5px auto -webkit-focus-ring-color; }
    .v-tab-page-nav li a.active {
      background: #fff;
      color: #b31b1b;
      border-left: 1px solid #e6e6e6;
      border-right: none;
      border-left: 3px solid #e7751d; }

.h-tab-page-nav {
  list-style: none;
  padding-left: 0;
  padding-top: 40px; }
  .h-tab-page-nav > div > div > div > div {
    border-bottom: 1px solid #e3e3e3; }

.section__show-hide {
  position: relative; }
  .section__show-hide:before, .section__show-hide:after {
    content: " ";
    display: table; }
  .section__show-hide:after {
    clear: both; }

.section__toggle {
  position: relative;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  color: #cf4520;
  border: 1px solid #dddddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600; }
  .section__toggle:after {
    content: "+";
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #dddddd; }
  .section__toggle:hover {
    background: #f7f7f7;
    color: #e7751d; }
  .section__toggle h2 {
    font-family: inherit;
    font-size: 15px;
    line-height: 18px;
    color: #cf4520;
    font-weight: bold;
    padding: 0;
    margin: 0; }

.section__toggle--open {
  color: #000000; }
  .section__toggle--open:not(.ctools-collapsible-container):after {
    content: "–";
    color: #cf4520; }
  .section__toggle--open:hover {
    color: #cf4520; }

.section__hide {
  display: none; }
  .lt-ie9 .section__hide {
    padding: 0 0 0 30px; }
  @media screen and (min-width: 767px) {
    .section__hide {
      padding: 0 0 0 30px; } }

.section__hide p {
  margin: 20px 0; }

.section__hide--open {
  display: block; }

.no-js .element__hide {
  display: block; }

.no-js .element__toggle:after {
  display: none; }

.tooltip-icon {
  color: #aaa;
  font-size: 16px; }

/* tipped element. should be inline-block or block */
.qtip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #3bb4e5;
  box-sizing: border-box;
  font-style: normal;
  transition: all 0.25s ease-in-out; }

/* the tip */
.qtip:before {
  content: attr(data-tip);
  min-width: 250px;
  text-align: left;
  color: #000;
  background: #fff;
  font-size: 14px;
  position: absolute;
  z-index: 100;
  padding: 15px 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 3px solid #ddd;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0;
  font-weight: normal; }
  .lt-ie9 .qtip:before {
    min-width: 440px; }
  @media screen and (min-width: 767px) {
    .qtip:before {
      min-width: 440px; } }

.qtip:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out; }

.qtip:hover:before,
.qtip:hover:after {
  visibility: visible;
  opacity: 1; }

/* top */
.qtip.tip-top:before {
  top: -3px;
  left: 10%;
  transform: translate(-10%, calc(-100% - 8px));
  box-sizing: border-box; }

.qtip.tip-top:after {
  border-width: 15px 15px 0 15px;
  border-color: #fff transparent transparent transparent;
  top: -15px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0); }

/* bottom */
.qtip.tip-bottom:before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 8px));
  box-sizing: border-box;
  border-radius: 3px; }

.qtip.tip-bottom:after {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent rgba(10, 20, 30, 0.85) transparent;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0); }

/* left */
.qtip.tip-left:before {
  left: 0;
  top: 50%;
  transform: translate(calc(-100% - 15px), -50%);
  box-sizing: border-box;
  border-radius: 3px; }

.qtip.tip-left:after {
  border-width: 15px 0 15px 15px;
  border-color: #fff transparent transparent transparent;
  left: -15px;
  top: 50%;
  z-index: 100;
  transform: translate(0, -50%); }

/* right */
.qtip.tip-right:before {
  right: 0;
  top: 50%;
  transform: translate(calc(100% + 8px), -50%);
  box-sizing: border-box;
  border-radius: 3px; }

.qtip.tip-right:after {
  border-width: 8px 8px 8px 0;
  border-color: transparent rgba(10, 20, 30, 0.85) transparent transparent;
  right: -8px;
  top: 50%;
  transform: translate(0, -50%); }

#map-canvas {
  border: 1px solid #aaaaaa;
  width: 96%;
  height: 210px;
  margin: 0 auto 5px; }
  .lt-ie9 #map-canvas {
    width: 92%; }
  @media screen and (min-width: 767px) {
    #map-canvas {
      width: 92%; } }

.panel-2col-right #main-content {
  width: 100%;
  padding: 0 0px 45px 0; }
  .lt-ie9 .panel-2col-right #main-content {
    display: inline;
    float: left;
    width: 66.66667%;
    margin: 0 0%;
    padding: 0 15px 45px 0; }
  @media screen and (min-width: 600px) {
    .panel-2col-right #main-content {
      display: inline;
      float: left;
      width: 66.66667%;
      margin: 0 0%;
      padding: 0 15px 45px 0; } }

.panel-2col-right #sidebar-second {
  width: 100%;
  padding: 0;
  float: left; }
  .lt-ie9 .panel-2col-right #sidebar-second {
    display: inline;
    float: left;
    width: 33.33333%;
    margin: 0 0%;
    padding: 0 0 0 15px; }
  @media screen and (min-width: 600px) {
    .panel-2col-right #sidebar-second {
      display: inline;
      float: left;
      width: 33.33333%;
      margin: 0 0%;
      padding: 0 0 0 15px; } }

.hero-image figcaption {
  margin: 0 10px 1em;
  text-align: right;
  font-style: italic; }
  .lt-ie9 .hero-image figcaption {
    margin: 0 0 1em; }
  @media screen and (min-width: 600px) {
    .hero-image figcaption {
      margin: 0 0 1em; } }

.view-teaser {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd; }
  .view-teaser img.panopoly-image-half {
    float: right;
    width: 40%;
    max-width: 40%;
    margin: 0.5em 0 0.5em 0.75em; }
  .view-teaser .post-date {
    color: #666666;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 0;
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 13px;
    letter-spacing: 2px; }

.front .view-news .view-teaser,
.news-teasers-pane .view-teaser {
  position: relative;
  padding-bottom: 0;
  overflow: hidden; }
  .front .view-news .view-teaser a,
  .news-teasers-pane .view-teaser a {
    display: inline-block !important;
    margin: 5px;
    width: 100%; }
  .front .view-news .view-teaser .news-link,
  .news-teasers-pane .view-teaser .news-link {
    position: relative;
    background: #b31b1b;
    width: 100%;
    height: 191px;
    text-align: center;
    top: 0;
    color: #fff; }
    .front .view-news .view-teaser .news-link:focus,
    .news-teasers-pane .view-teaser .news-link:focus {
      outline: 5px auto -webkit-focus-ring-color; }
    .front .view-news .view-teaser .news-link .news-link-content,
    .news-teasers-pane .view-teaser .news-link .news-link-content {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      padding: 0 2em;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
      .lt-ie9 .front .view-news .view-teaser .news-link .news-link-content, .lt-ie9
      .news-teasers-pane .view-teaser .news-link .news-link-content {
        padding: 0 4em; }
      @media screen and (min-width: 600px) {
        .front .view-news .view-teaser .news-link .news-link-content,
        .news-teasers-pane .view-teaser .news-link .news-link-content {
          padding: 0 4em; } }
      .front .view-news .view-teaser .news-link .news-link-content h2,
      .news-teasers-pane .view-teaser .news-link .news-link-content h2 {
        font-size: 1.5rem;
        line-height: 1.8rem;
        padding-bottom: 0;
        margin-top: 0;
        color: #fff; }
        .lt-ie9 .front .view-news .view-teaser .news-link .news-link-content h2, .lt-ie9
        .news-teasers-pane .view-teaser .news-link .news-link-content h2 {
          font-size: 1.8rem;
          line-height: 2rem; }
        @media screen and (min-width: 985px) {
          .front .view-news .view-teaser .news-link .news-link-content h2,
          .news-teasers-pane .view-teaser .news-link .news-link-content h2 {
            font-size: 1.8rem;
            line-height: 2rem; } }
        .front .view-news .view-teaser .news-link .news-link-content h2 a,
        .news-teasers-pane .view-teaser .news-link .news-link-content h2 a {
          color: #fff; }
      .front .view-news .view-teaser .news-link .news-link-content .icon-link,
      .news-teasers-pane .view-teaser .news-link .news-link-content .icon-link {
        display: inline-block;
        text-indent: -9999px;
        background: url(../images/news-linkout.png) no-repeat;
        width: 27px;
        height: 28px;
        margin-top: 5px; }

.news-hero-tiles .views-row-1 {
  width: 100%;
  float: left; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .news-hero-tiles .views-row-1 .views-field-field-featured-image {
      height: 240px;
      overflow: hidden; } }
  .lt-ie9 .news-hero-tiles .views-row-1 .views-field-field-featured-image {
    padding-right: 30px; }
  @media screen and (min-width: 767px) {
    .news-hero-tiles .views-row-1 .views-field-field-featured-image {
      padding-right: 30px; } }
  .lt-ie9 .news-hero-tiles .views-row-1 .views-field-field-featured-image {
    height: 400px;
    overflow: hidden; }
  @media screen and (min-width: 990px) {
    .news-hero-tiles .views-row-1 .views-field-field-featured-image {
      height: 400px;
      overflow: hidden; } }
  .lt-ie9 .news-hero-tiles .views-row-1 .views-field-field-featured-image {
    height: 430px;
    overflow: hidden; }
  @media screen and (min-width: 1200px) {
    .news-hero-tiles .views-row-1 .views-field-field-featured-image {
      height: 430px;
      overflow: hidden; } }
  .news-hero-tiles .views-row-1 img {
    width: 100%; }
  .lt-ie9 .news-hero-tiles .views-row-1 {
    width: 70%; }
  @media screen and (min-width: 767px) {
    .news-hero-tiles .views-row-1 {
      width: 70%; } }

.news-hero-tiles .views-row-2,
.news-hero-tiles .views-row-3 {
  width: 100%;
  float: left; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .news-hero-tiles .views-row-2,
    .news-hero-tiles .views-row-3 {
      display: none; } }
  .lt-ie9 .news-hero-tiles .views-row-2 .views-field-field-featured-image, .lt-ie9
  .news-hero-tiles .views-row-3 .views-field-field-featured-image {
    height: 165px;
    overflow: hidden; }
  @media screen and (min-width: 990px) {
    .news-hero-tiles .views-row-2 .views-field-field-featured-image,
    .news-hero-tiles .views-row-3 .views-field-field-featured-image {
      height: 165px;
      overflow: hidden; } }
  .lt-ie9 .news-hero-tiles .views-row-2 .views-field-field-featured-image, .lt-ie9
  .news-hero-tiles .views-row-3 .views-field-field-featured-image {
    height: 180px;
    overflow: hidden; }
  @media screen and (min-width: 1200px) {
    .news-hero-tiles .views-row-2 .views-field-field-featured-image,
    .news-hero-tiles .views-row-3 .views-field-field-featured-image {
      height: 180px;
      overflow: hidden; } }
  .lt-ie9 .news-hero-tiles .views-row-2, .lt-ie9
  .news-hero-tiles .views-row-3 {
    width: 30%; }
  @media screen and (min-width: 767px) {
    .news-hero-tiles .views-row-2,
    .news-hero-tiles .views-row-3 {
      width: 30%; } }

.news-hero-tiles .views-row .views-field-title {
  margin: 10px 0; }
  .lt-ie9 .news-hero-tiles .views-row .views-field-title {
    min-height: 50px; }
  @media screen and (min-width: 600px) {
    .news-hero-tiles .views-row .views-field-title {
      min-height: 50px; } }
  .news-hero-tiles .views-row .views-field-title a {
    font-size: 12px;
    line-height: 16px;
    color: #b31b1b;
    font-family: "1898Sans-Bold", sans-serif;
    border-bottom: none; }
    .lt-ie9 .news-hero-tiles .views-row .views-field-title a {
      font-size: 16px;
      line-height: 22px; }
    @media screen and (min-width: 1200px) {
      .news-hero-tiles .views-row .views-field-title a {
        font-size: 16px;
        line-height: 22px; } }
    .news-hero-tiles .views-row .views-field-title a:hover {
      text-decoration: underline; }
      .news-hero-tiles .views-row .views-field-title a:hover:after {
        text-decoration: none; }
    .news-hero-tiles .views-row .views-field-title a:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 80%;
      padding-left: 5px;
      color: #e7751d; }

.page-news #main h1 {
  margin-top: 20px;
  font-size: 40px;
  line-height: 48px;
  font-weight: normal; }

.news-teasers-pane .view-teaser {
  border-bottom: none; }

.view-news .teaser-text {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 15px; }

.pager li > a,
.pager li > span {
  border-radius: 20px; }

/* Practice Profiles */
.node-type-practice h4 a, .node-type-practice .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title a, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .node-type-practice .teaser-title a {
  border-bottom: 1px dotted #b31b1b !important; }
  .node-type-practice h4 a:after, .node-type-practice .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title a:after, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .node-type-practice .teaser-title a:after {
    content: none; }

.node-type-practice .simple-news-listing {
  margin-bottom: 30px; }

.node-type-practice .bx-wrapper .bx-controls-direction a {
  width: 30px;
  height: 40px;
  margin-top: -25px; }

.node-type-practice .bx-wrapper .bx-controls-direction .bx-prev {
  left: -30px;
  background: url(../images/control-arrows.png) no-repeat 6px 4px;
  background-size: 40px;
  border-bottom: none; }

.node-type-practice .bx-wrapper .bx-controls-direction .bx-next {
  right: -20px;
  background: url(../images/control-arrows.png) no-repeat -16px 4px;
  background-size: 40px;
  border-bottom: none; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .node-type-practice .bx-wrapper .bx-controls-direction .bx-next {
      right: -30px; } }

.node-type-practice #main {
  width: 100%;
  padding: 0;
  max-width: inherit; }
  .node-type-practice #main h1 {
    padding-top: 0; }

.node-type-practice .content h1 {
  color: #fff;
  border-bottom: none !important;
  margin-top: 5px; }

.node-type-practice .pane-node-body h3 {
  margin-bottom: 0;
  font-size: 1.5em; }

.node-type-practice h3.pane-title {
  margin-bottom: 0; }

.node-type-practice .pane-page-title {
  float: left;
  width: 100%;
  position: relative; }
  .node-type-practice .pane-page-title .page-title-services {
    position: relative;
    background: rgba(0, 0, 0, 0.75);
    padding: 1em; }
    .node-type-practice .pane-page-title .page-title-services .social-icons {
      position: absolute;
      left: -40px;
      width: 45px;
      top: 0;
      display: none;
      font-size: 1rem;
      color: #fff;
      text-align: center; }
      .lt-ie9 .node-type-practice .pane-page-title .page-title-services .social-icons {
        display: block; }
      @media screen and (min-width: 767px) {
        .node-type-practice .pane-page-title .page-title-services .social-icons {
          display: block; } }
      .node-type-practice .pane-page-title .page-title-services .social-icons a {
        display: block;
        margin-bottom: 5px;
        width: 40px;
        height: 32px;
        background: url(../images/WCM-org_mobile_share_icons_dark.png) no-repeat top center;
        background-size: 32px;
        border-bottom: none; }
        .node-type-practice .pane-page-title .page-title-services .social-icons a:hover {
          border-bottom: none; }
        .node-type-practice .pane-page-title .page-title-services .social-icons a.social-tw {
          background-position: center -33px; }
        .node-type-practice .pane-page-title .page-title-services .social-icons a.social-ln {
          background-position: center -66px; }
        .node-type-practice .pane-page-title .page-title-services .social-icons a.social-email {
          background-position: center -99px; }
        .node-type-practice .pane-page-title .page-title-services .social-icons a:after {
          content: ""; }
        .node-type-practice .pane-page-title .page-title-services .social-icons a img {
          display: none; }
  .node-type-practice .pane-page-title .clinical-services {
    color: #fff;
    font-size: 1.6rem; }
    .node-type-practice .pane-page-title .clinical-services a {
      font-weight: 700;
      color: #fff; }

.node-type-practice .has-background .page-title-services .social-icons {
  left: -50px;
  top: -4px; }
  .node-type-practice .has-background .page-title-services .social-icons a {
    background-image: url(../images/WCM-org_mobile_share_icons.png); }

.lt-ie9 .node-type-practice .has-background {
  height: 520px; }

@media screen and (min-width: 600px) {
  .node-type-practice .has-background {
    height: 520px; } }

.node-type-practice .bx-wrapper .bx-controls-direction .bx-prev,
.node-type-practice .bx-wrapper .bx-controls-direction .bx-next {
  background-image: url("../images/control-arrows-orange.png"); }

.node-type-practice .no-background {
  height: initial; }
  .node-type-practice .no-background .page-title-services {
    background: none;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 0 0 15px 0; }
    .node-type-practice .no-background .page-title-services h1 {
      color: #b31b1b; }
    .node-type-practice .no-background .page-title-services .clinical-services {
      color: #555; }
      .node-type-practice .no-background .page-title-services .clinical-services strong {
        color: #b31b1b; }
    .node-type-practice .no-background .page-title-services .social-icons {
      left: -50px;
      top: -17px; }
  .node-type-practice .no-background .clinical-services a {
    color: #b31b1b; }

.node-type-practice #media-slider {
  list-style: none; }
  .node-type-practice #media-slider li {
    float: left;
    max-height: 100px;
    overflow: hidden; }
    .node-type-practice #media-slider li a > img {
      border-radius: 0;
      width: 170px; }

.node-type-practice .pane-node-body {
  clear: both; }
  .node-type-practice .pane-node-body img {
    max-width: 100%;
    height: auto; }

.node-type-practice #insurances,
.node-type-practice #honors-and-awards,
.node-type-practice #education,
.node-type-practice #accordion {
  clear: both;
  font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, sans-serif; }
  .node-type-practice #insurances .accordion-panel,
  .node-type-practice #honors-and-awards .accordion-panel,
  .node-type-practice #education .accordion-panel,
  .node-type-practice #accordion .accordion-panel {
    display: none;
    overflow: auto; }
    .node-type-practice #insurances .accordion-panel .education ul li,
    .node-type-practice #honors-and-awards .accordion-panel .education ul li,
    .node-type-practice #education .accordion-panel .education ul li,
    .node-type-practice #accordion .accordion-panel .education ul li {
      list-style-position: inside; }

.node-type-practice .information-blocks {
  position: relative; }
  .node-type-practice .information-blocks .information-block {
    text-align: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
    border: 1px solid #ddd; }
    .lt-ie9 .node-type-practice .information-blocks .information-block {
      width: 50%;
      float: left; }
      .lt-ie9 .node-type-practice .information-blocks .information-block:nth-child(even) {
        margin-right: 0; }
    @media screen and (min-width: 600px) {
      .node-type-practice .information-blocks .information-block {
        width: 50%;
        float: left; }
        .node-type-practice .information-blocks .information-block:nth-child(even) {
          margin-right: 0; } }
    .node-type-practice .information-blocks .information-block img {
      width: 20%;
      float: left; }
      .lt-ie9 .node-type-practice .information-blocks .information-block img {
        width: auto;
        float: none; }
      @media screen and (min-width: 600px) {
        .node-type-practice .information-blocks .information-block img {
          width: auto;
          float: none; } }
    .node-type-practice .information-blocks .information-block h2 {
      padding-top: 1.5em;
      padding-bottom: 0;
      font-size: 1.5rem;
      color: #000;
      float: left;
      padding-left: 1em; }
      .lt-ie9 .node-type-practice .information-blocks .information-block h2 {
        float: none;
        padding-top: 0.5em;
        padding-left: 0;
        line-height: inherit; }
      @media screen and (min-width: 600px) {
        .node-type-practice .information-blocks .information-block h2 {
          float: none;
          padding-top: 0.5em;
          padding-left: 0;
          line-height: inherit; } }
    .node-type-practice .information-blocks .information-block a {
      padding: 1em;
      display: block;
      outline: none;
      color: black; }
      .lt-ie9 .node-type-practice .information-blocks .information-block a {
        padding: 2em; }
      @media screen and (min-width: 600px) {
        .node-type-practice .information-blocks .information-block a {
          padding: 2em; } }
      .node-type-practice .information-blocks .information-block a:hover {
        text-decoration: none; }
      .node-type-practice .information-blocks .information-block a:focus {
        outline: 5px auto -webkit-focus-ring-color; }

.node-type-practice .block-header {
  background-color: #ddd;
  text-align: center;
  padding: 1.5em 1em;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 1.7rem;
  clear: both; }
  .node-type-practice .block-header .btn-close {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 16px;
    cursor: pointer; }
    .node-type-practice .block-header .btn-close:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      height: 6px;
      width: 100%;
      margin: auto 0;
      left: 0;
      background-color: #cf4520; }
    .node-type-practice .block-header .btn-close:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 6px;
      height: 100%;
      margin: 0 auto;
      background-color: #cf4520; }

.node-type-practice .pane-wcmc-practice-information {
  position: relative;
  margin: 2em 0;
  clear: both;
  overflow: hidden; }

.node-type-practice .practice-media-outer-wrapper {
  margin-bottom: 30px; }
  .lt-ie9 .node-type-practice .practice-media-outer-wrapper .video-outer-wrapper {
    padding: 0 30px; }
  @media screen and (min-width: 990px) {
    .node-type-practice .practice-media-outer-wrapper .video-outer-wrapper {
      padding: 0 30px; } }
  .node-type-practice .practice-media-outer-wrapper .video-outer-wrapper p {
    margin-top: -45px; }
  .node-type-practice .practice-media-outer-wrapper .video-wrapper {
    margin: 30px 0; }
  .node-type-practice .practice-media-outer-wrapper .photo-wrapper {
    margin: 20px 0;
    max-width: 100%;
    padding: 0; }
    .lt-ie9 .node-type-practice .practice-media-outer-wrapper .photo-wrapper {
      padding: 0 30px; }
    @media screen and (min-width: 990px) {
      .node-type-practice .practice-media-outer-wrapper .photo-wrapper {
        padding: 0 30px; } }

.node-type-practice .practice-content-block {
  background-color: #f7f7f7;
  display: block;
  width: 100%;
  position: relative; }
  .node-type-practice .practice-content-block .practice-information-content {
    padding: 10px 15px 15px;
    font-size: 13px;
    line-height: 19.5px;
    font-size: 1.3rem;
    line-height: 1.95rem; }
    .node-type-practice .practice-content-block .practice-information-content p {
      font-size: inherit; }
    .node-type-practice .practice-content-block .practice-information-content .faq_question {
      font-weight: 700; }

.node-type-practice #our-physicians:after {
  content: "";
  display: block; }

.node-type-practice #our-physicians fieldset {
  width: 100%; }

.node-type-practice #our-physicians .filters .select2 {
  width: 80% !important;
  margin: 0 auto;
  display: block; }
  .node-type-practice #our-physicians .filters .select2:last-child {
    margin-top: 20px; }

.node-type-practice #our-physicians .physician_filter {
  clear: both;
  width: 80%;
  margin: 10px auto; }

.node-type-practice .accordion-expand-holder {
  clear: both;
  float: right;
  margin-bottom: 5px; }
  .node-type-practice .accordion-expand-holder a {
    font-weight: bold;
    color: #000;
    cursor: pointer;
    float: none;
    font-size: inherit;
    opacity: inherit;
    text-shadow: inherit;
    border-bottom: none; }
    .node-type-practice .accordion-expand-holder a:hover {
      border-bottom: none; }

.node-type-practice .ui-widget-content,
.node-type-practice .accordion-panel {
  border: none;
  background: none;
  margin: 1em 1.5em;
  clear: both;
  position: relative;
  width: 93%; }

.node-type-practice .pane-node-field-biographical-info {
  margin-top: 2em; }

.node-type-practice .pane-node-field-educational-background {
  padding-top: 0; }

.node-type-practice #main-content-header {
  padding: 0.5em 0;
  background-color: #f7f7f7;
  position: relative;
  width: 100%;
  z-index: 5; }
  .node-type-practice #main-content-header.sticky {
    position: fixed;
    top: 0;
    padding-bottom: 1em; }
  .node-type-practice #main-content-header .content {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 10px; }
    .node-type-practice #main-content-header .content .pane-wcmc-display-name {
      float: left; }
      .node-type-practice #main-content-header .content .pane-wcmc-display-name h1 {
        font-family: "1898Sans-Bold", sans-serif;
        padding-top: 5px;
        padding-bottom: 0; }
    .node-type-practice #main-content-header .content .pane-node-field-primary-specialty .field .field-label {
      font-weight: normal; }
    .node-type-practice #main-content-header .content .pane-node-field-primary-specialty .field .field-item {
      font-weight: bold; }
    .lt-ie9 .node-type-practice #main-content-header .content .pane-node-field-primary-specialty {
      float: right; }
    @media screen and (min-width: 767px) {
      .node-type-practice #main-content-header .content .pane-node-field-primary-specialty {
        float: right; } }

.node-type-practice #main-content {
  margin: 0 auto;
  padding: 0; }
  .node-type-practice #main-content .main-content-bg {
    background-size: cover;
    max-height: 200px;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute; }
    .lt-ie9 .node-type-practice #main-content .main-content-bg {
      max-height: 500px; }
    @media screen and (min-width: 600px) {
      .node-type-practice #main-content .main-content-bg {
        max-height: 500px; } }
  .node-type-practice #main-content .content-container {
    max-width: 970px;
    margin: 0 auto;
    padding: 1em 10px 0;
    position: relative; }
    .lt-ie9 .node-type-practice #main-content .content-container {
      max-width: 1180px; }
    @media screen and (min-width: 1200px) {
      .node-type-practice #main-content .content-container {
        max-width: 1180px; } }
  .node-type-practice #main-content .content {
    position: relative; }
    .lt-ie9 .node-type-practice #main-content .content {
      width: 100%;
      max-width: 585px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
    @media screen and (min-width: 767px) {
      .node-type-practice #main-content .content {
        width: 100%;
        max-width: 585px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); } }
    .lt-ie9 .node-type-practice #main-content .content {
      max-width: 780px; }
    @media screen and (min-width: 1200px) {
      .node-type-practice #main-content .content {
        max-width: 780px; } }
  .node-type-practice #main-content .provider-locations-container {
    position: relative;
    clear: both;
    padding-top: 20px; }
    .lt-ie9 .node-type-practice #main-content .provider-locations-container {
      padding-top: 0; }
    @media screen and (min-width: 767px) {
      .node-type-practice #main-content .provider-locations-container {
        padding-top: 0; } }
  .lt-ie9 .node-type-practice #main-content .provider-locations {
    position: absolute;
    width: 58%;
    right: -62%; }
  @media screen and (min-width: 767px) {
    .node-type-practice #main-content .provider-locations {
      position: absolute;
      width: 58%;
      right: -62%; } }
  .lt-ie9 .node-type-practice #main-content .provider-locations {
    right: -380px;
    width: 340px; }
  @media screen and (min-width: 1200px) {
    .node-type-practice #main-content .provider-locations {
      right: -380px;
      width: 340px; } }
  .node-type-practice #main-content .provider-locations .jump-to-top {
    clear: both; }
    .node-type-practice #main-content .provider-locations .jump-to-top a {
      height: 70px; }
  .node-type-practice #main-content #physicians-home .providers-image {
    text-align: center;
    margin-bottom: 10px; }
    .lt-ie9 .node-type-practice #main-content #physicians-home .providers-image {
      float: left;
      margin: 2px 20px 10px 0; }
    @media screen and (min-width: 600px) {
      .node-type-practice #main-content #physicians-home .providers-image {
        float: left;
        margin: 2px 20px 10px 0; } }
    .node-type-practice #main-content #physicians-home .providers-image .social-icons a {
      display: block;
      margin-bottom: 5px; }
  .node-type-practice #main-content .provider-languages,
  .node-type-practice #main-content .provider-gender,
  .node-type-practice #main-content .provider-accepting-new-patients {
    font-weight: 700;
    margin-bottom: 0.5em; }
    .lt-ie9 .node-type-practice #main-content .provider-languages, .lt-ie9
    .node-type-practice #main-content .provider-gender, .lt-ie9
    .node-type-practice #main-content .provider-accepting-new-patients {
      float: left;
      width: 50%; }
    @media screen and (min-width: 600px) {
      .node-type-practice #main-content .provider-languages,
      .node-type-practice #main-content .provider-gender,
      .node-type-practice #main-content .provider-accepting-new-patients {
        float: left;
        width: 50%; } }
    .node-type-practice #main-content .provider-languages span,
    .node-type-practice #main-content .provider-gender span,
    .node-type-practice #main-content .provider-accepting-new-patients span {
      font-weight: 400; }
  .node-type-practice #main-content .anchor-btns {
    float: left;
    margin-bottom: 3em; }
    .lt-ie9 .node-type-practice #main-content .anchor-btns {
      width: 42%;
      margin-bottom: 0; }
    @media screen and (min-width: 600px) {
      .node-type-practice #main-content .anchor-btns {
        width: 42%;
        margin-bottom: 0; } }
  .node-type-practice #main-content .provider-anchor-btn {
    border: 1px solid #fff;
    border-bottom: none;
    padding: 0.7em 1em;
    float: left;
    width: 100%;
    text-align: left;
    font-size: 1.7rem;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    box-sizing: border-box; }
    .node-type-practice #main-content .provider-anchor-btn:last-child {
      border-bottom: 1px solid #fff; }

.node-type-practice .has-background .pane-wcmc-practice-locations {
  padding-top: 200px; }
  .lt-ie9 .node-type-practice .has-background .pane-wcmc-practice-locations {
    padding-top: 507px; }
  @media screen and (min-width: 600px) {
    .node-type-practice .has-background .pane-wcmc-practice-locations {
      padding-top: 507px; } }

.header-style-b {
  background: #f7f7f7;
  margin-bottom: 30px; }
  .lt-ie9 .header-style-b .row {
    display: flex; }
  @media screen and (min-width: 767px) {
    .header-style-b .row {
      display: flex; } }
  .header-style-b .hero-background {
    background-size: cover;
    background-position: center;
    min-height: 140px; }
    .lt-ie9 .header-style-b .hero-background {
      min-height: 280px; }
    @media screen and (min-width: 990px) {
      .header-style-b .hero-background {
        min-height: 280px; } }
  .header-style-b .hero-info-wrapper {
    padding: 30px 20px;
    text-align: center; }
    .lt-ie9 .header-style-b .hero-info-wrapper {
      float: right;
      padding: 60px 0;
      margin-right: 30px;
      width: 440px;
      text-align: left; }
    @media screen and (min-width: 990px) {
      .header-style-b .hero-info-wrapper {
        float: right;
        padding: 60px 0;
        margin-right: 30px;
        width: 440px;
        text-align: left; } }
    .lt-ie9 .header-style-b .hero-info-wrapper {
      width: 540px; }
    @media screen and (min-width: 1200px) {
      .header-style-b .hero-info-wrapper {
        width: 540px; } }
    .header-style-b .hero-info-wrapper h1 {
      font-size: 20px;
      line-height: 26px;
      color: #333;
      margin: 0 0 10px 0;
      padding: 0; }
      .lt-ie9 .header-style-b .hero-info-wrapper h1 {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 20px 0; }
      @media screen and (min-width: 990px) {
        .header-style-b .hero-info-wrapper h1 {
          font-size: 28px;
          line-height: 36px;
          margin: 0 0 20px 0; } }
    .header-style-b .hero-info-wrapper .blurb-text {
      font-size: 15px;
      line-height: 25px;
      margin: 30px 0; }
    .header-style-b .hero-info-wrapper .btn {
      margin-top: 10px; }
      .lt-ie9 .header-style-b .hero-info-wrapper .btn {
        margin-top: 20px; }
      @media screen and (min-width: 990px) {
        .header-style-b .hero-info-wrapper .btn {
          margin-top: 20px; } }

.lt-ie9 .panel-home #main-content-container {
  max-width: 1180px; }

@media screen and (min-width: 1200px) {
  .panel-home #main-content-container {
    max-width: 1180px; } }

.lt-ie9 .panel-home #main-content {
  max-width: 1180px; }

@media screen and (min-width: 1200px) {
  .panel-home #main-content {
    max-width: 1180px; } }

.panel-home #sidebar-left {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  margin-bottom: 2em; }
  .lt-ie9 .panel-home #sidebar-left {
    width: 50%;
    padding-right: 3%;
    margin-bottom: 0; }
  @media screen and (min-width: 600px) {
    .panel-home #sidebar-left {
      width: 50%;
      padding-right: 3%;
      margin-bottom: 0; } }
  .panel-home #sidebar-left h3.pane-title {
    color: #666;
    font-weight: 400;
    font-size: 1.6rem; }

.panel-home #sidebar-right {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
  .lt-ie9 .panel-home #sidebar-right {
    width: 50%; }
  @media screen and (min-width: 600px) {
    .panel-home #sidebar-right {
      width: 50%; } }
  .panel-home #sidebar-right h3.pane-title {
    color: #666;
    font-weight: 400;
    font-size: 1.6rem; }

.lt-ie9 .pane-carousel-panel-pane-1, .lt-ie9
.pane-carousel-panel-pane-2 {
  width: 940px;
  margin: 0 auto 30px; }

@media screen and (min-width: 990px) {
  .pane-carousel-panel-pane-1,
  .pane-carousel-panel-pane-2 {
    width: 940px;
    margin: 0 auto 30px; } }

.lt-ie9 .pane-carousel-panel-pane-1, .lt-ie9
.pane-carousel-panel-pane-2 {
  width: 1160px; }

@media screen and (min-width: 1200px) {
  .pane-carousel-panel-pane-1,
  .pane-carousel-panel-pane-2 {
    width: 1160px; } }

.view-carousel {
  margin-bottom: 0em; }
  .view-carousel .thumbnail-controller {
    border-top: none;
    padding: 10px 0 9px; }
    .view-carousel .thumbnail-controller .pager {
      margin: 20px 0;
      border-bottom: none; }
      .view-carousel .thumbnail-controller .pager:hover {
        border-bottom: none; }
  .view-carousel .thumbnail-controller-wrap {
    line-height: 1rem; }
  .view-carousel .slide-text h2 {
    line-height: 2.3rem;
    padding-top: 15px; }
    .view-carousel .slide-text h2 a:after {
      content: "";
      display: none; }
  .view-carousel .slide-text p {
    color: #fff;
    padding-top: 14px; }
    .lt-ie9 .view-carousel .slide-text p {
      width: 65%; }
    @media screen and (min-width: 990px) {
      .view-carousel .slide-text p {
        width: 65%; } }
    .view-carousel .slide-text p a {
      color: #fff;
      text-decoration: underline; }
  .view-carousel #thumbnail-pager a > img {
    height: initial; }
  .view-carousel .mobile-pager a {
    line-height: 1.5; }

/******************************/
.contact-accordion-pane + .panel-separator {
  margin: 0; }

.contact-accordion-pane ul {
  list-style: none; }

.contact-accordion-pane h3 {
  position: relative;
  color: #cf4520;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 25px 55px 15px 10px;
  font-size: 16px; }
  .contact-accordion-pane h3:after {
    content: "+";
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #ddd; }
  .contact-accordion-pane h3.ui-state-active:after {
    content: " -"; }

.contact-accordion-pane .pane-content,
.contact-accordion-pane .fieldable-panels-pane {
  display: none;
  position: relative;
  clear: both;
  width: 100%;
  padding: 0 15px 0 10px; }
  .contact-accordion-pane .pane-content:before, .contact-accordion-pane .pane-content:after,
  .contact-accordion-pane .fieldable-panels-pane:before,
  .contact-accordion-pane .fieldable-panels-pane:after {
    content: " ";
    display: table; }
  .contact-accordion-pane .pane-content:after,
  .contact-accordion-pane .fieldable-panels-pane:after {
    clear: both; }
  .contact-accordion-pane .pane-content a,
  .contact-accordion-pane .fieldable-panels-pane a {
    color: #000; }
    .contact-accordion-pane .pane-content a:after,
    .contact-accordion-pane .fieldable-panels-pane a:after {
      color: #cf4520;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-size: 15px;
      display: inline-block;
      text-decoration: inherit;
      margin-right: 0.2em;
      padding-left: 10px;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    .contact-accordion-pane .pane-content a:hover:after, .contact-accordion-pane .pane-content a:active:after,
    .contact-accordion-pane .fieldable-panels-pane a:hover:after,
    .contact-accordion-pane .fieldable-panels-pane a:active:after {
      text-decoration: none; }
    .contact-accordion-pane .pane-content a:hover,
    .contact-accordion-pane .fieldable-panels-pane a:hover {
      border-bottom: none; }

/******************************/
.page-news .panel-2col-right #main-content,
.node-type-news-post .panel-2col-right #main-content {
  float: none;
  display: block; }
  .lt-ie9 .page-news .panel-2col-right #main-content, .lt-ie9
  .node-type-news-post .panel-2col-right #main-content {
    float: left;
    display: inline; }
  @media screen and (min-width: 600px) {
    .page-news .panel-2col-right #main-content,
    .node-type-news-post .panel-2col-right #main-content {
      float: left;
      display: inline; } }

.page-news .panel-2col-right #sidebar-second,
.node-type-news-post .panel-2col-right #sidebar-second {
  float: none; }
  .lt-ie9 .page-news .panel-2col-right #sidebar-second, .lt-ie9
  .node-type-news-post .panel-2col-right #sidebar-second {
    float: left; }
  @media screen and (min-width: 600px) {
    .page-news .panel-2col-right #sidebar-second,
    .node-type-news-post .panel-2col-right #sidebar-second {
      float: left; } }

.pane-views-panes h3 a:after {
  color: #e7751d;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-size: 15px;
  display: inline-block;
  text-decoration: inherit;
  margin-right: 0.2em;
  padding-left: 10px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: ""; }

.pane-views-panes h3 a:hover:after, .pane-views-panes h3 a:active:after {
  text-decoration: none; }

.pane-views-panes h3 a:after {
  padding-left: 7px;
  line-height: 24px; }

.node-type-news-post .pane-node-field-news-clinical-service h3.pane-title,
.node-type-news-post .pane-node-field-news-practice h3.pane-title,
.node-type-news-post .pane-node-field-news-provider h3.pane-title {
  color: #000;
  font-size: 16px; }

.node-type-news-post .pane-node-field-news-clinical-service a,
.node-type-news-post .pane-node-field-news-practice a,
.node-type-news-post .pane-node-field-news-provider a {
  display: block;
  width: fit-content;
  text-decoration: underline;
  border-bottom: none;
  line-height: 20px;
  margin-bottom: 5px; }
  .node-type-news-post .pane-node-field-news-clinical-service a:before,
  .node-type-news-post .pane-node-field-news-practice a:before,
  .node-type-news-post .pane-node-field-news-provider a:before {
    content: "\2022 ";
    padding-right: 5px;
    display: inline-block;
    width: 10px;
    margin-left: -10px;
    font-weight: bold;
    color: #000; }
  .node-type-news-post .pane-node-field-news-clinical-service a:hover:before, .node-type-news-post .pane-node-field-news-clinical-service a:active:before,
  .node-type-news-post .pane-node-field-news-practice a:hover:before,
  .node-type-news-post .pane-node-field-news-practice a:active:before,
  .node-type-news-post .pane-node-field-news-provider a:hover:before,
  .node-type-news-post .pane-node-field-news-provider a:active:before {
    text-decoration: none; }

.node-type-news-post .pane-node-field-news-clinical-service .field-news-clinical-service,
.node-type-news-post .pane-node-field-news-clinical-service .field-news-practice,
.node-type-news-post .pane-node-field-news-clinical-service .field-news-provider,
.node-type-news-post .pane-node-field-news-practice .field-news-clinical-service,
.node-type-news-post .pane-node-field-news-practice .field-news-practice,
.node-type-news-post .pane-node-field-news-practice .field-news-provider,
.node-type-news-post .pane-node-field-news-provider .field-news-clinical-service,
.node-type-news-post .pane-node-field-news-provider .field-news-practice,
.node-type-news-post .pane-node-field-news-provider .field-news-provider {
  padding-left: 10px;
  margin-left: 10px; }

.node-type-news-post .pane-node-field-news-category {
  display: none; }

.node-type-news-post .pane-node-field-news-category .field-name-field-news-category h3 {
  float: none; }

.node-type-news-post .news-sidebar-header h3 {
  padding-bottom: 0; }

.node-type-news-post .post-date {
  color: #666666;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12.5px;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px; }

.node-type-news-post .btn-view-all-news {
  margin: 15px auto;
  width: 100%; }

.node-type-news-post .pane-node-field-related-news-links {
  margin: 40px 0;
  padding: 10px 20px 20px;
  border: 1px solid #e7751d; }
  .node-type-news-post .pane-node-field-related-news-links .field-related-news-links {
    padding-left: 20px; }
  .node-type-news-post .pane-node-field-related-news-links h3.pane-title {
    text-transform: uppercase;
    color: #666;
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 13px;
    letter-spacing: 2px;
    padding-bottom: 10px; }
  .node-type-news-post .pane-node-field-related-news-links a {
    display: block;
    line-height: 1.6;
    clear: both;
    width: fit-content;
    font-size: 15px;
    margin-bottom: 3px; }
    .node-type-news-post .pane-node-field-related-news-links a:hover {
      text-decoration: none;
      border-bottom: 1px solid; }
    .node-type-news-post .pane-node-field-related-news-links a:before {
      content: "\2022";
      color: black;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em; }

.node-type-news-post .media-vimeo-player {
  width: 100%; }

.pane-news-panel-pane-1 .view-teaser h3,
.full-news-listing .view-teaser h3 {
  margin-top: 0px;
  padding-left: 0px; }

.pane-news-panel-pane-1 .view-teaser .teaser-text,
.pane-news-panel-pane-1 .view-teaser .post-date,
.full-news-listing .view-teaser .teaser-text,
.full-news-listing .view-teaser .post-date {
  padding-left: 0px; }

.pane-news-panel-pane-1 .view-teaser .panopoly-image-quarter,
.full-news-listing .view-teaser .panopoly-image-quarter {
  margin-right: 20px !important;
  float: right !important; }

.btn--news {
  width: 100%; }

.node-type-clinical-service h1.title {
  margin-top: 0;
  font-size: 22px; }
  .lt-ie9 .node-type-clinical-service h1.title {
    font-size: 40px; }
  @media screen and (min-width: 767px) {
    .node-type-clinical-service h1.title {
      font-size: 40px; } }

.node-type-clinical-service .panel-2col-right #main-content {
  float: none;
  display: block; }
  .lt-ie9 .node-type-clinical-service .panel-2col-right #main-content {
    float: left;
    display: inline; }
  @media screen and (min-width: 600px) {
    .node-type-clinical-service .panel-2col-right #main-content {
      float: left;
      display: inline; } }

.node-type-clinical-service .panel-2col-right #sidebar-second {
  float: none; }
  .lt-ie9 .node-type-clinical-service .panel-2col-right #sidebar-second {
    float: left; }
  @media screen and (min-width: 600px) {
    .node-type-clinical-service .panel-2col-right #sidebar-second {
      float: left; } }

.node-type-clinical-service .morecontent span {
  display: none; }

.node-type-clinical-service .pane-node-field-service-description ul,
.node-type-clinical-service .practice-list ul,
.node-type-clinical-service .pane-node-field-clinical-services-media ul {
  padding: 0 0 0 17px; }

.node-type-clinical-service .clinical-service-contact-info {
  border-top: 1px solid #dddddd; }
  .node-type-clinical-service .clinical-service-contact-info:before, .node-type-clinical-service .clinical-service-contact-info:after {
    content: " ";
    display: table; }
  .node-type-clinical-service .clinical-service-contact-info:after {
    clear: both; }
  .node-type-clinical-service .clinical-service-contact-info .contact-icon {
    text-align: center;
    margin-top: 15px;
    padding: 0 15px;
    font-weight: 600; }
    .lt-ie9 .node-type-clinical-service .clinical-service-contact-info .contact-icon {
      display: inline-block;
      border-right: 1px solid #dddddd; }
    @media screen and (min-width: 600px) {
      .node-type-clinical-service .clinical-service-contact-info .contact-icon {
        display: inline-block;
        border-right: 1px solid #dddddd; } }
    .node-type-clinical-service .clinical-service-contact-info .contact-icon a {
      border-bottom: none; }
      .node-type-clinical-service .clinical-service-contact-info .contact-icon a:hover {
        text-decoration: underline; }
    .node-type-clinical-service .clinical-service-contact-info .contact-icon .cta-icon {
      display: block;
      width: 35px;
      height: 35px;
      margin: 0 auto; }
    .node-type-clinical-service .clinical-service-contact-info .contact-icon:last-child {
      border-right: none; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social:after {
    font-size: 35px !important; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-facebook:after {
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    padding-left: 10px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-facebook:hover:after, .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-facebook:active:after {
    text-decoration: none; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-twitter:after {
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    padding-left: 10px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-twitter:hover:after, .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-twitter:active:after {
    text-decoration: none; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-linkedin:after {
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    padding-left: 10px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-linkedin:hover:after, .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-linkedin:active:after {
    text-decoration: none; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-youtube:after {
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    padding-left: 10px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-youtube:hover:after, .node-type-clinical-service .clinical-service-contact-info .icon-social.icon-youtube:active:after {
    text-decoration: none; }
  .node-type-clinical-service .clinical-service-contact-info .icon-phone {
    background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
    background-repeat: no-repeat;
    background-size: 1600%;
    background-position: -60px 5px;
    width: 64px;
    height: 64px;
    border: none;
    margin: 0 auto; }

.node-type-clinical-service .pane-node-field-clinical-services-media .pane-title {
  margin-top: 30px; }

.node-type-clinical-service .pane-node-field-clinical-services-media p {
  margin-top: -15px; }

.node-type-clinical-service .pane-wcmc-clinical-service-events .pane-title {
  padding-bottom: 0;
  margin-top: 30px; }

.node-type-clinical-service .pane-wcmc-clinical-service-events .view-header {
  padding-bottom: 10px; }

.node-type-clinical-service .clinical-services-sidebar {
  display: none;
  margin-bottom: 2em; }
  .lt-ie9 .node-type-clinical-service .clinical-services-sidebar {
    display: block; }
  @media screen and (min-width: 600px) {
    .node-type-clinical-service .clinical-services-sidebar {
      display: block; } }
  .node-type-clinical-service .clinical-services-sidebar a {
    display: none;
    border-bottom: 1px solid #eee;
    padding: 10px 5px;
    background: #fff url(../images/wcm-org_jump_arrow.png) no-repeat 95% 50%;
    background-size: 15px; }
    .node-type-clinical-service .clinical-services-sidebar a:hover {
      text-decoration: underline; }

.node-type-clinical-service h3.pane-title {
  font-size: 1.5em; }

.node-type-clinical-service #map-canvas {
  width: 100%;
  margin-bottom: 1em;
  height: 300px; }

.node-type-clinical-service .practice-list-container {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  margin: 30px 0;
  padding-top: 15px;
  border-top: 1px solid #ddd; }
  .node-type-clinical-service .practice-list-container .practice-list {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc); }
    .node-type-clinical-service .practice-list-container .practice-list:before, .node-type-clinical-service .practice-list-container .practice-list:after {
      content: " ";
      display: table; }
    .node-type-clinical-service .practice-list-container .practice-list:after {
      clear: both; }
    .node-type-clinical-service .practice-list-container .practice-list .practice-links {
      list-style: none;
      padding-left: 0; }
      .node-type-clinical-service .practice-list-container .practice-list .practice-links li a {
        padding: 6px 20px 6px 5px;
        font-size: 14px;
        line-height: 26px;
        color: #666;
        display: block;
        position: relative;
        border-bottom: 1px solid #ddd; }
        .node-type-clinical-service .practice-list-container .practice-list .practice-links li a:after {
          color: #e7751d;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          font-size: 15px;
          display: inline-block;
          text-decoration: inherit;
          margin-right: 0.2em;
          padding-left: 10px;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: ""; }
        .node-type-clinical-service .practice-list-container .practice-list .practice-links li a:hover:after, .node-type-clinical-service .practice-list-container .practice-list .practice-links li a:active:after {
          text-decoration: none; }
        .lt-ie9 .node-type-clinical-service .practice-list-container .practice-list .practice-links li a {
          font-size: 18px; }
        @media screen and (min-width: 767px) {
          .node-type-clinical-service .practice-list-container .practice-list .practice-links li a {
            font-size: 18px; } }
        .node-type-clinical-service .practice-list-container .practice-list .practice-links li a:after {
          position: absolute;
          top: 12px;
          right: 10px; }
        .node-type-clinical-service .practice-list-container .practice-list .practice-links li a:hover {
          color: #b31b1b;
          background: #fbfbfb; }
          .node-type-clinical-service .practice-list-container .practice-list .practice-links li a:hover:after {
            color: #b31b1b; }
    .node-type-clinical-service .practice-list-container .practice-list .expand-btn {
      width: 50px;
      height: 33px;
      display: block;
      position: absolute;
      top: 24px;
      right: 0;
      font-size: 35px;
      line-height: 30px;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      z-index: 2;
      color: #b31b1b;
      border-left: 1px solid #dddddd; }
      .node-type-clinical-service .practice-list-container .practice-list .expand-btn:after {
        content: "+"; }
      .node-type-clinical-service .practice-list-container .practice-list .expand-btn.state-active:after {
        content: " -"; }
    .node-type-clinical-service .practice-list-container .practice-list .info-icons {
      float: right;
      line-height: 1;
      position: relative;
      top: -15px; }
      .node-type-clinical-service .practice-list-container .practice-list .info-icons a:hover {
        text-decoration: none; }
    .node-type-clinical-service .practice-list-container .practice-list .icon-fap {
      background: url(../images/fap-icon.png) no-repeat center 5px;
      width: 27px;
      height: 27px; }
    .node-type-clinical-service .practice-list-container .practice-list .ss-info {
      float: none;
      font-size: 4rem;
      line-height: 5rem;
      color: #cf4520;
      margin-right: 5px; }
      .node-type-clinical-service .practice-list-container .practice-list .ss-info:hover {
        background: none;
        color: #cf4520;
        text-decoration: none; }
    .node-type-clinical-service .practice-list-container .practice-list span {
      clear: both;
      float: right;
      color: #000000;
      width: auto;
      background-color: transparent; }

.node-type-clinical-service .provider-locations-container {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 2em; }
  .node-type-clinical-service .provider-locations-container:before, .node-type-clinical-service .provider-locations-container:after {
    content: " ";
    display: table; }
  .node-type-clinical-service .provider-locations-container:after {
    clear: both; }
  .node-type-clinical-service .provider-locations-container .map-larger-view {
    position: absolute;
    top: 20px;
    left: 8px;
    background: #fff;
    z-index: 2; }
    .node-type-clinical-service .provider-locations-container .map-larger-view a {
      padding: 0.3em 1em;
      display: block;
      border-bottom: none; }
  .node-type-clinical-service .provider-locations-container .view-more-locations {
    clear: both;
    text-align: center;
    margin-top: 1em; }
  .node-type-clinical-service .provider-locations-container .provider-locations-wrapper {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .node-type-clinical-service .provider-locations-container .address-location {
    padding: 10px 10px 0 0;
    margin: 0;
    clear: none;
    padding-bottom: 10px;
    padding-top: 10px; }
    .node-type-clinical-service .provider-locations-container .address-location.address-location-hidden {
      display: none; }
    .node-type-clinical-service .provider-locations-container .address-location:before, .node-type-clinical-service .provider-locations-container .address-location:after {
      content: " ";
      display: table; }
    .node-type-clinical-service .provider-locations-container .address-location:after {
      clear: both; }
    .lt-ie9 .node-type-clinical-service .provider-locations-container .address-location {
      width: 49%;
      float: left; }
      .lt-ie9 .node-type-clinical-service .provider-locations-container .address-location:nth-child(odd) {
        clear: left; }
      .lt-ie9 .node-type-clinical-service .provider-locations-container .address-location:nth-child(even) {
        border-left: 1px solid #dddddd;
        padding-left: 10px; }
    @media screen and (min-width: 600px) {
      .node-type-clinical-service .provider-locations-container .address-location {
        width: 49%;
        float: left; }
        .node-type-clinical-service .provider-locations-container .address-location:nth-child(odd) {
          clear: left; }
        .node-type-clinical-service .provider-locations-container .address-location:nth-child(even) {
          border-left: 1px solid #dddddd;
          padding-left: 10px; } }
    .node-type-clinical-service .provider-locations-container .address-location .practice-count {
      float: left;
      width: 10%;
      padding: 2px 0;
      font-size: 2.5rem;
      line-height: 3rem;
      color: #e7751d;
      margin-top: 0; }
    .node-type-clinical-service .provider-locations-container .address-location .practice-info {
      float: left;
      width: 90%; }
    .node-type-clinical-service .provider-locations-container .address-location .practice-address {
      width: 95%; }
    .node-type-clinical-service .provider-locations-container .address-location .practice-name h4, .node-type-clinical-service .provider-locations-container .address-location .practice-name .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .node-type-clinical-service .provider-locations-container .address-location .practice-name .teaser-title {
      margin-top: 0;
      padding: 0 0 3px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px; }
    .node-type-clinical-service .provider-locations-container .address-location .practice-name a {
      color: #000; }
      .node-type-clinical-service .provider-locations-container .address-location .practice-name a:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 70%;
        padding-left: 5px;
        line-height: 20px; }
      .node-type-clinical-service .provider-locations-container .address-location .practice-name a:hover {
        text-decoration: underline; }
      .node-type-clinical-service .provider-locations-container .address-location .practice-name a:active {
        color: #666; }

.node-type-clinical-service .localist-event,
.node-type-practice .localist-event,
.pane-wcmc-in-your-neighborhood-events .localist-event {
  border-bottom: none;
  padding-bottom: 0; }
  .node-type-clinical-service .localist-event .date,
  .node-type-practice .localist-event .date,
  .pane-wcmc-in-your-neighborhood-events .localist-event .date {
    background: none;
    width: 75px;
    height: auto;
    vertical-align: top;
    margin-bottom: 0;
    padding-bottom: 0; }
    .node-type-clinical-service .localist-event .date:after,
    .node-type-practice .localist-event .date:after,
    .pane-wcmc-in-your-neighborhood-events .localist-event .date:after {
      content: "";
      border: none; }
    .node-type-clinical-service .localist-event .date .month,
    .node-type-practice .localist-event .date .month,
    .pane-wcmc-in-your-neighborhood-events .localist-event .date .month {
      display: inline-block;
      font-weight: 700;
      text-transform: none;
      font-size: 1.7rem;
      margin-bottom: 0; }
    .node-type-clinical-service .localist-event .date .day,
    .node-type-practice .localist-event .date .day,
    .pane-wcmc-in-your-neighborhood-events .localist-event .date .day {
      display: inline-block;
      font-weight: 700;
      font-size: 1.7rem;
      margin-right: 10px;
      margin-bottom: 0; }
    .node-type-clinical-service .localist-event .date .year,
    .node-type-practice .localist-event .date .year,
    .pane-wcmc-in-your-neighborhood-events .localist-event .date .year {
      display: inline-block;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1rem; }
  .node-type-clinical-service .localist-event .details-wrapper,
  .node-type-practice .localist-event .details-wrapper,
  .pane-wcmc-in-your-neighborhood-events .localist-event .details-wrapper {
    padding: 0; }
  .node-type-clinical-service .localist-event .event-title,
  .node-type-practice .localist-event .event-title,
  .pane-wcmc-in-your-neighborhood-events .localist-event .event-title {
    padding: 5px 0 0 0;
    font-size: 14px;
    line-height: 2rem;
    vertical-align: top; }

.pane-wcmc-practice-events .view-header {
  padding-bottom: 10px; }

.simple-news-listing {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px; }
  .simple-news-listing .pane-title {
    padding-bottom: 0;
    margin-top: 30px; }
  .simple-news-listing .view-teaser {
    border-bottom: none;
    padding-bottom: 7px;
    margin-bottom: 7px; }
  .simple-news-listing .teaser-title {
    font-size: 1.4rem; }
    .simple-news-listing .teaser-title a {
      color: inherit;
      border-bottom: none; }
      .simple-news-listing .teaser-title a:after {
        color: #e7751d;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        font-size: 15px;
        display: inline-block;
        text-decoration: inherit;
        margin-right: 0.2em;
        padding-left: 10px;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: ""; }
      .simple-news-listing .teaser-title a:hover:after, .simple-news-listing .teaser-title a:active:after {
        text-decoration: none; }
      .simple-news-listing .teaser-title a:hover {
        text-decoration: underline; }
      .simple-news-listing .teaser-title a:after {
        font-size: 12px;
        padding-left: 6px; }
  .simple-news-listing .views-field-created {
    color: #666; }
  .simple-news-listing .view-header {
    padding-bottom: 10px; }

@keyframes pulse {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.ctnr {
  display: flex;
  background-color: #f6a45d; }

.ldr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: 2.5em;
  height: 2.5em; }

.ldr-blk {
  height: 35%;
  width: 35%;
  animation: pulse 0.75s ease-in infinite alternate;
  background-color: #f27b15; }

.an_delay {
  animation-delay: 0.75s; }

.fancybox-nav {
  top: 25%;
  height: 50%;
  border-bottom: none; }
  .fancybox-nav:hover {
    border-bottom: none; }

#fancybox-buttons a {
  border-bottom: none; }
  #fancybox-buttons a:hover {
    border-bottom: none; }

#main .anchor-menu {
  font-size: 12px;
  line-height: 18px;
  padding: 10px 0 10px 20px; }
  .lt-ie9 #main .anchor-menu {
    line-height: 20px;
    font-size: 14px; }
  @media screen and (min-width: 600px) {
    #main .anchor-menu {
      line-height: 20px;
      font-size: 14px; } }
  #main .anchor-menu li {
    padding-bottom: 7px; }
    .lt-ie9 #main .anchor-menu li {
      padding-bottom: 10px; }
    @media screen and (min-width: 600px) {
      #main .anchor-menu li {
        padding-bottom: 10px; } }

.second-opinion-home {
  text-align: left;
  background: url(../../../../../sites/default/files/pages/wcm-on-demand-second-opinion.jpg) no-repeat 30% 40%;
  background-size: cover;
  background-color: #ffc72c;
  background-blend-mode: multiply;
  color: #fff;
  position: relative;
  height: 320px; }
  .second-opinion-home h3 {
    color: #fff;
    font-size: 2.8rem; }
  .second-opinion-home p {
    line-height: 2rem; }
    .second-opinion-home p:after {
      content: " \e802";
      font-size: 14px;
      color: #ffc72c;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none;
      display: inline-block;
      padding-left: 5px; }
  .lt-ie9 .second-opinion-home {
    border-left: 1px solid #fff;
    width: 35%;
    float: left;
    height: 289.5px; }
  @media screen and (min-width: 990px) {
    .second-opinion-home {
      border-left: 1px solid #fff;
      width: 35%;
      float: left;
      height: 289.5px; } }
  .second-opinion-home .second-opinion-link:hover {
    text-decoration: none; }
  .second-opinion-home .second-opinion-text {
    background: rgba(178, 21, 5, 0.8);
    padding: 5px 20px 0px 25px;
    position: absolute;
    bottom: 10px;
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

.header-component {
  background-image: url(../images/breast-cancer-concierge.jpg);
  min-height: 500px;
  height: auto;
  width: 100%;
  position: relative;
  margin: auto;
  background-size: cover;
  background-position: center; }
  .header-component .header-panel {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px 0 20px;
    overflow: hidden; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .header-component .header-panel {
        padding-bottom: 50px; } }
    .header-component .header-panel h1,
    .header-component .header-panel p {
      color: white;
      margin: 0;
      padding: 0;
      text-align: center !important;
      line-height: 1;
      isolation: isolate;
      position: relative;
      z-index: 100;
      line-height: 1.5;
      margin: 10px 100px 20px 100px; }
      .lt-ie9 .header-component .header-panel h1, .lt-ie9
      .header-component .header-panel p {
        width: 1180px;
        margin: auto;
        padding: 10px 100px 10px 100px; }
      @media screen and (min-width: 990px) {
        .header-component .header-panel h1,
        .header-component .header-panel p {
          width: 1180px;
          margin: auto;
          padding: 10px 100px 10px 100px; } }
      @media screen and (min-width: 767px) and (max-width: 989px) {
        .header-component .header-panel h1,
        .header-component .header-panel p {
          margin: 20px !important; } }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .header-component .header-panel h1,
        .header-component .header-panel p {
          padding: 10px 20px 10px 20px;
          margin: 0; } }
    .header-component .header-panel p {
      font-size: 16px;
      padding-bottom: 50px; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .header-component .header-panel p {
          padding-bottom: 80px; } }
    .header-component .header-panel:before {
      background-color: #cf4520;
      mix-blend-mode: multiply;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 10;
      margin-left: calc(-50vw + 50%); }
  .header-component .header-info {
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f7f7f7;
    z-index: 100;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }
    .lt-ie9 .header-component .header-info {
      width: 1180px; }
    @media screen and (min-width: 1200px) {
      .header-component .header-info {
        width: 1180px; } }
    @media screen and (min-width: 990px) and (max-width: 1199px) {
      .header-component .header-info {
        width: 980px; } }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .header-component .header-info {
        width: 80%;
        flex-direction: column;
        align-items: center;
        width: auto;
        border: 1px solid #dddddd; } }
    .header-component .header-info:after {
      border-bottom: 1px solid #dddddd;
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 80%; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .header-component .header-info:after {
          content: none; } }
    .header-component .header-info .phone {
      width: 200px;
      text-align: center;
      padding: 10px;
      font-size: 18px; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .header-component .header-info .phone {
          font-size: 16px;
          padding-top: 20px; } }
    .header-component .header-info .schedule {
      width: 200px; }
      @media screen and (min-width: 0) and (max-width: 599px) {
        .header-component .header-info .schedule {
          width: auto; } }
      .header-component .header-info .schedule .btn-primary {
        border-radius: 5px;
        background-color: #b31b1b;
        border-color: #b31b1b; }

.site-main {
  margin: 5px 0 0; }

.wcm-global-links {
  font-size: 15px;
  line-height: 25px; }

.wcm-global-links li:nth-child(1) {
  background: #fff; }
  .wcm-global-links li:nth-child(1) a {
    color: #cf4520;
    border: none; }
    .wcm-global-links li:nth-child(1) a:focus {
      color: #fff; }

footer {
  padding: 0; }

.footer-nav {
  width: auto;
  float: none;
  padding: 0;
  margin: 0;
  text-align: left; }
  .lt-ie9 .footer-nav {
    width: 80%;
    float: left;
    padding-left: 20px;
    margin: 0 0 20px; }
  @media screen and (min-width: 990px) {
    .footer-nav {
      width: 80%;
      float: left;
      padding-left: 20px;
      margin: 0 0 20px; } }
  .footer-nav a.external-link:after {
    content: ""; }

.lt-ie9 .footer-nav .level-1 {
  width: 25%;
  float: left;
  margin: 0;
  padding: 0 10px 5px;
  border: none; }

@media screen and (min-width: 990px) {
  .footer-nav .level-1 {
    width: 25%;
    float: left;
    margin: 0;
    padding: 0 10px 5px;
    border: none; } }

.footer-nav .level-1 ul {
  display: block; }

.footer-menu__toggle:after {
  width: 20px;
  height: 20px;
  content: "+";
  color: #cf4520;
  font-weight: 700;
  float: right;
  font-size: 18px;
  line-height: 18px; }
  .lt-ie9 .footer-menu__toggle:after {
    display: none; }
  @media screen and (min-width: 990px) {
    .footer-menu__toggle:after {
      display: none; } }

.footer-menu__section--open .footer-menu__toggle:after {
  content: "–"; }

.footer-nav .level-1:last-child {
  border: none; }

.footer-nav .footer-menu__section {
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase; }

.footer-nav .level-2 {
  padding: 10px 0 5px; }

.footer-nav .level-3 {
  padding: 0 0 5px; }

.footer-site-info a:hover,
.footer-nav a:hover,
.footer-links a:hover {
  color: #cf4520; }

.campaign-container {
  margin-bottom: 40px; }
  .campaign-container .campaign-title {
    text-align: center;
    font-size: 2.6rem;
    line-height: 2rem; }
    .lt-ie9 .campaign-container .campaign-title {
      font-size: 3rem;
      line-height: 3.6rem; }
    @media screen and (min-width: 767px) {
      .campaign-container .campaign-title {
        font-size: 3rem;
        line-height: 3.6rem; } }
  .campaign-container .teaser-text {
    font-size: 18px;
    width: 90%;
    margin: 0 auto 20px;
    text-align: center; }
    .lt-ie9 .campaign-container .teaser-text {
      width: 65%; }
    @media screen and (min-width: 990px) {
      .campaign-container .teaser-text {
        width: 65%; } }
  .campaign-container .campaign-image {
    position: relative;
    overflow: hidden;
    height: 250px; }
    .lt-ie9 .campaign-container .campaign-image {
      height: 450px; }
    @media screen and (min-width: 767px) {
      .campaign-container .campaign-image {
        height: 450px; } }
    .campaign-container .campaign-image img {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .campaign-container .campaign-image:hover img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1); }
    .campaign-container .campaign-image .campaign-link {
      display: block;
      height: 100%; }
      .campaign-container .campaign-image .campaign-link:before {
        background: none;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        -webkit-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out; }
      .campaign-container .campaign-image .campaign-link img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  @media screen and (min-width: 0) and (max-width: 599px) {
    .campaign-container .campaign-box1,
    .campaign-container .campaign-box2,
    .campaign-container .campaign-box3,
    .campaign-container .campaing-box4 {
      padding: 10px; } }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .campaign-container .campaign-box1,
    .campaign-container .campaign-box2,
    .campaign-container .campaign-box3,
    .campaign-container .campaing-box4 {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 767px) and (max-width: 989px) {
    .campaign-container .campaign-box1,
    .campaign-container .campaign-box4 {
      padding-bottom: 20px; } }
  .campaign-container .campaign-box1 .campaign-image .campaign-link,
  .campaign-container .campaign-box4 .campaign-image .campaign-link {
    background-image: url(https://weillcornell.org/sites/default/files/styles/panopoly_image_original/public/obstetrics_and_gynecology.jpg?itok=T6m5D4VO);
    background-size: cover; }
    .campaign-container .campaign-box1 .campaign-image .campaign-link:before,
    .campaign-container .campaign-box4 .campaign-image .campaign-link:before {
      background: none; }
  .campaign-container .campaign-box1 .campaign-image:hover .campaign-link,
  .campaign-container .campaign-box4 .campaign-image:hover .campaign-link {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  .campaign-container .campaign-box1 .content-container,
  .campaign-container .campaign-box4 .content-container {
    position: absolute;
    bottom: 0;
    z-index: 100;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 15px;
    background: white;
    margin: 20px;
    width: initial;
    transform: none; }
  .campaign-container .campaign-box1 h2,
  .campaign-container .campaign-box1 p,
  .campaign-container .campaign-box1 a,
  .campaign-container .campaign-box4 h2,
  .campaign-container .campaign-box4 p,
  .campaign-container .campaign-box4 a {
    color: white;
    position: relative;
    z-index: 100; }
  .campaign-container .campaign-box1 a,
  .campaign-container .campaign-box4 a {
    font-size: 14px; }
  .campaign-container .campaign-box1 span,
  .campaign-container .campaign-box4 span {
    color: #333;
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 0;
    font-size: 28px;
    line-height: 32px;
    font-family: "1898Sans-Bold", sans-serif; }
    .campaign-container .campaign-box1 span:after,
    .campaign-container .campaign-box4 span:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 70%;
      padding-left: 5px;
      line-height: 24px;
      color: #e7751d; }
  .campaign-container .campaign-box4 span {
    font-size: 16px; }
    .campaign-container .campaign-box4 span:after {
      display: none; }
    .campaign-container .campaign-box4 span:nth-of-type(2) {
      font-family: "1898Sans-Regular", sans-serif; }
  .campaign-container .campaign-box2 {
    height: 450px;
    background: #f7f7f7 !important;
    text-align: center; }
    .campaign-container .campaign-box2 .youtube-wrapper {
      height: 50%; }
    .campaign-container .campaign-box2 p {
      text-align: center;
      line-height: 1rem;
      padding: 5px; }
    .campaign-container .campaign-box2 h3 {
      margin-top: 30px;
      margin-bottom: 10px; }
    .campaign-container .campaign-box2 .schedule {
      margin-top: 20px; }
    .campaign-container .campaign-box2 .media-youtube-player {
      height: 100%;
      width: 100%; }
    .campaign-container .campaign-box2 .featured-review {
      height: 40%;
      background-color: #ffc62d; }
      .campaign-container .campaign-box2 .featured-review .review-content {
        font-size: 14px;
        line-height: 28px;
        padding: 20px calc(100% / 10);
        padding-bottom: 5px; }
        .lt-ie9 .campaign-container .campaign-box2 .featured-review .review-content {
          font-size: 18px; }
        @media screen and (min-width: 990px) {
          .campaign-container .campaign-box2 .featured-review .review-content {
            font-size: 18px; } }
    .campaign-container .campaign-box2 .more-content {
      padding: 5px;
      position: relative;
      height: 40%;
      width: 100%; }
    .campaign-container .campaign-box2 .review-quote-circle {
      font-size: 12em;
      display: inline-block;
      border: 0.02em solid white;
      background-color: white;
      position: absolute;
      -webkit-border-radius: 0.35em;
      border-radius: 0.35em;
      margin-bottom: 15px;
      width: 0.3em;
      height: 0.3em;
      margin-left: 20px;
      z-index: 100; }
      .campaign-container .campaign-box2 .review-quote-circle .featured-review-open-quote {
        left: 7px;
        top: -35px; }
      .campaign-container .campaign-box2 .review-quote-circle .img-quote-icon {
        height: 30px;
        width: 30px; }
    .campaign-container .campaign-box2 .full-story {
      background: white;
      position: relative;
      height: 70%;
      bottom: 0px;
      padding: 10px;
      width: 90%;
      margin: auto;
      margin-top: 10px; }
      .campaign-container .campaign-box2 .full-story a {
        float: right; }
  .campaign-container .campaign-box3 .content-container {
    background: #cf4520;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 100;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 0 10px 10px; }
    .campaign-container .campaign-box3 .content-container h2 {
      color: #fff; }
    .campaign-container .campaign-box3 .content-container p {
      display: inline-block;
      width: 80%;
      border-right: 1px solid #dddddd;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0px; }
    .campaign-container .campaign-box3 .content-container .right-arrow {
      float: right; }
      .campaign-container .campaign-box3 .content-container .right-arrow:after {
        content: "\e802";
        width: 40px;
        height: 50px;
        color: #fff;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 20px;
        text-align: center;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 50px;
        display: inline;
        float: right;
        -webkit-transition: background-color 0.25s;
        transition: background-color 0.25s; }
        .lt-ie9 .campaign-container .campaign-box3 .content-container .right-arrow:after {
          width: 50px; }
        @media screen and (min-width: 990px) {
          .campaign-container .campaign-box3 .content-container .right-arrow:after {
            width: 50px; } }
      .campaign-container .campaign-box3 .content-container .right-arrow:hover:after {
        margin-right: -3px;
        transition: 0.3s ease-in; }

.fancybox-transcript {
  color: #b31b1b; }
  .fancybox-transcript:hover {
    color: #b31b1b; }

.metric-section {
  position: relative;
  display: block;
  padding: 20px 0 0;
  margin: 20px 0; }
  .metric-section .metric {
    text-align: center;
    margin: 0 0 20px; }
    .lt-ie9 .metric-section .metric {
      min-height: 120px;
      border-right: 1px solid #ddd; }
      .lt-ie9 .metric-section .metric:nth-child(2) {
        border: none; }
    @media screen and (min-width: 767px) {
      .metric-section .metric {
        min-height: 120px;
        border-right: 1px solid #ddd; }
        .metric-section .metric:nth-child(2) {
          border: none; } }
    .lt-ie9 .metric-section .metric {
      min-height: 130px; }
      .lt-ie9 .metric-section .metric:nth-child(2) {
        border-right: 1px solid #ddd; }
    @media screen and (min-width: 990px) {
      .metric-section .metric {
        min-height: 130px; }
        .metric-section .metric:nth-child(2) {
          border-right: 1px solid #ddd; } }
    .metric-section .metric:last-of-type {
      border: none; }
  .metric-section .metric__value {
    font-size: 48px;
    line-height: 1;
    display: inline-block;
    font-family: "1898Sans-Regular", sans-serif;
    color: #e7751d; }
    .lt-ie9 .metric-section .metric__value {
      font-size: 64px; }
    @media screen and (min-width: 1200px) {
      .metric-section .metric__value {
        font-size: 64px; } }
    @media screen and (min-width: 767px) and (max-width: 989px) {
      .metric-section .metric__value {
        font-size: 48px; } }
  .metric-section .metric__description {
    font-size: 18px;
    line-height: 25px;
    font-family: "1898Sans-Bold", sans-serif;
    color: #666;
    padding: 10px 0; }
    .lt-ie9 .metric-section .metric__description {
      padding: 10px 20px; }
    @media screen and (min-width: 990px) {
      .metric-section .metric__description {
        padding: 10px 20px; } }

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #666; }

.zoomy-boi {
  overflow: hidden; }
  .zoomy-boi img {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .zoomy-boi img:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1); }

.zoomy-boi-max {
  overflow: hidden; }
  .zoomy-boi-max img {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .zoomy-boi-max:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }

.pane-news-pane-featured-news .view-content,
.pane-news-pane-featured-news-configurable .view-content {
  display: inline-block; }

.pane-news-pane-featured-news .featured-news-wrapper .row,
.pane-news-pane-featured-news-configurable .featured-news-wrapper .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.pane-news-pane-featured-news h3.pane-title,
.pane-news-pane-featured-news-configurable h3.pane-title {
  font-family: "1898Sans-Bold", sans-serif;
  color: #666;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center; }

.pane-news-pane-featured-news hr,
.pane-news-pane-featured-news-configurable hr {
  width: 100px;
  border-top: 2px solid #ddd;
  margin: 15px auto; }

.pane-news-pane-featured-news .view-header,
.pane-news-pane-featured-news-configurable .view-header {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
  text-align: center; }

.pane-news-pane-featured-news .teaser-title,
.pane-news-pane-featured-news-configurable .teaser-title {
  margin-bottom: 20px; }
  .lt-ie9 .pane-news-pane-featured-news .teaser-title, .lt-ie9
  .pane-news-pane-featured-news-configurable .teaser-title {
    margin-top: 0; }
  @media screen and (min-width: 990px) {
    .pane-news-pane-featured-news .teaser-title,
    .pane-news-pane-featured-news-configurable .teaser-title {
      margin-top: 0; } }
  .pane-news-pane-featured-news .teaser-title a:after,
  .pane-news-pane-featured-news-configurable .teaser-title a:after {
    content: " \e802";
    text-decoration: none;
    font-family: "fontello";
    font-size: 80%;
    padding-left: 5px;
    line-height: 28px;
    color: #e7751d; }

.pane-news-pane-featured-news .feature-image img,
.pane-news-pane-featured-news-configurable .feature-image img {
  width: 100%; }

.pane-news-pane-featured-news .teaser-text,
.pane-news-pane-featured-news-configurable .teaser-text {
  font-size: 15px;
  line-height: 25px; }

.pane-news-pane-featured-news .view-teaser,
.pane-news-pane-featured-news-configurable .view-teaser {
  border: none; }

.news-component .teaser-image {
  margin-bottom: 10px; }
  .news-component .teaser-image img {
    width: 100%; }

.news-component .field-content a {
  font-size: 14px;
  line-height: 18px;
  margin: 0 !important;
  color: #cf4520;
  font-weight: bold;
  padding-top: 10px;
  padding-right: 10px; }
  .lt-ie9 .news-component .field-content a {
    font-size: 16px;
    padding-top: 15px;
    line-height: 24px; }
  @media screen and (min-width: 990px) {
    .news-component .field-content a {
      font-size: 16px;
      padding-top: 15px;
      line-height: 24px; } }
  .news-component .field-content a:hover {
    color: #b31b1b; }

.news-component .field-content p {
  text-align: center; }

.news-component .field-content .callout-image {
  overflow: hidden;
  margin: 0 0 20px; }
  .news-component .field-content .callout-image img {
    width: 100%; }

.news-component .date {
  display: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  margin: 10px 0 10px 0;
  font-weight: bold; }
  .lt-ie9 .news-component .date {
    font-size: 16px;
    margin: 15px 0 15px 0; }
  @media screen and (min-width: 990px) {
    .news-component .date {
      font-size: 16px;
      margin: 15px 0 15px 0; } }

.news-component .view-footer {
  text-align: center; }

.news-component .view-teaser {
  border: none; }

.video-visit-callout-wrapper {
  padding: 20px; }
  .video-visit-callout-wrapper .video-visit-logo {
    width: 80%;
    margin-bottom: 10px; }
  .video-visit-callout-wrapper .blurb {
    margin-bottom: 15px; }
  .video-visit-callout-wrapper .phone-wrapper {
    margin-bottom: 20px;
    font-size: 24px; }
    .video-visit-callout-wrapper .phone-wrapper a {
      color: #000;
      text-decoration: none;
      border-bottom: none; }
      .video-visit-callout-wrapper .phone-wrapper a:hover {
        text-decoration: underline; }

.video-visit-icon.in-a-circle {
  height: 40px;
  padding: 9px 10px 10px 10px;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  border-radius: 50%;
  margin-right: 10px;
  float: left; }
  .video-visit-icon.in-a-circle img {
    width: 25px;
    height: 25px; }

/* This is to accomodate for the relative positioning of the sticky banner */
.lt-ie9 #page.compact {
  padding: 30px 0 0; }

@media screen and (min-width: 990px) {
  #page.compact {
    padding: 30px 0 0; } }

@media screen and (min-width: 0) and (max-width: 599px) {
  #page.compact {
    padding: 0; } }

@media screen and (min-width: 600px) and (max-width: 769px) {
  #page.compact {
    padding: 0; } }

@media screen and (min-width: 767px) and (max-width: 989px) {
  #page.compact {
    padding: 0; } }

/* Keep the Pinned Header at the top of the page */
.navbar #pinned-header,
.navbar .sticky-banner,
.no-navbar #pinned-header,
.no-navbar .sticky-banner {
  position: relative;
  top: 0; }

/* S E A R C H   R E S U L T S
----------------------------------------------------------------------------------*/
.page-search .doctor h1 {
  font-size: 16px !important;
  line-height: inherit !important;
  padding: 10px 0 0;
  line-height: normal;
  margin-bottom: -3px; }
  .page-search .doctor h1 a {
    color: #cf4520; }
    .page-search .doctor h1 a:after {
      content: '';
      display: none; }
    .page-search .doctor h1 a:hover {
      text-decoration: underline; }

.page-search .field-name-field-profile-image,
.page-search .pane-node-field-profile-image {
  float: left;
  margin-right: 25px;
  height: 133px;
  overflow: hidden; }
  .page-search .field-name-field-profile-image a > img,
  .page-search .pane-node-field-profile-image a > img {
    border-radius: 0;
    width: 100px; }

.page-search h2.pane-title {
  font-size: 100%;
  padding: 0;
  font-weight: 400;
  color: #000;
  margin-top: 0; }

.page-search .pane-node-field-board-certifications ul,
.page-search .pane-node-field-problem-procedure ul {
  list-style-type: none; }

.page-search .pane-node-field-problem-procedure {
  padding-top: 10px; }

.geary-container {
  margin: 0; }
  .geary-container .geary-header {
    background-color: #e0e0e0; }
  .geary-container .geary-column-content {
    border-top: 1px solid #ccc;
    padding-top: 3px;
    padding-bottom: 3px; }
  .geary-container .geary-column3 {
    padding-top: 10px; }
    .geary-container .geary-column3 ul {
      line-height: 1; }
  .geary-container .geary-column2 {
    padding-top: 10px; }
  .geary-container .doctor {
    padding-top: 10px; }

.page-search .block-facetapi ul {
  background-color: #fff;
  padding-right: 20px;
  padding-top: 10px; }

.page-search .block-facetapi ul li {
  list-style-type: none;
  list-style-image: none; }

.page-search .block-facetapi h2 {
  font-size: 1em;
  padding: 0px; }

.solr-count {
  font-size: 2em;
  font-weight: normal; }

.ds-search-extra {
  border-bottom: 1px solid #ccc;
  font-size: 1.4em;
  padding-bottom: .5em;
  background-color: #e0e0e0;
  padding: 10px 10px 10px 10px;
  /* display: none; */ }

/* #filtertoggle { display: none; } */
.page-search-site input#edit-keys {
  max-width: 200px; }

.page-search-site .panelizer-view-mode.node-unpublished {
  display: none; }

.form-item-keys {
  float: left;
  margin: 0;
  width: 265px; }

.form-item-keys label {
  float: left;
  margin-right: 10px; }

.solr-term {
  font-weight: bold;
  font-style: italic;
  font-size: 1.2em;
  background: #fff;
  padding: 0.2em;
  border-radius: 0.2em; }

#filtershow,
#hidecont,
#hidecontent {
  font-weight: bold;
  cursor: pointer;
  background-color: #e0e0e0;
  padding: 15px 10px; }

.block-facetapi {
  max-width: none;
  padding-bottom: 20px; }
  .lt-ie9 .block-facetapi {
    float: left; }
  @media screen and (min-width: 600px) {
    .block-facetapi {
      float: left; } }

.specialty-facet {
  margin-right: 0px; }

.page-search .field-name-field-related-practice {
  line-height: .3em; }

.page-search .field-collection-item-field-related-practices a {
  line-height: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem; }

.page-search .field-label {
  font-weight: normal;
  float: left; }

.page-search .field-name-field-related-phone {
  font-size: 13px;
  line-height: 19.5px;
  font-size: 1.3rem;
  line-height: 1.95rem; }

.page-search .pane-node-field-related-practices {
  padding-top: 10px; }

.page-search .specialties ul {
  line-height: 1; }

td.views-field-title,
td.views-field-field-related-practices,
td.views-field-field-problem-procedure {
  padding-top: 10px; }

td.views-field-field-problem-procedure .item-list:before {
  content: "Expertise";
  font-weight: bold; }

td.views-field-title > div > .item-list:before {
  content: "Board Certified In";
  font-weight: bold; }

td.views-field-title {
  padding-left: 20px; }

#result-statement {
  /*float: left;*/
  margin-right: 5px;
  line-height: 30px; }

.node-search-result {
  border-top: 1px solid #ccc; }
  .node-search-result .panel-separator {
    margin: 0; }
  .node-search-result.row {
    margin-left: 0;
    margin-right: 0; }
  .node-search-result .container {
    padding-left: 0;
    padding-right: 0;
    width: auto; }
    .node-search-result .container .clearfix, .node-search-result .container .menu, .node-search-result .container #primary-nav, .node-search-result .container .address-location, .node-search-result .container .landing-page-footer .footer-statement, .landing-page-footer .node-search-result .container .footer-statement, .node-search-result .container .node-type-practice .information-blocks, .node-type-practice .node-search-result .container .information-blocks, .node-search-result .container .node-type-practice .information-blocks .information-block a, .node-type-practice .information-blocks .information-block .node-search-result .container a, .node-search-result .container .node-type-practice #our-physicians, .node-type-practice .node-search-result .container #our-physicians {
      clear: none; }
      .node-search-result .container .clearfix:after, .node-search-result .container .menu:after, .node-search-result .container #primary-nav:after, .node-search-result .container .address-location:after, .node-search-result .container .landing-page-footer .footer-statement:after, .landing-page-footer .node-search-result .container .footer-statement:after, .node-search-result .container .node-type-practice .information-blocks:after, .node-type-practice .node-search-result .container .information-blocks:after, .node-search-result .container .node-type-practice .information-blocks .information-block a:after, .node-type-practice .information-blocks .information-block .node-search-result .container a:after, .node-search-result .container .node-type-practice #our-physicians:after, .node-type-practice .node-search-result .container #our-physicians:after {
        clear: none; }

.node-search-result.node-profile .radix-layouts-column2, .node-search-result.node-profile .radix-layouts-column3 {
  padding-left: 15px; }

p#filterhide {
  margin-right: 10px; }

.clear {
  height: 1px;
  padding: 0;
  margin: 0;
  clear: both; }

.chosen-container {
  max-width: 100%; }

.chosen-container-single .chosen-single {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 1px 0 0 8px;
  height: 26px; }

/* Special Styles For New Search */
.clinical-more:after,
.related-more:after {
  content: " more"; }

.clinical-more:before,
.related-more:before {
  content: "↓ "; }

.clinical-more.less:before,
.related-more.less:before {
  content: "↑ "; }

.clinical-more.less:after,
.related-more.less:after {
  content: " less"; }

.clinical-more,
.related-more {
  cursor: pointer;
  color: #cf4520; }

.clinical-more:hover,
.related-more:hover {
  text-decoration: underline; }

td.views-field-field-profile-image img {
  float: left;
  margin-right: 20px; }

td.views-field-field-profile-image h1 {
  margin-top: 13px; }

h3.show:before {
  content: "+ "; }

h3.show {
  cursor: pointer;
  background: #e0e0e0;
  padding: 10px 0px 10px 17px;
  color: #006872; }

h3.show.hide:before {
  content: "- "; }

#hidecont,
#hidecontent,
.show {
  margin-bottom: 40px; }

#hidecontent {
  margin-bottom: 20px; }

.show.hide {
  margin-bottom: 0px; }

p.cert {
  font-weight: bold;
  padding: 0;
  margin-bottom: -7px; }

#specialty-search label {
  float: left;
  margin-right: 10px; }

/*.search-results ul {
    @include font-size(1.3);
}*/
.search-results li {
  margin-bottom: 0px; }

/*#edit-departments {
  height: 27px;
}*/
#finder {
  padding-bottom: 10px;
  padding-top: 3px; }
  #finder form {
    max-width: 900px;
    margin: 0 auto;
    display: inline; }
  #finder input#edit-submit {
    margin-top: .5em;
    margin-left: 15px;
    padding: 0; }
    #finder input#edit-submit:disabled {
      cursor: initial;
      background-image: linear-gradient(0deg, #868686 0%, #7E7D7D 48.12%, #929292 51.64%, #CECECE 100%);
      background-image: -o-linear-gradient(0deg, #868686 0%, #7E7D7D 48.12%, #929292 51.64%, #CECECE 100%);
      background-image: -moz-linear-gradient(0deg, #868686 0%, #7E7D7D 48.12%, #929292 51.64%, #CECECE 100%);
      color: #ccc; }
  #finder .form-item-name {
    float: left;
    margin-right: 10px;
    margin-top: .5em; }
  #finder input {
    max-width: 250px;
    border-color: #B1B1B1;
    padding: 3px; }
  #finder input#edit-name {
    max-width: 250px;
    /*border: 1px solid black;*/ }
  #finder .form-item-name label,
  #finder .form-item-departments label,
  #finder #specialty-search label {
    font-weight: normal;
    float: left;
    margin-right: 10px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: inline; }
  #finder .chosen-container-single .chosen-single {
    padding: 3px;
    height: 18px;
    font-size: 16px;
    line-height: 24px;
    font-size: 1.6rem;
    line-height: 2.4rem; }
    #finder .chosen-container-single .chosen-single span {
      margin-top: -3px; }
  #finder .form-item-name > div {
    display: none; }

#edit_departments_chosen a {
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  font-size: 1.6rem;
  line-height: 2.4rem; }

.form-item-departments {
  margin-top: .5em;
  float: left; }

#edit_departments_chosen {
  float: left; }

#block-wcmc-custom-search-physician-search:before, #block-wcmc-custom-search-physician-search:after {
  content: " ";
  display: table; }

#block-wcmc-custom-search-physician-search:after {
  clear: both; }

.inner {
  max-width: 967px;
  margin: 0 auto; }

#finder #-wcmc-name-search-search #edit-submit,
#finder #-wcmc-name-search-search #edit-submit--2 {
  /*display: none;*/ }

.page-search #block-system-main {
  padding-bottom: 30px; }

input#specialtyinput {
  max-width: 250px; }

#specialty-search {
  padding-top: 7px; }

.finder-inner {
  max-width: 800px;
  margin: 0 auto; }
  .lt-ie9 .finder-inner {
    max-height: 36px; }
  @media screen and (min-width: 985px) {
    .finder-inner {
      max-height: 36px; } }

#autocomplete ul li {
  background-color: #d0ebe6;
  padding-left: 5px; }

.node-search-result .col-md-4:first-child {
  width: 100%; }
  .lt-ie9 .node-search-result .col-md-4:first-child {
    width: 33.33333333333333%; }
  @media screen and (min-width: 600px) {
    .node-search-result .col-md-4:first-child {
      width: 33.33333333333333%; } }

.node-search-result .radix-layouts-column2, .node-search-result .radix-layouts-column3 {
  width: 100%;
  padding-left: 0px; }
  .lt-ie9 .node-search-result .radix-layouts-column2, .lt-ie9 .node-search-result .radix-layouts-column3 {
    width: 33.33333333333333%;
    padding-left: 15px; }
  @media screen and (min-width: 600px) {
    .node-search-result .radix-layouts-column2, .node-search-result .radix-layouts-column3 {
      width: 33.33333333333333%;
      padding-left: 15px; } }

.node-search-result .col-md-4 {
  float: left; }

#hidecont {
  cursor: default;
  position: relative;
  z-index: 1; }

.page-search .block-facetapi ul {
  margin-bottom: 0; }

h3.show {
  cursor: default;
  color: inherit;
  font-weight: 400; }

h3.show:before {
  content: ""; }

#filtertoggle {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
  line-height: 21px;
  font-size: 1.4rem;
  line-height: 2.1rem; }

.headless-search-extra {
  border-bottom: 1px solid #ccc;
  font-size: 1.4em;
  padding-bottom: .5em;
  padding: 10px; }

.headless-search-extra, .ds-search-extra, #filtertoggle, #hidecont, #hidecontent {
  background: #f5f5f5; }

#hidecontent {
  font-weight: bold;
  cursor: default;
  padding: 15px 10px; }

#filtershow,
#hidecont,
#hidecontent {
  font-weight: normal; }

.solr-term {
  background: none;
  font-style: normal;
  color: #992800;
  font-size: 1.2em; }

.solr-term-extra {
  background: none;
  font-style: normal;
  color: #992800;
  font-size: 1.2em;
  font-weight: bold; }

.page-search .block-facetapi ul {
  padding-right: 0; }

.page-search .block-facetapi ul li {
  padding: 0 0 0.25em 0em;
  margin: 0;
  color: #544842; }

.page-search .block-facetapi ul li:hover {
  background-color: #0e5d86;
  color: #fff; }

.page-search .block-facetapi ul li:hover a {
  color: #fff;
  text-decoration: none; }

.page-search .block-facetapi ul li a {
  color: #544842;
  padding-left: 1.5em;
  display: block; }

.specialty-dropdown,
.expertise-dropdown,
.insurance-dropdown,
.language-dropdown,
.gender-dropdown,
.neighborhood-dropdown,
.primaryspecialty-dropdown {
  cursor: pointer;
  border-radius: 3px;
  -webkit-border-radius: 3px; }

.block-facetapi .content {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  position: absolute;
  max-height: 200px;
  margin-bottom: 0;
  border: 1px solid #ccc;
  width: 100%;
  z-index: 1;
  overflow: auto; }

.language-facet .content, .gender-facet .content {
  position: absolute;
  max-height: 200px;
  margin-bottom: 0;
  border: 1px solid #ccc;
  width: 100%;
  overflow: auto; }

.facet-dropdown {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  background: #fff url(../images/double-arrow.png) no-repeat 98% 4px;
  border: 1px solid #ccc;
  width: 100%;
  /*@include breakpoint($bp1) {
        width:300px;
    }*/
  padding: 5px; }

.facet-dropdown-small {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  background: #fff url(../images/double-arrow.png) no-repeat 98% 4px;
  border: 1px solid #ccc;
  width: 100%;
  /*@include breakpoint($bp1) {
        width:130px;
    }*/
  padding: 5px; }

.primaryspecialty-facet,
.expertise-facet,
.insurance-facet,
.neighborhood-facet {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  position: relative;
  width: 100%; }
  .lt-ie9 .primaryspecialty-facet, .lt-ie9
  .expertise-facet, .lt-ie9
  .insurance-facet, .lt-ie9
  .neighborhood-facet {
    width: 32%;
    margin-right: 2em; }
  @media screen and (min-width: 600px) {
    .primaryspecialty-facet,
    .expertise-facet,
    .insurance-facet,
    .neighborhood-facet {
      width: 32%;
      margin-right: 2em; } }

.gender-facet,
.language-facet {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  *behavior: url(/sites/all/themes/weillcornell/css/boxsizing.htc);
  position: relative;
  width: 100%; }
  .lt-ie9 .gender-facet, .lt-ie9
  .language-facet {
    width: 16%;
    margin-right: 2em; }
  @media screen and (min-width: 600px) {
    .gender-facet,
    .language-facet {
      width: 16%;
      margin-right: 2em; } }

.clear_filters {
  float: right;
  text-decoration: underline;
  color: #a9a9a9; }

.clear_filters.active {
  cursor: pointer;
  color: #0e5d86; }

#result-statement-headless {
  margin-right: 5px;
  line-height: 30px; }

.provider-badge {
  margin-top: 1rem;
  min-height: 2.5em; }
  .provider-badge .provider-badge-icon {
    height: 20px;
    width: 20px;
    margin: 0 .5rem .5rem 0;
    display: block;
    float: left; }
    .provider-badge .provider-badge-icon.provider-badge-icon-voluntary {
      background: url(../images/profile_badges_28x28.png) 0 0;
      background-size: cover; }
    .provider-badge .provider-badge-icon.provider-badge-icon-fulltime {
      background: url(../images/profile_badges_28x28.png) 0 41px;
      background-size: cover; }
    .provider-badge .provider-badge-icon.provider-badge-icon-wcpn {
      background: url(../images/profile_badges_28x28.png) 0 20px;
      background-size: cover; }
  .provider-badge > a {
    white-space: nowrap; }

/* Patient Review Styles
----------------------------------------------------------------------------------*/
/* Review List Styles
----------------------------------------------------------------------------------*/
.reviews {
  position: relative;
  margin: 0 auto;
  border-bottom: 1px solid #ddd; }
  .reviews h3 {
    margin-bottom: 15px; }

.main-content > div:first-child .reviews h3 {
  display: none; }

.review {
  margin-bottom: 15px;
  overflow: auto; }
  .review:last-child .review-details {
    border-bottom: none; }

.review-circle {
  display: table;
  float: left;
  border-radius: 50%;
  width: 75px;
  height: 75px; }

.pane-wcmc-patient-review-pane .review-circle .cta-icon {
  width: 30px;
  height: 30px;
  margin-left: 23px;
  margin-top: 23px;
  color: #fff;
  fill: #fff; }

.review-circle-letter {
  display: table-cell;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #FFFFFF;
  font-size: xx-large; }

.circle-color1 {
  background-color: #bd2b23; }

.circle-color2 {
  background-color: #ffca3e; }

.circle-color3 {
  background-color: #D75229; }

.review-details {
  float: left;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px; }

@media screen and (min-width: 600px) {
  .review-details {
    width: 80%;
    margin-left: 20px; } }

@media screen and (max-width: 599px) {
  .review-circle {
    margin-bottom: 10px; }
  .review-details {
    width: 100%; }
  .reviews {
    padding-right: 15px;
    padding-left: 15px; }
  .review {
    margin-bottom: 20px; } }

.review-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px; }

.review-target {
  font-size: 14px;
  margin-bottom: 15px;
  font-style: italic; }

.review-comment p {
  margin-bottom: 5px;
  line-height: 22px; }

.gray {
  color: #666; }

.pane-wcmc-patient-review-pane {
  width: 100%; }

.node-type-clinical-service .pane-wcmc-patient-review-pane {
  border-top: 1px solid #ddd; }

.review-disclaimer {
  color: #555;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 15px; }

.last-review .review-details {
  border-bottom: none; }

/* Featured Review Styles
----------------------------------------------------------------------------------*/
.featured-review-background-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  margin-top: 20px; }
  .featured-review-background-image ul {
    margin-bottom: 0; }
  .lt-ie9 .featured-review-background-image {
    margin-top: 30px;
    padding: 60px calc(100%/10); }
  @media screen and (min-width: 600px) {
    .featured-review-background-image {
      margin-top: 30px;
      padding: 60px calc(100%/10); } }

.not-front .featured-review-background-image {
  margin: 15px 0 30px;
  padding: 50px; }
  .lt-ie9 .not-front .featured-review-background-image {
    padding: 70px 90px; }
  @media screen and (min-width: 600px) {
    .not-front .featured-review-background-image {
      padding: 70px 90px; } }

.featured-review-style1 {
  display: table;
  margin: auto; }

.view-featured-reviews {
  text-align: center;
  position: relative; }

.featured-review-style1 .view-content {
  margin: 15px; }

.view-featured-reviews li {
  list-style: none; }

.views-field-field-review-body {
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px; }
  .lt-ie9 .views-field-field-review-body {
    font-size: 16px;
    line-height: 24px; }
  @media screen and (min-width: 600px) {
    .views-field-field-review-body {
      font-size: 16px;
      line-height: 24px; } }
  .lt-ie9 .views-field-field-review-body {
    font-size: 18px;
    line-height: 28px; }
  @media screen and (min-width: 985px) {
    .views-field-field-review-body {
      font-size: 18px;
      line-height: 28px; } }

.views-field-field-review-body div.field-content {
  margin-bottom: 0;
  padding-bottom: 0; }

.featured-review-style1:before {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 35px);
  right: -10px;
  top: -10px;
  position: absolute;
  border-top: 4px solid #e7751d;
  border-right: 4px solid #e7751d; }

.featured-review-style1:after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 35px);
  left: -10px;
  bottom: -10px;
  position: absolute;
  border-bottom: 4px solid #e7751d;
  border-left: 4px solid #e7751d; }

.views-field-field-reviewer > div.field-content {
  color: #cf4520; }
  .lt-ie9 .views-field-field-reviewer > div.field-content {
    font-size: 20px; }
  @media screen and (min-width: 985px) {
    .views-field-field-reviewer > div.field-content {
      font-size: 20px; } }

.views-field-field-review-source {
  color: #666; }

.views-field-field-review-source > div.field-content {
  display: inline; }

.img-quote-icon {
  height: 60px;
  width: 60px; }

.featured-review-close-quote .img-quote-icon {
  transform: rotate(180deg); }

.featured-review-style1 .featured-review-open-quote {
  left: -41px;
  top: -32px; }

.featured-review-open-quote {
  position: absolute; }

.featured-review-close-quote {
  left: calc(100% - 19px);
  bottom: -35px;
  position: absolute; }

.view-featured-reviews div.item-list ul li.views-row {
  margin: 0; }

/* Featured Review Styles - Style 2
----------------------------------------------------------------------------------*/
.featured-review-style2 {
  overflow: hidden;
  display: table;
  align-items: center;
  margin: auto; }

.featured-review-background-image {
  display: inline-block;
  overflow: auto; }

.featured-review-quote-circle {
  font-size: 22em;
  display: inline-block;
  border: 0.02em solid #e7751d;
  position: relative;
  border-radius: 0.35em;
  margin-bottom: 15px;
  width: 0.4em;
  height: 0.4em; }

a.featured-review-link {
  outline: 0;
  text-decoration: none;
  border-bottom: none; }
  a.featured-review-link:hover {
    border-bottom: none; }

a.featured-review-link:hover .featured-review-quote-circle, a.featured-review-link:active .featured-review-quote-circle, a.featured-review-link:focus .featured-review-quote-circle {
  border-color: #cf4520; }

a.featured-review-link:hover .featured-review-col2-text, a.featured-review-link:active .featured-review-col2-text, a.featured-review-link:focus .featured-review-col2-text {
  color: #cf4520; }

/*These styles add a hover state to the quote icons*/
.featured-review-style2 .featured-review-open-quote {
  left: 25px;
  top: -32px; }

.featured-review-col2-text {
  color: #cf4520;
  font-weight: bold; }

.featured-review-style2 .view-content {
  display: table-cell;
  vertical-align: middle; }

.featured-review-col2 {
  position: relative;
  display: inline-block;
  width: 150px; }

@media screen and (min-width: 833px) {
  .featured-review-style2 .view-content {
    padding-right: 50px; }
  .featured-review-col2 {
    padding-left: 50px;
    border-left: 1px solid #a2a2a2;
    margin-left: 20px;
    display: table-cell;
    vertical-align: middle; } }

@media screen and (max-width: 832px) {
  .featured-review-style2 .view-content {
    margin: 0;
    display: block;
    width: 100%; }
  .featured-review-col2 {
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid #555;
    margin-top: 20px; } }

/* Scheduling Styles
----------------------------------------------------------------------------------*/
/* Open Scheduling Widget Styles
----------------------------------------------------------------------------------*/
fieldset#edit-open-scheduling-widget-settings {
  width: 100%; }

iframe#openSchedulingFrame {
  width: 100%;
  display: block;
  height: 850px;
  border: none; }

@media screen and (min-width: 600px) {
  .os-main-panel {
    width: 100%;
    float: left; }
    .lt-ie9 .os-main-panel {
      width: 60%; } }
  @media screen and (min-width: 600px) and (min-width: 990px) {
    .os-main-panel {
      width: 60%; } }

@media screen and (min-width: 600px) {
  .os-side-panel {
    width: 100%;
    float: left;
    padding: 5px 10px 10px; }
    .lt-ie9 .os-side-panel {
      width: 40%; } }
  @media screen and (min-width: 600px) and (min-width: 990px) {
    .os-side-panel {
      width: 40%; } }

@media screen and (max-width: 599px) {
  .os-main-panel {
    width: 100%;
    float: left; }
  .os-side-panel {
    width: 100%;
    float: left;
    padding: 0; } }

.os-main-panel .schedule-location-wrapper {
  margin-top: 20px; }

.wcmc_onecol .container {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 990px) and (max-width: 1199px) {
    .wcmc_onecol .container {
      width: initial; } }

.provider-details {
  margin-bottom: 30px; }
  .provider-details .container {
    padding-left: 20px;
    padding-right: 20px; }
  .provider-details hr {
    margin-top: 15px;
    margin-bottom: 15px; }
  .provider-details .provider-image {
    display: block;
    margin: 0 auto 20px;
    max-width: 100%; }
    @media screen and (min-width: 0) and (max-width: 599px) {
      .provider-details .provider-image {
        height: 150px; } }
    .lt-ie9 .provider-details .provider-image {
      display: inline;
      margin: 0; }
    @media screen and (min-width: 767px) {
      .provider-details .provider-image {
        display: inline;
        margin: 0; } }
  .provider-details .provider-title {
    font-family: "1898Sans-Bold", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #b31b1b;
    margin-bottom: 5px;
    text-align: center; }
    .lt-ie9 .provider-details .provider-title {
      text-align: left; }
    @media screen and (min-width: 767px) {
      .provider-details .provider-title {
        text-align: left; } }
  .provider-details .primary-specialty {
    font-size: 14px;
    color: #363636;
    font-weight: bold;
    text-align: center; }
    .lt-ie9 .provider-details .primary-specialty {
      text-align: left; }
    @media screen and (min-width: 767px) {
      .provider-details .primary-specialty {
        text-align: left; } }
  .provider-details .view-profile-btn {
    display: block;
    margin: 0 auto;
    width: 120px; }
    .lt-ie9 .provider-details .view-profile-btn {
      margin: 0; }
    @media screen and (min-width: 767px) {
      .provider-details .view-profile-btn {
        margin: 0; } }
  .provider-details .appointment-details {
    margin-top: 5px; }
    .provider-details .appointment-details .detail-item {
      font-size: 14px;
      color: #363636;
      font-weight: bold; }
      .provider-details .appointment-details .detail-item span {
        font-weight: normal;
        color: #777;
        width: 180px;
        display: inline-block; }

.os-widget h2 {
  margin-bottom: 15px; }

.os-side-panel h4, .os-side-panel .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title, .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .os-side-panel .teaser-title {
  margin-top: 0; }

.lt-ie9 .os-side-panel div {
  padding-right: 20px; }

@media screen and (min-width: 1200px) {
  .os-side-panel div {
    padding-right: 20px; } }

/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
.node-type-profile .header-section {
  background-color: #f7f7f7;
  padding-bottom: 1rem; }
  @media screen and (min-width: 992px) {
    .node-type-profile .header-section {
      padding-bottom: 5rem; } }
  .node-type-profile .header-section__profile-image .field-profile-image {
    background-color: #fff;
    padding: 10px;
    margin-top: 30px;
    box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem #dddddd; }
    .node-type-profile .header-section__profile-image .field-profile-image .field-content-items {
      overflow: hidden;
      padding-bottom: 100%;
      position: relative;
      width: 100%; }
      .node-type-profile .header-section__profile-image .field-profile-image .field-content-items img {
        position: absolute;
        width: 100%;
        left: 50%;
        margin-left: -50%; }
  .node-type-profile .header-section__profile-and-award {
    position: relative;
    position: relative;
    float: left;
    width: 50%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 25%;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 992px) {
      .node-type-profile .header-section__profile-and-award {
        float: left;
        width: 25%; } }
    @media (min-width: 992px) {
      .node-type-profile .header-section__profile-and-award {
        margin-left: 0%; } }
    .node-type-profile .header-section__profile-and-award .award-container {
      position: absolute;
      top: 15px;
      left: -20px;
      width: 90px;
      display: none; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__profile-and-award .award-container {
          display: block; } }
    .node-type-profile .header-section__profile-and-award .awards-wrapper {
      padding: 15px;
      background: #fff;
      margin: 0 20px;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__profile-and-award .awards-wrapper {
          padding: 5% 10% 1% 10%;
          background: initial;
          margin: initial;
          margin-bottom: -1px; } }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__profile-and-award .awards-wrapper {
          background-color: #fff;
          -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); } }
      .node-type-profile .header-section__profile-and-award .awards-wrapper img {
        display: inline-block;
        max-width: 30%; }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__profile-and-award .awards-wrapper img {
            max-width: 49.5%; } }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__profile-and-award .awards-wrapper img {
            display: block;
            max-width: 100%; } }
    .node-type-profile .header-section__profile-and-award .awards-banner-arrow {
      position: relative;
      display: none; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__profile-and-award .awards-banner-arrow {
          display: unset; } }
      .node-type-profile .header-section__profile-and-award .awards-banner-arrow svg {
        filter: drop-shadow(0.0025rem 0.3rem 0.2rem rgba(0, 0, 0, 0.2)); }
        .node-type-profile .header-section__profile-and-award .awards-banner-arrow svg .triangle {
          color: #fff;
          fill: #fff; }
  .node-type-profile .header-section__provider-info {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 992px) {
      .node-type-profile .header-section__provider-info {
        float: left;
        width: 75%; } }
    .node-type-profile .header-section__provider-info .field-primary-specialty {
      text-transform: uppercase;
      margin-top: 3rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: #666666; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .field-primary-specialty {
          font-size: 1.8rem;
          margin-top: 5rem; } }
    .node-type-profile .header-section__provider-info .name-wrapper {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .name-wrapper {
          float: left;
          width: 70%; } }
    .node-type-profile .header-section__provider-info .btn-wrapper.schedule-appointment {
      margin-top: 2rem; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .btn-wrapper.schedule-appointment {
          float: left;
          width: 30%; } }
    .node-type-profile .header-section__provider-info .provider-name {
      margin-top: 1.5rem;
      font-size: 2rem; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .provider-name {
          font-size: 4rem;
          margin-top: 2.5rem; } }
    .node-type-profile .header-section__provider-info .secondary-info-wrapper {
      clear: both; }
    .node-type-profile .header-section__provider-info .nyp-branding {
      padding: 5px 0;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .nyp-branding {
          text-align: unset;
          padding: 15px 0; } }
      .node-type-profile .header-section__provider-info .nyp-branding span {
        font-size: 0.9rem;
        color: #666666;
        font-family: "1898Sans-Bold", sans-serif;
        text-transform: uppercase;
        margin-top: 0.5rem;
        margin-right: 3px;
        letter-spacing: 0.05rem; }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__provider-info .nyp-branding span {
            font-size: 1.2rem; } }
      .node-type-profile .header-section__provider-info .nyp-branding img {
        width: 140px;
        position: relative;
        top: -3px; }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__provider-info .nyp-branding img {
            width: 200px;
            top: 0; } }
    .node-type-profile .header-section__provider-info .provider-name,
    .node-type-profile .header-section__provider-info .field-primary-specialty {
      text-align: center; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .provider-name,
        .node-type-profile .header-section__provider-info .field-primary-specialty {
          text-align: unset; } }
    .node-type-profile .header-section__provider-info .field-medical-specialties {
      text-align: left;
      margin-left: 20px; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-info .field-medical-specialties {
          text-align: left;
          margin-left: 0; } }
      .node-type-profile .header-section__provider-info .field-medical-specialties .field-label {
        font-size: 1.6rem;
        text-transform: capitalize;
        color: #000000;
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block; }
      .node-type-profile .header-section__provider-info .field-medical-specialties .field-content-items {
        font-size: 1.4rem;
        color: #555555;
        display: inline-block; }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__provider-info .field-medical-specialties .field-content-items {
            font-size: 1.6rem; } }
        .node-type-profile .header-section__provider-info .field-medical-specialties .field-content-items .field-content-item {
          display: inline-block; }
      .node-type-profile .header-section__provider-info .field-medical-specialties .more-anchor {
        display: inline-block;
        font-size: 14px;
        margin-left: 5px; }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__provider-info .field-medical-specialties .more-anchor {
            font-size: 1.6rem; } }
        .node-type-profile .header-section__provider-info .field-medical-specialties .more-anchor:after {
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          content: " \e80f";
          padding-left: 0.4rem;
          color: #cf4520; }
    .node-type-profile .header-section__provider-info .scheduling-options {
      margin-top: 2rem; }
      .node-type-profile .header-section__provider-info .scheduling-options > [class^="field-"] {
        display: inline-block; }
        .node-type-profile .header-section__provider-info .scheduling-options > [class^="field-"]:not(:first-child) {
          margin-left: 1rem; }
        .node-type-profile .header-section__provider-info .scheduling-options > [class^="field-"] > img {
          display: inline-block;
          height: 2rem;
          margin-right: 0.25rem; }
        .node-type-profile .header-section__provider-info .scheduling-options > [class^="field-"] .field-label {
          display: inline-block;
          line-height: 2rem;
          font-family: "Open Sans", sans-serif;
          font-size: 1.5rem;
          text-transform: capitalize;
          font-weight: normal;
          margin-top: 0;
          margin-bottom: 0; }
    .node-type-profile .header-section__provider-info .online-scheduling-info-container {
      color: #666;
      font-size: 13px;
      line-height: 20px;
      margin: 20px 0 15px 20px;
      display: inline-block; }
      @media screen and (min-width: 768px) {
        .node-type-profile .header-section__provider-info .online-scheduling-info-container {
          margin: 15px 0 5px;
          font-size: 15px; } }
      .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag {
        margin-right: 15px;
        margin-bottom: 5px;
        white-space: nowrap; }
        @media screen and (min-width: 768px) {
          .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag {
            display: inline-block; } }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag:last-of-type {
          margin-right: 0; }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag span:first-of-type {
          margin-right: 5px; }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag span.dot {
          margin-left: 2px;
          margin-right: 11px; }
          @media screen and (min-width: 768px) {
            .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag span.dot {
              margin-left: 0;
              margin-right: 5px; } }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag span.icon.treats-children {
          margin-left: -1px;
          margin-right: 6px; }
          @media screen and (min-width: 768px) {
            .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag span.icon.treats-children {
              margin-left: 0;
              margin-right: 5px;
              margin-right: -2px; } }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .dot {
          height: 14px;
          width: 14px;
          background-color: #66ff33;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: 3px;
          margin-right: 10px; }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag.not-accepting .dot {
          background-color: #f44242; }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .icon {
          display: inline-block;
          width: 23px; }
          @media screen and (min-width: 768px) {
            .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .icon {
              width: initial; } }
          .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .icon img {
            width: 17px;
            height: 17px; }
          .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .icon img.treats-children-icon {
            width: 21px;
            height: 21px; }
          .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .icon img.concierge-medicine-icon {
            width: 19px;
            height: 20px; }
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .video-visit-icon,
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .treats-children-icon,
        .node-type-profile .header-section__provider-info .online-scheduling-info-container .text-tag .concierge-medicine-icon {
          opacity: 0.6; }
  .node-type-profile .header-section__provider-awards {
    text-align: center;
    margin-bottom: 10px;
    display: block; }
    @media screen and (min-width: 992px) {
      .node-type-profile .header-section__provider-awards {
        display: none;
        text-align: left;
        margin-bottom: 0; } }
    .node-type-profile .header-section__provider-awards .awards-wrapper {
      padding: 15px;
      background: #fff;
      margin: 0 20px;
      box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem #dddddd; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-awards .awards-wrapper {
          padding: 5% 10% 5% 10%;
          background: initial;
          margin: initial; } }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-awards .awards-wrapper {
          background-color: #fff;
          box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem #dddddd; } }
      .node-type-profile .header-section__provider-awards .awards-wrapper img {
        display: inline-block;
        max-width: 30%; }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__provider-awards .awards-wrapper img {
            max-width: 49.5%; } }
        @media screen and (min-width: 992px) {
          .node-type-profile .header-section__provider-awards .awards-wrapper img {
            display: block;
            max-width: 100%; } }
    .node-type-profile .header-section__provider-awards .awards-banner-arrow {
      position: relative;
      display: none; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-section__provider-awards .awards-banner-arrow {
          display: unset; } }
      .node-type-profile .header-section__provider-awards .awards-banner-arrow svg {
        filter: drop-shadow(0.0025rem 0.3rem 0.2rem #dddddd); }
        .node-type-profile .header-section__provider-awards .awards-banner-arrow svg .triangle {
          color: #fff;
          fill: #fff; }
  .node-type-profile .header-section .social-icons {
    display: none; }
    .node-type-profile .header-section .social-icons a i:hover {
      filter: brightness(0.2); }
    a i .node-type-profile .header-section .social-icons:after {
      font-size: 35px !important;
      color: red; }
  .node-type-profile .header-section .provider-ctas {
    display: none; }
    @media screen and (min-width: 992px) {
      .node-type-profile .header-section .provider-ctas {
        display: initial;
        float: right; } }
    .node-type-profile .header-section .provider-ctas a[class$="-icon"] {
      display: inline-block;
      min-width: 2rem;
      min-height: 2rem;
      margin: 1rem 0.5rem;
      background-size: contain;
      background-repeat: no-repeat;
      text-decoration: none;
      border: none;
      filter: brightness(1.2); }
      .node-type-profile .header-section .provider-ctas a[class$="-icon"]:hover {
        filter: brightness(0.5); }
      .node-type-profile .header-section .provider-ctas a[class$="-icon"].share-icon {
        background-image: url("../images/share-icon_awesome.svg"); }
      .node-type-profile .header-section .provider-ctas a[class$="-icon"].print-icon {
        background-image: url("../images/print-icon_awesome.svg"); }
    .node-type-profile .header-section .provider-ctas .popover .popover-content {
      display: inline-block; }
      .node-type-profile .header-section .provider-ctas .popover .popover-content a {
        display: block;
        border: 1px solid #666666;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        position: relative;
        margin-bottom: 0.5rem; }
        .node-type-profile .header-section .provider-ctas .popover .popover-content a i {
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          position: absolute;
          font-size: 2rem;
          color: #666666;
          text-align: center;
          width: 100%;
          top: 50%;
          transform: translateY(-50%); }
        .node-type-profile .header-section .provider-ctas .popover .popover-content a:after {
          content: ""; }
        .node-type-profile .header-section .provider-ctas .popover .popover-content a:hover {
          border-color: #cf4520; }
          .node-type-profile .header-section .provider-ctas .popover .popover-content a:hover i {
            color: #cf4520; }

.node-type-profile .header-nav-section {
  display: none; }
  @media screen and (min-width: 992px) {
    .node-type-profile .header-nav-section {
      display: block;
      margin-top: -3rem !important; } }
  .node-type-profile .header-nav-section .section-row {
    box-shadow: 0 0.5rem 0.25rem -0.25rem #dddddd; }
    .node-type-profile .header-nav-section .section-row > .btn-wrapper {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #dddddd;
      background-color: #fff; }
      @media (min-width: 992px) {
        .node-type-profile .header-nav-section .section-row > .btn-wrapper {
          float: left;
          width: 33.33333%; } }
      .node-type-profile .header-nav-section .section-row > .btn-wrapper:hover, .node-type-profile .header-nav-section .section-row > .btn-wrapper.active {
        color: #cf4520; }
      .node-type-profile .header-nav-section .section-row > .btn-wrapper .btn {
        color: #555;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding-top: 14px;
        padding-bottom: 14px; }
        .node-type-profile .header-nav-section .section-row > .btn-wrapper .btn:after {
          content: " "; }
      @media screen and (min-width: 992px) {
        .node-type-profile .header-nav-section .section-row > .btn-wrapper .btn {
          display: block; } }

.node-type-profile .sticky-section {
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: #dddddd 0rem 0.3rem 0.8rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  text-align: center;
  transition: top 0.25s ease-in-out;
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .node-type-profile .sticky-section {
      background-color: #f7f7f7;
      text-align: unset;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset; } }
  .node-type-profile .sticky-section .section-row.row-1 .provider-info {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 992px) {
      .node-type-profile .sticky-section .section-row.row-1 .provider-info {
        float: left;
        width: 66.66667%; } }
    .node-type-profile .sticky-section .section-row.row-1 .provider-info .provider-name {
      margin-top: 0; }
    .node-type-profile .sticky-section .section-row.row-1 .provider-info .field-primary-specialty {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.8rem;
      display: none;
      color: #666666; }
    .node-type-profile .sticky-section .section-row.row-1 .provider-info .provider-name {
      font-size: 22px;
      margin-bottom: 10px; }
      @media screen and (min-width: 992px) {
        .node-type-profile .sticky-section .section-row.row-1 .provider-info .provider-name {
          font-size: 40px;
          margin-bottom: 5px; } }
    @media screen and (min-width: 992px) {
      .node-type-profile .sticky-section .section-row.row-1 .provider-info .field-primary-specialty {
        display: unset; } }
  .node-type-profile .sticky-section .section-row.row-1 .schedule-appointment {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 992px) {
      .node-type-profile .sticky-section .section-row.row-1 .schedule-appointment {
        float: left;
        width: 33.33333%; } }
    .node-type-profile .sticky-section .section-row.row-1 .schedule-appointment .btn {
      width: 100%;
      white-space: normal; }
  .node-type-profile .sticky-section .section-row.row-2 {
    display: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    @media screen and (min-width: 992px) {
      .node-type-profile .sticky-section .section-row.row-2 {
        display: unset; } }
    .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #dddddd;
      background-color: #fff; }
      @media (min-width: 992px) {
        .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper {
          float: left;
          width: 33.33333%; } }
      .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper:hover .btn, .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper.active .btn {
        color: #cf4520; }
      .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper .btn {
        color: #555;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding-top: 14px;
        padding-bottom: 14px; }
        .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper .btn:after {
          content: " "; }
      @media screen and (min-width: 992px) {
        .node-type-profile .sticky-section .section-row.row-2 > .btn-wrapper .btn {
          display: block; } }

.node-type-profile .about-section {
  margin-bottom: 2rem; }
  .node-type-profile .about-section .section-row > div[class^="field-"] {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 1rem; }
    .node-type-profile .about-section .section-row > div[class^="field-"] .field-content-item .field-readmore[aria-expanded="0"], .node-type-profile .about-section .section-row > div[class^="field-"] .field-content-item .field-readmore[aria-expanded="false"] {
      mask-image: linear-gradient(to bottom, black 75%, transparent 100%); }
    .node-type-profile .about-section .section-row > div[class^="field-"] .field-content-item a[data-readmore-toggle] {
      font-size: 1.5rem; }
  .node-type-profile .about-section .field-provider-board-certs > .field-content-items > .field-content-item,
  .node-type-profile .about-section .field-problem-procedure > .field-content-items > .field-content-item,
  .node-type-profile .about-section .field-languages-spoken > .field-content-items > .field-content-item {
    font-size: 1.6rem;
    color: #555555;
    break-inside: avoid-column;
    page-break-inside: avoid;
    padding-bottom: 1.8rem;
    padding-top: 1.5rem;
    border-bottom: 1px solid #dddddd; }
    .node-type-profile .about-section .field-provider-board-certs > .field-content-items > .field-content-item::marker,
    .node-type-profile .about-section .field-problem-procedure > .field-content-items > .field-content-item::marker,
    .node-type-profile .about-section .field-languages-spoken > .field-content-items > .field-content-item::marker {
      font-size: smaller; }
    .node-type-profile .about-section .field-provider-board-certs > .field-content-items > .field-content-item > .entity-field-collection-item,
    .node-type-profile .about-section .field-problem-procedure > .field-content-items > .field-content-item > .entity-field-collection-item,
    .node-type-profile .about-section .field-languages-spoken > .field-content-items > .field-content-item > .entity-field-collection-item {
      vertical-align: middle;
      display: inline-block; }
  @media screen and (min-width: 992px) {
    .node-type-profile .about-section .field-problem-procedure > .field-content-items,
    .node-type-profile .about-section .field-languages-spoken > .field-content-items {
      max-width: 90%;
      columns: 2; } }
  .node-type-profile .about-section__research {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    .node-type-profile .about-section__research .field-vivo-url .field-content-item a {
      display: inline-block;
      background-color: #cf4520;
      padding: 1.5rem 4rem;
      border-radius: 0.5rem;
      font-weight: bold;
      font-size: 1.4rem;
      color: #fff;
      border-bottom: none; }
      .node-type-profile .about-section__research .field-vivo-url .field-content-item a:hover {
        background-color: #b31b1b; }

.node-type-profile .locations-appointments-section {
  padding: 20px; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .node-type-profile .locations-appointments-section .title-tag-wrapper {
      margin: 0 10px; }
      .node-type-profile .locations-appointments-section .title-tag-wrapper h2 {
        color: #000;
        font-size: 20px; } }
  .node-type-profile .locations-appointments-section .loading {
    clear: both;
    margin: 80px 0;
    padding-top: 10px;
    text-align: center; }
  .node-type-profile .locations-appointments-section .locations-map-wrapper {
    padding: 15px;
    display: flex;
    flex-direction: row; }
    @media screen and (min-width: 768px) {
      .node-type-profile .locations-appointments-section .locations-map-wrapper {
        padding: 30px; } }
  .node-type-profile .locations-appointments-section .locations-wrapper {
    border: 1px solid #e6e6e6;
    clear: both;
    margin-top: 15px; }
    .node-type-profile .locations-appointments-section .locations-wrapper .title-area {
      background: #f7f7f7;
      float: left;
      width: 100%;
      display: none; }
      @media screen and (min-width: 768px) {
        .node-type-profile .locations-appointments-section .locations-wrapper .title-area {
          display: block; } }
    .node-type-profile .locations-appointments-section .locations-wrapper .title-wrapper {
      padding: 35px 25px 40px;
      display: block;
      float: left; }
      .node-type-profile .locations-appointments-section .locations-wrapper .title-wrapper .cta-icon {
        display: block;
        width: 25px;
        height: 25px;
        margin: 0 auto; }
      .node-type-profile .locations-appointments-section .locations-wrapper .title-wrapper span {
        display: block;
        padding-top: 10px;
        font-size: 16px;
        font-weight: bold; }
    .node-type-profile .locations-appointments-section .locations-wrapper .location-info {
      background: #fff;
      color: #555; }
    .node-type-profile .locations-appointments-section .locations-wrapper .patient-portal {
      color: #cf4520; }
    .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper {
      clear: both; }
      .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container {
        margin-bottom: 30px; }
        @media screen and (min-width: 768px) {
          .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container {
            margin-bottom: 50px; } }
        .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .location-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 15px;
          line-height: 2.4rem; }
          .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .location-title a {
            text-decoration: underline;
            border-bottom: none;
            color: #b31b1b; }
        .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .address-line-1 {
          font-size: 15px;
          color: #555;
          margin-bottom: 2px; }
        .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .directions-wrapper {
          margin-bottom: 25px; }
          .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .directions-wrapper a {
            color: #b31b1b; }
        .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .phone span {
          display: block;
          font-size: 16px;
          color: #000000;
          font-weight: bold;
          margin-bottom: 5px; }
        .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .phone .telephone-number {
          font-size: 15px; }
          .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .phone .telephone-number::before {
            display: inline-block;
            content: '';
            background-image: url("/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/mobile.svg");
            background-size: contain;
            vertical-align: top;
            width: 2rem;
            height: 2rem;
            margin-right: .5rem; }
          .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .location-listing-container .phone .telephone-number a {
            color: #b31b1b; }
      .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .cta-button-inner {
        text-align: center; }
      .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .cta-container {
        margin-top: 40px; }
      .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .cta-button {
        border-radius: 5px;
        padding: 15px 60px;
        font-size: 14px;
        font-weight: 700;
        height: unset; }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .cta-button {
            width: 100%; } }
      .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .direct-scheduling-cta {
        margin-top: 10px;
        text-align: center; }
        .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .direct-scheduling-cta a {
          color: #b31b1b; }
      .node-type-profile .locations-appointments-section .locations-wrapper .react-locations-wrapper .call-to-action-wrapper .phone-cta .cta-button {
        padding: 15px 30px; }
  .node-type-profile .locations-appointments-section .google-map-react-wrapper {
    display: none; }
    @media screen and (min-width: 768px) {
      .node-type-profile .locations-appointments-section .google-map-react-wrapper {
        display: block; } }
  .node-type-profile .locations-appointments-section .location-listing, .node-type-profile .locations-appointments-section .video-visit-listing {
    border-top: none; }
  .node-type-profile .locations-appointments-section .disable-message p {
    font-size: 1.3rem;
    line-height: 2.0rem; }
  .node-type-profile .locations-appointments-section .provider-profile-wrapper .header-pane #info-pane #provider-header h3 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: #666666;
    letter-spacing: 2px; }

.node-type-profile .insurance-accepted-section {
  background-color: #f7f7f7;
  padding-bottom: 2rem;
  padding-top: 20px; }
  .node-type-profile .insurance-accepted-section .field-collection-container {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0; }
    .node-type-profile .insurance-accepted-section .field-collection-container .field-label {
      font-size: 2rem;
      color: #b31b1b;
      text-transform: capitalize; }
      @media screen and (min-width: 992px) {
        .node-type-profile .insurance-accepted-section .field-collection-container .field-label {
          font-size: 3rem; } }
    .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .insurance-asterisk {
      margin-bottom: 20px; }
      .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .insurance-asterisk span {
        font-size: 23px;
        color: #b31b1b;
        font-family: "1898Sans-Regular", sans-serif;
        position: relative;
        top: 7px;
        margin-right: 5px; }
    .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .see-insurances-trigger {
      font-size: 1.4rem; }
      @media screen and (min-width: 992px) {
        .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .see-insurances-trigger {
          display: none; } }
      .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .see-insurances-trigger:after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        content: " \e80f";
        padding-left: 0.4rem;
        color: #cf4520; }
    .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items {
      margin-left: -20px;
      margin-right: -20px;
      display: none; }
      .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items:before, .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items:after {
        content: " ";
        display: table; }
      .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items:after {
        clear: both; }
      @media screen and (min-width: 992px) {
        .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items {
          display: block; } }
      .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column {
        position: relative;
        float: left;
        width: 100%;
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px; }
        @media (min-width: 992px) {
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column {
            float: left;
            width: 33.33333%; } }
        .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item {
          background: #fff;
          margin-bottom: 1.6rem; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item input {
            display: none; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item label {
            transition: 0.2s ease;
            padding: 1.7rem 4rem 1.7rem 3.5rem;
            font-size: 1.6rem;
            margin-bottom: 0;
            position: relative; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item label.is-closed:before {
            content: "*";
            font-size: 23px;
            color: #b31b1b;
            font-family: "1898Sans-Regular", sans-serif;
            position: absolute;
            left: 15px;
            top: 24px; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item.children label {
            position: relative;
            cursor: pointer; }
            .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item.children label::after {
              content: "+";
              font-weight: bold;
              position: absolute;
              right: 1.5rem;
              font-size: 3rem;
              color: #cf4520;
              padding-left: 1.5rem;
              width: 4rem; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item.children:hover label {
            color: #cf4520; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item .content {
            padding-left: 7px;
            list-style: none;
            padding-bottom: 10px; }
            .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item .content li {
              margin-bottom: 10px;
              position: relative; }
              .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item .content li.is-closed span {
                font-size: 23px;
                color: #b31b1b;
                font-family: "1898Sans-Regular", sans-serif;
                position: absolute;
                left: -17px;
                top: 7px; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item input + label + .content {
            display: none;
            height: 0;
            font-size: 0;
            transition: 0.25s ease;
            border-top: 1px solid #dddddd;
            margin: 0 30px;
            padding-top: 20px; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item input:checked + label + .content {
            display: block;
            height: auto;
            font-size: unset;
            transition: 0.25s ease;
            font-size: 15px; }
          .node-type-profile .insurance-accepted-section .field-collection-container .field-provider-insurances .field-content-items .column .field-content-item.children input:checked + label::after {
            content: "–"; }

.node-type-profile .credentials-section {
  background-color: #f7f7f7; }
  .node-type-profile .credentials-section .section-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem; }
    .node-type-profile .credentials-section .section-wrapper > .section-row > div:not(:last-child) {
      margin-bottom: 2rem; }
    @media screen and (min-width: 992px) {
      .node-type-profile .credentials-section .section-wrapper > .section-row {
        display: flex;
        flex-wrap: wrap; }
        .node-type-profile .credentials-section .section-wrapper > .section-row > div:not(:last-child) {
          margin-bottom: unset; } }
  .node-type-profile .credentials-section__education, .node-type-profile .credentials-section__appointments {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    min-height: 1px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 992px) {
      .node-type-profile .credentials-section__education, .node-type-profile .credentials-section__appointments {
        float: left;
        width: 50%; } }
    @media screen and (min-width: 992px) {
      .node-type-profile .credentials-section__education, .node-type-profile .credentials-section__appointments {
        display: flex;
        flex-direction: column; } }
    .node-type-profile .credentials-section__education .column-wrapper, .node-type-profile .credentials-section__appointments .column-wrapper {
      background: #fff;
      border: 1px solid #dddddd;
      padding: 2rem; }
      @media screen and (min-width: 992px) {
        .node-type-profile .credentials-section__education .column-wrapper, .node-type-profile .credentials-section__appointments .column-wrapper {
          padding: 3rem 8rem;
          display: flex;
          flex-grow: 1;
          flex-direction: column; } }
      .node-type-profile .credentials-section__education .column-wrapper .cta-icon, .node-type-profile .credentials-section__appointments .column-wrapper .cta-icon {
        width: 100%;
        height: 3rem; }
    .node-type-profile .credentials-section__education .field-collection-container, .node-type-profile .credentials-section__appointments .field-collection-container {
      margin: 0;
      border: 0; }
      .node-type-profile .credentials-section__education .field-collection-container .field-label, .node-type-profile .credentials-section__appointments .field-collection-container .field-label {
        text-align: center; }
      .node-type-profile .credentials-section__education .field-collection-container > div[class^="field-"], .node-type-profile .credentials-section__appointments .field-collection-container > div[class^="field-"] {
        text-align: center; }
        .node-type-profile .credentials-section__education .field-collection-container > div[class^="field-"] > .field-content-items, .node-type-profile .credentials-section__appointments .field-collection-container > div[class^="field-"] > .field-content-items {
          display: inline-block;
          text-align: left;
          padding-left: 2rem;
          font-size: 1.5rem;
          color: #666666;
          list-style: none; }
          .node-type-profile .credentials-section__education .field-collection-container > div[class^="field-"] > .field-content-items > .field-content-item .field-collection-view, .node-type-profile .credentials-section__appointments .field-collection-container > div[class^="field-"] > .field-content-items > .field-content-item .field-collection-view {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            margin: 1rem 0;
            border: 0; }
            .node-type-profile .credentials-section__education .field-collection-container > div[class^="field-"] > .field-content-items > .field-content-item .field-collection-view .entity-field-collection-item .content div, .node-type-profile .credentials-section__appointments .field-collection-container > div[class^="field-"] > .field-content-items > .field-content-item .field-collection-view .entity-field-collection-item .content div {
              display: inline; }
            .node-type-profile .credentials-section__education .field-collection-container > div[class^="field-"] > .field-content-items > .field-content-item .field-collection-view .entity-field-collection-item .content > div ~ div::before, .node-type-profile .credentials-section__appointments .field-collection-container > div[class^="field-"] > .field-content-items > .field-content-item .field-collection-view .entity-field-collection-item .content > div ~ div::before {
              content: ", "; }

.node-type-profile .news-media-section .section-row > div[class^="field-"] {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }
  .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news {
    margin-bottom: 1rem;
    border-bottom: 0; }
    .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title {
      color: #b31b1b;
      font-family: "1898Sans-Bold", sans-serif;
      font-size: 1.6rem; }
      @media screen and (min-width: 992px) {
        .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title {
          font-size: 1.8rem; } }
      .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .teaser-title a::after {
        content: '';
        padding: 0; }
    .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-content div[class^="views-row"] .views-field-created {
      font-size: 1.5rem; }
    .node-type-profile .news-media-section .section-row > div[class^="field-"] .view-news .view-footer {
      display: none; }

.node-type-profile .external-relationships-section .section-row > div[class^="field-"] {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }
  .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label {
    text-transform: capitalize;
    margin-bottom: 0; }
    .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon {
      position: relative; }
      .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon i {
        position: absolute;
        left: 40%;
        margin-left: -12px;
        top: -2.3rem;
        width: 24px;
        height: 12px;
        overflow: hidden;
        display: none;
        z-index: 9999; }
        .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon i::after {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: #fff;
          border: 1px solid #dddddd;
          box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.5); }
      .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon::after {
        content: attr(aria-description);
        display: none; }
      .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon:hover::after, .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon:focus::after {
        /* Show the tooltip on hover and focus */
        display: block;
        position: absolute;
        z-index: 999;
        top: -20px;
        left: 10%;
        min-width: 2000%;
        max-width: 3000%;
        transform: translate(-5%, -100%);
        background-color: #fff;
        padding: 1.5rem 2rem;
        font-size: 1.4rem;
        color: #555555;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        border: 0.3rem solid #dddddd;
        border-radius: 2rem;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        text-align: left; }
      .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon:hover i {
        display: block; }
  .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-content-items {
    margin-bottom: 2rem;
    padding-left: 2.2rem;
    padding-top: 1rem; }
  @media screen and (min-width: 992px) {
    .node-type-profile .external-relationships-section .section-row > div[class^="field-"] > * {
      vertical-align: top; }
    .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label {
      margin: 0;
      min-width: 25%;
      position: relative; }
      .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon:hover::after, .node-type-profile .external-relationships-section .section-row > div[class^="field-"] .field-label .tooltip-icon:focus::after {
        left: 40%;
        transform: translate(-20%, -100%); } }

.node-type-profile section .section-wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .node-type-profile section .section-wrapper:before, .node-type-profile section .section-wrapper:after {
    content: " ";
    display: table; }
  .node-type-profile section .section-wrapper:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .node-type-profile section .section-wrapper {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .node-type-profile section .section-wrapper {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .node-type-profile section .section-wrapper {
      width: 1170px; } }
  .node-type-profile section .section-wrapper .section-row {
    margin-left: -20px;
    margin-right: -20px; }
    .node-type-profile section .section-wrapper .section-row:before, .node-type-profile section .section-wrapper .section-row:after {
      content: " ";
      display: table; }
    .node-type-profile section .section-wrapper .section-row:after {
      clear: both; }
  .node-type-profile section .section-wrapper h2 {
    font-size: 2rem; }
    @media screen and (min-width: 992px) {
      .node-type-profile section .section-wrapper h2 {
        font-size: 3rem; } }

.node-type-profile section .section-label {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }

.node-type-profile section p {
  font-size: 1.5rem;
  line-height: 2.8rem; }

.node-type-profile section .field-label {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  color: #666666; }

.node-type-profile section .btn-wrapper {
  width: 100%; }
  .node-type-profile section .btn-wrapper .btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: bold; }
    @media screen and (min-width: 992px) {
      .node-type-profile section .btn-wrapper .btn {
        display: inline-block;
        width: 100%; } }
    .node-type-profile section .btn-wrapper .btn:after {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      content: " \e80f";
      padding-left: 0.4rem; }

@media screen and (min-width: 992px) {
  .node-type-profile .about-section .section-wrapper,
  .node-type-profile .news-media-section .section-wrapper,
  .node-type-profile .external-relationships-section .section-wrapper {
    padding: 0 80px; } }

@media screen and (min-width: 1200px) {
  .node-type-profile .about-section .section-wrapper,
  .node-type-profile .news-media-section .section-wrapper,
  .node-type-profile .external-relationships-section .section-wrapper {
    padding: 0 175px; } }

.field-accepting-new-patients .dot {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  background-color: lime;
  border-radius: 50%;
  margin-right: .5rem; }
