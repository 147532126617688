footer {
  &.page-footer {
    .footer-partnership {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      padding-top: 1.5rem;
      max-width: 100%;

      &__wcm,
      &__nyp {
        display: block;
        margin-left: auto;
        background-size: contain;
      }
      &__wcm {
        width: 40rem;
        max-width: 100%;
        height: 4.2rem;
        max-height: auto;
        margin-right: auto;
      }

      &__nyp {
        background-image: url("/sites/all/themes/weillcornell/images/nyp-logo-with-connecting-language.svg");
        background-size: cover;
        background-position-x: 1.5rem;
        background-position-y: 44%;
        width: 38.3rem;
        max-width: 100%;
        height: 1.9rem;
        max-height: auto;
        margin-right: 0;
        margin-top: 1.2rem;
      }
    }

    //Scroll to Top
    .magical-back-to-top-button {
      margin: 10px;
      background-color: $wcm-dark-orange;
      width: 50px;
      height: 50px;
      position: fixed;
      bottom: 50px;
      left: 10px;
      border-radius: 25px;
      border: none;
      z-index: 100;
      display: none;
      @include breakpoint($md) {
        display: inline-block;
      }

      &:after {
        content: " \e80b";
        font-family: "fontello";
        font-size: 24px;
        text-align: center;
        color: #fff;
        top: 45%;
        transform: translateY(-50%);
        position: absolute;
        width: 100%;
      }

      &:hover {
        background-color: $wcm-red;
        cursor: pointer;
      }
    }
  }
}
