.about-section {
  margin-bottom: 2rem;

  .section-row {
    // All of the fields that are direct children of the row become columns.
    > div[class^="field-"] {
      @include make-xs-column(12);
      margin-bottom: 1rem;

      .field-content-item{
        .field-readmore{
          &[aria-expanded="0"],
          &[aria-expanded="false"]{
            mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
          }
        }

        a[data-readmore-toggle]{
          font-size: 1.5rem;
        }
      }

    }
  }

  .field-provider-board-certs,
  .field-problem-procedure,
  .field-languages-spoken {

    > .field-content-items {
      > .field-content-item {
        font-size: 1.6rem;
        color: $wcm-dark-gray;
        break-inside: avoid-column;
        page-break-inside: avoid;
        padding-bottom: 1.8rem;
        padding-top: 1.5rem;
        border-bottom: 1px solid $wcm-border-gray;

        &::marker {
          font-size: smaller;
        }

        > .entity-field-collection-item {
          vertical-align: middle;
          display: inline-block;
        }
      }
    }
  }

  .field-problem-procedure,
  .field-languages-spoken  {
    > .field-content-items {
      @include breakpoint($md) {
        max-width: 90%;
        columns: 2;
      }
    }
  }

  &__research {
    @include make-xs-column(12);
    .field-vivo-url {
      .field-content-item {

        a {
          display: inline-block;
          background-color: $wcm-dark-orange;
          padding: 1.5rem 4rem;
          border-radius: 0.5rem;
          font-weight: bold;
          font-size: 1.4rem;
          color: $wcm-white;
          border-bottom: none;

          &:hover {
            background-color: $wcm-red;
          }
        }
      }
    }
  }
} // .about-section
