// WCM Base theme and Bootstrap variables and mixins
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/mixins";

// Doctor Profile Content Type
// GENERIC CODE ONLY FOR ALL SECTIONS
// Place section code in profile folder and import to make merges easier
.node-type-profile {
  // Separate files for each section to make merges easier
  // KEEP INSIDE .node-type-profile selector
  @import "./profile/header-section";
  @import "./profile/header-nav-section";
  @import "./profile/sticky-section";
  @import "./profile/about-section";
  @import "./profile/locations-section";
  @import "./profile/insurance-section";
  @import "./profile/credentials-section";
  @import "./profile/news-media-section";
  @import "./profile/external-relationships-section";

  section {
    .section-wrapper {
      @include container-wrap();
      .section-row {
        @include make-row();
      }

      h2 {
        font-size: 2rem;

        @include breakpoint($md) {
          font-size: 3rem;
        }
      }
    }

    .section-label {
      @include make-xs-column(12);
    }

    p {
      font-size: 1.5rem;
      line-height: 2.8rem;
    }

    .field-label {
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: bold;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0;
      color: $wcm-med-gray;
    }

    .btn-wrapper {
      width: 100%;

      .btn {
        display: block;
        @include breakpoint($md) {
          display: inline-block;
          width: 100%;
        }
        margin-left: auto;
        margin-right: auto;
        border-radius: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: bold;

        &:after {
          @include fontello();
          content: " \e80f";
          padding-left: 0.4rem;
        }
      }
    }
  } // section

  // Padding for content in applicable sections
  .about-section,
  .news-media-section,
  .external-relationships-section {
    .section-wrapper {
      @include breakpoint($md) {
        padding: 0 80px;
      }
      @include breakpoint($lg) {
        padding: 0 175px;
      }
    }
  } // end section padding
} // .node-type-profile
