.news-media-section {
  .section-row {

    > div[class^="field-"] {
      @include make-xs-column(12);

      .view-news {
        margin-bottom: 1rem;
        border-bottom: 0;

        .view-content{

          div[class^="views-row"]{

            .teaser-title{
              @extend h4;
              color: $wcm-red;
              font-family: $wcm-bold;
              font-size: 1.6rem;
              @include breakpoint($md){
                font-size: 1.8rem;
              }

              a{
                &::after{
                  content: '';
                  padding: 0;
                }
              }

            }
            .views-field-created{
              font-size: 1.5rem;
            }

          }
        }

        .view-footer {
          display: none; // hiding the 'view all news' link for this view here rather than the View config.
        }
      }
    }
  }
} // .news-media-section