@charset "utf-8";
/* S E A R C H   R E S U L T S
----------------------------------------------------------------------------------*/
.page-search .doctor h1 {
  font-size: 16px !important;
  line-height: inherit !important;
  padding: 10px 0 0;
  line-height: normal;
  margin-bottom: -3px;

  a {
    color: $wcm-dark-orange;

    &:after {
      content: '';
      display: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.page-search .field-name-field-profile-image,
.page-search .pane-node-field-profile-image {
  float: left;
  margin-right: 25px;
  height: 133px;
  overflow: hidden;

  a > img {
    border-radius: 0;
    width: 100px;
  }
}

.page-search h2.pane-title {
  font-size: 100%;
  padding: 0;
  font-weight: 400;
  color: #000;
  margin-top: 0;
}

.page-search .pane-node-field-board-certifications ul,
.page-search .pane-node-field-problem-procedure ul {
  list-style-type: none;
}

 .page-search .pane-node-field-problem-procedure {
  padding-top: 10px;
 }

.geary-container {
  margin: 0;
  .geary-header {
    background-color: #e0e0e0;
  }
  .geary-column-content {
    border-top: 1px solid #ccc;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .geary-column3 {
    padding-top: 10px;
    ul {
      line-height: 1;
    }
  }
  .geary-column2 {
    padding-top: 10px;
  }
  .doctor {
    padding-top: 10px;
  }
}

.page-search .block-facetapi {
  ul {
    background-color: #fff;
    padding-right: 20px;
    padding-top: 10px;
  }
  ul li {
    list-style-type: none;
    list-style-image: none;
  }
  h2 {
    font-size: 1em;
    padding: 0px;
  }
}

 .solr-count {
  font-size: 2em;
  font-weight: normal;
 }

 .ds-search-extra {
  border-bottom: 1px solid #ccc;
  font-size: 1.4em;
  padding-bottom: .5em;
  background-color: #e0e0e0;
  padding: 10px 10px 10px 10px;
  /* display: none; */
 }

 /* #filtertoggle { display: none; } */

 .page-search-site input#edit-keys {
  max-width: 200px;
}

.page-search-site {
  .panelizer-view-mode.node-unpublished {
    display: none;
  }
}

.form-item-keys {
  float: left;
  margin: 0;
  width: 265px;
}

.form-item-keys label {
  float: left;
  margin-right: 10px;
}

.solr-term {
 font-weight: bold;
 font-style: italic;
 font-size: 1.2em;
 background:#fff;
 padding:0.2em;
 border-radius:0.2em;
}

#filtershow,
#hidecont,
#hidecontent {
  font-weight: bold;
  cursor: pointer;
  background-color: #e0e0e0;
  padding: 15px 10px;
}

.block-facetapi {
  max-width: none;

  @include breakpoint($bp1) {
//      max-width: 35%;
//      width: 100%;
//      margin-right: 27px;

      float: left;
  }
  padding-bottom: 20px;
}

.specialty-facet {
  margin-right: 0px;
}

.page-search .field-name-field-related-practice {
  line-height: .3em;
}

.page-search .field-collection-item-field-related-practices a{
  line-height: normal;
//  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  @include font-size(1.3);
}

.page-search .field-type-addressfield{
//  line-height: 1.4em;
}

.page-search .field-label {
  font-weight: normal;
  float: left;
}

.page-search .field-name-field-related-phone {
  @include font-size(1.3);
//  line-height: .3rem;
}

.page-search .pane-node-field-related-practices {
  padding-top: 10px;
}

.page-search .specialties ul {
  line-height: 1;
}

td.views-field-title,
td.views-field-field-related-practices,
td.views-field-field-problem-procedure {
  padding-top: 10px;
}

td.views-field-field-problem-procedure .item-list:before {
  content: "Expertise";
  font-weight: bold;
}

td.views-field-title > div > .item-list:before {
  content: "Board Certified In";
  font-weight: bold;
}

td.views-field-title {
  padding-left: 20px;
}

#result-statement {
  /*float: left;*/
  margin-right: 5px;
  line-height: 30px;
}

.node-search-result {
  border-top: 1px solid #ccc;

    .panel-separator {
        margin: 0;
    }
    &.row {
        margin-left: 0;
        margin-right: 0;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
        width: auto;

        .clearfix {
            clear: none;
            &:after {
                clear: none;
            }
        }
    }
}

.node-search-result.node-profile {
    .radix-layouts-column2, .radix-layouts-column3 {
        padding-left: 15px;
    }
}

p#filterhide {
  margin-right: 10px;
}

.clear {
  height: 1px;
  padding: 0;
  margin: 0;
  clear: both;
}

.chosen-container {
  max-width: 100%;
}

.chosen-container-single .chosen-single {
  @include border-radius(0px);
  @include box-shadow(none);
  padding: 1px 0 0 8px;
  height: 26px;
}

/* Special Styles For New Search */

.clinical-more:after,
.related-more:after {
  content: " more";
}

.clinical-more:before,
.related-more:before {
  content: "↓ ";
}

.clinical-more.less:before,
.related-more.less:before {
  content: "↑ ";
}

.clinical-more.less:after,
.related-more.less:after {
  content: " less";
}

.clinical-more,
.related-more {
  cursor: pointer;
  color: $wcm-dark-orange; // #607890;
}

.clinical-more:hover,
.related-more:hover {
  text-decoration: underline;
}

td.views-field-field-profile-image img {
  float: left;
  margin-right: 20px;
}

td.views-field-field-profile-image h1 {
  margin-top: 13px;
}

h3.show:before {
  content: "+ ";
}

h3.show {
  cursor: pointer;
  background: #e0e0e0;
  padding: 10px 0px 10px 17px;
  color: #006872;
}

h3.show.hide:before {
  content: "- ";
}

#hidecont,
#hidecontent,
.show {
  margin-bottom: 40px;
}

#hidecontent {
    margin-bottom: 20px;
}

.show.hide {
  margin-bottom: 0px;
}

p.cert {
  font-weight: bold;
  padding: 0;
  margin-bottom: -7px;
}

#specialty-search label {
  float: left;
  margin-right: 10px;
}

/*.search-results ul {
    @include font-size(1.3);
}*/
.search-results li {
    margin-bottom: 0px;
}

/*#edit-departments {
  height: 27px;
}*/

#finder {
  padding-bottom: 10px;
  padding-top: 3px;

  form {
    max-width: 900px;
    margin: 0 auto;
    display: inline;
  }

  input#edit-submit {
    margin-top: .5em;
    margin-left: 15px;
    padding: 0;

    &:disabled {
      cursor: initial;
      background-image: linear-gradient(0deg, #868686 0%, #7E7D7D 48.12%, #929292 51.64%, #CECECE 100%);
      background-image: -o-linear-gradient(0deg, #868686 0%, #7E7D7D 48.12%, #929292 51.64%, #CECECE 100%);
      background-image: -moz-linear-gradient(0deg, #868686 0%, #7E7D7D 48.12%, #929292 51.64%, #CECECE 100%);
      color: #ccc;
    }
  }

  .form-item-name {
    float: left;
    margin-right: 10px;
    margin-top: .5em;
  }

  input {
    max-width: 250px;
    border-color: #B1B1B1;
    padding: 3px;
  }

  input#edit-name {
    max-width: 250px;
    /*border: 1px solid black;*/
  }

  .form-item-name label,
  .form-item-departments label,
  #specialty-search label {
    font-weight: normal;
    float: left;
    margin-right: 10px;
    color: #ffffff;
    @include font-size(1.6);
    display: inline;
  }

  .chosen-container-single .chosen-single {
    padding: 3px;
    height: 18px;
    @include font-size(1.6);

    span { margin-top: -3px; }
  }

  .form-item-name > div { display: none; }
}

#edit_departments_chosen a {
  background: #fff;
  @include font-size(1.6);
}

.form-item-departments {
  margin-top: .5em;
  float: left;
}

#edit_departments_chosen {
  float: left;
}

#block-wcmc-custom-search-physician-search { @include clearfix(); }

.inner {
  max-width: 967px;
  margin: 0 auto;
}

#finder
#-wcmc-name-search-search {
  #edit-submit,
  #edit-submit--2 { /*display: none;*/ }
}

.page-search #block-system-main {
//  padding-top: 30px;
  padding-bottom: 30px;
}

input#specialtyinput {
  max-width: 250px;
}

#specialty-search { padding-top: 7px; }

.finder-inner {
  max-width: 800px;
  margin: 0 auto;
  @include breakpoint($bp2) {
    max-height: 36px;
  }
}

#autocomplete ul li {
  background-color: #d0ebe6;
  padding-left: 5px;
}

.node-search-result {
    .col-md-4:first-child {
        width: 100%;
        @include breakpoint($bp1) {
            width: 33.33333333333333%;
        }
    }
    .radix-layouts-column2, .radix-layouts-column3 {
        width: 100%;
        padding-left: 0px;
        @include breakpoint($bp1) {
            width: 33.33333333333333%;
            padding-left: 15px;
        }
    }
}

.node-search-result .col-md-4 {
  float:left;
//  width: 33.33333333333333%;
}

#hidecont {
  cursor: default;
  position: relative;
  z-index: 1;
}

.page-search .block-facetapi ul {
  margin-bottom: 0;
}

h3.show {
  cursor: default;
  color:inherit;
  font-weight: 400;
}
h3.show:before {
  content: "";
}
#filtertoggle {
    margin-bottom: 0;
    margin-top: 0;
    @include font-size(1.4);
}

.headless-search-extra {
    border-bottom: 1px solid #ccc;
    font-size: 1.4em;
    padding-bottom: .5em;
    padding: 10px;
}

.headless-search-extra, .ds-search-extra, #filtertoggle, #hidecont, #hidecontent {
  background: #f5f5f5;
}

#hidecontent {
  font-weight: bold;
  cursor: default;
  padding: 15px 10px; }

#filtershow, #hidecont, #hidecontent {
  font-weight: normal;
}

.solr-term {
  background: none;
  font-style: normal;
  color:#992800;
  font-size: 1.2em;
}
.solr-term-extra {
  background: none;
  font-style: normal;
  color:#992800;
  font-size: 1.2em;
  font-weight: bold;
}

.page-search .block-facetapi ul {
  padding-right: 0;
}
.page-search .block-facetapi ul li {
  padding: 0 0 0.25em 0em;
  margin: 0;
  color: #544842;
}
.page-search .block-facetapi ul li:hover {
  background-color: #0e5d86;
  color:#fff;
}
.page-search .block-facetapi ul li:hover a {
  color:#fff;
  text-decoration: none;
}
.page-search .block-facetapi ul li a {
  color: #544842;
  padding-left: 1.5em;
  display:block;
}

.specialty-dropdown,
.expertise-dropdown,
.insurance-dropdown,
.language-dropdown,
.gender-dropdown,
.neighborhood-dropdown,
.primaryspecialty-dropdown {
  cursor: pointer;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}



.block-facetapi .content {
    @include box-sizing();
    position: absolute;
    max-height: 200px;
    margin-bottom: 0;
    border: 1px solid #ccc;
//    width: 310px;
    width: 100%;
    z-index: 1;
    overflow : auto;
}
.language-facet .content, .gender-facet .content {
    position: absolute;
    max-height: 200px;
    margin-bottom: 0;
    border: 1px solid #ccc;
//    width: 140px;
    width: 100%;
    overflow: auto;
}
.facet-dropdown {
    @include box-sizing();
    background: #fff url(../images/double-arrow.png) no-repeat 98% 4px;
    border:1px solid #ccc;
    width: 100%;
    /*@include breakpoint($bp1) {
        width:300px;
    }*/
    padding:5px;
}
.facet-dropdown-small {
    @include box-sizing();
    background: #fff url(../images/double-arrow.png) no-repeat 98% 4px;
    border:1px solid #ccc;
    width: 100%;
    /*@include breakpoint($bp1) {
        width:130px;
    }*/
    padding:5px;
}
.primaryspecialty-facet,
.expertise-facet,
.insurance-facet,
.neighborhood-facet {
    @include box-sizing();
    position: relative;
    width: 100%;
    @include breakpoint($bp1) {
        width: 32%;
        margin-right: 2em;
    }
}

.gender-facet,
.language-facet {
    @include box-sizing();
    position: relative;
    width: 100%;
    @include breakpoint($bp1) {
        width: 16%;
        margin-right: 2em;
    }
}

.clear_filters {
    float:right;
    text-decoration: underline;
    color: #a9a9a9;
}
.clear_filters.active {
  cursor:pointer;
  color:#0e5d86;
}

#result-statement-headless {
  margin-right: 5px;
  line-height: 30px;
}

.provider-badge {
  margin-top: 1rem;
  min-height: 2.5em;

  .provider-badge-icon {
    height: 20px;
    width: 20px;
    margin: 0 .5rem .5rem 0;
    display: block;
    float: left;

    &.provider-badge-icon-voluntary {
      background: url(../images/profile_badges_28x28.png) 0 0;
      background-size: cover;
    }
    &.provider-badge-icon-fulltime {
      background: url(../images/profile_badges_28x28.png) 0 41px;
      background-size: cover;
    }
    &.provider-badge-icon-wcpn {
      background: url(../images/profile_badges_28x28.png) 0 20px;
      background-size: cover;
    }
  }

  & > a {
    white-space: nowrap;
  }
}
