/* Patient Review Styles
----------------------------------------------------------------------------------*/

/* Review List Styles
----------------------------------------------------------------------------------*/
.reviews{
  position: relative;
  margin: 0 auto;
  border-bottom: 1px solid $cornell-light-gray;

  h3 {
    margin-bottom: 15px;
  }
}

.main-content > div:first-child .reviews h3 {
  display: none;
}

.review{
  margin-bottom: 15px;
  overflow: auto;

  &:last-child {
    .review-details {
      border-bottom: none;
    }
  }
}
.review-circle{
  display: table;
  float: left;
  border-radius: 50%;
  width: 75px;
  height: 75px;
}
.pane-wcmc-patient-review-pane .review-circle .cta-icon{
  width: 30px;
  height: 30px;
  margin-left: 23px;
  margin-top: 23px;
  color: #fff;
  fill: #fff;
}
.review-circle-letter{
  display: table-cell;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #FFFFFF;
  font-size: xx-large;
}
.circle-color1{
  background-color: #bd2b23;
}
.circle-color2{
  background-color: #ffca3e;
}
.circle-color3{
  background-color: #D75229;
}
.review-details{
  float: left;
  border-bottom: 1px solid $cornell-light-gray;
  padding-bottom: 15px;
}
@media screen and (min-width: $bp-mobile) {
  .review-details{
    width: 80%;
    margin-left: 20px;
  }
}
@media screen and (max-width: $bp-mobile - 1) {
  .review-circle{
    margin-bottom: 10px;
  }
  .review-details{
    width: 100%;
  }
  .reviews{
    padding-right: 15px;
    padding-left: 15px;
  }
  .review{
    margin-bottom: 20px;
  }
}
.review-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}
.review-target {
  font-size: 14px;
  margin-bottom: 15px;
  font-style: italic;
}
.review-comment p {
  margin-bottom: 5px;
  line-height: 22px;
}
.gray{
  color: $cornell-light-text;
}
.pane-wcmc-patient-review-pane {
  width: 100%;
}

.node-type-clinical-service .pane-wcmc-patient-review-pane {
  border-top: 1px solid $cornell-light-gray;
}
.review-disclaimer {
  color: $cornell-dark-gray;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 15px;
}
.last-review .review-details {
  border-bottom: none;
}

/* Featured Review Styles
----------------------------------------------------------------------------------*/
.featured-review-background-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  margin-top: 20px;

  ul {
    margin-bottom: 0;
  }

  @include breakpoint($bp1) {
    margin-top: 30px;
    padding: 60px calc(100%/10);
  }
}

.not-front .featured-review-background-image {
  margin: 15px 0 30px;
  padding: 50px;

  @include breakpoint($bp1) {
    padding: 70px 90px;
  }
}

.featured-review-style1 {
  display: table;
  margin: auto;
}
.view-featured-reviews {
  text-align: center;
  position: relative;
}
.featured-review-style1 .view-content {
  margin: 15px;
}
.view-featured-reviews li {
  list-style: none;
}
.views-field-field-review-body {
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;

  @include breakpoint($bp1) {
    font-size: 16px;
    line-height: 24px;
  }

  @include breakpoint($bp2) {
    font-size: 18px;
    line-height: 28px;
  }
}
.views-field-field-review-body div.field-content{
  margin-bottom: 0;
  padding-bottom: 0;
}
.featured-review-style1:before {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 35px);
  right: -10px;
  top: -10px;
  position: absolute;
  border-top: 4px solid $cornell-bright-orange;
  border-right: 4px solid $cornell-bright-orange;
}
.featured-review-style1:after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 35px);
  left: -10px;
  bottom: -10px;
  position: absolute;
  border-bottom: 4px solid $cornell-bright-orange;
  border-left: 4px solid $cornell-bright-orange;
}
.views-field-field-reviewer > div.field-content {
  color: $cornell-dark-orange;

  @include breakpoint($bp2) {
    font-size: 20px;
  }
}
.views-field-field-review-source {
  color: $cornell-light-text;
}
.views-field-field-review-source > div.field-content {
  display: inline;
}
.img-quote-icon {
  height: 60px;
  width: 60px;
}
.featured-review-close-quote .img-quote-icon {
  transform: rotate(180deg);
}
.featured-review-style1 .featured-review-open-quote {
  left: -41px;
  top: -32px;
}
.featured-review-open-quote {
  position: absolute;
}
.featured-review-close-quote {
  left: calc(100% - 19px);
  bottom: -35px;
  position: absolute;
}
// styles to create quote icons in css
//.featured-review-quote-icon {
//  width: 25px;
//  height: 25px;
//  -moz-border-radius: 50px;
//  -webkit-border-radius: 50px;
//  border-radius: 50px;
//  margin: 0 1px 0 1px;
//  position: absolute;
//}
//.featured-review-open-quote > .featured-review-quote-icon:after {
//  content: "";
//  width: 0;
//  height: 0;
//  border-top: 23px solid;
//  border-right: 24px solid transparent;
//  position: absolute;
//  bottom: -13px;
//  right: 1px;
//
//}
//.featured-review-close-quote > .featured-review-quote-icon:after {
//  content: "";
//  width: 0;
//  height: 0;
//  border-bottom: 23px solid;
//  border-left: 24px solid transparent;
//  position: absolute;
//  top: -13px;
//  left: 1px;
//}
//.featured-review-open-quote > .featured-review-quote-icon.featured-review-quote-color1 {
//  position: absolute;
//  left: 1px;
//}
//.featured-review-open-quote > .featured-review-quote-icon.featured-review-quote-color2 {
//  position: absolute;
//  left: 30px;
//}
//.featured-review-close-quote > .featured-review-quote-icon.featured-review-quote-color2 {
//  position: absolute;
//  left: 1px;
//}
//.featured-review-close-quote > .featured-review-quote-icon.featured-review-quote-color1 {
//  position: absolute;
//  left: 30px;
//}
//.featured-review-quote-color1 {
//  background-color: $cornell-dark-orange;
//}
//.featured-review-quote-color2 {
//  background-color: $cornell-bright-orange;
//}
//.featured-review-open-quote > .featured-review-quote-icon.featured-review-quote-color1:after {
//  border-top-color: $cornell-dark-orange;
//}
//.featured-review-open-quote > .featured-review-quote-icon.featured-review-quote-color2:after {
//  border-top-color: $cornell-bright-orange;
//}
//.featured-review-close-quote > .featured-review-quote-icon.featured-review-quote-color1:after {
//  border-bottom-color: $cornell-dark-orange;
//}
//.featured-review-close-quote > .featured-review-quote-icon.featured-review-quote-color2:after {
//  border-bottom-color: $cornell-bright-orange;
//}
.view-featured-reviews div.item-list ul li.views-row{
  margin: 0;
}
/* Featured Review Styles - Style 2
----------------------------------------------------------------------------------*/
.featured-review-style2 {
  overflow: hidden;
  display: table;
  align-items: center;
  margin: auto;
}
.featured-review-background-image {
  display: inline-block;
  overflow: auto;
}
.featured-review-quote-circle {
  font-size: 22em;
  display: inline-block;
  border: 0.02em solid $cornell-bright-orange;
  position: relative;
  border-radius: 0.35em;
  margin-bottom: 15px;
  width: 0.4em;
  height: 0.4em;
}
a.featured-review-link {
  outline: 0;
  text-decoration: none;
  border-bottom: none;

  &:hover {
    border-bottom: none;    
  }
}
a.featured-review-link:hover .featured-review-quote-circle, a.featured-review-link:active .featured-review-quote-circle, a.featured-review-link:focus .featured-review-quote-circle {
  border-color: $cornell-dark-orange;
}
a.featured-review-link:hover .featured-review-col2-text, a.featured-review-link:active .featured-review-col2-text, a.featured-review-link:focus .featured-review-col2-text {
  color: $cornell-dark-orange;
}
/*These styles add a hover state to the quote icons*/
//a.featured-review-link:hover .featured-review-open-quote div, a.featured-review-link:active .featured-review-open-quote div, a.featured-review-link:focus .featured-review-open-quote div {
//  background-color: $cornell-dark-orange;
//}
//a.featured-review-link:hover .featured-review-open-quote div:after, a.featured-review-link:active .featured-review-open-quote div:after, a.featured-review-link:focus .featured-review-open-quote div:after {
//  border-top-color: $cornell-dark-orange;
//}
.featured-review-style2 .featured-review-open-quote {
  left: 25px;
  top: -32px;
}
.featured-review-col2-text {
  color: $cornell-dark-orange;
  font-weight: bold;
}
.featured-review-style2 .view-content {
  display: table-cell;
  vertical-align: middle;
}
.featured-review-col2 {
  position: relative;
  display: inline-block;
  width: 150px;
}
@media screen and (min-width: 833px) {
  .featured-review-style2 .view-content {
    padding-right: 50px;
  }
  .featured-review-col2 {
    padding-left: 50px;
    border-left: 1px solid lighten($cornell-dark-gray, 30%);
    margin-left: 20px;
    display: table-cell;
    vertical-align: middle;
  }
}
@media screen and (max-width: 832px) {
  .featured-review-style2 .view-content {
    margin: 0;
    display: block;
    width: 100%;
  }
  .featured-review-col2 {
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid $cornell-dark-gray;
    margin-top: 20px;
  }
}
