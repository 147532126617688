/* Scheduling Styles
----------------------------------------------------------------------------------*/

/* Open Scheduling Widget Styles
----------------------------------------------------------------------------------*/
fieldset#edit-open-scheduling-widget-settings {
  width: 100%;
}

iframe#openSchedulingFrame {
  width: 100%;
  display: block;
  height: 850px;
  border: none;
}

@media screen and (min-width: $bp-mobile) {
  .os-main-panel {
    width: 100%;
    float: left;
    @include breakpoint($bp-desktop) {
      width: 60%;
    }
  }

  .os-side-panel {
    width: 100%;
    float: left;
    padding: 5px 10px 10px;
    @include breakpoint($bp-desktop) {
      width: 40%;
    }
  }
}

@media screen and (max-width: $bp-mobile - 1) {
  .os-main-panel {
    width: 100%;
    float: left;
  }
  .os-side-panel {
    width: 100%;
    float: left;
    padding: 0;
  }
}

.os-main-panel {
  .schedule-location-wrapper {
    margin-top: 20px;
  }
}

.wcmc_onecol {
  .container {
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint($bp-desktop-only) {
      width: initial;
    }
  }
}

.provider-details {
  margin-bottom: 30px;

  .container {
    padding-left: 20px;
    padding-right: 20px;
    // width: initial;
  }

  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .provider-image {
    display: block;
    margin: 0 auto 20px;
    max-width: 100%;

    @include breakpoint($bp-mobile-only) {
      height: 150px;
    }

    @include breakpoint($bp-tablet) {
      display: inline;
      margin: 0;
    }
  }
  .provider-title {
    font-family: "1898Sans-Bold", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #b31b1b;
    margin-bottom: 5px;
    text-align: center;

    @include breakpoint($bp-tablet) {
      text-align: left;
    }
  }

  .primary-specialty {
    font-size: 14px;
    color: #363636;
    font-weight: bold;
    text-align: center;

    @include breakpoint($bp-tablet) {
      text-align: left;
    }
  }

  .view-profile-btn {
    display: block;
    margin: 0 auto;
    width: 120px;

    @include breakpoint($bp-tablet) {
      margin: 0;
    }
  }

  .appointment-details {
    margin-top: 5px;
    
    .detail-item {
      font-size: 14px;
      color: #363636;
      font-weight: bold;

      span {
        font-weight: normal;
        color: #777;
        width: 180px;
        display: inline-block;
      }
    }
  }
}

.os-widget {
  h2 {
    margin-bottom: 15px;
  }
}

.os-side-panel {
  h4 {
    margin-top: 0;
  }

  div {
    @include breakpoint($bp-desktop-large) {
      padding-right: 20px;
    }
  }
}