/* Weill Cornell Physicians Styles
----------------------------------------------------------------------------------*/
$bp-mobile-only: 0, 600px;
$bp-mobile: 600px;
$bp-tablet: 767px;
$bp-mobile-to-tablet: 600px 770px;
$bp-tablet-only: 767px 990px;
$bp-desktop: 990px;
$bp-desktop-only: 990px, 1200px;
$bp-desktop-large: 1200px;

// Javascript breakpoint helpers.
body:before {
  content: "mobile";
  display: none; /* Prevent from displaying. */
}
@media (min-width: $bp-tablet) {
  body:before {
    content: "tablet";
  }
}
@media (min-width: $bp-desktop) {
  body:before {
    content: "desktop";
  }
}
@media (min-width: $bp-desktop-large) {
  body:before {
    content: "desktop-large";
  }
}

.alert {
  display: none;
}

$wcmc-base: "../../../../../profiles/wcmc/themes/wcmc_base/";

$cornell-sans-bold: "1898Sans-Bold", sans-serif;
$cornell-sans-regular: "1898Sans-Regular", sans-serif;
$cornell-sans-italic: "1898Sans-Italic", sans-serif;

@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_clearfix";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_variables";

$cornell-red: #b31b1b;
$cornell-dark-orange: #cf4520;
$cornell-bright-orange: #e7751d;
$cornell-white: #fff;
$cornell-light-gray: #ddd;
$cornell-light-text: #666;
$cornell-dark-gray: #555;
//$cornell-black: #000000;

$primary-color: $cornell-red;
$secondary-color: $cornell-red;
$tertiary-color: #727d84;
$quadrary-color: #d8d2c9; //Horizontal rules, borders. Section separators
$base-color: #fff; //Background of page mostly
$text-color: #2f3236; // #4a4c52; // #333539;
$link-color: #3787b0; // #0068ac; //Duh
$button-color: #0093d0; //As stated, unless overridden by other styles
//$social-button-color: #89cce2; //Obvious

//If once you've changed the above colors things still don't look right, customize these values
$heading-colors: $primary-color;
$desktop-site-name-color: $primary-color;
$mobile-site-name-color: $tertiary-color;
$secondary-content-header-color: #00355f; //Heading in sidebars
$header-borders-color: #606a70;
$third-level-nav-background-color: $base-color;
$third-level-nav-inactive-color: $tertiary-color;
$body-nav-background-color: $tertiary-color;
$primary-nav-background-color: $secondary-color; //Background of main nav bar
$secondary-nav-highlight-color: $tertiary-color; //Active primary/secondary nav
$mobile-wcmc-text: #a5aeb4; //WCMC text at top of page in mobile
$light-gray: #ccc;

.wcm-site-logo {
  img {
    height: 100px;
    padding: 15px 0;
  }
}

// Buttons
.btn-primary {
  background-color: $wcm-dark-orange;
  border-color: $wcm-dark-orange;

  &:hover {
    background-color: $wcm-red;
    border-color: $wcm-red;
  }
}

.btn-secondary {
  background-color: $wcm-dark-orange;
  border-color: $wcm-dark-orange;
  color: #fff;
  height: 20px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 17px;
  padding: 0px 15px;
}

.btn-primary {
  $btn-primary-bg: #000;
}

.btn {
  &:hover,
  &:focus,
  &:active {
    background-color: $wcm-red;
    border-color: $wcm-red;
  }
}

.btn-primary-rect {
  @include border-radius(5px);
  background: $wcm-red;
  border: 1px solid $wcm-red;
  font-size: 14px;
  padding: 7px 40px 7px 40px;
  height: 40px;
  color: #fff;

  &:hover {
    background: $wcm-dark-orange;
    border: 1px solid $wcm-dark-orange;
    @include transition(all 0s 0s);
  }
}

.small-grey {
  border-color: #505d63;
  border-width: 2px;
  padding: 1px 20px;
  color: #505d63;
  font-size: 14px;

  &:hover {
    background-color: #505d63;
    border-color: #505d63;
  }
}

.read-more,
.read-less {
  font-size: 14px;
}

.more-link {
  margin-left: 5px;
}

.read-less {
  display: inline-block;
  margin-bottom: 15px;
}

ul.move-it-in {
  margin-left: 20px;
}

@mixin icon-after($character, $color: $wcm-dark-orange) {
  &:after {
    color: $color;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    padding-left: 10px;
    text-align: center;

    font-variant: normal;
    text-transform: none;

    line-height: 1em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: $character;
  }

  &:hover,
  &:active {
    &:after {
      text-decoration: none;
    }
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// To add external link icon on demand
.external-link-icon:after {
  content: " \e811";
  text-decoration: none;
  font-family: "fontello";
}

// Call to Action Icons
.cta-icon {
  color: $cornell-dark-orange;
  fill: $cornell-bright-orange;
}

.cta-icon-youtube,
.cta-icon-facebook,
.cta-icon-twitter,
.cta-icon-linkedin,
.cta-icon-instagram,
.cta-icon-vimeo,
.cta-icon-google-plus,
.cta-icon-flickr {
  fill: #fff;
}

.svg-icon {
  width: 45px;
  height: 45px;
  float: left;
  margin-right: 15px;
}

.form-error {
  color: #cc0000;
  margin-bottom: 15px;
  display: none;
}

.form-field-error {
  border: 2px solid #cc0000;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus,
a.skip-main:active {
  color: $cornell-dark-orange;
  background-color: #fff;
  left: auto;
  top: auto;
  width: 25%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  // border-radius: 25px;
  // border:4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

// Select2 Modifications
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 45px;
}

/* =Typography
----------------------------------------------------------------------------------*/
h1 strong {
  color: #992800;
  font-weight: 600;
  font-style: italic;
}

p {
  @include font-size(1.4, 2.6);
}

.pane-node-body {
  ul,
  ol {
    padding: 0 0 0 15px;
  }

  li {
    padding: 0 0 10px;
  }
}

dt {
  font-weight: bold;
}

#main {
  max-width: 960px;
  padding: 0 10px;

  @include breakpoint($bp-desktop-large) {
    max-width: 1180px;
  }

  .search-results ul,
  .node-search-result ul {
    @include font-size(1.3);
  }

  ul,
  ol {
    @include font-size(1.4, 2.6);
  }

  &:focus {
    outline: none;
  }
}

// Temporary adjustment for width of WCMC One Col layout.
.wcmc_onecol {
  .container {
    padding: 0;

    @include breakpoint($bp-desktop-only) {
      width: 940px;
    }
  }
}

// *** 2020 Header / Nav Update ***
// .wrap {
// max-width: 960px;
// padding: 0 10px;
// }

.hr {
  width: 80%;
  height: 2.5em;
  margin: 0 auto 30px;
  border-bottom: 1px solid #e2e2d5;
  float: none;
  clear: both;
}

blockquote {
  @include box-shadow(0, 1px, 4px, rgba(0, 0, 0, 0.2));
  background: #fff;
}

.wcmc-banner--local {
  position: relative;
  z-index: 999;
}

.wcmc-banner__indicator {
  line-height: 1.5;
  margin-right: 1em;
}

.wcmc-banner__message {
  @include breakpoint($bp2) {
    line-height: 34px;
  }
}

#branding a {
  display: block;
  width: 220px;
  height: 77px;
  padding-top: 12px;
  text-indent: -9999px;
  background: url(../images/WCM-logo.png) no-repeat center;
  background-size: 215px;

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.site-title {
  background: $wcm-dark-orange;
  text-align: center;
  color: #fff;
  padding-bottom: 0;
  margin-top: 0;
  line-height: 1.5;
  font-size: 18px;
  display: none;

  @include breakpoint($bp1) {
    font-size: 46px;
    display: block;
  }

  font-family: $cornell-sans-regular;
  position: relative;
  @include clearfix();
  @include transition(all 0.25s 0s);

  &:hover {
    background: $cornell-red;

    a span {
      opacity: 1;
    }
  }

  &.mobile-version {
    display: block;
    text-align: left;

    a {
      padding-bottom: 2px;
    }

    @include breakpoint($bp1) {
      display: none;
    }
  }

  a {
    position: relative;
    max-width: 960px;
    padding: 0.25em 10px 0;
    margin: 0 auto;
    display: block;
    color: #fff;
    border-bottom: none;

    span {
      float: left;
      font-size: 3rem;
      color: #fff;
      position: absolute;
      left: 10px;
      top: 0;
      height: 100%;
      padding: 0em 1em;
      line-height: 3.2;
      background: $wcm-dark-orange;
      display: none;
      opacity: 0;
      @include transition(all 0.25s 0s);
      @include breakpoint($bp1) {
        display: block;
      }

      &:before {
        color: $cornell-red;
      }
    }

    &:hover {
      text-decoration: none;
      border-bottom: none;
    }

    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}

.header-nav-wrapper {
  float: right;

  .primary-action-icons-wrapper {
    display: none;
    padding-top: 10px;
    margin-left: 20px;

    @include breakpoint($bp1) {
      display: block;
    }

    .primary-action-icon {
      &:first-child {
        margin-right: 10px;
        padding-left: 20px;
        border-left: 1px solid #ddd;

        &:focus {
          outline: 5px auto -webkit-focus-ring-color;
        }
      }
    }
  }
}

// Primary action icons
.primary-action-icons-wrapper {
  float: left;

  .primary-action-icon {
    text-align: center;
    display: inline-block;
  }

  .cta-icon {
    width: 35px;
    height: 35px;
    display: block;
    margin: 0 auto 5px;
  }

  a span {
    font-size: 12px;
  }
}

// Primary action icons (new theme - will be able to remove above after)
.top-bar-primary-action-items {
  float: left;
  margin: 0;
  display: none;

  @include breakpoint($bp-desktop) {
    display: block;
  }

  .primary-action-icon {
    display: block;
    float: left;
    padding: 11px 10px;

    &:hover {
      background: $wcm-red;
    }

    &:first-of-type {
      border-right: 1px solid #e7751d;
    }

    @media screen and (min-width: 1050px) {
      padding: 11px 20px;
    }
  }

  .cta-icon {
    width: 30px;
    height: 30px;
    margin: 10px 10px 3px 0;
    color: #fff;
    fill: #fff;
  }

  a span {
    font-size: 12px;
    color: #fff;
    position: relative;
    top: -12px;

    @media screen and (min-width: 1050px) {
      font-size: 15px;
    }
  }
}

// Primary action icons in slide nav
.slide-nav-primary-action-items {
  @include breakpoint($bp-desktop) {
    display: none;
  }

  .primary-action-icon {
    display: block;
    float: left;
    padding: 35px 0;
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #868686;

    &:first-of-type {
      border-right: 1px solid #868686;
    }

    &:focus,
    &:hover {
      text-decoration: none;
      background: #555;
    }
  }

  .cta-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    color: #fff;
    fill: #fff;
  }

  a span {
    font-size: 12px;
    color: #fff;
    display: block;
    margin-top: 2px;
  }
}

/* =Navigation
----------------------------------------------------------------------------------*/
.expand-menu {
  font-family: "Open Sans", sans-serif;
  line-height: inherit;
  color: #fff !important;
}

.menu-button {
  color: $cornell-dark-gray;
  &.is-expanded {
    // background: $primary-nav-background-color;
    // color: #fff;
    color: $primary-nav-background-color;
  }
}

.fap-links {
  @include clearfix();
  background-color: #fff;
  display: block;

  @include breakpoint($bp1) {
    display: none;
  }

  .primary-action-icons-wrapper {
    width: 100%;

    .primary-action-icon {
      float: left;
      width: 100%;

      &:first-child {
        border-right: 1px solid $wcm-red;
      }
    }

    .cta-icon {
      margin: 12px auto 0;
    }

    a span {
      font-size: 16px;
    }
  }
}

#top-nav {
  max-width: 960px;
  margin: 0 15px 15px;
  padding: 1rem;
  @include box-sizing();

  background-color: $primary-nav-background-color;

  @include breakpoint($bp1) {
    background-color: $base-color;
    padding: 0 10px;
    margin: 0 auto;
  }

  @include breakpoint($bp-desktop-large) {
    max-width: 1180px;
  }
}

#primary-nav {
  background-color: #fff;
  border-color: $light-gray; //$secondary-nav-highlight-color;
  border-bottom: none;
  line-height: 2.5;
}

#primary-nav .active-trail {
  background: #fff; // $cornell-red; //$secondary-nav-highlight-color;
  &.temp-inactive {
    background: transparent;
    color: $wcm-dark-orange;
  }
}

#primary-nav .menu {
  font-size: 1.4rem;
  margin: 0 auto;
  max-width: 100%;
  border-bottom: 5px solid $wcm-dark-orange;
}

#primary-nav .level-1 {
  a {
    color: $wcm-dark-orange;
    font-weight: 700;

    &:hover {
      border-bottom: 5px solid $cornell-light-gray;
      text-decoration: none;
    }
  }

  &.active-trail {
    &:after {
      @include breakpoint($bp1) {
        border-bottom-color: $wcm-dark-orange;
        border-bottom: 10px solid $wcm-dark-orange;
        bottom: 0;
        left: 45%;
      }
    }
  }

  > .active-trail {
    color: $cornell-light-text;

    &:hover {
      border-bottom: none;
      text-decoration: none;
    }
  }

  &.is-open {
    background-color: #fff;
    &:after {
      border-bottom-color: $light-gray;
      border-top-color: transparent;
    }
  }

  width: 25%; // $primary-nav-width; //Customize me based on content!
  padding: 1em 0.2em;
}

#drawer-nav {
  line-height: 40px;

  ul.menu {
    font-size: inherit;
    margin: 0 auto;

    @include breakpoint($bp1) {
      font-size: 1.6rem;
    }
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: underline;
    }

    @include breakpoint($bp1) {
      color: black;
      &:hover {
        color: $wcm-dark-orange;
      }
    }

    &.active {
      color: #fff;
      @include breakpoint($bp1) {
        color: inherit;
      }
    }
  }

  .level-1 {
    > .external-link:after {
      color: #fff;
    }

    > a:after,
    > .external-link:after {
      @include breakpoint($bp1) {
        color: $wcm-dark-orange;
      }
    }

    font-family: "Open Sans", sans-serif;
    border-color: $cornell-light-gray;

    > .menu {
      border-color: $cornell-light-gray;
      background: #910614;

      @include breakpoint($bp1) {
        background-color: #f7f7f7;
      }
    }
  }

  .level-2 {
    a {
      color: #fff;
    }

    @include breakpoint($bp1) {
      float: left;
      width: 33%;
      a {
        color: inherit;
      }
    }

    @include breakpoint($bp2) {
      width: 23%;
      @include font-size(1.3);
      padding-right: 10px;

      a:hover {
        color: $wcm-dark-orange;
      }
    }
  }

  .level-3.active-trail {
    color: $wcm-dark-orange;
  }

  .level-4 {
    display: none;
  }
}

#active-second-level-nav {
  background: $cornell-white; // $cornell-red;
  line-height: inherit;

  > .menu {
    max-width: none;
    @include breakpoint($bp1) {
      border-top: 5px solid $cornell-light-gray;
      border-bottom: 1px solid $cornell-light-gray;
    }
  }
  @include breakpoint($bp1) {
    .active-trail > a {
      background: none;
      @include font-size(1.3);
      &:after {
        content: "\e800";
        font-size: 15px;
        padding-left: 10px;
        color: #cf4520;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none;
        display: inline-block;
      }
    }
  }
}

#active-third-level-nav {
  max-width: none;

  .level-3 {
    margin: 0 25px 20px;
  }
}

#body-nav {
  background: $body-nav-background-color;
  color: $base-color;
  li:after {
    border-color: #fff transparent transparent transparent;
  }
  a {
    color: $base-color;
    text-decoration: none;
  }
}

#mobile-sub-nav:after {
  border-color: transparent transparent $base-color transparent;
}

#second-level-nav .wcmc-button {
  background-color: $body-nav-background-color;
  transition: background-color 0.25s;
  &:hover {
    color: white;
    background-color: darken($body-nav-background-color, 5%);
    text-decoration: none;
  }
}

// Mobile Section Navigation
.third-level-navigation,
.mobile-section-navigation {
  @include breakpoint($bp1) {
    // display: none;
  }

  margin: 10px 0 25px;
  padding: 0 1em;
  font-size: 12px;
  line-height: 28px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: $cornell-light-text;

  h2,
  h3 {
    font-size: 14px;
    margin-top: 14px;

    &:after {
      content: "+";
      width: 45px;
      display: block;
      position: absolute;
      top: 8px;
      right: 0;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    &.menu-active {
      &:after {
        content: " -";
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  // Hide menu contents on load.
  .content {
    display: none;
  }

  // Hide menu contents on load - menu block.
  > ul {
    display: none;
  }

  ul {
    padding-bottom: 0;
    margin-bottom: 5px;
    line-height: 3.5rem !important;

    li {
      list-style: none;
      border-top: 1px solid #f8f8f8;

      a {
        display: block;
        border-bottom: none;

        &:hover {
          text-decoration: none;
        }
      }
    }

    ul {
      padding-left: 15px;
      margin-bottom: 0;

      li {
        font-size: 12px;
      }
    }
  }
}

.third-level-navigation {
  display: block;
  margin: 0 10px 20px;

  @include breakpoint($bp-desktop) {
    display: none;
  }
}

// Program Menu specific
.node-type-program,
.node-type-program-page {
  #active-third-level-nav {
    display: none;
  }

  .mobile-section-navigation {
    display: block;

    @include breakpoint($bp-desktop) {
      display: none;
    }
  }
}

.node-type-program {
  .mobile-section-navigation {
    margin: 10px 10px 25px;
  }

  .section-navigation {
    > ul.menu {
      width: initial;

      @include breakpoint($bp-desktop) {
        width: 940px;
      }

      @include breakpoint($bp-desktop-large) {
        width: 1160px;
      }
    }
  }
}

// Hide third level navigation on clinical service pages since
// this level is handled by the section navigation.
.node-type-clinical-service {
  #active-third-level-nav {
    display: none;
  }
}

/********************/

#main h1 {
  border-bottom: none;
  padding-top: 10px;
  margin-top: 0;
  @include font-size(2.8, 3.5);
  margin-bottom: 0;
  border-bottom: none;
  padding-top: 10px;
  margin-top: 0;
}

.menu-button {
  @include font-size(2.5);
  @include inline-block;
  position: relative;
  width: auto;
  float: right;
  margin-right: 5%;
  line-height: 1;
  margin-top: -60px;
  padding: 0.25em;
  cursor: pointer;
  background: none;
  border: none;

  @include transition(none);

  @include breakpoint($bp1) {
    display: none;
  }

  &:before {
    font-family: "fontello";
    content: "\e808";
    font-size: 30px;
  }

  &.is-expanded {
    @include transition(none);
    &:before {
      font-size: 35px;
      line-height: 2rem;
      font-family: "fontello";
    }
  }
}

.menu {
  @extend .clearfix;
}

#main-menu {
  max-width: 970px;
  width: 100%;
  display: none;

  @include breakpoint($bp1) {
    display: block;
  }
}

#main-menu-links {
  margin: 0;
  padding: 0;
  clear: both;
}

#main-menu-links li {
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  @include breakpoint($bp-tablet) {
    float: left;
    border-right: 1px solid #fdfaf3;
    border-bottom: none;
  }
  border-bottom: 1px solid #fdfaf3;
  list-style-type: none;
}

#main-menu-links li.last {
  padding: 0;
}

#main-menu-links li a {
  @include font-size(1.4);
  @include box-sizing();
  font-weight: bold;
  text-decoration: none;
  display: block;
  background-color: #aaa;
  color: #fff;
  padding: 9px 0 2px 0;
  text-align: center;
  transition: background 0.3s;
}

#main-menu-links li a:hover {
  background-color: $cornell-light-text;
}

#main-menu-links li a.active,
#main-menu-links li a.active-trail {
  background: #992800;
}

.menu-1112 a {
  @include breakpoint($bp-tablet) {
    width: 106.7px;
  }
}

.menu-1195 a {
  @include breakpoint($bp-tablet) {
    width: 155.2px;
  }
}

.menu-1138 a {
  @include breakpoint($bp-tablet) {
    width: 164.9px;
  }
}

.menu-1139 a {
  @include breakpoint($bp-tablet) {
    width: 145.5px;
  }
}

.menu-1140 a {
  @include breakpoint($bp-tablet) {
    width: 184.3px;
  }
}

.menu-1141 a {
  @include breakpoint($bp-tablet) {
    width: 207px;
  }
  background: url(../images/WCC_Logo_NavBar.png) #aea89d no-repeat center;
  background-size: contain;
  background-size: auto;
  height: auto;
  text-indent: -999em;
}

.menu-1141 a:hover {
  background: url(../images/WCC_Logo_NavBar.png) #c1beb7 no-repeat center;
}

/*.menu-1112 a {width: 106.7px;}
.menu-1195 a {width: 155.2px;}
.menu-1138 a {width: 164.9px;}
.menu-1139 a {width: 145.5px;}
.menu-1140 a {width: 184.3px;}
.menu-1141 a {
      width: 207.0px;
      background: url(../images/WCC_Logo_NavBar.png) #aea89d no-repeat center;
      background-size: contain;
      background-size: auto;
      height: auto;
      text-indent: -999em;
}

.menu-1141 a:hover {
  background: url(../images/WCC_Logo_NavBar.png) #c1beb7 no-repeat center;
}*/
/* =Secondary Navigation
----------------------------------------------------------------------------------*/

#sidebar-first .menu {
  @include font-size(1.3, 1.3);
  margin: 0 0 20px;
  padding: 0;
}

#sidebar-first .menu li {
  float: left;
  padding: 0 0 8px 0;
  margin: 0 0 8px;
  list-style: none;
  list-style-type: none;
  list-style-image: none;
}

#sidebar-first .menu li.first {
  border-bottom: 1px dotted #aea89d;
  padding: 0 0 10px;
  margin: 0 0 10px;
}

#sidebar-first .menu li a {
  color: #607890;
  border-left: 4px solid #fdfaf3;
  padding-left: 7px;
}

#sidebar-first .menu li.first a {
  border-left: 4px solid #f6f0e1;
}

#sidebar-first .menu li a:hover,
#sidebar-first .menu li a.active,
#sidebar-first .menu li a.active-trail {
  border-left: 4px solid #e5d6ba;
  color: #544842;
}

#sidebar-first .menu li a {
  float: left;
  width: 144px;
}

// Fix for page width on Drupal form generated pages

.page-schedule-an-appointment,
.page-request-an-appointment {
  .site-main {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    @include breakpoint($bp-desktop) {
      width: 960px;
      padding: 0;
    }
    @include breakpoint($bp-desktop-large) {
      width: 1160px;
      padding: 0;
    }
  }

  .alert {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* Forms
----------------------------------------------------------------------------------*/

.form-base {
  margin: 40px 10px;

  .panel-heading {
    background: none;
    border: none;
    padding-top: 15px;
    padding-bottom: 0;

    .panel-title {
      font-family: $cornell-sans-bold;
      color: $wcm-red;
    }
  }

  fieldset {
    width: 100%;
  }

  .captcha {
    margin-bottom: 30px;
  }

  .form-control {
    max-width: 100%;
  }

  @include breakpoint($bp-tablet) {
    .form-type-textfield,
    .form-type-password {
      width: 47.5%;
      float: left;
      margin-right: 20px;
    }
  }

  hr {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.request-appointment-form {
  margin-top: 15px;

  .breadcrumb {
    padding: 0;
  }

  h2 {
    margin-bottom: 20px;
  }

  @include breakpoint($bp-tablet) {
    .form-item-first-name,
    .form-item-middle-name,
    .form-item-last-name {
      width: 31%;
    }

    .form-item-date-of-birth {
      width: 31%;
    }

    .form-item-address-line-1 {
      clear: both;
    }

    .form-item-state,
    .form-item-zip-code {
      width: 22.5%;
    }
  }
}

.location-wrapper {
  border-radius: 4px;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  margin: 0 2px 25px;
  padding: 20px 20px 16px 16px;

  .selected-location-title {
    color: $wcm-red;
    font-family: $cornell-sans-bold;
    font-size: 17px;
    padding: 0 10px 15px 5px;
  }

  .cta-icon {
    width: 25px;
    height: 25px;
  }

  .location-snippet {
    position: relative;
    display: inline-block;
    top: -6px;
    font-size: 14px;
    line-height: 18px;
    margin-left: 7px;
  }
}

.simple-details-wrapper {
  border-radius: 4px;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  margin: 20px 0 25px;
  padding: 0 20px 10px 20px;

  h3 {
    margin-bottom: 10px;
  }

  .detail-entry {
    margin-bottom: 15px;

    .cta-icon {
      width: 35px;
      height: 35px;
      float: left;
    }

    .detail-entry-content {
      font-size: 14px;
      margin-top: 6px;

      span {
        font-weight: bold;
      }

      .reference-number {
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
}

.in-person-visit-wrapper,
.video-concierge-wrapper {
  .simple-details-wrapper {
    padding: 15px 20px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 22px;

    .date-time {
      margin-top: 15px;
    }
  }

  fieldset {
    width: 100%;
    border: none;
    padding: 0;
  }

  label {
    margin: 15px 0 10px;
  }

  span {
    font-weight: bold;
  }

  ol {
    padding-left: 10px;
  }

  li {
    padding: 5px;
  }

  .new-user ol,
  ul {
    padding-left: 20px;
  }

  .register-button-wrapper {
    margin-top: 15px;

    .btn-secondary {
      -webkit-border-radius: 5px;
      padding: 10px 40px 5px 40px;
      height: 40px;
      font-size: 14px;
    }
  }
}

// WCMC Flexi A Fullwidth Reset
.panel-flexi-a-fullwidth {
  #main {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  #main-content {
    padding: 0;
  }

  .inner-wrapper {
    margin: 0 -20px;
  }
}

.promotion-tile-wrapper {
  float: left;
  color: #fff;
  border: none;
  position: relative;
  width: 100%;
  margin: 20px 0;
  height: 273px;
  overflow: hidden;

  @include breakpoint($bp-tablet) {
    width: 66.6%;
    margin-left: 20px;
    margin-right: -20px;
    margin: 0 -20px 0 20px;
  }

  img {
    @include transition(all 0.25s);
    width: 100%;
    margin-bottom: 0 !important;
  }

  &:hover {
    img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  a {
    border-bottom: none;
  }

  .background-wrapper {
    background-color: $wcm-dark-orange;
  }

  .background-image img {
    mix-blend-mode: multiply;
    transition: all 0.25s;
  }

  .promotion-tile-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    a {
      position: absolute;
      top: 0;
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      text-align: center;
      padding: 0 20px;
    }
  }

  .title {
    font-size: 28px;
    line-height: 62px;
    font-family: $cornell-sans-bold;

    @include breakpoint($bp-tablet) {
      font-size: 42px;
    }
  }

  .description {
    font-size: 14px;
    line-height: 22px;
    margin: 0 auto 20px;

    @include breakpoint($bp-tablet) {
      font-size: 16px;
      width: 70%;
    }
  }

  &:hover {
    color: #fff;
    border-bottom: none;

    .circle-arrow {
      // removing because it looks kinda weird with the zoom as well
      // margin-right: -10px;
      // @include transition(all .2s 0s);
    }
  }
}

.circle-arrow {
  @include icon-after("\e802", #fff);
  display: inline-block;
  width: 55px;
  height: 55px;
  @include border-radius(30px);
  border: 1px solid #fff;

  &:after {
    font-size: 24px;
    padding-top: 10px;
    line-height: 1.5em;
    padding-left: 8px;
  }
}

.arrow-up {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -5px;
}

// Legacy - 2019-03-01 - to be removed soon
.fap-search-formz {
  background: $wcm-dark-orange;
  color: #fff;
  text-align: center;
  padding: 30px;
  clear: both;
  margin-bottom: 30px;

  form {
    .submit-button {
      border: none;
      padding: 6px 50px;

      @include breakpoint($bp-desktop) {
        width: 220px;
      }

      &:hover {
        background: #fff;
        color: $wcm-dark-orange;
      }
    }
  }

  h3 {
    color: #fff;
    font-size: 32px;
    line-height: 44px;
    font-family: $cornell-sans-regular;
    margin: 0 0 10px;
  }

  label {
    font-weight: normal;
    display: inline;
    margin-left: 8px;
  }

  .fap-form {
    margin: 0 auto;

    @include breakpoint($bp-desktop) {
      width: 65%;
    }
  }

  .form-text {
    border: none;
    height: 48px;
    max-width: none;
    width: 100%;
  }

  .search-term {
    .form-group {
      position: relative;
    }
  }

  select {
    max-width: 100%;
  }

  .select2-selection__rendered {
    font-size: 15px;
    text-align: left;
    color: $cornell-light-text;
  }

  .insurance {
    @include breakpoint($bp-desktop) {
      padding-right: 8px;
    }
  }

  .location {
    @include breakpoint($bp-desktop) {
      padding-left: 8px;
    }
  }

  .accepting-new-patients {
    text-align: left;
  }

  .btn-primary:hover {
    background-color: #b53a21;
    border-color: #b53a21;
  }

  .btn-primary[disabled] {
    background-color: #e7751d;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #b73b21;
    border-color: #b73b21;
  }
}
// end legacy

// Autosuggest OLD
.autosuggest-wrapper {
  position: absolute;
  z-index: 10;
  margin: 0;
  width: 100%;
  color: black;
  text-align: left;

  .list-group-item {
    border-bottom: 1px solid #eee;
  }

  .list-group-item.heading {
    font-size: 16px;
    font-weight: bold;
    color: #444;
  }

  .list-group-item.list-item {
    padding-left: 30px;

    &:hover {
      background: #f3f3f3;
      color: $wcm-dark-orange;
      cursor: pointer;
    }
  }
}

// Provider Profiles Faceted
#main .block-facetapi {
  clear: both;
  padding-bottom: 0;

  h2 {
    font-size: 16px;
  }

  .content {
    position: relative;
    border: none;
  }
}

.view-provider-profiles-faceted {
  clear: both;
}

// Date Scrubber

.timegrid-datescrubber-container {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  display: inline-block;
  //width: 100%;
  z-index: 100;
  background: white;
  padding: 7px 0;
  float: right;

  @include breakpoint($bp-mobile-only) {
    width: 100%;
  }
  @include breakpoint($bp-mobile-to-tablet) {
    width: 100%;
  }
  @include breakpoint($bp-tablet) {
    padding: 20px 0px;
  }
}

.timegrid-datescrubber-inner-container {
  // margin: 0 auto;
}

.timegrid-datescrubber-wrapper {
  text-align: center;

  @include breakpoint($bp-tablet) {
    float: right;
  }
}

.date-picker-container {
  float: left;

  @include breakpoint($bp-mobile-only) {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
  @include breakpoint($bp-tablet-only) {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
}

.date-scrubber-container {
  margin: 0 auto;
  display: inline-block;
  text-align: center;

  @include breakpoint($bp-mobile-only) {
    width: 100%;
  }

  @include breakpoint($bp-tablet) {
    float: left;
    text-align: left;
  }

  @include breakpoint($bp-tablet-only) {
    width: 320px;
    // width: 100%;
  }
}

.date-picker-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-top: 5px;

  @include breakpoint($bp-mobile-only) {
    width: 32px;
    height: 32px;
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.date-scrubber-wrapper {
  background: #f7f7f7;
  display: inline-flex;
  padding: 10px 10px;
  width: 100%;
  @include border-radius(5px);

  @include breakpoint($bp-desktop) {
    width: 318px;
  }

  @include breakpoint($bp-desktop-large) {
    width: 390px;
  }

  .date-scrubber-dates {
    display: flex;
    flex-direction: row;
    width: 100%;
    // flex: 1;

    .date-scrub-item {
      &:first-child {
        // border-left: 1px solid #e3e3e3;
        // padding-left: 27px;

        @include breakpoint($bp-mobile-only) {
          // padding-left: 17px;
        }
      }

      &:last-child {
        // margin-right: 0;
      }

      div {
        text-align: center;
      }
    }
  }

  .date-scrub-item {
    float: left;
    // border-right: 1px solid #e3e3e3;
    margin: auto;
    // margin-right: 32px;
    // padding-right: 32px;

    @include breakpoint($bp-mobile-only) {
      // margin-right: 17px;
      // padding-right: 17px;
    }

    &.date-scrub-control a {
      border-bottom: none;
      display: block;
      width: 30px;
      height: 30px;

      &:hover {
        &:after {
          color: $wcm-red;
        }
      }
    }

    &.date-scrub-left {
      padding-right: 0;
      margin-top: 9px;
      margin-right: 0;
      border: none;

      a {
        @include breakpoint($bp-mobile-only) {
          width: 15px;
        }
      }
    }

    &.date-scrub-right {
      border-right: none;
      margin-right: 0;
      margin-top: 9px;
      padding-right: 0px;

      &.date-scrub-control a {
        padding-left: 10px;
      }
    }

    a:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 100%;
      padding-left: 5px;
      line-height: 24px;
      color: #cf4520;
    }

    &.date-scrub-left {
      a:after {
        content: " \e810";
      }
    }

    span {
      display: none;
    }
  }
}

.timeslots-loading-spinner {
  margin-top: 20px;
}

// Standalone Schedules (NEW)
.scheduling-container {
  margin: auto;
  float: none;
  padding: 0px 20px 20px 20px;
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  @include border-radius(5px);

  .date-picker-icon {
    margin: 0 0 12px 0;
  }

  .date-scrubber-wrapper,
  .timegrid-datescrubber-container,
  .date-scrubber-container,
  .timegrid-datescrubber-wrapper,
  .date-picker-container {
    width: 100%;
    float: none;
  }
}

.pane-wcmc-react-scheduling-timeslots {
  min-height: 430px;

  h1 {
    text-align: center;
  }
}

// React Modals
.wcm-react-modal {
  .modal-contents-wrapper {
    padding: 15px;
    text-align: center;

    @include breakpoint($bp-tablet) {
      padding: 20px;
    }
  }

  .modal-heading {
    padding: 0;
    margin-bottom: 20px;
  }

  h2 {
    font-family: $cornell-sans-bold;
    color: $wcm-red;
    font-size: 18px;
    font-weight: normal;
  }
}

.visit-type-modal {
  .modal-close {
    top: 5px;
    right: 5px;
  }

  .visit-type-item {
    margin-bottom: 20px;
  }

  .modal-container {
    height: auto;
    padding: 20px;

    @include breakpoint($bp-mobile-only) {
      padding: 10px;
    }

    @include breakpoint($bp2) {
      padding: 30px;
    }
  }
}

.provider-scheduling-modal {
  .modal-contents-wrapper {
    min-height: 380px;
  }

  > div:nth-child(2) > div {
    @include breakpoint($bp-mobile-only) {
      margin: 15px;
    }
  }

  .date-picker-wrapper {
    width: 100%;
    display: inline-block;
    text-align: center;

    @include breakpoint($bp-tablet) {
      padding-right: 50px;
      text-align: left;
    }
  }

  .cta-container {
    margin: 15px 0 0;
  }

  .timeslots-grid-container {
    width: 92%;

    @include breakpoint($bp-tablet) {
      width: 62%;
    }
  }
}

// Schedule Appointment Form
.schedule-appointment-form {
  .patient-identification,
  .patient-information,
  .insurance-information,
  .back-button,
  .captcha-and-submit,
  .proxy-information {
    display: none;
  }

  .insurance-list,
  .relationship-list {
    width: 520px;
    max-width: initial;

    @include breakpoint($bp-mobile-only) {
      width: 100% !important;
    }
  }
}

.form-details-wrapper,
.scheduling-method,
.captcha-and-submit {
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;

  > .panel-body {
    padding: 0;
  }
}

.method-text {
  margin-top: 20px;
  padding: 0 20px;

  @include breakpoint($bp-desktop) {
    padding: 0;
  }

  .method-options {
    text-align: center;
  }

  .guest-option {
    @include breakpoint($bp-desktop) {
      padding: 0 60px 0 20px;
    }
  }

  .login-option {
    @include breakpoint($bp-desktop) {
      padding: 0 20px 0 60px;
    }
  }

  .or-col span {
    font-family: $cornell-sans-bold;
    font-size: 18px;
    color: #555;
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
    text-align: left;

    @include breakpoint($bp-desktop) {
      margin-left: -30px;
      margin-top: 50px;
      text-align: center;
    }
  }

  .title-text {
    font-family: $cornell-sans-bold;
    font-size: 17px;
    color: $cornell-red;
    margin-bottom: 15px;
    display: block;
  }
}

.appointment-information {
  margin-top: 20px;
  font-size: 14px;

  .provider-headshot-wrapper {
    width: 125px;

    @include breakpoint($bp-mobile-only) {
      width: 100%;
      text-align: center;
    }
  }

  .provider-headshot {
    margin-bottom: 20px;
    border-radius: 5px;

    @include breakpoint($bp-mobile-only) {
      width: 55%;
    }
  }

  .detail-heading {
    font-family: $cornell-sans-bold;
    color: #333;
    display: inline-block;
  }

  .provider-heading {
    display: inline-block;
    margin-bottom: 18px;

    @include breakpoint($bp-mobile) {
      margin-bottom: 0;
    }
  }

  .location-label {
    display: inline-block;
    margin-top: 18px;

    @include breakpoint($bp-tablet) {
      margin-top: 0;
    }
  }

  .detail-label {
    font-weight: bold;
    font-size: 13px;
    width: 150px;
    display: inline-block;
    margin-bottom: 4px;

    @include breakpoint($bp-mobile) {
      margin-bottom: 15px;
    }
  }

  .reason-for-visit {
    margin-top: 18px;

    @include breakpoint($bp-mobile) {
      margin-top: 0;
    }
  }

  .phone {
    display: inline-block;
    margin-top: 5px;
  }

  .form-item-reason-for-visit {
    display: inline-block;
    margin-top: 18px;

    @include breakpoint($bp-tablet) {
      display: inline;
      margin-top: 0;
    }
  }
}

.appointment-info-title {
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  padding: 15px 0 0 15px;
}

.appointment-info-wrapper {
  padding: 0.35em 0.625em 0;
}

.schedule-appointment-form {
  .btn {
    width: 200px;
  }

  .back-button {
    margin: 0 0 25px 0;
    color: #fff;

    &:before {
      color: #fff !important;
    }
  }

  .form-item-insurance-name {
    display: none;
  }

  .video-visit-inner-wrapper {
    margin-top: 15px;
  }

  .form-item-contact-information {
    width: 100%;
    float: left;
    margin-right: 20px;

    label {
      display: inline-block;
      font-weight: bold;
    }
  }

  .form-item-visit-for {
    display: inline-block;

    &.radio {
      &:last-child {
        margin-left: 37px;
      }
    }

    &.form-group {
      margin-top: 20px;
      width: 100%;
    }
  }
}

.proxy-information {
  .form-item-proxy-contact-information {
    width: 100%;
    float: left;
    margin-right: 20px;

    label {
      font-weight: bold;
    }
  }
}

.alert {
  ul {
    margin-left: 20px;
  }
}

// Hide phone and fax on online appointment form
.page-schedule-an-appointment {
  .phone,
  .direct-scheduling-cta {
    display: none;
  }
}

// Select new time slot
.select-new-time-slot-wrapper {
  display: none;

  .modal-title {
    font-size: 20px;
    line-height: 28px;
    font-family: $cornell-sans-bold;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
  }

  .instruction-text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .provider-name {
    font-weight: bold;
  }

  .button-wrapper {
    text-align: center;
    margin-top: 30px;
  }

  .back-to-search-results {
    margin-top: 20px;
    text-align: center;
  }

  @include breakpoint($bp-tablet) {
    padding: 30px;
  }

  @include breakpoint($bp-desktop-large) {
    width: 980px;
  }

  .google-map-react-wrapper,
  .visit-type-filter-wrapper {
    display: none; // temp solution until we render conditionally
  }

  .timegrid-datescrubber-container {
    background: transparent;
    padding-top: 15px;
    top: 0px !important;
  }

  .date-scrubber-wrapper {
    @include breakpoint($bp-desktop) {
      width: 422px;
    }

    @include breakpoint($bp-desktop-large) {
      width: 520px;
    }
  }

  .timeslots-grid-container {
    @include breakpoint($bp-desktop) {
      width: 85%;
    }
  }

  .cta-container {
    max-height: 183px;
    overflow: scroll;
  }
}

.fancybox-skin {
  background: #fff;
}

.fancybox-overlay {
  z-index: 1000;
}

.fancybox-opened {
  z-index: 1005;
}

.fancybox-close {
  text-decoration: none;
  border-bottom: none;

  &:hover {
    text-decoration: none;
    border-bottom: none;
  }
}

/////////

.ui-widget-content {
  @include font-size(1.3);
  @include border-radius(0px);
  border: 1px solid #c2dcd7;
  background: #d0ebe6;
  color: #444;
}

.ui-widget-content a {
  @include border-radius(0px);
  color: #444;
}

.ui-widget-content a:hover {
  color: #036;
}

/* Interaction states
----------------------------------*/
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555555;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #005d88;
  background: #005d88 url(../images/ui-bg_flat_75_005d88_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #fff;
}

.ui-state-hover a,
.ui-state-hover a:hover {
  color: #fff;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #aaaaaa;
  background: #ffffff url(../images/ui-bg_flat_65_ffffff_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #212121;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121;
  text-decoration: none;
}

.ui-widget :active {
  outline: none;
}

/* =Blocks
----------------------------------------------------------------------------------*/

.best_doctor {
  margin: 15px 0;
  width: 165px;
  height: 344px;
  background: url(../images/dr_banner.png) center top no-repeat;
  float: left;
}

.best_doctor a.top {
  width: 165px;
  height: 234px;
  float: left;
}

div.best_doctor a.bottom {
  width: 165px;
  height: 110px;
  float: left;
}

/* =Sidebars
----------------------------------------------------------*/

#sidebar-first,
#sidebar-second {
  @include font-size(1.4, 2.2);
  //  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 20px;
}

#sidebar-first a,
#sidebar-second a {
  //  display: block;
  //  padding: 0 0 5px;
}

#sidebar-first h2,
#sidebar-second h2 {
  @include font-size(1.8);
  color: #423934;
  //  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  padding: 0 0 10px;
  margin: 0 0 10px;
  border-bottom: 1px dotted #aea89d;
}

#sidebar-first ul li,
#sidebar-second ul li {
  list-style: none;
  line-height: 1.5;
  padding-bottom: 10px;
}

#sidebar-second .panel-pane {
  clear: both;
}

#sidebar-first .related-providers ul li a {
  @include font-size(1.3);
  padding: 0;
}

.jump-to-top {
  width: 200px;
  a {
    font-size: 1.5rem;
    width: 200px;
    border: none;
    color: $cornell-light-text;
    font-weight: 400;

    &:hover {
      text-decoration: none;
      color: $cornell-light-text;
    }

    &:before {
      @include font-size(1.5);
      line-height: inherit;
      content: "\F500";
      font-family: "SSStandard";
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      text-rendering: optimizeLegibility;
      white-space: nowrap;
      display: block;
      color: $cornell-bright-orange;

      //          padding-left: 5px;
    }

    span {
      border-top: 1px solid $cornell-light-gray;
      display: block;
      padding-top: 5px;
      margin-top: 5px;
    }
  }
}

// Weill Cornell Connect (2017-10-09)

.landing-page-connect-logo {
  width: 400px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.wcc-title {
  span {
    font-family: $cornell-sans-regular;
    color: $wcm-bright-orange;
  }
}

.wcc-features {
  margin-top: 20px;

  .cta-icon {
    width: 35px;
    height: 35px;
    float: left;
    margin-right: 15px;
    display: none;

    @include breakpoint($bp-mobile) {
      width: 50px;
      height: 50px;
    }
  }

  .icon-title-wrapper {
    margin-top: 20px;

    h4 {
      color: #423934;
      font-weight: normal;
      font-family: $cornell-sans-bold;
      line-height: 3.7rem;
    }
  }

  .details-wrapper {
    margin-bottom: 10px;
    margin-top: 15px;

    @include breakpoint($bp-mobile) {
      float: left;
    }
  }
}

.wcc-login-widget {
  margin: 10px;
  padding: 30px;
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  text-align: center;
  position: relative;

  .qtip {
    position: absolute;
    top: 7px;
    right: 7px;

    &.tip-top:after {
      top: -25px;
    }

    &.tip-top:before {
      top: -10px;
      right: 10%;
      left: initial;
      transform: translate(10%, calc(-100% - 8px));
    }

    &:before {
      @include breakpoint($bp-tablet) {
        min-width: 360px;
      }
    }
  }

  hr {
    width: 100px;
    border-top: 2px solid #ddd;
    margin: 15px auto 20px;
  }

  .widget-title {
    font-size: 16px;
  }

  h3 {
    margin-top: 0;
  }

  .btn--wcm.external-link:before {
    content: "";
  }

  .label-text {
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 16px;
  }

  .forgot-stuff {
    font-size: 11px;
    text-align: center;
    margin-top: 10px;

    a:first-of-type {
      margin-right: 10px;
    }
  }

  .form-text {
    margin-bottom: 15px;
  }

  .btn {
    width: 95%;
    display: block;
    margin: 0 auto;
    font-size: 13px;
    line-height: 18px;
  }

  .btn-primary {
    padding: 9px 0;
  }

  .new-user {
    margin-top: 20px;

    .btn {
      width: 55%;
    }
  }
}

// Homepage adjustments
.front .wcc-login-widget {
  width: 100%;
  float: left;
  margin: 20px 0;

  @include breakpoint($bp-tablet) {
    width: 33.3%;
    margin: 0 20px 20px -20px;
  }
}

.app-store-badges {
  display: inline-block;

  a {
    float: left;
    width: 45%;
    margin-right: 10px;
    border-bottom: none;

    @include breakpoint($bp-desktop) {
      width: 160px;
      margin-right: 20px;
    }
  }
}

.app-store-badges-footer-wrapper {
  display: inline-block;
  position: relative;

  @include breakpoint($bp-tablet-only) {
    width: 60%;
  }

  @include breakpoint($bp-desktop) {
    float: left;
  }

  @include breakpoint($bp-desktop-large) {
    width: 200px;
  }

  .link-wrapper {
    list-style: none;
    margin: 15px 0 0 0;
  }

  .link-wrapper span,
  .link-wrapper a {
    display: block;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $wcm-med-gray;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .link-wrapper span {
    margin-bottom: 0;
  }
}

// Events simple widget
.events-widget {
  margin: 40px 0;
  padding: 30px;
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  text-align: center;

  hr {
    width: 100px;
    border-top: 2px solid #ddd;
    margin: 15px auto 20px;
  }

  .widget-title {
    font-size: 16px;
  }

  a {
    font-size: 14px;
  }

  .view-calendar {
    .cta-icon {
      width: 25px;
      height: 25px;
      position: relative;
      top: 7px;
      margin-right: 6px;
    }
  }
}

.app-store-badges-footer,
.app-store-badges-callout {
  ul li {
    list-style: none;
  }

  ul {
    margin: 0;
  }

  a {
    float: left;
    width: 45%;
    border-bottom: none;
  }
}

.app-store-badges-footer {
  a {
    margin: 10px 10px 0px 0px;
    @include breakpoint($bp-desktop) {
      width: 140px;
    }
  }
}

.app-store-badges-callout {
  @include breakpoint($bp-desktop) {
    padding: 10px;
  }

  a {
    margin: 20px 10px auto;
    width: 90%;
    @include breakpoint($bp-desktop) {
      width: 180px;
    }
  }

  ul {
    display: flex;
  }
}

.app-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include breakpoint($bp-desktop) {
    flex-direction: row;
  }

  @include breakpoint($bp-mobile-only) {
    text-align: center;
  }
}

.wcc-links {
  a {
    margin-right: 20px;
  }
}

// Notice

.notice-widget {
  background: #f5f7f8;
  padding: 20px 25px 25px 20px;
  position: relative;
  @include border-radius(10px);

  h3 {
    font-size: 17px;
    margin-top: 0;
  }

  .notice-icon {
    position: absolute;
    left: 20px;
    top: 30px;
  }

  .notice-icon:after {
    content: " \e813";
    font-family: "fontello";
    font-size: 45px;
    color: #838484;
  }

  .details {
    padding-left: 60px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .btn {
    margin: 10px auto 0;
    display: block;
    width: 140px;
  }
}

// Contact Us Widget
.contact-us {
  padding: 0 10px;

  h3 {
    font-size: 20px;
    border-bottom: none;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 3px;
  }

  .cta-icon {
    width: 35px;
    height: 35px;
    margin-right: 9px;
    float: left;
    position: relative;
    margin-top: -5px;
  }
}

// Guest Pay Widget
.guest-pay-wrapper {
  margin-top: 30px;
  padding: 0 10px;

  p {
    margin-bottom: 3px;
  }

  .cta-icon {
    width: 35px;
    height: 35px;
    margin-right: 9px;
    float: left;
    position: relative;
    margin-top: -5px;
  }
}

/* =Connect Login
----------------------------------------------------------*/

#defaultColumn {
  //  font-family: Helvetica, Arial, sans-serif;
  background: #eaeaea;
  width: 213px;
  float: left;
  padding: 7px;
  border: 1px solid black;
  position: relative;
}

#defaultColumn h3 {
  @include font-size(1.3);
}

#defaultColumn h3 .WCCLogo {
  background-image: url(../images/WCCLogo.png);
  -webkit-background-size: auto 34px;
  -moz-background-size: auto 34px;
  background-size: auto 34px;
  height: 34px;
  background-repeat: no-repeat;
  padding-top: 5px;
  margin: 0 auto;
}

.lt-ie9 #defaultColumn h3 .WCCLogo {
  background-image: none;
  height: 30px;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='http://webmedia.med.cornell.edu/cmn/weillcornell/images/WCCLogo.png', sizingMethod='scale');
}

#defaultColumn span.login_title {
  float: right;
  margin-right: 2%;
  font-weight: 500;
}

#defaultForm {
  width: 100%;
}

#defaultForm > a:first-child {
  margin: 20px 0 0;
}

#defaultColumn #defaultForm h3 {
  @include font-size(1);
  display: block;
  bottom: 10px;
  text-align: right;
  width: 215px;
  float: right;
  margin-bottom: 5px;
  padding: 0;
  font-weight: normal;
}

#defaultForm a {
  @include font-size(1.3, 1.3);
  display: block;
  text-align: right;
}

#defaultFooter {
  float: left;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  margin: 10px 0 0 0;
  padding: 8px 0 0;
  width: 100%;
}

#defaultFooter h2 {
  font-size: 1em;
  padding: 0;
}

#defaultFooter h2 span {
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 400;
  font-family: sans-serif;
  margin-top: 0;
}

#signupFooter ul {
  list-style: none;
  padding-left: 0;
  margin: 5px 0;
}

#signupFooter ul li {
  @include font-size(1.2);
  line-height: 1.4em;
  height: auto;
}

#defaultColumn .button {
  @include font-size(1.9, 2.3);
  color: #ca3510;
  text-align: center;
  display: block;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #ffffff;
  background-image: -ms-linear-gradient(top, white, #d6d6d6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#d6d6d6));
  background-image: -webkit-linear-gradient(top, white, #d6d6d6);
  background-image: -o-linear-gradient(top, white, #d6d6d6);
  background-image: linear-gradient(top, white, #d6d6d6);
  background-image: -moz-linear-gradient(top, white, #d6d6d6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  text-decoration: none;
  border-color: #dddddd #dddddd #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=0);
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  margin: 4px auto;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

#defaultColumn .button:hover {
  text-decoration: none;
  background-color: #d6d6d6;
  background-position: 0 -30px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}

#defaultColumn .red {
  color: white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ca3510', endColorstr='#920401', GradientType=0);
  background-image: -moz-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -o-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -webkit-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: linear-gradient(0deg, #920401 0%, #ca3510 100%);
  background-color: #920401;
  text-shadow: 0 1px 1px #960000;
}

#defaultColumn .red:hover {
  background-color: #920401;
}

/* Patient Resource Center
----------------------------------------------------------*/

.view-online-resources {
  .views-exposed-form {
    border-bottom: 1px solid #ddd;
    padding-bottom: 25px;
    margin-bottom: 25px;

    .form-select {
      max-width: initial;
      width: 215px;
    }

    .btn-primary {
      background: $wcm-dark-orange;
      border-color: $wcm-dark-orange;
      margin-top: 30px;

      &:hover {
        background: $wcm-bright-orange;
        border-color: $wcm-bright-orange;
      }
    }
  }

  .views-row {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }

  .view-empty {
    margin-bottom: 30px;
  }

  p {
    padding-bottom: 0;
  }

  .resource-link {
    margin-bottom: 15px;
  }

  .resource-document {
    display: inline-block;

    a {
      text-align: center;

      img {
        margin-bottom: 5px;
      }
    }
  }

  .document-icon {
    width: 35px;
  }

  .resource-video {
    .media-vimeo-player {
      width: 100%;
    }
  }
}

.tag-wrapper {
  margin: 10px 0;

  .tag-label {
    font-weight: bold;
    display: inline-block;
  }

  .tags {
    color: #888;
    display: inline-block;
  }
}

.mmprc-localist-pane {
  margin-top: 20px;

  .view-teaser {
    &:last-child {
      border: none;
    }
  }
}

.view-events-button,
.view-news-button {
  text-align: center;
  margin: 20px 0 30px;
}

.view-news-button {
  padding-bottom: 30px;
  border-bottom: 1px solid $wcm-border-gray;
}

// MMPRC Map
.mmprc-map-widget {
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  padding: 15px;
  margin-bottom: 20px;
  position: relative;

  #mmprc-map {
    width: 100%;
    height: 280px;
    margin-bottom: 10px;
  }

  .map-larger-view {
    position: absolute;
    top: 20px;
    background: #fff;
    z-index: 2;
    left: 20px;

    a {
      padding: 0.3em 1em;
      display: block;
      font-size: 12px;
      border-bottom: none;
    }
  }

  h4 {
    margin-top: 5px;
    font-family: $cornell-sans-bold;
  }

  .mmprc-contact-details {
    div {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  .btn-primary {
    margin-top: 15px;
    width: 100%;
  }
}

// PRC Contact Us
.email-button-wrapper {
  margin-bottom: 20px;
}

#main-content .email-button-wrapper a {
  color: #fff;

  &:after {
    content: "";
    display: none;
  }
}

// Back Button
.back-button-wrapper {
  margin-bottom: 20px;
}

.back-button {
  width: 100%;

  &.btn--wcm:before {
    content: "\e810";
    opacity: 1;
    left: 7%;
    color: $wcm-dark-orange;
  }

  &:hover {
    &.btn--wcm:before {
      color: #fff;
    }
  }
}

// Icons
.social-icons-wrapper {
  border-top: 1px solid #eee;
  margin: 10px 0;

  h3 {
    font-size: 18px;
    color: #000;
    font-weight: normal;
    font-family: $cornell-sans-regular;
    text-align: center;
  }

  .social-icons {
    text-align: center;
  }

  .icon {
    display: inline-block;
    text-align: center;
    margin: 5px 15px;

    svg {
      width: 45px;
      height: 45px;
    }

    a {
      color: #000;
      border-bottom: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Form
.form-item {
  margin-bottom: 20px;
}

.webform-confirmation {
  margin: 20px 0;
}

.webform-client-form {
  h3 {
    padding-bottom: 0;
  }

  label {
    font-size: 14px;
  }

  .radio label,
  .checkbox label {
    font-size: 13px;
  }

  .half-n-half {
    .form-type-checkbox,
    .form-type-radio {
      @include breakpoint($bp2) {
        width: 48%;
        display: inline-block;
      }
    }
  }

  .half-n-half-component {
    @include breakpoint($bp2) {
      width: 47%;
      display: inline-block;
    }
  }

  .webform-component-textfield.half-n-half-textfield {
    @include breakpoint($bp2) {
      width: 47%;
      display: inline-block;

      & + .half-n-half-textfield {
        float: right;
        margin-right: 11px;
      }
    }
  }

  .thirds {
    .form-type-checkbox,
    .form-type-radio {
      @include breakpoint($bp2) {
        width: 32%;
        display: inline-block;
      }
    }
  }
}

.asterisk-description {
  color: #666;
  font-size: 14px;

  span {
    color: #b31b1b;
  }
}

.small-allcaps {
  color: $cornell-light-text;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

//Request Additional Resources Form
.resources-form-gender,
.age-group-fieldset,
.send-info-fieldset {
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 0px;

  .panel-heading {
    display: none;
  }

  .panel-body {
    padding: 0px;
  }
}

// Appointment CTA Widget
.appointment-cta-widget {
  text-align: center;
  margin: 20px 0 35px;
  padding: 20px 0;
  background: #f7f7f7;

  h3,
  .small-allcaps {
    color: $cornell-light-text;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  h3 {
    margin: 0 0 15px;
    padding-bottom: 0;
  }

  .small-allcaps {
    margin: 15px 0;
  }

  hr {
    width: 55%;
    margin: 15px auto;
    border-top: 1px solid #e3e3e3;
  }

  .phone-number {
    font-size: 30px;
    line-height: 38px;
    margin: 20px 0;

    a {
      border-bottom: none;
      color: #161616;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .request-appointment {
    margin: 20px 0;

    a {
      font-size: 14px;
    }

    a:hover {
      text-decoration: none;
    }

    a.btn {
      color: $wcm-red;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: #fff;
      }
    }

    span {
      display: block;
    }

    .cta-icon {
      width: 55px;
      height: 55px;
    }
  }

  .request-appointment {
    a.btn.request-appointment-btn:hover {
      color: #fff;
    }
  }
}

.program-cta-widget {
  .request-appointment {
    a {
      color: $wcm-red;

      &:hover,
      &:active,
      &:focus {
        color: $wcm-red;
      }
    }
  }
}

// Clinical Services version
.main-content .clinical-service-cta-widget,
.main-content .clinical-service-fap-widget,
.main-content .clinical-service-scheduling-cta,
.main-content .video-visit-callout-wrapper {
  @include breakpoint($bp1) {
    display: none;
  }
}

.information-column .clinical-service-cta-widget,
.information-column .clinical-service-fap-widget,
.information-column .clinical-service-scheduling-cta,
.information-column .video-visit-callout-wrapper {
  @include breakpoint(0 $bp1) {
    display: none;
  }

  @include breakpoint($bp1) {
    display: block;
  }
}

.clinical-service-cta-widget,
.program-cta-widget {
  .request-appointment .btn {
    width: 80%;

    @include breakpoint($bp1) {
      width: 260px;
    }
  }
}

// Widget
.standard-widget {
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  padding: 15px;
  margin-bottom: 20px;
  position: relative;

  h3 {
    color: $cornell-dark-gray;
    font-family: $cornell-sans-bold;
    margin-top: 0;
    padding-bottom: 4px;
  }
}

// Contact Widget
.clinical-service-phone-widget {
  margin: 20px 0 30px;

  .phone-number {
    font-size: 20px;
    margin-bottom: 5px;

    .cta-icon {
      height: 25px;
      width: 25px;
      position: relative;
      top: 5px;
      margin-right: 7px;
    }

    a {
      color: #000;
      cursor: default;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none !important;
        outline: none;
      }
    }
  }
}

.main-content .clinical-service-phone-widget {
  margin: 10px 0 20px;

  h3 {
    font-size: 17px;
  }

  .phone-number {
    font-size: 18px;

    .cta-icon {
      height: 20px;
      width: 20px;
      top: 4px;
    }
  }

  @include breakpoint($bp1) {
    display: none;
  }
}

.information-column .clinical-service-phone-widget {
  @include breakpoint(0 $bp1) {
    display: none;
  }

  @include breakpoint($bp1) {
    display: block;
  }
}

// Reports

.view-clinical-services-report {
  .form-select {
    max-width: initial;
  }
}

/* Section Navigation / Program Menu
----------------------------------------------------------*/
.section-navigation,
#block-menu-menu-headache-program {
  display: none;

  @include breakpoint($bp-desktop) {
    display: block;
  }

  ul.menu {
    background: $cornell-dark-orange;
    width: 100%;

    li {
      font-weight: bold;
      font-size: 13px;
      float: left;
      list-style: none;
      zoom: 1;
      background: $cornell-dark-orange;
      margin-bottom: 0;

      &:hover {
        background: #b31b1b;
        color: #fff;
        position: relative;
      }

      .active {
        // background: #b31b1b;
      }

      a {
        display: block;
        padding: 5px 26px;
        line-height: 26px;
        color: #fff;
        border-bottom: none;

        @include breakpoint($bp-desktop-large) {
          padding: 5px 44px;
        }

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }

      &:hover > ul {
        visibility: visible;
      }
    }

    ul {
      width: 250px;
      visibility: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      padding-left: 0;
      margin-left: 0;
      padding-bottom: 0;
      z-index: 1;

      li {
        font-weight: normal;
        background: #f7f7f7;
        float: none;
        font-size: 13px;

        a {
          color: $cornell-dark-orange;
          border-top: 1px solid #ddd;
          line-height: 19px;
          padding: 8px 26px;
        }

        &:hover {
          background: #fff;

          a {
            color: $cornell-dark-orange;
          }
        }

        &:first-of-type a {
          border: none;
        }

        ul {
          visibility: visible;

          li {
            font-size: 11px;

            a {
              padding-left: 40px;
            }
          }
        }
      }
    }
  }
}

.section-navigation.pane-menu-block {
  width: 100%;
  text-align: center;

  > ul {
    display: inline-block;
  }

  .menu {
    text-align: left;
  }
}

// this can be removed I believe now...
#block-menu-menu-headache-program {
  width: 100%;
  text-align: center;

  .content {
    display: inline-block;
  }

  .menu {
    text-align: left;
  }
}

// Grid Additions
.fixed-width {
  // width: 960px;
}

// Listings
.standard-view-listing {
  margin-bottom: 30px;

  h3 {
    padding-bottom: 5px;
  }

  .views-row {
    font-size: 14px;
    padding: 8px 10px 8px 0;
    border-bottom: 1px solid #f8f8f8;
  }
}

/* Programs
----------------------------------------------------------*/

// Program homepage.
.node-type-program {
  #main {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  #main-content {
    padding: 0;
  }

  .inner-wrapper {
    margin: 0 -20px;
  }

  .pane-node-field-hero-image {
    .content {
      max-height: 420px;
      overflow: hidden;
    }

    img {
      width: 100%;
    }
  }

  .pane-node-body {
    padding: 0 100px;
  }

  .pane-node-body,
  .call-to-action-wrapper {
    p {
      color: #737373;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }

    @include breakpoint($bp-mobile-only) {
      padding: 0 10px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .pane-node-body {
    margin: 30px 0 10px;
  }

  .call-to-action-wrapper {
    margin: 25px 0;

    @include breakpoint($bp-mobile-only) {
      margin: 10px 0 40px;
    }

    .call-to-action {
      text-align: center;

      .cta-text {
        margin-top: 20px;
      }

      .phone-number {
        color: $wcm-bright-orange;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  // Map
  .program-locations-container .address-location {
    width: 100%;

    @include breakpoint($bp-tablet) {
      width: 50%;
    }

    @include breakpoint($bp-desktop-large) {
      width: 25%;
    }
  }

  .image-callouts-a {
    .container {
      margin: 0;
    }

    .row:first-of-type {
      padding: 20px 0 10px;
    }

    .image-tile-callout {
      height: 220px;
      padding-top: 100px;

      span {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.social-buttons {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  margin: 30px 0;

  .container {
    padding: 25px 120px 20px;
  }

  .small-allcaps {
    padding-top: 15px;
    display: inline-block;
  }

  .callout {
    display: block;
    text-align: center;
    padding: 20px 0;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }
  }

  svg.cta-icon {
    width: 50px;
    height: 50px;
  }
}

// Program Maps
.program-locations-container {
  margin-top: 30px;

  #map-canvas {
    width: 100%;
    height: 340px;
    margin: 0;
    border: none;
  }

  .addresses-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  .address-location {
    width: 100%;
    margin: 0;
    clear: none;
    float: left;

    @include breakpoint($bp-tablet) {
      width: 50%;
    }

    .practice-count {
      float: left;
      width: 60px;
      padding: 2px 0 2px 20px;
      font-size: 2.5rem;
      line-height: 3rem;
      color: $cornell-bright-orange;

      @include breakpoint($bp-desktop-large) {
        width: 13%;
        padding-left: 0;
      }
    }

    .practice-address {
      width: 80%;

      @include breakpoint($bp-desktop-large) {
        width: 87%;
      }
    }

    .practice-name {
      font-size: 14px;
      padding-bottom: 5px;
      display: inline-block;
    }

    .address {
      width: 97%;
      float: left;
    }

    &:hover {
      cursor: pointer;

      .practice-name {
        text-decoration: underline;
      }
    }
  }

  .location-contact-details {
    display: none;
  }
}

.program-title,
#main h1.program-title {
  color: $cornell-light-text;
  font-size: 30px;
  line-height: 36px;
  font-family: $cornell-sans-bold;
  text-align: center;
  padding: 5px 0 30px 0;
  display: inline-block;
  width: 100%;

  a {
    color: $cornell-light-text;
    font-weight: normal;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
      color: $wcm-bright-orange;
      vertical-align: initial;
    }
  }
}

.program-secondary-actions,
.program-secondary-actions-contact {
  border-top: 1px solid $wcm-border-gray;
  margin-top: 30px;
  padding-top: 30px;
}

.program-contact-body-wrapper,
.program-contact-referring-physicians-wrapper {
  width: 50%;
  float: left;
  margin-bottom: 30px;

  @include breakpoint($bp-mobile-only) {
    width: 100%;
  }
}

.program-contact-body-wrapper {
  padding-right: 20px;
}

.program-contact-referring-physicians-wrapper {
  padding-left: 20px;
}

// Landing Page
.node-type-landing-page {
  .brand__nav {
    background-color: $cornell-dark-orange;
    margin: 0;
    padding: 0;

    .navbar-nav > li > a {
      display: inline-block;

      @include breakpoint($bp-mobile-only) {
        line-height: 20px;
        padding: 10px;
        font-size: 1.2rem;
      }
    }
  }

  @include breakpoint($bp-tablet) {
    .file-video-youtube {
      text-align: center;
    }
  }

  .header {
    .section-title-a {
      margin-top: 5px;
      text-align: left;

      @include breakpoint($bp-mobile-only) {
        text-align: center;
      }
    }

    p {
      @include breakpoint($bp-mobile-only) {
        text-align: center;
      }
    }
  }

  .featured-review-background-image {
    margin-bottom: 0px !important;
    padding-bottom: 30px !important;
    overflow: hidden;
    background-image: none !important;
  }

  .read-more-wrapper {
    margin-bottom: 30px;
    justify-content: center;
    display: flex;
  }
}

// Use this class when a link is on a dark background.
.white-link {
  color: #fff;
  border-bottom: 1px dotted #fff;

  &:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
  }
}

.photo-callout {
  background: url(https://weillcornell.org/sites/default/files/styles/panopoly_image_original/public/patient-care.jpg)
    center no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;
  margin: 15px 0 30px 0;

  .inner-content {
    text-align: center;
    padding: 30px 0;
    width: 70%;
    margin: 0 auto;

    @include breakpoint($bp-desktop) {
      padding: 60px 0;
      width: 40%;
    }

    .title {
      margin: 20px 0 10px;
      color: white;
      font-weight: normal;
      font-family: $cornell-sans-bold;
      font-size: 2.4rem;
      line-height: 3rem;

      @include breakpoint($bp-desktop) {
        margin: 30px 0 20px;
        font-size: 3.8rem;
        line-height: 4.6rem;
      }
    }

    .blurb p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: white;

      @include breakpoint($bp-desktop) {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }
}

// Program Landing Page
.custom-logo {
  img {
    @include breakpoint($bp-mobile) {
      height: 80px;
    }
    @media screen and (max-width: 850px) {
      margin-right: 50px;
      display: block;
    }
  }
  position: relative;
  .contact-info {
    display: inline;
    right: 0px;
    margin-right: 20px;
    font-size: 18px;
    color: #b31b1b;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    line-height: 27px;

    a {
      text-decoration: underline;
    }

    @include breakpoint($bp-tablet) {
      position: absolute;
    }

    @media screen and (max-width: 850px) {
      width: 100%;
      float: left;
      display: block;
      padding-top: 15px;
    }
  }
}

.program-landing-page {
  padding-top: 0 !important;

  .brand__nav {
    background-color: $cornell-dark-orange;
    margin: 0;
    padding: 0;

    .navbar-nav > li > a {
      display: inline-block;

      @include breakpoint($bp-mobile-only) {
        line-height: 20px;
      }
    }
  }

  hr.red-divider {
    margin-top: 0;
  }

  .global-links {
    a {
      color: #fff;
      transition: color 0.25s;
      border: none;

      &:hover {
        background: none;
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  header {
    padding: 20px 0 10px 0;
    height: auto;
  }

  @include breakpoint($bp-mobile-only) {
    .header-component {
      background-image: none;
      min-height: 250px;

      .header-panel {
        padding: 35px 0;
      }
    }
  }

  #main {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  #main-content {
    padding: 0;
  }

  .inner-wrapper {
    margin: 0 -20px;
  }

  .video-player-wrapper {
    margin: 40px 0;
    background: #555;
  }

  // Customisations
  .callout {
    @include breakpoint($bp-mobile) {
      min-height: 180px;
    }
  }

  .col-md-3 {
    padding-top: 10px;
    padding-bottom: 10px;

    @include breakpoint($bp-desktop) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .image-tile-callout {
    &.adult-kidney,
    &.living-kidney {
      background-image: url(http://wcmcpops.lndo.site/sites/default/files/styles/panopoly_image_original/public/vascular-biology.jpg?itok=OX0aHXf0);
    }

    &.pediatric-kidney {
      background-image: url(http://wcmcpops.lndo.site/sites/default/files/styles/panopoly_image_original/public/metabolism-and-diabetes.jpg?itok=EEu3Fgrn);
    }

    &.financial-district,
    &.tribeca {
      @include breakpoint($bp-mobile-only) {
        margin-bottom: 20px;
      }
      @include breakpoint($bp-mobile-to-tablet) {
        margin-bottom: 20px;
      }
    }
  }

  .fieldable-panels-pane .field-basic-text-text {
    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
  }
}

.locations-listing {
  h2 {
    padding-bottom: 0;
    margin-top: 20px;

    @include breakpoint($tablet) {
      margin-top: 30px;
    }
  }

  hr {
    margin: 15px 0 0 0;
  }

  .location {
    border-bottom: 1px solid #eee;
    padding: 0 0 10px;
    margin: 10px 0;

    @include breakpoint($tablet) {
      padding: 20px 0;
      margin: 20px 0;
    }

    .practice-photo {
      @include breakpoint($bp-mobile-only) {
        height: 170px;
        overflow: hidden;

        img {
          margin-top: -30px;
        }
      }

      @include breakpoint($tablet) {
        height: 220px;
        overflow: hidden;
      }
    }

    .title {
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-family: $cornell-sans-regular;
      color: $wcm-red;
      padding-top: 5px;

      @include breakpoint($bp-desktop) {
        font-size: 1.8rem;
        line-height: 2.6rem;
        padding-top: 10px;
      }
    }

    .address,
    .phone-number {
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #333;
      padding-bottom: 5px;

      @include breakpoint($bp-desktop) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    .detail-label {
      text-transform: uppercase;
      font-size: 1.2rem;
      color: #666;
      font-weight: bold;
    }

    .view-location-button {
      margin: 10px 0;
    }
  }
}

hr.red-divider {
  border-top: 5px solid $wcm-dark-orange;
}

hr.no-margins {
  margin: 0;
}

.intro-wrapper {
  text-align: center;

  @include breakpoint($bp-desktop) {
    width: 80%;
    margin: 0 auto;
  }
}

.section-title-a {
  text-align: center;
  font-size: 2rem;
  line-height: 2.6rem;
  font-family: $cornell-sans-bold;
  color: $wcm-red;
  margin-top: 35px;
  margin-bottom: 15px;

  @include breakpoint($bp-desktop) {
    font-size: 3rem;
    line-height: 3.6rem;
  }
}

.section-blurb-a {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #555;
  margin-bottom: 30px;

  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @include breakpoint($bp-desktop) {
    font-size: 1.8rem;
    line-height: 2.6rem;

    p {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
}

.section-title-buttons {
  .btn--wcm {
    display: block;
    margin: 10px auto;

    @include breakpoint($bp-desktop) {
      margin-right: 10px;
      display: inline-block;
    }
  }
}

.landing-breadcrumbs {
  font-size: 1.4rem;
}

.callout-tiles {
  .callout-tile {
    text-align: center;
    border-bottom: 5px solid $cornell-red;
    margin-top: 10px;
    margin-bottom: 20px;

    .photo-wrapper {
      overflow: hidden;
      height: 120px;

      @include breakpoint($bp-tablet) {
        height: 100px;
      }

      @include breakpoint($bp-desktop) {
        height: 140px;
      }

      @include breakpoint($bp-desktop-large) {
        height: 180px;
      }
    }

    .callout-label {
      margin-top: 12px;
      margin-bottom: 8px;
      color: $cornell-red;
      font-weight: normal;
      font-family: $cornell-sans-bold;
      font-size: 1.6rem;
      line-height: 2rem;

      @include breakpoint($bp-desktop) {
        margin-top: 16px;
        margin-bottom: 13px;
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }

    .learn-more-button {
      margin-bottom: 20px;
    }
  }
}

.callout-cards {
  .callout-card {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 30px;
    border-bottom: none;
    @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
    // box-shadow: 1px 2px 2px 1px #ddd;

    &:hover {
      border-bottom: none;
    }

    .photo-wrapper {
      overflow: hidden;
      height: 120px;

      @include breakpoint($bp-tablet) {
        height: 100px;
      }

      @include breakpoint($bp-desktop) {
        height: 140px;
      }

      @include breakpoint($bp-desktop-large) {
        height: 160px;
      }
    }

    .callout-label {
      margin-top: 25px;
      margin-bottom: 8px;
      color: $cornell-red;
      font-weight: normal;
      font-family: $cornell-sans-bold;
      font-size: 1.6rem;
      line-height: 2rem;

      @include breakpoint($bp-desktop) {
        margin-top: 25px;
        margin-bottom: 9px;
        font-size: 2rem;
        line-height: 2.6rem;
      }

      &:before {
        content: "\e802" !important;
        font-family: "fontello";
        position: absolute;
        color: #ef8e3c;
        font-size: 16px;
        line-height: 30px;
        height: 50px;
        width: 50px;
        text-align: center;
        display: block;
        left: 45%;
        margin-top: -50px;
        border: 10px solid #fff;
        border-radius: 25px;
        background-color: #fff;
        margin-bottom: 35px;
        padding-left: 2px;
      }
    }

    .learn-more-button {
      margin-bottom: 20px;
    }

    .blurb {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #333;

      @include breakpoint($bp-desktop) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
}

.marketing-callouts {
  @include breakpoint($bp-desktop) {
    width: 80%;
    margin: 30px auto;
  }

  .marketing-callout {
    text-align: center;
    margin: 10px 0;

    @include breakpoint($bp-desktop) {
      margin: 20px 0;
    }

    .cta-icon {
      width: 40px;
      height: 40px;

      @include breakpoint($bp-mobile) {
        width: 50px;
        height: 50px;
      }
    }

    .callout-label {
      margin-top: 10px;
      color: #423934;
      font-weight: normal;
      font-family: $cornell-sans-regular;
      font-size: 1.4rem;
      line-height: 1.8rem;

      @include breakpoint($bp-desktop) {
        margin-top: 20px;
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }
  }
}

.sticky-banner {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1899;
}

// Landing Page Map
.pane-wcmc-landing-page-map {
  @media screen and (min-width: 770px) {
    .program-locations-container .address-location {
      width: 25%;
    }
  }

  @include breakpoint($bp-desktop) {
    .program-locations-container .address-location {
      height: 120px;
    }
  }

  #map-canvas {
    height: 1000px !important;
  }
  .address-location .schedule-btn-wrapper {
    padding-top: 5px;
  }
  .h2 a:after {
    display: none !important;
    content: none;
  }
}

.landing-map {
  padding-top: 20px;
}

// Bar Callouts
.bar-callout__text {
  @media screen and (min-width: 992px) {
    font-size: 15px;
  }
  @media screen and (min-width: 768px) {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    margin: 0;
    font-size: 13px;
  }
  .bar-callout__title {
    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
  }
  .bar-callout__description {
    @media screen and (min-width: 992px) {
      font-size: 18px;
      line-height: 1.6;
    }
  }
}

// Physician Cards
.providers {
  margin-top: 1em;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.provider-images {
  width: 80px;
  float: left;
  margin-right: 15px;
  border-radius: 0;
  @include breakpoint($bp-mobile) {
    display: inline-block;
    width: 144px;
    height: 192px;
    float: none;
    margin-right: 0;
  }
}

.provider {
  margin-bottom: 1em;
  width: 100%;
  padding-left: 1em;
  @include box-sizing();

  @include breakpoint($bp-mobile) {
    margin-bottom: 2em;
    text-align: center;
    padding-left: 0em;
    width: 48%;
    margin-right: 2%;
  }

  @include breakpoint($bp-desktop-large) {
    width: 31%;
    margin-bottom: 10px;
    min-height: 290px;
  }

  .provider-name {
    font-weight: bold;
    margin-top: 5px;

    a {
      color: #000;
      border-bottom: none;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 75%;
        padding-left: 5px;
        line-height: 15px;
        display: inline-block;
        color: $wcm-bright-orange;
      }
    }
  }

  .provider-specialty {
    margin: 0 0 0.5em;
  }
}

.providers-grid-listing {
  .view-content:after {
    clear: both;
    content: "";
    display: block;
  }
}

// This is for the Clinical Service page version of this. This basically
// reverts to the previous float approach. We would need to make further
// changes to the view and template code to make this work with the new
// flexbox approach.
.pane-provider-listing-grid-listing-pane {
  .providers {
    display: block;
  }

  .provider {
    float: left;
  }
}

// Homepage Tiles

.homepage-tiles-wrapper {
  text-align: center;

  @include breakpoint($bp-mobile) {
    padding-top: 20px; // temp when hiding affiliation
    margin-bottom: 30px;
  }
}

.affiliation-wrapper {
  text-align: center;
  display: none; // temp

  .logos-wrapper {
    margin-bottom: 20px;
  }

  span {
    height: 40px;
    display: inline-block;

    &:first-of-type {
      @include breakpoint($bp-mobile) {
        border-right: 1px solid #e7e7e7;
      }
    }
  }

  img {
    &.wcm-logo {
      height: 21px;
      position: relative;

      @include breakpoint($bp-mobile) {
        padding-right: 20px;
        top: 8px;
      }
    }

    &.nyp-logo {
      height: 32px;
      position: relative;

      @include breakpoint($bp-mobile) {
        padding-left: 15px;
        top: 15px;
      }
    }
  }
}

.homepage-tiles {
  width: 80%;

  @include breakpoint($bp-tablet) {
    width: initial;
    display: inline-block;
  }

  .wcm-cta__tile {
    width: 50%;

    @include breakpoint($bp-tablet) {
      width: 160px;
      border: none;
      border-right: 1px solid #ddd;

      &:nth-child(4) {
        border: none;
      }
    }

    @include breakpoint($bp-desktop) {
      width: 200px;
    }
  }
}

//WCM App Promotion
.wcc_connect-app-promotion {
  border-top: 5px solid #e87722;
  margin-top: 10px;

  .connect-form-wrapper {
    width: 100%;

    @include breakpoint($bp-tablet) {
      display: table;
    }

    .connect-info {
      display: table-cell;
      vertical-align: top;

      .homepage-connect-logo {
        width: 400px;
        display: inline-block;
        text-align: center;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 15px;

        @include breakpoint($bp-tablet) {
          margin: 40px 0px 20px 135px;
          font-size: 4rem;
          line-height: 6rem;
        }

        @include breakpoint($bp-tablet-only) {
          margin: 40px 0px 20px 85px;
        }
      }

      .wcc-cornell {
        color: #b31b1b;
        font-size: 3.5rem;
        line-height: 5rem;
        display: inline-block;
        text-align: center;
        margin-top: 10px;
        margin-left: 10px;

        @include breakpoint($bp-tablet) {
          margin: 40px 0px 0px 125px;
          font-size: 4rem;
          line-height: 6rem;
        }

        @include breakpoint($bp-tablet-only) {
          margin: 40px 0px 0px 85px;
        }

        .wcc-connect {
          font-family: "1898Sans-Regular", sans-serif;
          color: #e87722;
          font-size: 3.5rem;
          font-weight: 400;
          display: inline-block;
          @include breakpoint($bp-tablet) {
            font-size: 4rem;
          }
        }
      }

      .wcc-links {
        text-align: center;

        @include breakpoint($bp-tablet) {
          margin: 30px 0px 30px 160px;
          text-align: left;
        }
      }
    }

    ul {
      margin-left: 85px;
      @include breakpoint($bp-tablet) {
        margin-left: 160px;
      }
    }

    .wcc-wrapper {
      margin-top: 20px;

      @include breakpoint($bp-tablet) {
        margin-top: 0;
        display: table-cell;
      }
    }

    .wcc-login-widget {
      @include breakpoint($bp-tablet) {
        margin-right: 30px;
        margin-top: 100px;
      }

      @include breakpoint($bp-desktop) {
        margin-right: 100px;
        margin-top: 100px;
      }

      @include breakpoint($bp-desktop-large) {
        margin-right: 205px;
        margin-top: 100px;
      }
    }
  }

  .download-app-wrapper {
    display: table;

    .iphone-wcm-app {
      display: block;

      @include breakpoint($bp-tablet) {
        padding-left: 125px;
        display: table-cell;
      }

      &:before {
        background-image: url(../images/iphone-wcm-app-3.jpg);
        content: "";
        width: 300px;
        display: block;
        height: 400px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin: auto auto;
        margin-top: 10px;

        @include breakpoint($bp-tablet) {
          width: 350px;
        }
      }
    }

    .download-app-info {
      display: inline-block;
      text-align: center;

      @include breakpoint($bp-tablet) {
        display: table-cell;
        float: right;
        margin: 0px 0px 80px 0px;
      }

      @include breakpoint($bp-desktop-large) {
        margin: 0px 0px 120px 155px;
      }

      @include breakpoint($bp-tablet-only) {
        margin-left: 0px;
      }

      h2 {
        font-size: 3.6rem;
      }

      .app-store-badges {
        @include breakpoint($bp-desktop-only) {
          margin-left: 20px;
        }

        a {
          @include breakpoint($bp-mobile-only) {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

//Connect Promotion
.connect-promotion {
  justify-content: center;
  display: flex;
  flex-direction: column;

  .connect-apps {
    display: block;

    &:before {
      background-image: url(../images/iphone-wcm-app-3.jpg);
      content: "";
      width: 250px;
      display: block;
      height: 350px;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      margin: auto auto;
      margin-top: 20px;
    }
  }

  img {
    padding-top: 20px;
    margin: auto;
    width: 300px;
    display: table-cell;
  }

  h2,
  p {
    text-align: center;
  }

  ul {
    margin: auto;
    width: fit-content;
  }

  .download-app-wrapper {
    margin: auto;
    padding-top: 20px;
  }
}

// Listing Component - Conditions and Treatments
.header-banner,
.image-tile-callout {
  background-color: $wcm-yellow;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  height: 275px;
  padding: 100px 10px 0;
  display: block;
  border-bottom: none;

  @include breakpoint($bp-mobile-only) {
    height: 220px;
    padding-top: 85px;
  }

  h2,
  span {
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    font-family: $cornell-sans-bold;
    display: block;
    line-height: 35px;
  }

  div.cta-text {
    text-align: center;
    margin-top: 30px;

    span {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      line-height: 20px;
      font-weight: normal;
      border-bottom: 1px dotted white;
      display: inline;
    }
  }

  &.red-style {
    background-color: $wcm-red;

    &:hover {
      background-color: lighten($wcm-red, 5%);
    }
  }

  &.orange-style {
    background-color: $wcm-dark-orange;

    &:hover {
      background-color: lighten($wcm-dark-orange, 5%);
    }
  }

  &:hover {
    background-color: lighten($wcm-yellow, 5%);
    text-decoration: none;
    border-bottom: none;

    div.cta-text span {
      border-bottom: 1px solid white;
    }
  }
}

.image-callouts-a {
  .container {
    width: 100%;
  }

  .row:first-of-type {
    @include breakpoint($bp-mobile) {
      padding-bottom: 40px;
    }
  }
}

.pane-wcmc-specialty-callouts {
  display: none;

  h2 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;

    @include breakpoint($bp-mobile) {
      margin-top: 40px;
    }
  }
}

.specialty-callouts {
  margin-top: 0;
  margin-bottom: 0;

  @include breakpoint($bp-mobile) {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  @include breakpoint($bp-desktop) {
    margin-bottom: 40px;
  }

  .image-tile-callout {
    padding-top: 50px;
    height: 185px;
    margin-bottom: 30px;

    @include breakpoint($bp-desktop) {
      margin-bottom: 0;
    }

    span {
      font-size: 26px;
      line-height: 30px;
    }
  }

  .row {
    margin-bottom: 0;

    @include breakpoint($bp-desktop) {
      margin-bottom: 20px;
    }

    &:first-of-type {
      padding-bottom: 0;
    }
  }
}

.listing-component {
  margin-top: 10px;

  .blurb {
    margin: 20px 0 0;
  }

  .item-list ul li {
    margin: 0;
    padding: 10px;
  }

  li {
    color: $wcm-dark-orange;
    border-bottom: 1px solid #ddd;
    list-style-position: inside;
    margin: 0;

    &:last-of-type {
      border: none;
    }

    .views-field-title {
      display: inline-block;
      font-size: 15px;
    }

    a {
      border-bottom: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.conditions-component {
  margin-right: 20px;

  @include breakpoint($bp-desktop-large) {
    margin-right: 25px;
  }

  @include breakpoint($bp-mobile-only) {
    margin-right: 0;
  }
}

.treatments-component {
  margin-left: 20px;

  @include breakpoint($bp-desktop-large) {
    margin-left: 25px;
  }

  @include breakpoint($bp-mobile-only) {
    margin-left: 0;
  }

  .header-banner {
    background-color: $wcm-bright-orange;

    &:hover {
      background-color: lighten($wcm-bright-orange, 5%);
    }
  }
}

// Treatments and Conditions Accordions
.view-treatments-listing,
.view-conditions-listing {
  .sub-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    display: block;
    margin-bottom: 10px;
  }

  .views-field-body {
    .item-list {
      margin-bottom: 30px;
    }
  }

  .field-content {
    ul {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }
}

//Instructions Callouts
.instructions-container {
  height: auto;
  background: #f7f7f7;
  margin-bottom: 20px;

  h2 {
    padding-bottom: 20px;
  }

  .hide-on-mobile {
    @include breakpoint($bp-mobile-only) {
      display: none !important;
    }
  }

  .content-container {
    padding: 20px 100px 20px 100px;
    text-align: center;
    margin: auto !important;
    display: flex;
    flex-direction: column;

    @include breakpoint($bp-desktop-large) {
      max-width: 1180px;
    }

    @include breakpoint($bp-desktop-only) {
      max-width: 980px;
    }

    .step,
    .step-1 {
      margin: auto;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 15px;

      background: white;
      color: $cornell-dark-orange;
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .active ~ li {
      .step {
        background: white;
        color: $cornell-dark-orange;

        &::before {
          background: white;
          color: $cornell-dark-orange;
        }
      }
    }

    li {
      list-style-type: none;

      .step,
      .step-1 {
        background: $cornell-dark-orange;
        color: white;

        &::before {
          background: $cornell-dark-orange;
          color: white;
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 14px;
    }

    p {
      margin-top: 15px;
    }

    .step::before {
      content: "";
      position: absolute;
      top: 23px;
      left: -40%;
      width: 80%;
      height: 0.2em;
      background: white;
      display: none;

      @include breakpoint($bp-desktop-only) {
        width: 78%;
        left: -36%;
      }

      @include breakpoint($bp2) {
        display: inline;
      }
    }
  }

  .step-1::before {
    display: none;
  }
}

// Callouts A
.callouts-a {
  background: #f7f7f7;
  padding: 20px 0;
  margin: 20px 0;

  .col-1 {
    border-right: 2px solid #e4e3e4;
  }

  .column {
    @include breakpoint($bp-mobile) {
      border-right: 2px solid #e4e3e4;
    }

    &:last-of-type {
      border: none;
    }
  }

  a.callout {
    display: block;
    text-align: center;
    padding: 20px 0;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }
  }

  svg.cta-icon {
    width: 65px;
    height: 65px;
  }

  span {
    display: block;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;

    @include breakpoint($bp-mobile) {
      font-size: 24px;
      line-height: 30px;
    }

    &:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
      color: $wcm-bright-orange;
    }
  }

  &.style-b {
    background: #fff;

    span {
      font-size: 18px;
      line-height: 24px;

      @include breakpoint($bp-mobile) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}

// Blurb A
.blurb-a {
  .internal-wrapper {
    text-align: center;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 15px 0;
    margin: 10px 0;

    @include breakpoint($bp-mobile) {
      padding: 35px 100px;
      margin: 20px 0;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: #555;

      @include breakpoint($bp-mobile) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
}

// Simple Callout
.simple-callout {
  margin: 20px 0;

  @include breakpoint($bp-mobile-only) {
    margin: 20px 30px;
  }

  a {
    color: #fff;
    font-size: 22px;
    font-family: $cornell-sans-regular;
    display: block;
    text-align: center;
    padding-top: 80px;
    height: 180px;
    width: 100%;
    background: $wcm-red;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
    }
  }

  &.style-red a {
    background: $wcm-red;
  }

  &.style-bright-orange a {
    background: $wcm-dark-orange; // due to "accessibility" contrast ratio concerns this has been overridden to be darker.
  }

  &.style-dark-orange a {
    background: $wcm-dark-orange;
  }

  &.style-yellow a {
    background: $wcm-yellow;
  }

  &.small-version {
    a {
      font-size: 18px;
      padding-top: 58px;
      height: 130px;
    }
  }
}

.contact-us-callouts {
  margin: -15px 0 20px;
}

.callouts-404 {
  margin: -15px 0 20px;
}

// Simple Callout Header and Link
.simple-callout-heading-and-link {
  margin: 20px 0;
  padding: 17px 23px 16px;
  min-height: 75px;
  @include border-radius(5px);

  @include breakpoint($bp-mobile-only) {
    margin: 10px 0;
  }

  span {
    color: #fff;
    font-size: 17px;
    line-height: 20px;
    font-family: $cornell-sans-bold;
    display: block;
    margin-bottom: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  a {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px dotted white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid white;
    }

    &:after {
      content: " \e802";
      text-decoration: none;
      font-family: "fontello";
      font-size: 75%;
      padding-left: 7px;
      line-height: 20px;
      display: inline-block;
      border-bottom: none;
    }
  }

  &.style-red {
    background: $wcm-red;
  }

  &.style-bright-orange {
    background: $wcm-bright-orange;
  }

  &.style-dark-orange {
    background: $wcm-dark-orange;
  }

  &.style-yellow {
    background: $wcm-yellow;
  }

  &.style-neutral {
    background: #ffffff;
    border: 1px solid #e7e7e7;

    span {
      color: $wcm-red;
    }

    a {
      color: $wcm-dark-orange;
      border-bottom-color: $wcm-dark-orange;
    }
  }
}

.info-pane-a {
  h3 {
    font-family: "Open Sans", sans-serif;
    text-align: center;
    color: #555;
    font-weight: bold;
    font-size: 24px;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .text-box p {
    font-size: 16px;
    line-height: 28px;
  }
}

.info-pane-b {
  h3 {
    font-family: "Open Sans", sans-serif;
    text-align: center;
    color: #555;
    font-weight: bold;
    font-size: 24px;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .info-wrapper {
    @include breakpoint($bp-desktop) {
      padding: 0 140px;
    }

    img {
      float: left;
    }
  }
}

// Simple Image Callout
.simple-image-callout {
  margin: 20px 0;

  @include breakpoint($bp-mobile-only) {
    margin: 20px 50px;
  }

  a {
    color: $wcm-red;
    font-size: 22px;
    font-family: $cornell-sans-regular;
    display: block;
    height: 165px;
    overflow: hidden;

    span {
      display: block;
      margin: 4px 0 10px;

      &:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 75%;
        padding-left: 7px;
        line-height: 20px;
        display: inline-block;
      }
    }
  }
}

// Document Image Listing
.document-image-listing {
  margin: 30px 0;

  @include breakpoint($bp-mobile-only) {
    margin: 30px 50px;
  }

  .thumbnail {
    border: 1px solid #ddd;
    padding: 2px;
  }

  .title {
    border-bottom: 1px solid $light-gray;
    padding: 8px 0 10px;
    margin: 0 0 20px;

    @include breakpoint($bp-mobile-only) {
      padding: 15px 0 10px;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: $cornell-light-text;
    }
  }
}

.icon-link {
  margin: 10px 0 10px;

  .cta-icon {
    font-size: 24px;
    margin-right: 8px;
    position: relative;
    top: 7px;

    @include breakpoint($bp-mobile-only) {
      font-size: 16px;
      margin-right: 3px;
      top: 3px;
    }
  }

  a {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

// Contact Component

.contact-details {
  text-align: center;

  h4 {
    font-family: "1898Sans-Bold", sans-serif;
  }
}

/* Providers / Practices
----------------------------------------------------------*/

.view-profiles table {
  width: 100%;
}

.node-type-practice .content h1 {
  @include font-size(3, 3);
  font-weight: 200;
  padding-bottom: 5px;
}

.node-type-profile .field-name-field-primary-specialty {
  @include font-size(1.6);
  margin-top: 12px;
  font-weight: 200;

  h3 {
    color: $wcm-black;
  }

  ul {
    color: $cornell-red;
  }
}

#provider-tabs {
  @include font-size(1.2);
  @include clearfix();
  margin: 20px 0;
}

#provider-tabs li {
  float: left;
  list-style-type: none;
}

#provider-tabs li a {
  display: block;
  float: left;
  padding: 5px 14px;
  background: #aaa;
  color: #fff;
}

#provider-tabs li a:hover,
#provider-tabs li a.active {
  background: #992800;
  text-decoration: none;
}

.provider-image {
  max-height: 216px;
  max-width: 144px;
  border: 1px solid #b8b2a7;
  margin: 5px 20px 10px 0;
  padding: 2px;
  float: left;

  @include breakpoint($bp-desktop-large) {
    max-height: 100%;
    max-width: 100%;
    height: 95%;
    width: 95%;
  }
}

// Map widget
.provider-locations-wrapper {
  background: #fff;
  color: #000;
  clear: both;
  padding: 13px 0 10px;
  margin-bottom: 30px;
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  float: left;
  width: 100%;
  position: relative;
  z-index: 100;

  .map-larger-view {
    position: absolute;
    top: 20px;
    background: #fff;
    z-index: 2;
    left: 20px;

    a {
      padding: 0.3em 1em;
      display: block;
      color: $cornell-dark-orange;
      border-bottom: none;
    }
  }
}

.address-location {
  margin: 0em 1em 0.25em;
  padding: 1em 1em 0.75em 0.5em;

  @extend .clearfix;

  &.active {
    background-color: #f2f2f2;
  }

  .address-wrapper,
  .practice-address-container {
    cursor: pointer;
  }

  .practice-count {
    float: left;
    width: 10%;
    margin-top: 3px;
  }

  .practice-address {
    float: left;
    width: 90%;

    a.telephone-wrapper {
      border-bottom: none;

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .cta-icon-location {
    width: 25px;
    height: 25px;
  }

  .hours {
    margin: 5px 0;
  }

  .directions-wrapper {
    padding-bottom: 5px;
  }

  .practice-phone {
    float: left;
    margin-top: 5px;
  }

  .contact-label {
    text-transform: uppercase;
    font-size: 12px;
    color: $cornell-light-text;
    font-weight: bold;
  }

  .telephone-number,
  .fax-number {
    display: inline-block;
  }

  .fax-number {
    color: #555;
    margin-bottom: 5px;
  }

  .practice-name {
    font-weight: bold;

    a {
      color: #000;
      border-bottom: none;
      line-height: 17px;
      display: block;
      margin-bottom: 7px;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 70%;
        padding-left: 5px;
        line-height: 20px;
        color: $wcm-bright-orange;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.practice-contact-container {
  a {
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.address-location .request-appointment {
  margin-top: 10px;

  .btn {
    width: 90%;

    @include breakpoint($bp1) {
      width: 250px;
      margin: 0;
    }
  }
}

// Provider version
.provider-locations-container .request-appointment {
  padding: 0 1em;
  margin-bottom: 1em;

  .btn {
    width: 80%;

    @include breakpoint($bp1) {
      width: 260px;
    }
  }
}

// Practice version
.pane-wcmc-practice-locations .request-appointment {
  padding: 0;
  margin-bottom: 0;

  .btn {
    width: 80%;

    @include breakpoint($bp1) {
      width: 250px;
    }
  }
}

.location-map {
  text-align: center;
  padding-bottom: 10px;
}

.contact-option {
  padding: 0 2em;
  font-weight: bold;

  .phone-number {
    padding-top: 5px;
    color: $cornell-red;
    font-size: 24px;
    font-weight: normal;

    a {
      border-bottom: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.request-appointment-secondary {
  margin: -5px 0 10px;
  text-align: center;
  color: #888;

  a {
    color: #888;
    text-decoration: underline;
    border-bottom: none;

    &:hover {
      text-decoration: none;
    }
  }
}

// Open Scheduling
.open-schedule-container {
  position: relative;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  padding: 0 1em;

  .open_schedule_btn,
  .open_schedule_btn-react {
    width: 80%;
    z-index: 10;

    @include breakpoint($bp1) {
      width: 260px;
    }

    &:before {
      content: "\e80a";
      opacity: 1;
      left: 86%;
      color: $wcm-bright-orange;
    }

    &:hover:before {
      color: #fff;
    }

    &.activated {
      background-color: $wcm-bright-orange;
      border-color: $cornell-dark-orange;
      color: #fff;

      &.btn--wcm:before {
        content: "\e809";
        opacity: 1;
        left: 86%;
        color: #fff;
      }
    }
  }
}

.schedule-options,
.schedule-options-react {
  display: none;
  z-index: 5;
  position: relative;
  width: 260px;
  padding: 5px 15px;
  margin: 0 auto;
  border-radius: 20px;
  border-width: 4px;
  font-size: 13px;
  border-style: solid;
  background-color: transparent;
  border-color: #e0e0e0;
  margin-top: -40px;
  padding-top: 40px;

  a,
  .open-schedule-visit-type {
    border-bottom: 1px solid lighten($light-gray, 10%);
    padding: 7px 0;
    display: block;
    z-index: 40;

    &:hover {
      text-decoration: underline;
    }
  }

  span.call {
    padding: 7px 0;
    display: block;
    z-index: 40;
  }

  a.mychart {
    border-top: 1px solid lighten($light-gray, 10%);
    border-bottom: none;

    &.no-border {
      border: none;
    }
  }
}

.open-schedule {
  cursor: pointer;
  position: relative;

  &:before {
    font-family: "fontello";
    content: "\e80a";
    opacity: 1;
    position: absolute;
    right: 10px;
    color: $wcm-bright-orange;
  }

  &.activated {
    &:before {
      font-family: "fontello";
      content: "\e809";
      opacity: 1;
      position: absolute;
      right: 10px;
      color: $wcm-bright-orange;
    }
  }
}

.visit-type-options {
  display: none;
  margin-left: 15px;

  a:last-child,
  .call-link {
    border-bottom: none;
  }

  .call-link:hover {
    text-decoration: none;
  }

  .telephone-wrapper {
    display: inline;
  }

  .phone-vt {
    display: inline;

    &:hover {
      text-decoration: underline;
    }
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: url(../images/img_overlay.png);
  left: 0;
  top: 0;
  display: none;
}

////Returning patient modal

.returning-patient-modal {
  background: #fff;
  max-width: 860px;
  width: 100%;
  padding: 20px;
  height: 90%;

  @include breakpoint($bp-mobile-only) {
    padding: 10px;
  }

  @include breakpoint($bp2) {
    padding: 30px;
    height: auto;
    padding: 30px;
  }

  z-index: 1;
  opacity: 1;
  margin: 0 auto;
  left: 0;
  right: 0;

  @include box-sizing();

  .header {
    background: $cornell-dark-orange;
    color: #fff;
    padding: 1em;
    font-size: 18px;
    font-family: $cornell-sans-bold;
    font-weight: normal;
  }

  .modal-container {
    border: 1px solid $light-gray;
    height: 100%;
    overflow: auto;
  }

  .login-container {
    margin-top: 0;
    @include breakpoint($bp1) {
      margin-top: 2em;
    }

    .alert {
      margin: 0 20px 0 20px;

      @include breakpoint($bp-tablet) {
        transform: translate(0, -20px);
      }
    }
  }

  .login-header {
    font-weight: 700;
    @include font-size(1.6);
    display: block;
    margin-bottom: 1em;
  }

  .login-section {
    text-align: center;
    @include box-sizing();
    padding: 1em 1em;
    @include breakpoint($bp1) {
      padding: 1em 3em;
    }
    @include font-size(1.4);

    .form-text {
      margin-bottom: 15px;
    }

    .forgot-stuff {
      font-size: 11px;
      text-align: center;
      margin-top: 10px;

      a:first-of-type {
        margin-right: 10px;
      }
    }
  }

  .divider {
    @include breakpoint($bp1) {
      float: left;
      width: 3%;
      margin: 0;
    }

    margin: 0 1em;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #abb0b6;
    background: #fff;
    position: relative;

    span {
      background: #fff;
      padding: 0 5px;
      position: relative;
    }

    &:before {
      content: "";
      border-bottom: 1px solid #abb0b6;
      position: absolute;
      top: 21px;
      width: 100%;
      left: 0;
    }
  }

  .section-login {
    @include breakpoint($bp1) {
      width: 46%;
      float: left;
    }
  }

  .section-login {
    @include breakpoint($bp1) {
      width: 46%;
      float: left;
    }
  }
  .section-register {
    @include breakpoint($bp1) {
      width: 51%;
      float: left;
      border-left: 1px solid #ccc;
      margin-left: -10px;
      margin-bottom: 70px;
    }

    .note-asterisk {
      color: $cornell-red;
    }
  }
}

.modal-close {
  background-image: url(../images/img_sprite.png);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.field-name-field-related-practice {
  @include font-size(1.6);
}

.field-name-field-related-neighborhood {
  font-weight: 700;
}

.field-type-addressfield {
  @include font-size(1.3);
  //  line-height: 20px;
}

.field-name-field-related-fax {
  //  padding: 5px 0 0;
  margin-bottom: 10px;
}

.field-name-field-related-phone {
  @include font-size(1.3);
}

.pane-node-field-provider-appointments,
.pane-node-field-board-certifications,
.pane-node-field-educational-background {
  padding-top: 10px;
}

.insurance-plans {
  @include font-size(1.8);
  background: #fafafa;
  border: 1px solid gray;
  float: left;
  width: 463px;
  margin: 0.5em 0 1.5em 0;
  padding: 30px 30px 10px 30px;
}

.insurance-plans li {
  line-height: 1.25em !important;
  width: 100%;
  margin-bottom: 1em;
  list-style: none;
}

.plan {
  @include font-size(1.4);
}

.field-collection-item-field-related-practices a {
  @include font-size(1.6, 2);
  color: $wcm-dark-orange;
  border-bottom: none;

  &:hover {
    border-bottom: none;
    text-decoration: underline;
  }
}

.organisation-name {
  font-weight: bold;
}

/* =Breadcrumb
----------------------------------------------------------------------------------*/

.breadcrumb {
  color: $cornell-light-text;
  margin: 0.5em auto;
  max-width: 960px;
  padding: 0 10px 10px;

  @include font-size(1.1);
  @include box-sizing();

  @include breakpoint($bp1) {
    padding: 10px 10px 10px;
  }

  @include breakpoint($bp-desktop-large) {
    max-width: 1180px;
  }

  a {
    color: $cornell-light-text;
  }

  & > .active {
    color: $cornell-light-text;
  }

  a:hover {
    color: $wcm-dark-orange;
  }
}

.breadcrumb .last a {
  color: #544842;
}

/* =Page Options
----------------------------------------------------------------------------------*/

.page_options {
  float: left;
  width: 215px;
  margin-bottom: 10px;
  text-align: right;
}

.page_options ul li {
  float: left;
  list-style: none;
}

.page_options img {
  float: left;
  margin-bottom: 10px;
}

/* =Content Styles
----------------------------------------------------------------------------------*/

.pane-node-field-featured-image img {
  margin: 0 0 15px 0;
  width: 100%;
}

img.float_left {
  float: left;
  margin: 5px 15px 5px 0;
  border: 1px solid #b8b2a7;
  padding: 2px;
}

div.caption-right {
  float: right;
  margin: 5px 0 5px 15px;
}

div.caption-inner {
  border: none;
  background: none;
}

.pane-content img {
  vertical-align: middle;
}

ul.reg_list_bullets {
  margin: 0 0 0 18px;
}

ul.reg_list {
  margin: 0;
}

ul.reg_list li {
  list-style-type: none;
}

ul.d_list {
  padding: 0;
}

div.list_left {
  @include box-sizing();

  @include breakpoint($bp1) {
    //    width: 235px;
    width: 50%;
    float: left;
  }
  padding: 0 10px;

  a {
    //        text-decoration: underline;
    /*color: $cornell-light-text; // #0e5d86;
        &:hover {
            color: $cornell-bright-orange;
        }*/
    //        color: $wcm-dark-orange;
  }
}

div.list_right {
  @include box-sizing();
  @include breakpoint($bp1) {
    width: 50%;
    //    width: 235px;
    float: left;
  }
  padding: 0 10px;
  a {
    //        text-decoration: underline;
    /*color: $cornell-light-text;
        &:hover {
            color: $cornell-bright-orange;
        }*/
    //        color: $wcm-dark-orange;
  }
}

.pane-bundle-image-link {
  margin-bottom: 10px;
}

/* =Home
----------------------------------------------------------*/
.front #main {
  margin: 0 auto;
  width: 100%;
}

.front #content {
  padding: 0px 0px 45px 0;
}

.front h1.title {
  @include font-size(3.2);
  padding-bottom: 25px;
  color: #544842;
}

.wcc_connect {
  margin: 3em 0;
  text-align: center;

  .wcc-cornell {
    color: $cornell-red;
    @include font-size(4);
    display: inline-block;
  }

  .wcc-connect {
    font-family: $cornell-sans-regular;
    color: $cornell-bright-orange;
    font-size: 4rem;
    font-weight: 400;
    display: inline-block;
  }

  .wcc_connect_form {
    div {
      display: inline-block;
      text-align: right;

      a {
        font-size: 1.1rem;
        color: $cornell-light-text;
        margin-bottom: 0.2em;
        display: block;
        text-decoration: underline;
        border-bottom: none;

        &:hover {
          border-bottom: none;
          text-decoration: none;
        }
      }
    }

    div:nth-child(1),
    div:nth-child(2) {
      width: 100%;
      @include breakpoint($bp1) {
        width: auto;
      }
    }
  }

  .username-wrapper {
    @include breakpoint($bp1) {
      margin-right: 10px;
    }
  }

  .password-wrapper {
    margin-bottom: 15px;

    @include breakpoint($bp1) {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .login-button-wrapper {
    margin-right: 10px;
  }

  .wcc-icons {
    @include box-shadow(0px 0px 8px 2px rgba(0, 0, 0, 0.2));
    @include clearfix();
    margin: 1.5em 0 1em;

    .wcc-icon {
      @include box-sizing();
      width: 100%;
      text-align: left;
      line-height: 5;
      border-bottom: 1px solid #adadad;
      @include breakpoint($bp1) {
        width: 20%;
        border-right: 1px solid #adadad;
        border-bottom: none;
        text-align: center;
        line-height: 2;
      }
      float: left;
      padding: 1em 0 1em;
      color: $cornell-light-text;
      font-weight: 600;

      &:last-child {
        border-right: none;
      }

      &:before {
        content: "";
        background: url(../images/wcc-icons.png) no-repeat center;

        width: 100px;
        float: left;
        display: block;
        height: 65px;

        @include breakpoint($bp1) {
          width: auto;
          float: none;
        }
      }

      &.icon-heart {
        &:before {
          background-position: center 0;
        }
      }
      &.icon-calendar {
        &:before {
          background-position: center -65px;
        }
      }
      &.icon-pill {
        &:before {
          background-position: center -130px;
        }
      }
      &.icon-speech-box {
        &:before {
          background-position: center -195px;
        }
      }
      &.icon-money {
        &:before {
          background-position: center -260px;
        }
      }
    }
  }

  .download-btns {
    font-size: 1.2rem;
    color: $cornell-light-text;

    p {
      font-size: 1.2rem;
    }

    a {
      color: $cornell-light-text;
      display: inline-block;
      padding: 0 0.5em;
      border-bottom: none;

      &:after {
        content: "";
        background: url(../images/icon-download.png) no-repeat;
        width: 19px;
        height: 18px;
        display: block;
        margin: 0.3em auto 0;
      }

      &:hover {
        border-bottom: none;
      }
    }
  }
}

.welcome-message {
  padding-top: 1em;
  h1 {
    font-size: 3rem;
  }
}

.panel-home .welcome-message {
  @include breakpoint($bp-mobile-only) {
    padding: 8px 0 0;
  }
}

.patient-stories {
  text-align: center;
  border-top: 5px solid $cornell-bright-orange;
  border-bottom: 5px solid $cornell-bright-orange;
  padding: 2em 0;
  margin-bottom: 2em;

  h2 {
    color: $wcm-dark-orange;
    font-size: 3.3rem;
    line-height: 4rem;
  }

  iframe {
    height: 250px;
    @include breakpoint($bp-mobile) {
      height: 540px;
    }
  }
}

.localist-event {
  @include clearfix();
  border-bottom: none;
  padding-bottom: 0;

  .event-details {
    display: table;
  }

  .date {
    display: table-cell;
    vertical-align: middle;
    background: $cornell-light-gray;
    width: 125px;
    height: 100px;
    position: relative;

    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: $cornell-light-gray;
      border-width: 20px;
      margin-top: -20px;
    }

    .month {
      text-align: center;
      display: block;
      color: $cornell-dark-gray;
      text-transform: uppercase;
      font-size: 2.2rem;
      line-height: 2.5rem;
      margin-bottom: 1px;
    }

    .day {
      display: block;
      text-align: center;
      font-weight: 700;
      font-size: 3.8rem;
      line-height: 3rem;
      margin-bottom: 2px;
      color: $cornell-dark-gray;
    }

    .year {
      display: block;
      text-align: center;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: $cornell-dark-gray;
    }
  }

  .event-title {
    display: table-cell;
    vertical-align: middle;
    font-family: $cornell-sans-regular;
    font-size: 15px;
    padding-bottom: 5px;
    word-break: break-word;

    a {
      color: $wcm-dark-orange;

      &:after {
        padding-left: 6px;
        font-size: 80%;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .details-wrapper {
    padding: 5px 0 0 30px;
    font-size: 13px;

    .location {
      color: #000;
      border-bottom: none;

      &:after {
        font-size: 80%;
      }

      &:hover {
        border-bottom: none;
      }
    }
  }
}

.survey-callout {
  border: 5px solid $cornell-bright-orange;
  text-align: center;
  padding: 2em;
  margin-top: 2em;

  @include breakpoint($bp1) {
    padding: 2em 10em;
  }

  .icon-survey {
    width: 59px;
    height: 67px;
    background: url(../images/icon-survey.png) no-repeat;
    display: inline-block;
    margin-bottom: 1em;
  }

  h3 {
    color: $cornell-light-text;
    padding-bottom: 0;
    font-weight: 400;
  }
}

.orange-hover {
  background: $cornell-bright-orange;

  &:hover {
    background: $wcm-dark-orange;
    text-decoration: none;
  }
}

#featured_physicians,
#wcc {
  clear: both;
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto;
}

#who_we_are {
  width: 65%;
  float: left;
}

#who_we_are p {
  @include font-size(1.5);
}

#who_we_are dl.first {
  margin-left: 26px;
  float: left;
  width: 55%;
}

#who_we_are dl dt {
  @include font-size(1.9);
  font-family: Helvetica, sans-serif;
  font-weight: normal;
}

#who_we_are dl dd {
  @include font-size(1.4, 2);
  padding: 1px;
}

.slideshow {
  float: left;
  margin-right: 1em;
  width: 200px;
  height: 300px;
}

#wcc_intro {
  clear: both;
  @include font-size(1.5);
  font-family: Georgia, serif;
}

#wcc_intro p {
  @include font-size(1.5);
  font-family: Georgia, serif;
}

#WCC_Logo {
  background: url(../images/WCCLogo.png) no-repeat;
  background-size: contain;
  height: 53px;
  padding: 0;
  text-indent: -999em;
}

#wcc_intro .column {
  float: left;
  height: auto;
  width: 28%;
  padding: 0.5em;
  position: relative;
}

#wcc_intro .column.first {
  width: 65%;
}

#wcc_intro ul {
  @include font-size(1.6, 2);
  clear: left;
  margin: 0 16px 16px 32px;
}

#wcc_intro .column.last {
  width: 31%;
  padding: 0.5em;
  background: url(../images/WCC-iPhone.png) 95% 0.5em no-repeat;
  height: 255px;
  margin-top: -50px;
}

#wcc_intro p.disclaimer {
  margin: 16px 0;
  font-size: small;
  width: auto;
  float: left;
}

#wcc_intro .column.last #mychart_app {
  margin: 16px 8px 6px 8px;
  float: left;
}

#wcc_intro .column.last p {
  margin-right: 35%;
  margin-top: 15px;
  clear: right;
  margin-bottom: 6px;
}

#video_player {
  float: left;
  width: 970px;
  height: 360px;
  background: #f6f0e2;
  margin-top: 15px;
}

#video-player {
  font-size: 1.5rem;
  background: #f5f5f5;
  color: #000;
  padding: 10px 15px 10px 10px;

  .video {
    width: 640px;
    float: left;
  }

  .video-desc {
    line-height: 1.3;
    padding-top: 10px;
  }
}

.clearfix {
  clear: both;
}

/* =Clinical Services
----------------------------------------------------------*/

.footer-secondary-menu .view-clinical-services h2 a:after {
  content: "";
}

.view-clinical-services,
.d_list_cont,
.content .callout-links {
  width: 100%;
  border-top: 1px solid $cornell-light-gray;
  border-bottom: none;
  float: left;
  padding: 10px 10px 0;
}

.view-clinical-services,
.callout-links {
  padding: 20px 10px;
}

.view-clinical-services {
  font-size: 14px;

  .views-row {
    padding: 8px 10px;
    border-bottom: 1px solid #f8f8f8;

    a {
      border-bottom: none;
    }
  }
}

.callout-links {
  margin-bottom: 10px;
}

.callout-links li,
.d_list li {
  list-style: none;
  float: left;
  clear: left;
  padding: 0 0 5px;
}

.view-clinical-services {
  .visible-xs,
  .visible-sm {
    display: none !important;
  }
  .row {
    margin: 0;
  }
}

.view-clinical-services .cols-2 a,
.view-clinical-services .col-1,
.view-clinical-services .col-2 {
  float: left;
  padding: 5px;
  margin-bottom: 0.2em;
  width: 50%;
}

.callout-links a,
.d_list li a {
  width: 100%;
  float: left;
  padding: 5px;
  margin-bottom: 5px;
}

.view-clinical-services a:hover,
.d_list li a:hover,
.callout-links a:hover {
  text-decoration: underline;
}

.wcmc_threecol .d_list_cont {
  width: 100%;
  margin-bottom: 24px;
}

.wcmc_twocol_right .list_left {
  @include box-sizing();
  @include breakpoint($bp1) {
    width: 50%;
    float: left;
  }
  padding: 0 10px;
}

.wcmc_twocol_right .list_right {
  @include box-sizing();
  @include breakpoint($bp1) {
    width: 50%;
    float: left;
  }
  padding: 0 10px;
}

.wcmc_twocol_right .d_list li a {
  width: 325px;
  float: left;
}

.callout-links {
  width: 100%;
  border-top: 1px solid $cornell-light-gray;
  border-bottom: none;
  float: left;
  padding: 15px 10px 0;
}

.clinical-service-scheduling-cta {
  .cta-online {
    display: inline;

    .btn {
      border-radius: 5px;
    }
  }

  .cta-phone {
    font-size: 15px;
    display: inline;

    .cta-icon {
      margin-left: 7px;
    }
  }
}

.clinical-service-fap-widget {
  text-align: center;
  margin: 30px 0 30px;
  padding: 25px 20px 30px;
  background: #f7f7f7;

  h3 {
    color: #555;
    margin-top: 0;
    padding-bottom: 15px;
  }

  .btn {
    @include border-radius(5px);
    background: $wcm-dark-orange;
    border: 1px solid $wcm-dark-orange;
    font-size: 14px;
    width: 80%;

    &:hover {
      background: $wcm-red;
      border: 1px solid $wcm-red;
    }
  }
}

// Footer block.
.clinical-services-footer-block {
  width: 80%;
  float: left;
  border-right: 1px solid #ddd;

  @include breakpoint($bp-mobile-only) {
    width: 100%;
  }

  h2,
  h3 {
    font-size: 1.2rem;
    padding-bottom: 0;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-top: 3px;
    color: $cornell-light-text;

    @include breakpoint($bp-mobile-only) {
      text-align: center;
    }

    & a:after {
      content: "";

      @include breakpoint($bp-mobile-only) {
        padding: 0;
      }
    }
  }

  .view-content {
    margin-bottom: 15px;
    display: none;

    @include breakpoint($bp-tablet) {
      column-count: 2;
      column-gap: 15px;
      padding-right: 15px;
      display: block;
    }

    @include breakpoint($bp-desktop) {
      column-count: 3;
      column-gap: 15px;
      padding-right: 15px;
    }

    .views-row {
      a {
        font-size: 12px;
        border-bottom: 1px solid #f8f8f8;
        height: 32px;
        line-height: 31px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.footer-clinical-services-wrapper {
  width: 80%;
  float: left;

  .clinical-services-footer-block {
    width: 100%;
  }
}

/* =Two Sidebar Layout
----------------------------------------------------------------------------------*/

.pane-profiles-panel-pane-2 {
  a {
    color: $wcm-red;
  }
}

.node-type-physicians {
  h1 {
    @include font-size(3, 3);
    letter-spacing: -0.02em;
    font-weight: 200;
    color: #544842;
    padding: 0;
  }
  #sidebar-first,
  #sidebar-second {
    @include font-size(1.3);
    letter-spacing: 0.01em;
  }
  #sidebar-first h2,
  #sidebar-second h2 {
    @include font-size(1.8);
    font-weight: normal;
    margin-bottom: 9px;
    padding-bottom: 6px;
    border-bottom: 1px dotted #aea89d;
    color: #423934;
  }
  #sidebar-first p,
  #sidebar-second p {
    line-height: 1.5;
  }
  #sidebar-first .field-item,
  #sidebar-second .field-item {
    padding-bottom: 10px;
  }
}

.pane-node-field-main-specialty {
  @include font-size(1.6);
  font-weight: 200;
  color: #992800;
  line-height: 2em;
  border-bottom: 1px dotted #aea89d;
  margin-bottom: 10px;
}

.physician_profile_pic {
  width: 144px;
  height: 216px;
  border: 1px solid #b8b2a7;
  margin: 5px 20px 10px 0;
  padding: 2px;
  float: left;
}

.field-name-field-appointments li {
  list-style-type: none;
}

.field-name-field-appointments span {
  @include font-size(1.1);
}

.page-search h3.pane-title {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 1.3rem;
  color: #000;
}

/* =Provider / Practice Indexes
----------------------------------------------------------------------------------*/

html.js input.form-autocomplete {
  background-position: 100% 7px;
}

html.js input.throbbing {
  background-position: 100% -14px;
}

/* =Footer
----------------------------------------------------------------------------------*/

footer.landing-page-footer .wrap {
  max-width: 970px;
  width: auto;
  padding: 0 10px;

  @include breakpoint($bp-desktop-large) {
    max-width: 1180px;
  }

  .footer-links {
    border-top: 1px solid $cornell-light-gray;
    padding-top: 1em;
    text-align: center;
  }

  .footer-wcm {
    text-align: center;
    margin-bottom: 1.5em;
  }

  .nypp-logo {
    display: inline-block;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
    @include retina-image($wcmc-base + "images/NYP_logo_red", 270px 31px, png);
    background-repeat: no-repeat;
    background-position: center left;
    width: 270px;
    height: 60px;
  }

  .wcm-logo {
    font: 0/0 a;
    display: inline-block;
    background: url("../images/wcm-logo-footer.png") no-repeat center left;
    background-size: 275px 25px;
    width: 275px;
    height: 60px;
    padding-right: 0;
    @include breakpoint($bp1) {
      margin-right: 8rem;
    }
  }
}

footer.landing-page-footer {
  ul {
    @include font-size(1.2);
  }

  ul.links li {
    list-style-type: none;
    list-style-image: none;
    @include breakpoint($bp1) {
      &:first-child {
        list-style-type: none;
        list-style-image: none;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }

      float: left;
      margin: 0 2em 0 2px;
      list-style-type: disc;
      list-style-position: outside;
    }
  }

  ul.links li.menu-1150,
  ul.links li.last {
    border: none;
  }

  a,
  a.active {
    color: $cornell-light-text;
  }

  a:hover {
    color: $wcm-dark-orange;
    text-decoration: underline;
  }

  #footer-menu {
    clear: both;
    @include breakpoint($bp1) {
      clear: none;
      padding-top: 0;
      display: inline-block;
    }
  }

  .nyp_logo {
    @include breakpoint($bp1) {
      float: right;
    }
    background-image: url(../images/nyp_logo.png);
    height: 27px !important;
    width: 201px;
  }

  .my_nyp {
    @include font-size(1.1);
    @include breakpoint($bp1) {
      float: right;
    }
    float: left;
    clear: both;
    margin-top: 10px;
  }

  .cornell_copyright {
    @include font-size(1.2);
    display: inline-block;
    vertical-align: top;
  }
}

.landing-page-footer .footer-statement {
  border-top: 5px solid $wcm-dark-orange;
  border-bottom: 5px solid $wcm-dark-orange;
  padding: 1em 0;
  margin: 1.5em 0 0.5em;
  clear: both;

  @extend .clearfix;

  .wcmc-emblem {
    float: none;
    cursor: default;

    @include wcmc-emblem(red);

    background-size: 60px;
    background-position: left;
    width: 60px;
    height: 60px;
    padding-left: 0;
    margin: 0 auto 1em;
    @include breakpoint($bp1) {
      float: left;
      margin-right: 20px;
    }
  }

  .wcmc-statement {
    color: $cornell-light-text;
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-family: $cornell-sans-regular;
    font-style: italic;

    span {
      font-family: $cornell-sans-bold;
    }

    @include breakpoint($bp1) {
      width: 87%;
      float: left;
    }
  }

  .wcmc-person {
    color: $wcm-dark-orange;
    margin-top: 0.5em;
    font-size: 1.4rem;
    font-family: $cornell-sans-regular;
  }
}

.footer-statement__statement,
.footer-links {
  color: $cornell-light-text;
}

/* =Form Elements
----------------------------------------------------------------------------------*/

fieldset.fieldset_gender,
fieldset.fieldset_languages {
  width: 100%;

  @include breakpoint($bp1) {
    width: 16%;
  }
}

label {
  @include font-size(1.4, 2);
  display: block;
  font-weight: bold;
  margin: 0 0 5px;
}

.submit {
  @include font-size(1.9);
  @include box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05));
  width: 100%;
  color: #ca3510;
  text-align: center;
  display: block;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #ffffff;
  background-image: -ms-linear-gradient(top, white, #d6d6d6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#d6d6d6));
  background-image: -webkit-linear-gradient(top, white, #d6d6d6);
  background-image: -o-linear-gradient(top, white, #d6d6d6);
  background-image: linear-gradient(top, white, #d6d6d6);
  background-image: -moz-linear-gradient(top, white, #d6d6d6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  text-decoration: none;
  border-color: #ddd #ddd #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=0);
  margin: 4px auto;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-family: Helvetica, sans-serif;
}

.submit:hover {
  @include transition(background-position 0.1s linear);
  text-decoration: none;
  background-color: #d6d6d6;
  background-position: 0 -30px;
}

.red {
  color: #fff;
  font-weight: normal;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ca3510', endColorstr='#920401', GradientType=0);
  background-image: -moz-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -o-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: -webkit-linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-image: linear-gradient(90deg, #920401 0%, #ca3510 100%);
  background-color: #920401;
  text-shadow: 0 1px 1px #960000;
  padding: 4px 0px 4px;
}

.submit.red:hover {
  background-color: #920401;
  color: #fff;
}

.node-webform {
  h2 {
    display: none;
  }

  fieldset.captcha {
    width: 100%;
    max-width: 500px;
    @include box-sizing();
  }
}

/* =Utility
----------------------------------------------------------------------------------*/

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.form-item-name,
.form-item-departments {
  margin-bottom: 0;
}

// Physicians Directories
.physicians-directory {
  .views-exposed-widgets {
    margin-bottom: 30px;
  }

  table a {
    color: $wcm-red;
  }
}

/* Provider Profiles */

// Legacy ending on 2019-01-23
// NOTE - 2019-12-04: review required before removal. A few things still affect the new layout.
.node-type-profile {
  #main {
    width: 100%;
    padding: 0;
    max-width: inherit;
  }

  .content h1 {
    border-bottom: none;
  }

  #insurances,
  #education,
  #accordion,
  .provider-accordion {
    clear: both;
    font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, sans-serif;

    .accordion-panel,
    .element__hide {
      display: none;
      overflow: auto;
      padding-top: 10px;
      padding-bottom: 10px;

      .list-items {
        margin-bottom: 10px;
        margin-bottom: 1rem;
      }

      ul {
        margin-bottom: 0;
      }

      p {
        margin: 0 0 5px;
      }

      .education ul li {
        list-style-position: inside;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: normal;
      font-family: $cornell-sans-regular;
      color: #000;
      font-weight: normal;
      padding: 19px 55px 13px 10px;
    }

    h3 {
      font-size: 17px;
    }

    div:first-child h3 {
      margin-top: 0;
    }
  }

  .accordion-expand-holder {
    clear: both;
    float: right;
    margin-bottom: 5px;

    a {
      font-weight: bold;
      color: #000;
      cursor: pointer;
      float: none;
      font-size: inherit;
      opacity: inherit;
      text-shadow: inherit;
      border-bottom: none;

      &:hover {
        border-bottom: none;
      }
    }
  }

  #physicians-home {
    .pane-content,
    strong {
      vertical-align: top;
    }
  }

  .pane-wcmc-collapsible-fields {
    margin-bottom: 2em;
    border-bottom: 1px solid #ddd;
  }

  .ui-widget-content,
  .accordion-panel {
    border: none;
    background: none;
    margin: 1em 1.5em;
    clear: both;
    position: relative;
    width: 93%;
  }

  .provider-bio h3 {
    margin-bottom: 0;
    padding: 0;
  }

  .appt-title {
    font-weight: 700;
  }
  .provider-appointments {
    margin-bottom: 1em;
  }

  .pane-node-field-biographical-info {
    margin-top: 2em;
  }

  .pane-node-field-educational-background {
    padding-top: 0;
  }

  #main-content-header {
    padding: 15px 0;
    background-color: #f7f7f7;
    position: relative;
    width: 100%;
    z-index: 5;
    @include box-shadow(inset 0 5px 10px -5px rgba(0, 0, 0, 0.3));

    .content {
      max-width: 960px;
      margin: 0 auto;
      padding: 0 10px;

      @include breakpoint($bp-desktop-large) {
        max-width: 1180px;
      }

      .pane-wcmc-display-name {
        float: left;

        h1 {
          font-family: $cornell-sans-bold;
          padding-top: 5px;
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      .pane-node-field-primary-specialty {
        .field .field-label {
          font-family: "Open Sans", sans-serif;
          margin: 0 3px 0 0;
          padding: 0;
          font-weight: normal;
          float: left;
          @include font-size(1.6);
        }

        ul {
          float: left;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .field .field-item,
        ul li {
          font-weight: bold;
          float: left;
          @include font-size(1.6);
        }

        clear: both;
        @include breakpoint($bp-tablet) {
          float: right;
          clear: none;
        }
      }
    }
  }

  #main-content {
    margin: 0 auto;
    padding: 0;

    .main-content-bg {
      background: #f7f7f7;
      @include breakpoint($bp-mobile) {
        max-height: 306px;
      }
      max-height: 540px;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .content-container {
      max-width: 970px;
      min-height: 600px;
      margin: 0 auto;
      padding: 0 10px 0;
      position: relative;

      @include breakpoint($bp-desktop-large) {
        max-width: 1180px;
      }
    }

    .content {
      position: relative;
      @include breakpoint($bp-tablet) {
        width: 100%;
        max-width: 585px;
        @include box-sizing();
      }

      @include breakpoint($bp-desktop-large) {
        max-width: 780px;
      }
    }

    .empty-btn {
      padding: 1em;
    }

    .profile-btn {
      background: $cornell-dark-gray;
      display: block;
      text-align: center;
      color: #fff;
      padding: 1em;
      font-weight: 700;
      font-size: 1.5rem;
      text-decoration: none;
      margin-bottom: 1px;
      border-bottom: none;
      @include transition(all 0.25s 0s);

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: $cornell-white;
        color: $cornell-dark-gray;
      }
    }

    #physicians-home {
      .providers-image {
        @include breakpoint($bp-mobile) {
          float: left;
          margin: 2px 20px 10px 0;
        }
        text-align: center;
        margin-bottom: 10px;

        .social-icons {
          position: absolute;
          left: -45px;
          width: 40px;
          display: none;
          font-size: 1rem;

          @include breakpoint($bp-tablet) {
            display: block;
          }

          a {
            display: block;
            margin-bottom: 5px;
            width: 40px;
            height: 32px;
            background: url(../images/WCM-org_mobile_share_icons_dark.png) no-repeat top center;
            background-size: 32px;
            border-bottom: none;

            &:hover {
              border-bottom: none;
            }

            &.social-tw {
              background-position: center -33px;
            }
            &.social-ln {
              background-position: center -66px;
            }
            &.social-email {
              background-position: center -99px;
            }

            img {
              display: none;
            }
          }
        }
      }
    }

    .provider-badge {
      font-size: 1.5rem;
      margin-top: 1rem;
      padding-top: 7px;
      font-weight: bold;

      a {
        font-weight: normal;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .provider-badge-icon {
      height: 33px;
      width: 32px;
      display: none;
      float: left;
      margin-top: -7px;

      &.provider-badge-icon-voluntary {
        background: url(../images/profile_badges_32x33.png) 0 0;
        background-size: cover;
        display: none;
      }
      &.provider-badge-icon-fulltime {
        background: url(../images/profile_badges_32x33.png) 0 -33px;
        background-size: cover;
      }
      &.provider-badge-icon-wcpn {
        background: url(../images/profile_badges_32x33.png) 0 -66px;
        background-size: cover;
      }
    }

    .specialties-and-expertise-outer {
      margin-bottom: 20px;
    }

    .personal-statement {
      clear: both;
      color: $wcm-black;
      padding-top: 0;

      h2 {
        font-size: 19px;
      }
    }

    .anchor-btns {
      float: left;
      margin: 0.3em 0 2.5em;
      @include breakpoint($bp-mobile) {
        margin-bottom: 0;
        width: 420px;
      }

      @include breakpoint($bp-desktop-large) {
        width: 615px;
      }
    }

    .provider-anchor-btn {
      border-bottom: 1px solid #e6e6e6;
      padding: 11px 0;
      float: left;
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #555;
      text-decoration: none;
      box-sizing: border-box;
      background: url(../images/wcm-org_jump_arrow.png) no-repeat 95% 50%;
      background-size: 15px;

      &:hover {
        color: $cornell-bright-orange;
        text-decoration: underline;
        @include transition(all 0.25s 0s);
      }

      &:first-child {
        border-top: 1px solid #e6e6e6;
      }
    }
  }
}

// end provider legacy styles

.provider-badge {
  a {
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.is-sticky {
  #main-content-header {
    @include box-shadow(0 0 6px 0 rgba(0, 0, 0, 0.5));
  }
}

.spacer {
  height: 10px;
}

.closed-panel {
  color: $cornell-red;
}

.insurance-plans {
  width: auto;
  font-size: inherit;
  border: none;
  float: none;
  background: none;
  padding: 0;
  margin: 0;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  ul {
    padding-bottom: 0;
  }
  li {
    box-sizing: border-box;
    //        width: 50%;
    //        float: left;
    margin-bottom: 0;
    padding-right: 10px;
    list-style-position: inside;
    line-height: 1.8em !important;

    &:nth-child(odd) {
      clear: both;
    }
  }
}

.list-items {
  width: auto;
  font-size: inherit;
  border: none;
  float: none;
  background: none;
  padding: 0;
  margin: 0;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  ul {
    padding-bottom: 0;
  }

  li {
    box-sizing: border-box;
    width: 50%;
    float: left;
    margin-bottom: 0;
    padding-right: 10px;
    list-style-position: inside;
    line-height: 1.8em !important;

    &:nth-child(odd) {
      clear: both;
    }
  }
}

.provider-image {
  float: none;
  padding: 0;
  border: none;
  margin: 0;
}

.view-research-link {
  display: block;
  text-align: left;
  padding-top: 8px;
  color: #000;
  border-bottom: none;

  &:hover {
    border-bottom: none;
  }
}

// Provider Profiles NEW (2019-01-23) / Physician Profiles
.provider-profile-wrapper {
  .simple-news-listing {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 15px;
    padding-left: 15px;
  }

  .back-to-search {
    display: none;
  }

  .location-listings-wrapper,
  .video-visit-listing-wrapper {
    margin-bottom: 30px;

    @include breakpoint($bp-tablet) {
      margin-bottom: 0;
    }

    .item-0 {
      @include breakpoint($bp-mobile-only) {
        border-top: none !important;
        margin-top: 17px !important;
      }
    }

    .location-listing,
    .video-visit-listing {
      .date-picker-container {
        @include breakpoint($bp-mobile-only) {
          display: block !important;
        }

        @include breakpoint($bp-tablet-only) {
          display: block !important;
        }
      }

      .date-scrubber-container {
        @include breakpoint($bp-mobile-only) {
          padding-left: 20px;
          padding-right: 20px;
        }

        @include breakpoint($bp-tablet-only) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      .timegrid-datescrubber-container {
        @include breakpoint($bp-mobile-only) {
          margin-top: 0px !important;
        }

        .date-scrubber-container {
          @include breakpoint($bp-mobile-only) {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  .google-map-react-wrapper {
    // width: 100%;
    // height: 260px;
    // margin: 10px 0 10px;
  }

  .timegrid-datescrubber-container {
    background: transparent;
    padding-top: 15px;
    top: 0px !important;
  }

  .date-scrubber-wrapper {
    @include breakpoint($bp-desktop) {
      width: 422px;
    }

    @include breakpoint($bp-desktop-large) {
      width: 520px;
    }
  }

  .timeslots-grid-container {
    @include breakpoint($bp-desktop) {
      width: 85%;
    }
  }

  .provider-headshot {
    width: 100%;
    display: none;

    @include breakpoint($bp-tablet) {
      display: block;
    }
  }

  .headshot-mobile {
    padding: 7px 10px 18px;
    margin-left: auto;
    margin-right: auto;
    width: 40%;

    @include breakpoint($bp-tablet) {
      display: none;
    }
  }

  .provider-headshot-mobile {
    display: block;

    @media screen and (max-width: $bp-tablet) {
      // order: -1;
    }
    @include breakpoint($bp-tablet) {
      display: none;
    }
  }

  .no-gutters {
    @media (max-width: $bp-tablet) {
      // display: flex;
      // flex-direction: column;
    }
  }

  //Header
  .header-pane {
    // height: fit-content;
    // display: flex;
    // flex-direction: column;
    // order: -2;
    // left: -50px;

    @include breakpoint($bp-tablet) {
      // height: fit-content;
      // flex-direction: row;
      // left: 0px;
      padding: 0 0 0 30px;
      margin-bottom: 10px;
      min-height: 243px;
    }

    a#back {
      display: inline;
      width: fit-content;
      order: -3;
      margin: 0px 0px 23px 20px;
      @include breakpoint($bp-tablet) {
        display: none;
      }
    }

    .social-icons {
      display: none;

      a {
        display: block;
        margin-bottom: 5px;
      }
    }

    #info-pane {
      // order: -2;
      width: 100%;

      #provider-header {
        position: relative;
        width: -webkit-fill-available;

        @include breakpoint($bp-tablet) {
          // min-height: 76px;
        }

        @media (max-width: $bp-tablet) {
          padding-left: 0;
          // order: -2;
        }

        h1 {
          @media (max-width: $bp-tablet) {
            font-size: 20px;
            line-height: 20px;
            text-align: center;
          }
        }

        h3 {
          margin-top: 0px;
          color: #555;
          padding-bottom: 5px;

          @media (max-width: $bp-tablet) {
            font-size: 18px;
            text-align: center;
          }
        }
      }

      #share-print-wrapper {
        float: right;
        position: absolute;
        top: 0px;
        right: 0px;
        border-left: 1px solid #e6e6e6;
        margin-top: 10px;
        width: 100px;

        .print-icon,
        .share-icon {
          padding-left: 20px;
        }

        a[class="share-icon"] {
          background: url(/sites/all/themes/weillcornell/images/share-icon.png);
          display: block;
          background-repeat: no-repeat;
          width: 35px;
          height: 35px;
          margin: 0px 0px 10px 30px;
          border-bottom: 0px;
          &:focus {
            color: transparent;
          }
          @media (max-width: $bp-tablet) {
            background: url(/sites/all/themes/weillcornell/images/share-icon-mobile.png);
            display: none;
            background-repeat: no-repeat;
            margin: 0px 0px 10px 5px;
          }
        }

        a:hover {
          color: transparent;
        }

        .print-icon:hover,
        .share-icon:hover {
          cursor: pointer;
        }

        .print-icon {
          background: url(/sites/all/themes/weillcornell/images/print-icon.png);
          display: block;
          width: 35px;
          height: 27px;
          background-repeat: no-repeat;
          margin: 10px 0px 0px 30px;
          @media (max-width: $bp-tablet) {
            display: none;
          }
        }
        @media (max-width: $bp-tablet) {
          border-left: 0px;
          margin-top: 0px;
          position: absolute;
          left: 250px;
        }

        .popover {
          @include breakpoint($bp-tablet) {
            top: -55px !important;
          }

          &.left > .arrow {
            @include breakpoint($bp-tablet) {
              top: 70px !important;
            }
          }
        }

        .popover-content {
          @include breakpoint($bp-tablet) {
            display: block;
          }

          a {
            display: block;
            margin-bottom: 5px;
            width: 40px;
            height: 32px;
            background: url(../images/WCM-org_mobile_share_icons_dark.png) no-repeat top center;
            background-size: 32px;
            border-bottom: none;

            &:hover {
              border-bottom: none;
            }

            &.social-tw {
              background-position: center -33px;
            }
            &.social-ln {
              background-position: center -66px;
            }
            &.social-email {
              background-position: center -99px;
            }

            img {
              display: none;
            }
          }
        }
      }
    }

    // Thinking remove from here:

    [class*="col-"]:nth-child(2) {
      @media (max-width: $bp-tablet) {
        position: relative;
        margin-left: auto;
        display: flex;
        flex-direction: row;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        z-index: 100;
        width: 1px;
        height: 60%;
        background: #e6e6e6;
        @media (max-width: $bp-tablet) {
          display: none;
        }
      }
    }

    [class*="col-"]:nth-child(3) {
      @media (max-width: $bp-tablet) {
        //display: none;
        padding-top: 0px;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        z-index: 100;
        width: 1px;
        height: 100%;
        background: #e6e6e6;
        @media (max-width: $bp-tablet) {
          display: none;
        }
      }
    }

    [class*="col-"]:nth-child(4) {
      @media (max-width: $bp-tablet) {
        //order: -1;
        display: none;
      }
    }

    [class*="col-"] {
      border-top: 1px solid #e6e6e6;
      padding-top: 20px;
      margin-top: 20px;
      @media (max-width: $bp-tablet) {
        border-top: none;
        padding-top: 5px;
        margin-top: 0px;
      }
    }

    // to here

    .btn-secondary {
      border-radius: 5px;
      padding: 10px 40px 5px 40px;
      height: 40px;
      font-size: 14px;

      @media (max-width: $bp-tablet) {
        width: 100%;
      }
    }

    a {
      font-size: 14px;
      font-weight: 400;
    }

    .about-voluntary {
      margin-top: 20px;
      padding-left: 10px;
      border-top: 1px solid #e7e7e7;
    }

    .voluntary-label-wrapper {
      font-size: 1.5rem;
      margin-top: 0;
      padding-top: 0;
      font-weight: bold;
      width: 100%;
      text-align: center;

      a {
        border-bottom: none;

        &:hover {
          text-decoration: underline;
        }
      }

      a {
        font-weight: normal;

        &:hover {
          text-decoration: underline;
        }
      }

      @include breakpoint($bp-tablet) {
        text-align: left;
      }
    }

    .provider-badge-icon {
      height: 33px;
      width: 32px;
      display: block;
      float: left;
      margin-top: -7px;

      &.provider-badge-icon-voluntary {
        background: url(../images/profile_badges_32x33.png) 0 0;
        background-size: cover;
      }
      &.provider-badge-icon-fulltime {
        background: url(../images/profile_badges_32x33.png) 0 -33px;
        background-size: cover;
      }
      &.provider-badge-icon-wcpn {
        background: url(../images/profile_badges_32x33.png) 0 -66px;
        background-size: cover;
      }
    }
  }

  .research {
    margin: 7px 0 10px;

    a {
      font-size: 14px;
    }
  }

  .research-inner-wrapper {
    padding-left: 15px;
  }

  .online-scheduling-info-wrapper {
    margin-top: 5px;
    display: inline-block;

    @include breakpoint($bp-mobile-only) {
      width: 80%;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }

  .appointment-btn-wrapper {
    margin-bottom: 10px;

    @include breakpoint($bp-tablet) {
      margin-top: 10px;
    }
  }

  .header-pane-sticky {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    z-index: 5;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);

    .physician-title {
      font-size: 2.8rem;
      line-height: 3.5rem;
      margin: 0;
      font-weight: normal;
      color: #b31b1b;
      font-family: "1898Sans-Bold", sans-serif;

      @include breakpoint($bp-mobile-only) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .physician-specialty {
      color: #555;
      font-size: 20px;
      line-height: 26px;
      margin: 4px 0 10px;
      font-family: "1898Sans-Bold", sans-serif;

      @include breakpoint($bp-mobile-only) {
        font-size: 15px;
        line-height: 20px;
        margin-top: 3px;
      }
    }

    h3 {
      margin-top: 0px;
      color: #555;

      @include breakpoint($bp-mobile-only) {
        font-size: 18px;
      }
    }

    .physician-info,
    .appointment-btn-wrapper {
      display: inline-block;
      padding-top: 15px;
    }

    .appointment-btn-wrapper {
      float: right;
      margin-top: 10px;

      @include breakpoint($bp-mobile-only) {
        width: 100%;
        margin-right: 0;
        padding-top: 0;
        margin-top: 0;
      }

      .btn-secondary {
        font-size: 14px;
        border-radius: 5px;
        padding: 10px 40px 5px 40px;
        height: 40px;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .header-pane-sticky-content {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .gender-languages-wrapper {
    display: inline-block;
    padding-top: 3px;

    @include breakpoint($bp-mobile-only) {
      width: 100%;
      margin: 7px 0 10px;
    }
  }

  .provider-languages,
  .provider-gender {
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    color: #555;

    span {
      font-weight: 400;
      color: #000;
    }

    @media (max-width: $bp-tablet) {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      text-align: center;
    }
  }

  // Section Content
  .section-content {
    h3 {
      background-color: #f8f8f8;
      padding-top: 10px;
      padding-left: 15px;
      margin-top: 10px;
      color: #555;
      @include breakpoint($bp-mobile-only) {
        font-size: 15px;
        padding: 8px 0px 8px 15px;
      }
    }

    h2 {
      color: #555;
    }

    h4 {
      font-weight: bold;
      margin-top: 10px;

      @include breakpoint($bp-mobile-only) {
        font-size: 15px;
      }
    }

    .list-items {
      width: auto;
      font-size: inherit;
      border: none;
      float: none;
      background: none;
      padding-left: 15px;
      margin: 0;
      margin-top: -15px;
    }

    ul li {
      list-style-position: inside;
    }

    li {
      @media (max-width: $bp-tablet) {
        width: 100%;
      }
    }

    #provider-bio,
    .personal-statement-inner-wrapper,
    #provider-insurances,
    #provider-honors,
    .voluntary-inner-wrapper {
      padding-left: 15px;
    }
  }

  .about-section {
    .specialties-and-expertise-wrapper {
      padding-left: 15px;
    }
  }

  .locations-and-appointments-section {
    .direct-scheduling-cta {
      @include breakpoint($bp-mobile-only) {
        width: 100%;
      }
    }
  }

  // Patient Forms
  .patient-forms-inner-wrapper {
    ul {
      padding: 15px 0 0 15px;
      list-style: none;
    }
  }

  .provider-education-wrapper {
    .list-items {
      margin: 20px 0;
    }
  }

  // Panes
  #honors-and-awards {
    h3 {
      @include breakpoint($bp-mobile-only) {
        display: none;
      }
    }

    #provider-honors {
      ul {
        margin-top: 20px;
      }

      span {
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px !important;
      }

      li {
        line-height: 28px !important;
      }
    }
  }

  .video-outer-wrapper {
    @include breakpoint($bp-desktop) {
      padding: 0 100px;
    }

    p {
      margin-top: -45px; // aesthetic fix due to extra <br> that is injected
    }
  }

  .video-wrapper {
    margin: 30px 0;
  }

  .photo-wrapper {
    margin: 20px 0;
    max-width: 100%;
    padding: 0;

    @include breakpoint($bp-desktop) {
      padding: 0 100px;
    }
  }

  //Visit Type Filter
  .visit-type-filter-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 20px 0 10px;
    margin-top: -20px;

    .visit-type-label {
      display: inline;
      font-size: 14px !important;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      line-height: 24px;
      padding-top: 15px;
      margin: 12px 30px 0px 15px;

      .form-group {
        display: inline !important;
      }
    }

    .clear-selections {
      font-family: "Open Sans", sans-serif;
      margin-left: 15px;
      padding-top: 5px;
      font-size: 13px;
    }

    .visit-type-wrapper {
      min-width: fit-content;
    }

    .checkbox-wrapper {
      padding: 0 10px 0 0;
    }

    .filter-item {
      margin-right: 35px;

      span {
        font-size: 14px !important;
        font-family: "Open Sans", sans-serif;
        line-height: 24px;
      }
    }
  }

  .visittype-filter {
    margin-top: 12px;
    padding: 10px 20px 0px 20px !important;

    .expansions-panel-summary {
      margin: 0px !important;
      padding: 0;

      p {
        margin: 0px !important;
      }
    }

    .filter-wrapper {
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .panel-title {
      margin: 0px !important;
      padding: 0px;
    }
  }

  .external-relationships-inner-container {
    padding: 0 15px;

    .external-relationship-row {
      margin-bottom: 20px;

      .er-label {
        font-weight: bold;
        text-align: left;
        font-size: 14px;
        line-height: 22px;

        @include breakpoint($bp-desktop) {
          text-align: right;
        }

        .tooltip-icon {
          margin-right: 5px;
          position: relative;
          top: 2px;
        }
      }

      .er-content {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

// Accepting new patients
#accepting-patients,
.simple-tag {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  border-radius: 20px;
  width: 200px;
  margin-top: 0;

  @include breakpoint($bp-tablet) {
    float: left;
    height: 40px;
    margin-right: 15px;
  }

  @media (max-width: $bp-tablet) {
    position: relative;
    width: 97%;
    text-align: center;
    margin-top: 5px;
    background-color: transparent;
    border-color: transparent;
  }

  .new-patients,
  .tag-text {
    position: relative;
    top: -7px;
    padding-left: 8px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    color: #555;

    @media (max-width: $bp-tablet) {
      top: -2px;
      padding-left: 5px;
      font-weight: normal;
    }
  }

  .dot {
    height: 22px;
    width: 23px;
    background-color: #66ff33;
    border-radius: 50%;
    display: inline-block;
    margin: 9px 0px 0px 11px;

    @media (max-width: $bp-tablet) {
      height: 13px;
      width: 12px;
    }
  }

  &.not-accepting {
    @include breakpoint($bp-tablet) {
      width: 250px;
    }

    .dot {
      background-color: #f44242 !important;
    }
  }

  .icon {
    padding: 10px 0 0 15px;
    display: inline-block;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .video-visit-icon {
    opacity: 0.6;
  }

  .tag-text {
    top: 0;
    padding-left: 6px;
  }
}

.not-accepting-new-patients {
  background-color: #f44242 !important;
}

.for-selected-insurance {
  width: 370px !important;
  display: inline;

  @include breakpoint($bp-mobile) {
    display: block;
  }
}

.not-for-selected-insurance {
  width: 400px !important;
  .dot {
    background-color: #f44242 !important;
  }
}

// Various mods for results view (ANP tag, VV tag and logo etc)
.search-result-item {
  #accepting-patients,
  .simple-tag {
    height: 33px;
    margin-top: 0;
    margin-left: 0;
    float: none;

    @include breakpoint($bp-mobile-only) {
      margin-top: 0;
    }

    @include breakpoint($bp-desktop) {
      width: 180px;
    }

    .new-patients {
      top: -8px;
      font-size: 12px;
    }

    .dot {
      height: 15px;
      width: 15px;
      margin: 9px 0px 5px 11px;
    }

    .tag-text {
      top: -1px;
      font-size: 12px;
    }

    .icon {
      padding: 7px 0 0 11px;

      img {
        width: 17px;
        height: 17px;
      }
    }
  }

  .not-accepting {
    @include breakpoint($bp-tablet) {
      width: 220px !important;
    }
  }

  .video-visit-details {
    text-align: center;

    @include breakpoint($bp-tablet) {
      text-align: left;
    }

    img {
      height: 35px;
      margin-top: 3px;
      padding: 0;

      @include breakpoint($bp-desktop) {
        height: 45px;
        margin-top: 20px;
        margin-left: 5px;
      }
    }
  }

  .video-visit-listing {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

// Best Hospital Badge
.hospital-badge-wrapper {
  border: 3px solid #e6e6e6;
  margin: 30px 0;
  position: relative;

  @include breakpoint($bp-mobile-only) {
    margin: 30px;
  }

  .badge-title {
    position: relative;
    top: -12px;
    background: #fff;
    padding: 0 20px;
    //transform: translate(30%, 0%);
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0%);
    @include breakpoint($bp-tablet) {
      //padding: 0px 5px;
    }
  }

  .hospital-badge-logo-wrapper {
    padding-top: 10px;
    display: inline-block;

    img {
      width: 100%;
    }

    @media screen and (max-width: 529px) and (min-width: 0px) {
      padding: 0px 60px 10px 60px;
    }

    @media screen and (max-width: 599px) and (min-width: 530px) {
      padding: 0px 80px 10px 80px;
    }

    @include breakpoint($bp-tablet) {
      margin: -12px 40px 0px;
    }

    @include breakpoint($bp-mobile-to-tablet) {
      margin: -12px 14px 0;
    }

    @include breakpoint($bp-desktop-only) {
      margin: -12px 30px 0px;
    }

    a {
      border-bottom: none;
      &:hover {
        text-decoration: none;
        border: none;
      }
    }
  }

  .hospital-badge-image-logo-wrapper {
    display: inline-block;

    @media screen and (max-width: 529px) and (min-width: 0px) {
      padding: 0px 60px 10px 60px;
    }

    @media screen and (max-width: 599px) and (min-width: 530px) {
      padding: 0px 80px 10px 80px;
    }

    @include breakpoint($bp-tablet) {
      margin: 0px 40px 0px;
    }

    @include breakpoint($bp-mobile-to-tablet) {
      margin: 0px 14px 0;
    }

    @include breakpoint($bp-desktop-only) {
      margin: 0px 30px 0px;
    }

    a {
      border-bottom: none;
      &:hover {
        text-decoration: none;
        border: none;
      }
    }
  }

  .nyp-logo-wrapper {
    padding: 25px 100px 25px 100px;
  }

  .badge-affiliations {
    position: relative;
    text-align: center;
    left: 50%;
    transform: (translate(-50%, 0%));
    padding-bottom: 20px;
    a {
      font-size: 14px;
    }
  }
}

// Castle Connolly Badge
.castle-connolly-badge-wrapper {
  border: 3px solid #e6e6e6;
  margin: 30px 0;
  position: relative;

  @include breakpoint($bp-mobile-only) {
    margin: 30px;
  }

  .badge-title {
    position: absolute;
    top: -12px;
    left: 20px;
    background: #fff;
    padding: 0 20px;
  }

  .castle-connolly-logo-wrapper {
    margin: 30px 70px 0;

    @include breakpoint($bp-tablet) {
      margin: 40px;
    }

    @include breakpoint($bp-mobile-to-tablet) {
      margin: 30px 210px 0;
    }

    @include breakpoint($bp-desktop) {
      padding-right: 30px;
      border-right: 2px solid #e6e6e6;
    }
  }

  .castle-connolly-text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 90px 80px 15px 0;

    @include breakpoint($bp-mobile-only) {
      padding: 30px;
      font-size: 14px;
      line-height: 20px;
    }

    @include breakpoint($bp-mobile-to-tablet) {
      padding: 30px;
    }
  }
  a {
    border-bottom: none;

    &:hover {
      text-decoration: none;
      border: none;
    }
  }
}

.section-title {
  @include breakpoint($bp-tablet) {
    display: none;
  }
}

.section-content {
  display: none;

  &.active {
    @include breakpoint($bp-tablet) {
      display: block;
      position: relative;
    }
  }
}

.v-tab-page-nav {
  display: none;
  list-style: none;
  text-align: center;

  @include breakpoint($bp-tablet) {
    display: block;
  }

  li {
    a {
      font-family: $cornell-sans-bold;
      font-size: 16px;
      line-height: 22px;
      color: $cornell-light-text;
      border-bottom: none;
      padding: 25px 0;
      display: block;
      border-bottom: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;

      &:hover {
        text-decoration: none;
        background: #f9f9f9;
      }

      &:active {
        background: #fff;
      }

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
      }
      &.active {
        background: #fff;
        color: $cornell-red;
        border-left: 1px solid #e6e6e6;
        border-right: none;
        border-left: 3px solid $cornell-bright-orange;
      }
    }
  }
}

.h-tab-page-nav {
  list-style: none;
  padding-left: 0;
  padding-top: 40px;

  > div > div > div > div {
    border-bottom: 1px solid #e3e3e3;
  }
}

// Section Hide/Shows
.section__show-hide {
  position: relative;
  @include clearfix();
}

.section__toggle {
  position: relative;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  color: $wcm-dark-orange;
  border: 1px solid $wcm-border-gray;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;
  &:after {
    content: "+";
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid $wcm-border-gray;
  }
  &:hover {
    background: #f7f7f7;
    color: $wcm-bright-orange;
  }

  h2 {
    font-family: inherit;
    font-size: 15px;
    line-height: 18px;
    color: $wcm-dark-orange;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}

.section__toggle--open {
  color: $wcm-black;
  &:not(.ctools-collapsible-container):after {
    content: "–";
    color: $wcm-dark-orange;
  }
  &:hover {
    color: $wcm-dark-orange;
  }
}

.section__hide {
  display: none;

  @include breakpoint($bp-tablet) {
    padding: 0 0 0 30px;
  }
}

.section__hide p {
  margin: 20px 0;
}

.section__hide--open {
  display: block;
}

.no-js {
  .element__hide {
    display: block;
  }
  .element__toggle:after {
    display: none;
  }
}

// Tooltip
.tooltip-icon {
  color: #aaa;
  font-size: 16px;
}

// https://codepen.io/deineko/pen/YZeQJP
/* tipped element. should be inline-block or block */
.qtip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #3bb4e5;
  box-sizing: border-box;
  font-style: normal;
  transition: all 0.25s ease-in-out;
}

/* the tip */
.qtip:before {
  content: attr(data-tip);
  min-width: 250px;
  text-align: left;
  color: #000;
  background: #fff;
  font-size: 14px;
  position: absolute;
  z-index: 100;
  padding: 15px 20px;
  @include border-radius(20px);
  @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.2));
  border: 3px solid #ddd;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0;
  font-weight: normal;

  @include breakpoint($bp-tablet) {
    min-width: 440px;
  }
}

.qtip:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.qtip:hover:before,
.qtip:hover:after {
  visibility: visible;
  opacity: 1;
}

/* top */
.qtip.tip-top:before {
  top: -3px;
  left: 10%;
  transform: translate(-10%, calc(-100% - 8px));
  box-sizing: border-box;
}

.qtip.tip-top:after {
  border-width: 15px 15px 0 15px;
  border-color: #fff transparent transparent transparent;
  top: -15px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0);
}

/* bottom */
.qtip.tip-bottom:before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 8px));
  box-sizing: border-box;
  border-radius: 3px;
}

.qtip.tip-bottom:after {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent rgba(10, 20, 30, 0.85) transparent;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);
}

/* left */
.qtip.tip-left:before {
  left: 0;
  top: 50%;
  transform: translate(calc(-100% - 15px), -50%);
  box-sizing: border-box;
  border-radius: 3px;
}

.qtip.tip-left:after {
  border-width: 15px 0 15px 15px;
  border-color: #fff transparent transparent transparent;
  left: -15px;
  top: 50%;
  z-index: 100;
  transform: translate(0, -50%);
}

/* right */
.qtip.tip-right:before {
  right: 0;
  top: 50%;
  transform: translate(calc(100% + 8px), -50%);
  box-sizing: border-box;
  border-radius: 3px;
}

.qtip.tip-right:after {
  border-width: 8px 8px 8px 0;
  border-color: transparent rgba(10, 20, 30, 0.85) transparent transparent;
  right: -8px;
  top: 50%;
  transform: translate(0, -50%);
}

// Map

#map-canvas {
  border: 1px solid #aaaaaa;
  width: 96%;
  @include breakpoint($bp-tablet) {
    width: 92%;
  }
  height: 210px;
  margin: 0 auto 5px;
}

.panel-2col-right #main-content {
  width: 100%;
  padding: 0 0px 45px 0;
  @include breakpoint($bp1) {
    @include column(8);
    padding: 0 15px 45px 0;
  }
}

.panel-2col-right #sidebar-second {
  width: 100%;
  padding: 0;
  float: left;
  @include breakpoint($bp1) {
    @include column(4);
    padding: 0 0 0 15px;
  }
}

.hero-image figcaption {
  margin: 0 10px 1em;
  text-align: right;
  font-style: italic;
  @include breakpoint($bp1) {
    margin: 0 0 1em;
  }
}

.view-teaser {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $wcm-border-gray;
  img.panopoly-image-half {
    float: right;
    width: 40%;
    max-width: 40%;
    margin: 0.5em 0 0.5em 0.75em;
  }
  .post-date {
    color: $wcm-med-gray;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 0;
    font-family: $cornell-sans-bold;
    font-size: 13px;
    letter-spacing: 2px;
  }
}

.front .view-news,
.news-teasers-pane {
  .view-teaser {
    position: relative;
    padding-bottom: 0;
    overflow: hidden;

    a {
      display: inline-block !important;
      margin: 5px;
      width: 100%;
    }

    .news-link {
      position: relative;
      background: $wcm-red;
      width: 100%;
      height: 191px;
      text-align: center;
      top: 0;
      color: #fff;

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
      }

      .news-link-content {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 0 2em;

        @include box-sizing();

        @include breakpoint($bp1) {
          padding: 0 4em;
        }

        h2 {
          font-size: 1.5rem;
          line-height: 1.8rem;
          padding-bottom: 0;
          margin-top: 0;
          color: #fff;

          @include breakpoint($bp2) {
            font-size: 1.8rem;
            line-height: 2rem;
          }

          a {
            color: #fff;
          }
        }

        .icon-link {
          display: inline-block;
          text-indent: -9999px;
          background: url(../images/news-linkout.png) no-repeat;
          width: 27px;
          height: 28px;
          margin-top: 5px;
        }
      }
    }
  }
}

// Hero News Tiles
.news-hero-tiles {
  .views-row-1 {
    width: 100%;
    float: left;

    .views-field-field-featured-image {
      @include breakpoint($bp-mobile-only) {
        height: 240px;
        overflow: hidden;
      }

      @include breakpoint($bp-tablet) {
        padding-right: 30px;
      }

      @include breakpoint($bp-desktop) {
        height: 400px;
        overflow: hidden;
      }

      @include breakpoint($bp-desktop-large) {
        height: 430px;
        overflow: hidden;
      }
    }

    img {
      width: 100%;
    }

    @include breakpoint($bp-tablet) {
      width: 70%;
    }
  }

  .views-row-2,
  .views-row-3 {
    width: 100%;
    float: left;

    @include breakpoint($bp-mobile-only) {
      display: none;
    }

    .views-field-field-featured-image {
      @include breakpoint($bp-desktop) {
        height: 165px;
        overflow: hidden;
      }

      @include breakpoint($bp-desktop-large) {
        height: 180px;
        overflow: hidden;
      }
    }

    @include breakpoint($bp-tablet) {
      width: 30%;
    }
  }

  .views-row {
    .views-field-title {
      margin: 10px 0;

      @include breakpoint($bp-mobile) {
        min-height: 50px;
      }

      a {
        font-size: 12px;
        line-height: 16px;
        color: $cornell-red;
        font-family: $cornell-sans-bold;
        border-bottom: none;

        @include breakpoint($bp-desktop-large) {
          font-size: 16px;
          line-height: 22px;
        }

        &:hover {
          text-decoration: underline;

          &:after {
            text-decoration: none;
          }
        }

        &:after {
          content: " \e802";
          text-decoration: none;
          font-family: "fontello";
          font-size: 80%;
          padding-left: 5px;
          color: $cornell-bright-orange;
        }
      }
    }
  }
}

// News page.

.page-news {
  #main h1 {
    margin-top: 20px;
    font-size: 40px;
    line-height: 48px;
    font-weight: normal;
  }
}

// Colorful news tiles.
.news-teasers-pane {
  .view-teaser {
    border-bottom: none;
  }
}

.view-news {
  .teaser-text {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 15px;
  }
}

.pager li > a,
.pager li > span {
  border-radius: 20px;
}

/* Practice Profiles */
// updated 2020-02-25
.node-type-practice {
  // Accessibility fix
  h4 a {
    border-bottom: 1px dotted #b31b1b !important;

    &:after {
      content: none;
    }
  }

  .simple-news-listing {
    margin-bottom: 30px;
  }

  .bx-wrapper .bx-controls-direction {
    a {
      width: 30px;
      height: 40px;
      margin-top: -25px;
    }

    .bx-prev {
      left: -30px;
      background: url(../images/control-arrows.png) no-repeat 6px 4px;
      background-size: 40px;
      border-bottom: none;
    }

    .bx-next {
      right: -20px;

      @include breakpoint($bp-mobile-only) {
        right: -30px;
      }

      background: url(../images/control-arrows.png) no-repeat -16px 4px;
      background-size: 40px;
      border-bottom: none;
    }
  }

  #main {
    width: 100%;
    padding: 0;
    max-width: inherit;

    h1 {
      padding-top: 0;
    }
  }

  .content h1 {
    color: #fff;
    border-bottom: none !important;
    margin-top: 5px;
  }

  .pane-node-body h3 {
    margin-bottom: 0;
    font-size: 1.5em;
  }

  h3.pane-title {
    margin-bottom: 0;
  }

  .pane-page-title {
    float: left;
    width: 100%;
    position: relative;

    .page-title-services {
      position: relative;
      background: rgba(0, 0, 0, 0.75);
      padding: 1em;

      .social-icons {
        position: absolute;
        left: -40px;
        width: 45px;
        top: 0;
        display: none;
        font-size: 1rem;
        color: #fff;
        text-align: center;

        @include breakpoint($bp-tablet) {
          display: block;
        }

        a {
          display: block;
          margin-bottom: 5px;
          width: 40px;
          height: 32px;
          background: url(../images/WCM-org_mobile_share_icons_dark.png) no-repeat top center;
          background-size: 32px;
          border-bottom: none;

          &:hover {
            border-bottom: none;
          }

          &.social-tw {
            background-position: center -33px;
          }
          &.social-ln {
            background-position: center -66px;
          }
          &.social-email {
            background-position: center -99px;
          }

          &:after {
            content: "";
          }

          img {
            display: none;
          }
        }
      }
    }

    .clinical-services {
      color: #fff;
      font-size: 1.6rem;

      a {
        font-weight: 700;
        color: #fff;
      }
    }
  }

  .has-background {
    .page-title-services {
      .social-icons {
        left: -50px;
        top: -4px;

        a {
          background-image: url(../images/WCM-org_mobile_share_icons.png);
        }
      }
    }

    @include breakpoint($bp-mobile) {
      height: 520px;
    }
  }

  .bx-wrapper .bx-controls-direction {
    .bx-prev,
    .bx-next {
      background-image: url("../images/control-arrows-orange.png");
    }
  }

  .no-background {
    height: initial;

    .page-title-services {
      background: none;
      margin-bottom: 20px;
      border-bottom: 1px solid $light-gray;
      padding: 0 0 15px 0;

      h1 {
        color: $wcm-red;
      }

      .clinical-services {
        color: #555;

        strong {
          color: $wcm-red;
        }
      }

      .social-icons {
        left: -50px;
        top: -17px;
      }
    }

    .clinical-services a {
      color: $wcm-red;
    }
  }

  #media-slider {
    list-style: none;

    li {
      float: left;
      max-height: 100px;
      overflow: hidden;

      a > img {
        border-radius: 0;
        width: 170px;
      }
    }
  }

  .pane-node-body {
    clear: both;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  #insurances,
  #honors-and-awards,
  #education,
  #accordion {
    clear: both;

    font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, sans-serif;

    .accordion-panel {
      display: none;
      overflow: auto;

      .education ul li {
        list-style-position: inside;
      }
    }
  }

  .information-blocks {
    position: relative;

    @extend .clearfix;

    .information-block {
      text-align: center;
      @include box-sizing();
      border: 1px solid $cornell-light-gray;

      @include breakpoint($bp-mobile) {
        width: 50%;
        float: left;

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      img {
        width: 20%;
        float: left;

        @include breakpoint($bp-mobile) {
          width: auto;
          float: none;
        }
      }

      h2 {
        padding-top: 1.5em;
        padding-bottom: 0;
        font-size: 1.5rem;
        color: #000;
        float: left;
        padding-left: 1em;

        @include breakpoint($bp-mobile) {
          float: none;
          padding-top: 0.5em;
          padding-left: 0;
          line-height: inherit;
        }
      }

      a {
        @extend .clearfix;
        padding: 1em;
        @include breakpoint($bp-mobile) {
          padding: 2em;
        }
        display: block;
        outline: none;
        color: black;

        &:hover {
          text-decoration: none;
        }

        &:focus {
          outline: 5px auto -webkit-focus-ring-color;
        }
      }
    }
  }

  .block-header {
    background-color: #ddd;
    text-align: center;
    padding: 1.5em 1em;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.7rem;
    clear: both;

    .btn-close {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 16px;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        height: 6px;
        width: 100%;
        margin: auto 0;
        left: 0;
        background-color: $wcm-dark-orange;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 6px;
        height: 100%;
        margin: 0 auto;
        background-color: $wcm-dark-orange;
      }
    }
  }

  .pane-wcmc-practice-information {
    position: relative;
    margin: 2em 0;
    clear: both;
    overflow: hidden;
  }

  // Practice Media
  .practice-media-outer-wrapper {
    margin-bottom: 30px;

    .video-outer-wrapper {
      @include breakpoint($bp-desktop) {
        padding: 0 30px;
      }

      p {
        margin-top: -45px; // aesthetic fix due to extra <br> that is injected
      }
    }

    .video-wrapper {
      margin: 30px 0;
    }

    .photo-wrapper {
      margin: 20px 0;
      max-width: 100%;
      padding: 0;

      @include breakpoint($bp-desktop) {
        padding: 0 30px;
      }
    }
  }

  .practice-content-block {
    background-color: #f7f7f7;
    display: block;
    width: 100%;
    position: relative;

    .practice-information-content {
      padding: 10px 15px 15px;
      @include font-size(1.3);

      p {
        font-size: inherit;
      }
      .faq_question {
        font-weight: 700;
      }
    }
  }

  #our-physicians {
    @extend .clearfix;

    &:after {
      content: "";
      display: block;
    }

    fieldset {
      width: 100%;
    }

    .filters {
      .select2 {
        width: 80% !important;
        margin: 0 auto;
        display: block;

        &:last-child {
          margin-top: 20px;
        }
      }
    }

    .physician_filter {
      clear: both;
      width: 80%;
      margin: 10px auto;
    }
  }

  .accordion-expand-holder {
    clear: both;
    float: right;
    margin-bottom: 5px;

    a {
      font-weight: bold;
      color: #000;
      cursor: pointer;
      float: none;
      font-size: inherit;
      opacity: inherit;
      text-shadow: inherit;

      border-bottom: none;

      &:hover {
        border-bottom: none;
      }
    }
  }

  .ui-widget-content,
  .accordion-panel {
    border: none;
    background: none;
    margin: 1em 1.5em;
    clear: both;
    position: relative;
    width: 93%;
  }

  .pane-node-field-biographical-info {
    margin-top: 2em;
  }
  .pane-node-field-educational-background {
    padding-top: 0;
  }

  #main-content-header {
    padding: 0.5em 0;
    background-color: #f7f7f7;
    position: relative;
    width: 100%;
    z-index: 5;

    &.sticky {
      position: fixed;
      top: 0;
      padding-bottom: 1em;
    }

    .content {
      max-width: 960px;
      margin: 0 auto;
      padding: 0 10px;

      .pane-wcmc-display-name {
        float: left;
        h1 {
          font-family: $cornell-sans-bold;
          padding-top: 5px;
          padding-bottom: 0;
        }
      }
      .pane-node-field-primary-specialty {
        .field .field-label {
          font-weight: normal;
        }
        .field .field-item {
          font-weight: bold;
        }

        @include breakpoint($bp-tablet) {
          float: right;
        }
      }
    }
  }

  #main-content {
    margin: 0 auto;
    padding: 0;

    .main-content-bg {
      background-size: cover;
      max-height: 200px;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;

      @include breakpoint($bp-mobile) {
        max-height: 500px;
      }
    }

    .content-container {
      max-width: 970px;
      margin: 0 auto;
      padding: 1em 10px 0;
      position: relative;

      @include breakpoint($bp-desktop-large) {
        max-width: 1180px;
      }
    }

    .content {
      position: relative;
      @include breakpoint($bp-tablet) {
        width: 100%;
        max-width: 585px;
        @include box-sizing();
      }

      @include breakpoint($bp-desktop-large) {
        max-width: 780px;
      }
    }

    .provider-locations-container {
      position: relative;
      clear: both;
      padding-top: 20px;

      @include breakpoint($bp-tablet) {
        padding-top: 0;
      }
    }

    .provider-locations {
      @include breakpoint($bp-tablet) {
        position: absolute;
        width: 58%;
        right: -62%;
      }

      @include breakpoint($bp-desktop-large) {
        right: -380px;
        width: 340px;
      }

      .jump-to-top {
        clear: both;
        a {
          height: 70px;
        }
      }
    }

    #physicians-home {
      .providers-image {
        @include breakpoint($bp-mobile) {
          float: left;
          margin: 2px 20px 10px 0;
          //width: fit-content;
        }
        text-align: center;
        margin-bottom: 10px;

        .social-icons {
          a {
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }

    .provider-languages,
    .provider-gender,
    .provider-accepting-new-patients {
      @include breakpoint($bp-mobile) {
        float: left;
        width: 50%;
      }
      font-weight: 700;
      margin-bottom: 0.5em;

      span {
        font-weight: 400;
      }
    }

    .anchor-btns {
      float: left;
      margin-bottom: 3em;
      @include breakpoint($bp-mobile) {
        width: 42%;
        margin-bottom: 0;
      }
    }
    .provider-anchor-btn {
      border: 1px solid #fff;
      border-bottom: none;
      padding: 0.7em 1em;
      float: left;
      width: 100%;
      text-align: left;
      font-size: 1.7rem;
      color: #fff;
      font-weight: 700;
      text-decoration: none;
      box-sizing: border-box;

      &:last-child {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .has-background {
    .pane-wcmc-practice-locations {
      padding-top: 200px;

      @include breakpoint($bp-mobile) {
        padding-top: 507px;
      }
    }
  }
}

// Header Style B
.header-style-b {
  background: #f7f7f7;
  margin-bottom: 30px;

  .row {
    @include breakpoint($bp-tablet) {
      display: flex;
    }
  }

  .hero-background {
    background-size: cover;
    background-position: center;
    min-height: 140px;

    @include breakpoint($bp-desktop) {
      min-height: 280px;
    }
  }

  .hero-info-wrapper {
    padding: 30px 20px;
    text-align: center;

    @include breakpoint($bp-desktop) {
      float: right;
      padding: 60px 0;
      margin-right: 30px;
      width: 440px;
      text-align: left;
    }

    @include breakpoint($bp-desktop-large) {
      width: 540px;
    }

    h1 {
      font-size: 20px;
      line-height: 26px;
      color: #333;
      margin: 0 0 10px 0;
      padding: 0;

      @include breakpoint($bp-desktop) {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 20px 0;
      }
    }

    .blurb-text {
      font-size: 15px;
      line-height: 25px;
      margin: 30px 0;
    }

    .btn {
      margin-top: 10px;

      @include breakpoint($bp-desktop) {
        margin-top: 20px;
      }
    }
  }
}

.panel-home #main-content-container {
  @include breakpoint($bp-desktop-large) {
    max-width: 1180px;
  }
}

.panel-home #main-content {
  @include breakpoint($bp-desktop-large) {
    max-width: 1180px;
  }
}

.panel-home #sidebar-left {
  width: 100%;
  @include box-sizing();
  margin-bottom: 2em;
  @include breakpoint($bp1) {
    width: 50%;
    padding-right: 3%;
    margin-bottom: 0;
  }

  h3.pane-title {
    color: $cornell-light-text;
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.panel-home #sidebar-right {
  width: 100%;
  @include box-sizing();
  @include breakpoint($bp1) {
    width: 50%;
  }

  h3.pane-title {
    color: $cornell-light-text;
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.pane-carousel-panel-pane-1,
.pane-carousel-panel-pane-2 {
  @include breakpoint($bp-desktop) {
    width: 940px;
    margin: 0 auto 30px;
  }

  @include breakpoint($bp-desktop-large) {
    width: 1160px;
  }
}

.view-carousel {
  margin-bottom: 0em;

  .thumbnail-controller {
    border-top: none;
    padding: 10px 0 9px;

    .pager {
      margin: 20px 0;
      border-bottom: none;

      &:hover {
        border-bottom: none;
      }
    }
  }

  .thumbnail-controller-wrap {
    line-height: 1rem;
  }

  .slide-text {
    h2 {
      line-height: 2.3rem;
      padding-top: 15px;

      a:after {
        content: "";
        display: none;
      }
    }

    p {
      color: $cornell-white;
      padding-top: 14px;

      @include breakpoint($bp-desktop) {
        width: 65%;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  #thumbnail-pager a > img {
    height: initial;
  }

  .mobile-pager a {
    line-height: 1.5;
  }
}

/******************************/
.contact-accordion-pane + .panel-separator {
  margin: 0;
}

.contact-accordion-pane {
  ul {
    list-style: none;
  }
  h3 {
    position: relative;
    color: $wcm-dark-orange;
    border: 1px solid $cornell-light-gray;
    border-width: 1px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 25px 55px 15px 10px;
    font-size: 16px;

    &:after {
      content: "+";
      width: 50px;
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      border-left: 1px solid $cornell-light-gray;
    }

    &.ui-state-active {
      &:after {
        content: " -";
      }
    }
  }

  .pane-content,
  .fieldable-panels-pane {
    @include clearfix();
    display: none;
    position: relative;
    clear: both;
    width: 100%;
    padding: 0 15px 0 10px;

    a {
      color: #000;
      @include icon-after("\e802", $wcm-dark-orange);

      &:hover {
        border-bottom: none;
      }
    }
  }
}

/******************************/
.page-news,
.node-type-news-post {
  .panel-2col-right #main-content {
    float: none;
    display: block;
    @include breakpoint($bp1) {
      float: left;
      display: inline;
    }
  }

  .panel-2col-right #sidebar-second {
    float: none;
    @include breakpoint($bp1) {
      float: left;
    }
  }
}

.pane-views-panes {
  h3 a {
    @include icon-after("\e802", $wcm-bright-orange);

    &:after {
      padding-left: 7px;
      line-height: 24px;
    }
  }
}

.node-type-news-post {
  .pane-node-field-news-clinical-service,
  .pane-node-field-news-practice,
  .pane-node-field-news-provider {
    h3.pane-title {
      color: #000;
      font-size: 16px;
    }

    a {
      display: block;
      width: fit-content;
      text-decoration: underline;
      border-bottom: none;
      line-height: 20px;
      margin-bottom: 5px;

      &:before {
        content: "\2022 ";
        padding-right: 5px;
        display: inline-block;
        width: 10px;
        margin-left: -10px;
        font-weight: bold;
        color: #000;
      }

      &:hover,
      &:active {
        &:before {
          text-decoration: none;
        }
      }
    }

    .field-news-clinical-service,
    .field-news-practice,
    .field-news-provider {
      padding-left: 10px;
      margin-left: 10px;
    }
  }

  .pane-node-field-news-category {
    display: none; // hiding temporarily until usage of categories improves
  }

  .pane-node-field-news-category .field-name-field-news-category h3 {
    float: none;
  }

  .news-sidebar-header {
    h3 {
      padding-bottom: 0;
    }
  }

  .post-date {
    color: $wcm-med-gray;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 12.5px;
    font-family: $cornell-sans-bold;
    font-size: 13px;
    letter-spacing: 2px;
  }

  .btn-view-all-news {
    margin: 15px auto;
    width: 100%;
  }

  .pane-node-field-related-news-links {
    margin: 40px 0;
    padding: 10px 20px 20px;
    border: 1px solid #e7751d;

    .field-related-news-links {
      padding-left: 20px;
    }

    h3.pane-title {
      text-transform: uppercase;
      color: #666;
      font-family: $cornell-sans-bold;
      font-size: 13px;
      letter-spacing: 2px;
      padding-bottom: 10px;
    }
    a {
      display: block;
      line-height: 1.6;
      clear: both;
      width: fit-content;
      font-size: 15px;
      margin-bottom: 3px;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid;
      }

      &:before {
        content: "\2022";
        color: black;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  .media-vimeo-player {
    width: 100%;
  }
}

// News Teasers
.pane-news-panel-pane-1,
.full-news-listing {
  .view-teaser {
    h3 {
      margin-top: 0px;
      padding-left: 0px;
    }

    .teaser-text,
    .post-date {
      padding-left: 0px;
    }

    .panopoly-image-quarter {
      margin-right: 20px !important;
      float: right !important;
    }
  }
}

.btn--news {
  width: 100%;
}

.node-type-clinical-service {
  h1.title {
    margin-top: 0;
    font-size: 22px;

    @include breakpoint($bp-tablet) {
      font-size: 40px;
    }
  }

  .panel-2col-right #main-content {
    float: none;
    display: block;
    @include breakpoint($bp1) {
      float: left;
      display: inline;
    }
  }

  .panel-2col-right #sidebar-second {
    float: none;
    @include breakpoint($bp1) {
      float: left;
    }
  }

  .morecontent span {
    display: none;
  }

  .pane-node-field-service-description,
  .practice-list,
  .pane-node-field-clinical-services-media {
    ul {
      padding: 0 0 0 17px;
    }
  }

  .clinical-service-contact-info {
    @include clearfix();
    border-top: 1px solid $wcm-border-gray;

    .contact-icon {
      @include breakpoint($bp1) {
        display: inline-block;
        border-right: 1px solid $wcm-border-gray;
      }

      text-align: center;
      margin-top: 15px;
      padding: 0 15px;
      font-weight: 600;

      a {
        border-bottom: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .cta-icon {
        display: block;
        width: 35px;
        height: 35px;
        margin: 0 auto;
      }

      &:last-child {
        border-right: none;
      }
    }

    .icon-social {
      &:after {
        font-size: 35px !important;
      }
      &.icon-facebook {
        @include icon-after("\e804");
      }
      &.icon-twitter {
        @include icon-after("\e807");
      }
      &.icon-linkedin {
        @include icon-after("\e806");
      }
      &.icon-youtube {
        @include icon-after("\f16a");
      }
    }

    .icon-phone {
      background-image: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
      background-repeat: no-repeat;
      background-size: 1600%;
      background-position: -60px 5px;
      width: 64px;
      height: 64px;
      border: none;
      margin: 0 auto;
    }
  }

  .pane-node-field-clinical-services-media {
    .pane-title {
      margin-top: 30px;
    }

    p {
      margin-top: -15px;
    }
  }

  .pane-wcmc-clinical-service-events {
    .pane-title {
      padding-bottom: 0;
      margin-top: 30px;
    }
    .view-header {
      padding-bottom: 10px;
    }
  }

  .clinical-services-sidebar {
    display: none;
    margin-bottom: 2em;
    @include breakpoint($bp1) {
      display: block;
    }

    a {
      display: none;
      border-bottom: 1px solid #eee;
      padding: 10px 5px;
      background: #fff url(../images/wcm-org_jump_arrow.png) no-repeat 95% 50%;
      background-size: 15px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h3.pane-title {
    font-size: 1.5em;
  }

  #map-canvas {
    width: 100%;
    margin-bottom: 1em;
    height: 300px;
  }

  .practice-list-container {
    @include box-sizing();
    margin: 30px 0;
    padding-top: 15px;
    border-top: 1px solid #ddd;

    .practice-list {
      @include box-sizing();
      @include clearfix();

      .practice-links {
        list-style: none;
        padding-left: 0;

        li a {
          padding: 6px 20px 6px 5px;
          font-size: 14px;
          line-height: 26px;
          color: #666;
          display: block;
          position: relative;
          border-bottom: 1px solid #ddd;
          @include icon-after("\e802", $wcm-bright-orange);

          @include breakpoint($bp-tablet) {
            font-size: 18px;
          }

          &:after {
            position: absolute;
            top: 12px;
            right: 10px;
          }

          &:hover {
            color: $cornell-red;
            background: #fbfbfb;

            &:after {
              color: $cornell-red;
            }
          }
        }
      }

      .expand-btn {
        width: 50px;
        height: 33px;
        display: block;
        position: absolute;
        top: 24px;
        right: 0;
        font-size: 35px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        z-index: 2;
        color: $wcm-red;
        border-left: 1px solid $wcm-border-gray;

        &:after {
          content: "+";
        }

        &.state-active {
          &:after {
            content: " -";
          }
        }
      }

      .info-icons {
        float: right;
        line-height: 1;
        position: relative;
        top: -15px;

        a:hover {
          text-decoration: none;
        }
      }

      .icon-fap {
        background: url(../images/fap-icon.png) no-repeat center 5px;
        width: 27px;
        height: 27px;
      }

      .ss-info {
        float: none;
        font-size: 4rem;
        line-height: 5rem;
        color: $wcm-dark-orange;
        margin-right: 5px;
        &:hover {
          background: none;
          color: $wcm-dark-orange;
          text-decoration: none;
        }
      }

      span {
        clear: both;
        float: right;
        color: $wcm-black;
        width: auto;
        background-color: transparent;
      }
    }
  }

  .provider-locations-container {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 2em;
    @include clearfix();

    .map-larger-view {
      position: absolute;
      top: 20px;
      left: 8px;
      background: #fff;
      z-index: 2;

      a {
        padding: 0.3em 1em;
        display: block;
        border-bottom: none;
      }
    }

    .view-more-locations {
      clear: both;
      text-align: center;
      margin-top: 1em;
    }

    .provider-locations-wrapper {
      @include box-shadow(none);
    }

    .address-location {
      padding: 10px 10px 0 0;
      margin: 0;
      clear: none;

      &.address-location-hidden {
        display: none;
      }

      @include clearfix();
      @include breakpoint($bp1) {
        width: 49%;
        float: left;

        &:nth-child(odd) {
          clear: left;
        }

        &:nth-child(even) {
          border-left: 1px solid $wcm-border-gray;
          padding-left: 10px;
        }
      }

      padding-bottom: 10px;
      padding-top: 10px;

      .practice-count {
        float: left;
        width: 10%;
        padding: 2px 0;
        font-size: 2.5rem;
        line-height: 3rem;
        color: $cornell-bright-orange;
        margin-top: 0;
      }

      .practice-info {
        float: left;
        width: 90%;
      }

      .practice-address {
        width: 95%;
      }

      .practice-name {
        h4 {
          margin-top: 0;
          padding: 0 0 3px;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
        }

        a {
          color: #000;

          &:after {
            content: " \e802";
            text-decoration: none;
            font-family: "fontello";
            font-size: 70%;
            padding-left: 5px;
            line-height: 20px;
          }

          &:hover {
            text-decoration: underline;
          }

          &:active {
            color: $cornell-light-text;
          }
        }
      }
    }
  }
}

.node-type-clinical-service,
.node-type-practice,
.pane-wcmc-in-your-neighborhood-events {
  .localist-event {
    border-bottom: none;
    padding-bottom: 0;

    .date {
      background: none;
      width: 75px;
      height: auto;
      vertical-align: top;
      margin-bottom: 0;
      padding-bottom: 0;

      &:after {
        content: "";
        border: none;
      }

      .month {
        display: inline-block;
        font-weight: 700;
        text-transform: none;
        font-size: 1.7rem;
        margin-bottom: 0;
      }

      .day {
        display: inline-block;
        font-weight: 700;
        font-size: 1.7rem;
        margin-right: 10px;
        margin-bottom: 0;
      }

      .year {
        display: inline-block;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 1rem;
      }
    }

    .details-wrapper {
      padding: 0;
    }

    .event-title {
      padding: 5px 0 0 0;
      font-size: 14px;
      line-height: 2rem;
      vertical-align: top;
    }
  }
}

.pane-wcmc-practice-events {
  .view-header {
    padding-bottom: 10px;
  }
}

.simple-news-listing {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;

  .pane-title {
    padding-bottom: 0;
    margin-top: 30px;
  }

  .view-teaser {
    border-bottom: none;
    padding-bottom: 7px;
    margin-bottom: 7px;
  }

  .teaser-title {
    font-size: 1.4rem;

    a {
      color: inherit;
      border-bottom: none;
      @include icon-after("\e802", $wcm-bright-orange);

      &:hover {
        text-decoration: underline;
      }

      &:after {
        font-size: 12px;
        padding-left: 6px;
      }
    }
  }

  .views-field-created {
    color: $cornell-light-text;
  }

  .view-header {
    padding-bottom: 10px;
  }
}

$orange: #f6a45d;

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ctnr {
  display: flex;
  background-color: $orange;
}

.ldr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: 2.5em;
  height: 2.5em;
}

.ldr-blk {
  height: 35%;
  width: 35%;
  animation: pulse 0.75s ease-in infinite alternate;
  background-color: darken($orange, 15%);
}

.an_delay {
  animation-delay: 0.75s;
}

.fancybox-nav {
  top: 25%;
  height: 50%;
  border-bottom: none;

  &:hover {
    border-bottom: none;
  }
}

#fancybox-buttons {
  a {
    border-bottom: none;

    &:hover {
      border-bottom: none;
    }
  }
}

// Anchor Menu

#main .anchor-menu {
  font-size: 12px;
  line-height: 18px;
  padding: 10px 0 10px 20px;

  @include breakpoint($bp1) {
    line-height: 20px;
    font-size: 14px;
  }

  li {
    padding-bottom: 7px;

    @include breakpoint($bp1) {
      padding-bottom: 10px;
    }
  }
}

// Second Opinion
.second-opinion-home {
  text-align: left;
  background: url(../../../../../sites/default/files/pages/wcm-on-demand-second-opinion.jpg) no-repeat 30% 40%;
  background-size: cover;
  background-color: #ffc72c;
  background-blend-mode: multiply;
  color: #fff;
  position: relative;
  height: 320px;

  h3 {
    color: #fff;
    font-size: 2.8rem;
  }

  p {
    line-height: 2rem;

    &:after {
      content: " \e802";
      font-size: 14px;
      color: #ffc72c;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none;
      display: inline-block;
      padding-left: 5px;
    }
  }

  @include breakpoint($bp-desktop) {
    border-left: 1px solid #fff;
    width: 35%;
    float: left;
    height: 289.5px;
  }

  .second-opinion-link:hover {
    text-decoration: none;
  }

  .second-opinion-text {
    background: rgba(178, 21, 5, 0.8);
    padding: 5px 20px 0px 25px;
    position: absolute;
    bottom: 10px;
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

// New Header Component
.header-component {
  background-image: url(../images/breast-cancer-concierge.jpg);
  min-height: 500px;
  height: auto;
  width: 100%;
  position: relative;
  margin: auto;
  background-size: cover;
  background-position: center;

  .header-panel {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px 0 20px;
    overflow: hidden;

    @include breakpoint($bp-mobile-only) {
      padding-bottom: 50px;
    }

    h1,
    p {
      color: white;
      margin: 0;
      padding: 0;
      text-align: center !important;
      line-height: 1;
      isolation: isolate;
      position: relative;
      z-index: 100;
      line-height: 1.5;
      margin: 10px 100px 20px 100px;

      @include breakpoint($bp-desktop) {
        width: 1180px;
        margin: auto;
        padding: 10px 100px 10px 100px;
      }

      @include breakpoint($bp-tablet-only) {
        margin: 20px !important;
      }

      @include breakpoint($bp-mobile-only) {
        padding: 10px 20px 10px 20px;
        margin: 0;
      }
    }

    p {
      font-size: 16px;
      padding-bottom: 50px;

      @include breakpoint($bp-mobile-only) {
        padding-bottom: 80px;
      }
    }

    &:before {
      background-color: #cf4520;
      mix-blend-mode: multiply;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 10;
      margin-left: calc(-50vw + 50%);
    }
  }

  .header-info {
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f7f7f7;
    z-index: 100;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    //temp
    @include breakpoint($bp-desktop-large) {
      width: 1180px;
    }

    @include breakpoint($bp-desktop-only) {
      width: 980px;
    }

    @include breakpoint($bp-mobile-only) {
      width: 80%;
      flex-direction: column;
      align-items: center;
      width: auto;
      border: 1px solid #dddddd;
    }

    &:after {
      border-bottom: 1px solid #dddddd;
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 80%;

      @include breakpoint($bp-mobile-only) {
        content: none;
      }
    }

    .phone {
      width: 200px;
      text-align: center;
      padding: 10px;
      font-size: 18px;

      @include breakpoint($bp-mobile-only) {
        font-size: 16px;
        padding-top: 20px;
      }
    }

    .schedule {
      width: 200px;

      @include breakpoint($bp-mobile-only) {
        width: auto;
      }

      .btn-primary {
        border-radius: 5px;
        background-color: $cornell-red;
        border-color: $cornell-red;
      }
    }
  }
}

// Updated Nav / Header

.site-main {
  margin: 5px 0 0;
}

.wcm-global-links {
  font-size: 15px;
  line-height: 25px;
}

.wcm-global-links li:nth-child(1) {
  background: #fff;

  a {
    color: #cf4520;
    border: none;

    &:focus {
      color: #fff;
    }
  }
}

// Footer Navigation

footer {
  padding: 0;
}

.footer-nav {
  width: auto;
  float: none;
  padding: 0;
  margin: 0;
  text-align: left;

  @include breakpoint($bp-desktop) {
    width: 80%;
    float: left;
    padding-left: 20px;
    margin: 0 0 20px;
  }

  a.external-link:after {
    content: "";
  }
}

.footer-nav .level-1 {
  // temporary fix for mobile until accordions are adjusted:
  // border-bottom: 1px solid $wcm-border-gray;
  // margin: 0 0 10px;

  @include breakpoint($bp-desktop) {
    width: (100% / 4);
    float: left;
    margin: 0;
    padding: 0 10px 5px;
    border: none;
  }

  ul {
    display: block; // temporary fix for mobile until accordions are adjusted
  }
}

.footer-menu__toggle:after {
  width: 20px;
  height: 20px;
  content: "+";
  color: $wcm-dark-orange;
  font-weight: 700;
  float: right;
  font-size: 18px;
  line-height: 18px;

  @include breakpoint($bp-desktop) {
    display: none;
  }
}

.footer-menu__section--open .footer-menu__toggle:after {
  content: "–";
}

.footer-nav .level-1:last-child {
  border: none;
}

.footer-nav .footer-menu__section {
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer-nav .level-2 {
  padding: 10px 0 5px;
}

.footer-nav .level-3 {
  padding: 0 0 5px;
}

.footer-site-info,
.footer-nav,
.footer-links {
  a:hover {
    color: #cf4520;
  }
}

//Campaign Callouts
.campaign-container {
  margin-bottom: 40px;

  .campaign-title {
    text-align: center;
    font-size: 2.6rem;
    line-height: 2rem;

    @include breakpoint($bp-tablet) {
      font-size: 3rem;
      line-height: 3.6rem;
    }
  }

  .teaser-text {
    font-size: 18px;
    width: 90%;
    margin: 0 auto 20px;
    text-align: center;

    @include breakpoint($bp-desktop) {
      width: 65%;
    }
  }

  .campaign-image {
    position: relative;
    overflow: hidden;
    height: 250px;

    @include breakpoint($bp-tablet) {
      height: 450px;
    }

    img {
      @include transition(all 0.3s ease);
    }

    &:hover {
      img {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }

    .campaign-link {
      display: block;
      height: 100%;

      &:before {
        background: none;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        -webkit-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .campaign-box1,
  .campaign-box2,
  .campaign-box3,
  .campaing-box4 {
    @include breakpoint($bp-mobile-only) {
      padding: 10px;
    }

    @include breakpoint($bp-tablet-only) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .campaign-box1,
  .campaign-box4 {
    @include breakpoint($bp-tablet-only) {
      padding-bottom: 20px;
    }

    .campaign-image {
      .campaign-link {
        background-image: url(https://weillcornell.org/sites/default/files/styles/panopoly_image_original/public/obstetrics_and_gynecology.jpg?itok=T6m5D4VO);
        background-size: cover;

        &:before {
          background: none;
        }
      }

      &:hover {
        .campaign-link {
          -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }

    .content-container {
      position: absolute;
      bottom: 0;
      z-index: 100;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      padding: 15px;
      background: white;
      margin: 20px;
      width: initial;
      transform: none;
    }

    h2,
    p,
    a {
      color: white;
      position: relative;
      z-index: 100;
    }

    a {
      font-size: 14px;
    }

    span {
      color: #333;
      position: relative;
      z-index: 0;
      margin: 0;
      padding: 0;
      font-size: 28px;
      line-height: 32px;
      font-family: "1898Sans-Bold", sans-serif;

      &:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 70%;
        padding-left: 5px;
        line-height: 24px;
        color: #e7751d;
      }
    }
  }

  .campaign-box4 {
    span {
      font-size: 16px;

      &:after {
        display: none;
      }

      &:nth-of-type(2) {
        font-family: $cornell-sans-regular;
      }
    }
  }

  .campaign-box2 {
    height: 450px;
    background: #f7f7f7 !important;
    text-align: center;

    .youtube-wrapper {
      height: 50%;
    }

    p {
      text-align: center;
      line-height: 1rem;
      padding: 5px;
    }

    h3 {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .schedule {
      margin-top: 20px;
    }

    .media-youtube-player {
      height: 100%;
      width: 100%;
    }

    .featured-review {
      height: 40%;
      background-color: #ffc62d;

      .review-content {
        font-size: 14px;
        line-height: 28px;
        padding: 20px calc(100% / 10);
        padding-bottom: 5px;

        @include breakpoint($bp-desktop) {
          font-size: 18px;
        }
      }
    }

    .more-content {
      padding: 5px;
      position: relative;
      height: 40%;
      width: 100%;
    }

    .review-quote-circle {
      font-size: 12em;
      display: inline-block;
      border: 0.02em solid white;
      background-color: white;
      position: absolute;
      -webkit-border-radius: 0.35em;
      border-radius: 0.35em;
      margin-bottom: 15px;
      width: 0.3em;
      height: 0.3em;
      margin-left: 20px;
      z-index: 100;

      .featured-review-open-quote {
        left: 7px;
        top: -35px;
      }

      .img-quote-icon {
        height: 30px;
        width: 30px;
      }
    }

    .full-story {
      background: white;
      position: relative;
      height: 70%;
      bottom: 0px;
      padding: 10px;
      width: 90%;
      margin: auto;
      margin-top: 10px;

      a {
        float: right;
      }
    }
  }

  .campaign-box3 {
    .content-container {
      h2 {
        color: #fff;
      }

      p {
        display: inline-block;
        width: 80%;
        border-right: 1px solid #dddddd;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0px;
      }

      background: #cf4520;
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 100;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      padding: 10px 0 10px 10px;

      .right-arrow {
        float: right;

        &:after {
          content: "\e802";
          width: 40px;
          height: 50px;
          color: #fff;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          font-size: 20px;
          text-align: center;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline;
          text-decoration: inherit;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 50px;
          display: inline;
          float: right;
          -webkit-transition: background-color 0.25s;
          transition: background-color 0.25s;

          @include breakpoint($bp-desktop) {
            width: 50px;
          }
        }

        &:hover {
          &:after {
            margin-right: -3px;
            transition: 0.3s ease-in;
          }
        }
      }
    }
  }
}

.fancybox-transcript {
  color: #b31b1b;

  &:hover {
    color: #b31b1b;
  }
}

// Metrics / Statistics Call-Out
.metric-section {
  position: relative;
  display: block;
  padding: 20px 0 0;
  margin: 20px 0;

  .metric {
    text-align: center;
    margin: 0 0 20px;

    @include breakpoint($bp-tablet) {
      min-height: 120px;
      border-right: 1px solid #ddd;

      &:nth-child(2) {
        border: none;
      }
    }

    @include breakpoint($bp-desktop) {
      min-height: 130px;

      &:nth-child(2) {
        border-right: 1px solid #ddd;
      }
    }

    &:last-of-type {
      border: none;
    }
  }

  .metric__value {
    font-size: 48px;
    line-height: 1;
    display: inline-block;
    font-family: $cornell-sans-regular;
    color: $cornell-bright-orange;

    @include breakpoint($bp-desktop-large) {
      font-size: 64px;
    }

    @include breakpoint($bp-tablet-only) {
      font-size: 48px;
    }
  }

  .metric__description {
    font-size: 18px;
    line-height: 25px;
    font-family: $cornell-sans-bold;
    color: #666;
    padding: 10px 0;

    @include breakpoint($bp-desktop) {
      padding: 10px 20px;
    }
  }
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: $cornell-light-text;
}

// Image Zoom
.zoomy-boi {
  overflow: hidden;

  img {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

.zoomy-boi-max {
  overflow: hidden;

  img {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  &:hover {
    img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

// News Panes

// Featured News
.pane-news-pane-featured-news,
.pane-news-pane-featured-news-configurable {
  .view-content {
    display: inline-block;
  }

  .featured-news-wrapper .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  h3.pane-title {
    font-family: "1898Sans-Bold", sans-serif;
    color: #666;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center;
  }

  hr {
    width: 100px;
    border-top: 2px solid #ddd;
    margin: 15px auto;
  }

  .view-header {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 25px;
    text-align: center;
  }

  .teaser-title {
    margin-bottom: 20px;

    @include breakpoint($bp-desktop) {
      margin-top: 0;
    }

    a {
      &:after {
        content: " \e802";
        text-decoration: none;
        font-family: "fontello";
        font-size: 80%;
        padding-left: 5px;
        line-height: 28px;
        color: $wcm-bright-orange;
      }
    }
  }

  .feature-image img {
    width: 100%;
  }

  .teaser-text {
    font-size: 15px;
    line-height: 25px;
  }

  .view-teaser {
    border: none;
  }
}

// News Component
.news-component {
  .teaser-image {
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  .field-content {
    a {
      font-size: 14px;
      line-height: 18px;
      margin: 0 !important;
      color: $cornell-dark-orange;
      font-weight: bold;
      padding-top: 10px;
      padding-right: 10px;

      @include breakpoint($bp-desktop) {
        font-size: 16px;
        padding-top: 15px;
        line-height: 24px;
      }

      &:hover {
        color: $wcm-red;
      }
    }

    p {
      text-align: center;
    }

    .callout-image {
      overflow: hidden;
      margin: 0 0 20px;

      img {
        width: 100%;
      }
    }
  }

  .date {
    display: none; // hiding temp
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #666;
    margin: 10px 0 10px 0;
    font-weight: bold;

    @include breakpoint($bp-desktop) {
      font-size: 16px;
      margin: 15px 0 15px 0;
    }
  }

  .view-footer {
    text-align: center;
  }

  .view-teaser {
    border: none;
  }
}

// Video Visit Callout Wrapper
.video-visit-callout-wrapper {
  padding: 20px;

  .video-visit-logo {
    width: 80%;
    margin-bottom: 10px;
  }

  .blurb {
    margin-bottom: 15px;
  }

  .phone-wrapper {
    margin-bottom: 20px;
    font-size: 24px;

    a {
      color: #000;
      text-decoration: none;
      border-bottom: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.video-visit-icon.in-a-circle {
  height: 40px;
  padding: 9px 10px 10px 10px;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  border-radius: 50%;
  margin-right: 10px;
  float: left;

  img {
    width: 25px;
    height: 25px;
  }
}

/* This is to accomodate for the relative positioning of the sticky banner */
#page.compact {
  @include breakpoint($bp-desktop) {
    padding: 30px 0 0;
  }
  @include breakpoint($bp-mobile-only) {
    padding: 0;
  }
  @include breakpoint($bp-mobile-to-tablet) {
    padding: 0;
  }
  @include breakpoint($bp-tablet-only) {
    padding: 0;
  }
}

/* Keep the Pinned Header at the top of the page */
.navbar #pinned-header,
.navbar .sticky-banner,
.no-navbar #pinned-header,
.no-navbar .sticky-banner {
  position: relative;
  top: 0;
}
