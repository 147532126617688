// SCSS for the header
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/variables";

@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/mixins";


.top-bar-primary-action-items{
  .primary-action-icon{
    max-height: 7rem; //This is to prevent overflowing the wrapper, which height is set to 70px
    &.active{
      background-color: $wcm-red;
    }
  }
}