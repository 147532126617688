/* Weill Cornell Physicians Layout
----------------------------------------------------------------------------------*/

@import "./layout/header";
@import "./layout/footer";
@import "./mixins";
@import "./base";

/* =Mobile First
----------------------------------------------------------------------------------*/

header,
#main-menu,
#main-content,
#sidebar-first,
#sidebar-second {
  @include box-sizing();
}

header,
#main-menu,
#main,
.wrap {
  margin: 0 auto;
  display: block;
  float: none;
  @include box-sizing();
}

#finder {
  min-height: 30px;
  clear: both;
  background-color: #992800;
}

#page {
  background: #fff;
}

#main-menu {
  padding: 0;
}

#main {
  clear: both;
  background: #fff;
  overflow: hidden;
  min-height: 400px;
}

#main-content {
  padding: 0 10px 45px 25px;
}

#sidebar-first {
}

#sidebar-second {
}
